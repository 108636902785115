import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as saveAs from 'file-saver';
import { Endpoint } from 'src/app/models/Endpoint';
import { AuthService } from 'src/app/modules/auth';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UserPermissions } from 'src/app/shared/user_permissions';
import { Guard } from 'src/app/shared/utils/guard';
import { Role } from 'src/app/modules/auth';
import { ActivatedRoute, Router } from '@angular/router';
import {ConfirmPinComponent} from "../../confirm-pin/confirm-pin.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PageInfoService} from "../../../_metronic/layout";
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  loading: boolean;
  page = 1;
  page_error : boolean = false;
  query = '';
  items:any = [];
  totals:number = 0;
  pageSize : number = 10;
  profils:any;
  filterForm : any ;
  roles : any [] = [];
  searchText : string;
  searchTextNotifier  = new Subject();
  itemModel = new Role()
  itemId : number = 0
  filterModel:any = {};

  public role=UserPermissions.MANAGE_Default
  titlePage:any=[

    {
      title: 'Role',
      path: '/utilisateurs/list',
      isSeparator: true
   },
    {
      title: 'Liste',
      path: '/role/list',
      isSeparator: false
    }
  ]
  constructor(public modalService :NgbModal,  private pageInfo : PageInfoService,  private authService : AuthService, private router:Router,
    private fb : FormBuilder,
    private toastr : ToastrService,
    public guard: Guard) { }

  ngOnInit(): void {
    this.initFilter();
    this.pageInfo.updateTitle('Administrator');
    this.pageInfo.updateBreadcrumbs(this.titlePage);
    this.searchTextNotifier.pipe(debounceTime(250)).subscribe(text => this.search());
  }
  initFilter() {
    this.query = '';
    this.searchText = '';
    this.getList();

  }

  filterItem() {
    this.query = '';
    this.page = 1;
    if (this.filterModel.name) this.query += `&nom=${this.filterModel.name}`;
    if (this.filterModel.status) this.query += `&status=${this.filterModel.status}`;
    this.getList();

  }

  search($next : Boolean = false){
    if($next) this.searchTextNotifier.next('')
    else{
      if(this.searchText == ''){
        this.query = ''
      }
      else{
        this.query += `&search=${this.searchText}`;
        this.page = 1
      }
      this.getList();
    }
  }
  getList(){
    this.loading =true
    this.authService.getAll(Endpoint.ROLES_CARBURANT,this.page,this.query).subscribe((res:any)=>{
    console.log('ibintiss ', res['hydra:member']);
    this.items = res['hydra:member'];
    this.totals = res['hydra:totalItems'];
    this.loading =false;
    },error => {
      console.log('error',error)
      this.loading =false;
    })
  }
  changeStatus(id : any, item : any){

    const dialogRef = this.modalService.open(ConfirmPinComponent, {centered: true, size:"md"});
    dialogRef.componentInstance.data = {
      message: "Etes vous sûr de vouloir changer le statut.",
      title: "Changement statut"
    };
    dialogRef.result
      .then((res:any) => {
        if(res.confirmed) {
          this.loading = true;
          this.authService.upsert(id, Endpoint.ROLES, { status: !item.status })?.subscribe({
            next: (res : any) => {
              item.status = !item.status;
              this.toastr.success("Status modifié avec succés ")
              this.loading =false;
              console.log(res);
            },
            error: err => {
              console.log('error',err)
              this.toastr.error("Une erreur est survenue ")
              this.loading =false;
            }
          })
        }
        if (!res) {
          return;
        }
      });

  }



  paginate(p: number){
    console.log("event -> ", p)
    this.page = p
    this.getList()
  }

  pageChange(page:any){
    this.page=page;
    this.getList();
  }

  export(type = '') {
    this.loading = true;
    if (type == 'pdf') {
      const fileName = 'roles.pdf';
      this.authService.export(Endpoint.ROLES_PDF, this.query).subscribe((res:any) => {
          this.loading = false;
          saveAs(res.body, fileName);
        }, error => {
          this.loading = false;
        });
    } else {
      const fileName = 'roles.csv';
      this.authService.export(Endpoint.ROLES_CSV, this.query).subscribe((res:any) => {
          this.loading = false;
          saveAs(res.body, fileName);
        }, error => {
          this.loading = false;
        });
    }
    return;
  }
}
