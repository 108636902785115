
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <!--begin::Toolbar-->

        <!--end::Toolbar-->
        <!--begin::Post-->
        <div class="loaderPage" *ngIf="loading">
            <div class="lds-roller " >
              <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
          </div>
        <div class="post d-flex flex-column-fluid" id="kt_post">
            <!--begin::Container-->
            <div id="kt_content_container" class="container-xxl">
                <!--begin::Layout-->
                <div class="d-flex flex-column flex-lg-row">
                    <!--begin::Content-->
                    <div class="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                        <!--begin::Card-->
                        <div class="card card-flush pt-3 mb-5 mb-xl-10">
                            <!--begin::Card header-->
                            <div class="card-header">
                                <!--begin::Card title-->
                                <div class="card-title">
                                    <h2 class="fw-bolder" translate="GENERAL.DETAIL_TRANSACTION"> </h2>
                                </div>
                                <!--begin::Card title-->
                                <!--begin::Card toolbar-->
                                <!--end::Card toolbar-->
                            </div>
                            <!--end::Card header-->
                            <!--begin::Card body-->
                            <div class="card-body pt-3">
                                <!--begin::Section-->
                                <div class="mb-10">
                                    <!--begin::Title-->
                                    <h5 class="mb-4" translate="GENERAL.INFORMATION_TRANSACTION"></h5>
                                    <div class="d-flex align-items-center align-middle">
                                        <!--begin::Avatar-->

                                        <!--end::Avatar-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-column">
                                            <!--begin::Name-->
                                            <a href="#" class="fs-4 fw-bolder text-gray-900 text-primary me-2"translate="GENERAL.NUME_TRANSACTION"> <span  class="fs-4 fw-bolder text-primary me-2">  {{itemModel.numTransaction}}</span></a>
                                            <!--end::Name-->
                                            <!--begin::Email-->

                                            <!--end::Email-->
                                        </div>
                                        <!--end::Info-->
                                    </div>
                                    <!--end::Title-->
                                    <!--begin::Details-->
                                    <div class="d-flex flex-wrap py-5">
                                        <!--begin::Row-->
                                        <div class="flex-equal me-5">
                                            <!--begin::Details-->
                                            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                                                <!--begin::Row-->

                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400" translate="GENERAL.MONTANTE"></td>
                                                    <td class="text-gray-800 client">{{itemModel.montant | number}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-400" translate="GENERAL.SOLDEAVANT"></td>
                                                    <td class="text-gray-800 client">{{itemModel.soldeAvant | number}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.SOLDEAPRES"></td>
                                                    <td class="text-gray-800 client">{{itemModel.soldeApres | number}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.FRAIS"></td>
                                                    <td class="text-gray-800 client">{{itemModel.frais}}</td>
                                                </tr>

                                                <!--end::Row-->
                                                <!--begin::Row-->



                                                <!--end::Row-->
                                            </table>
                                            <!--end::Details-->
                                        </div>
                                        <!--end::Row-->
                                        <!--begin::Row-->
                                        <div class="flex-equal">
                                            <!--begin::Details-->
                                            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.OPERATION"></td>
                                                    <td class="text-gray-800 client">{{itemModel.operation}}</td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400" translate="GENERAL.CODE_CAISSE"></td>
                                                    <td class="text-gray-800 client">{{itemModel.codeCaisse}} </td>
                                                </tr>
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400 min-w-175px w-175px"translate="GENERAL.COMMENTAIRE"></td>
                                                    <td class="text-gray-800  client min-w-200px">
                                                        <a href="#" class="text-gray-800  text-hover-primary">{{itemModel.commentaires}}</a>
                                                    </td>
                                                </tr>
                                                <tr>

                                                    <td class="text-gray-400 min-w-175px w-175px"translate="MENU.STATUS"></td>
                                                    <td class="text-gray-800  client min-w-200px">
                                                    <span *ngIf="itemModel?.status === 1" class="badge badge-light-success"translate="MENU.ACTIF"></span>
                                                    <span *ngIf="itemModel?.status !== 1" class="badge badge-light-danger"translate="MENU.INACTIF"></span>
                                                    </td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->

                                                <!--end::Row-->
                                                <!--begin::Row-->

                                                <!--end::Row-->
                                                <!--begin::Row-->


                                                <!--end::Row-->
                                            </table>
                                            <!--end::Details-->
                                        </div>
                                        <div class="flex-equal">
                                            <!--begin::Details-->
                                            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.REFERENCE_CARTE"></td>
                                                    <td class="text-gray-800 client">{{itemModel.carte?.reference}}</td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.SOLDE_CARTE"></td>
                                                    <td class="text-gray-800 client">{{itemModel.carte?.solde | number }}</td>
                                                </tr>
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400 min-w-175px w-175px"translate="GENERAL.DATE_CREATION_CARTE"></td>
                                                    <td class="text-gray-800  client min-w-200px">
                                                        <a href="#" class="text-gray-800  text-hover-primary">{{itemModel.carte?.dateCreation | date : 'short'}}</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-400 min-w-175px w-175px"translate="GENERAL.DATE_MODIFICATION_CARTE"></td>
                                                    <td class="text-gray-800  client min-w-200px">
                                                        <a href="#" class="text-gray-800  text-hover-primary">{{itemModel.carte?.dateModification | date : 'short'}}</a>
                                                    </td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->

                                                <!--end::Row-->
                                                <!--begin::Row-->

                                                <!--end::Row-->
                                                <!--begin::Row-->


                                                <!--end::Row-->
                                            </table>
                                            <!--end::Details-->
                                        </div>


                                        <!--end::Row-->
                                    </div>
                                    <div class="d-flex flex-wrap py-5">
                                        <!--begin::Row-->
                                        <div class="flex-equal me-5">
                                            <!--begin::Details-->
                                            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                                                <!--begin::Row-->

                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.NOM_PRENOM_CLIENT"></td>
                                                    <td class="text-gray-800 client">{{itemModel.carte?.client?.prenom}} {{itemModel.carte?.client?.nom}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.ADRESSE_CLIENT"></td>
                                                    <td class="text-gray-800 client">{{itemModel.carte?.client?.adresse}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.NATIONALITE_CLIENT"></td>
                                                    <td class="text-gray-800 client">{{itemModel.carte?.client?.nationalite}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.TELEPHONE_CLIENT"></td>
                                                    <td class="text-gray-800 client">{{itemModel.carte?.client?.telephone}}</td>
                                                </tr>

                                                <!--end::Row-->
                                                <!--begin::Row-->



                                                <!--end::Row-->
                                            </table>
                                            <!--end::Details-->
                                        </div>
                                        <!--end::Row-->
                                        <!--begin::Row-->
                                        <div class="flex-equal">
                                            <!--begin::Details-->
                                            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.NOM_SOCIETE"></td>
                                                    <td class="text-gray-800 client">{{itemModel.carte?.compagnie?.nom}}</td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400 min-w-175px w-175px"translate="GENERAL.CODE_SOCIETE"></td>
                                                    <td class="text-gray-800  client min-w-200px">
                                                        <a href="#" class="text-gray-800  text-hover-primary">{{itemModel.carte?.compagnie?.code}}</a>
                                                    </td>
                                                </tr>

                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.SOLDE_SOCIETE"></td>
                                                    <td class="text-gray-800 client">{{itemModel.carte?.compagnie?.solde}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.NINEA_SOCIETE"></td>
                                                    <td class="text-gray-800 client">{{itemModel.carte?.compagnie?.ninea}}</td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->

                                                <!--end::Row-->
                                                <!--begin::Row-->

                                                <!--end::Row-->
                                                <!--begin::Row-->


                                                <!--end::Row-->
                                            </table>
                                            <!--end::Details-->
                                        </div>
                                        <div class="flex-equal">
                                            <!--begin::Details-->
                                            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                                                <tr>
                                                    <td class="text-gray-400"translate="MENU.AUTEURE"></td>
                                                    <td class="text-gray-800 client">{{itemModel.auteur}}</td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400" translate="GENERAL.REFERENCE_BON"></td>
                                                    <td class="text-gray-800 client">{{itemModel.bon?.reference }}</td>
                                                </tr>
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400 min-w-175px w-175px"translate="GENERAL.MONTANT_BON"></td>
                                                    <td class="text-gray-800  client min-w-200px">
                                                        <a href="#" class="text-gray-800  text-hover-primary">{{itemModel.bon?.montant}}</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-400 min-w-175px w-175px"translate="GENERAL.ETAT_BON"></td>
                                                    <td class="text-gray-800  client min-w-200px">
                                                        <a href="#" class="text-gray-800  text-hover-primary">{{itemModel.bon?.etat}}</a>
                                                    </td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->

                                                <!--end::Row-->
                                                <!--begin::Row-->

                                                <!--end::Row-->
                                                <!--begin::Row-->


                                                <!--end::Row-->
                                            </table>
                                            <!--end::Details-->
                                        </div>


                                        <!--end::Row-->
                                    </div>

                                    <!--end::Row-->
                                </div>
                                <!--end::Section-->
                                <!--begin::Section-->

                                <!--end::Section-->
                            </div>
                            <!--end::Card body-->
                        </div>
                        <!--end::Card-->
                        <!--begin::Card-->

                        <!--end::Card-->
                        <!--begin::Card-->

                        <!--end::Card-->
                    </div>
                    <!--end::Content-->
                    <!--begin::Sidebar-->

                    <!--end::Sidebar-->
                </div>
                <!--end::Layout-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Post-->
    </div>




