<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Toolbar-->

  <div class="card content">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-6 ">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <h3 translate="GENERAL.TRANSACTION"></h3>

        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->
      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Toolbar-->
        <div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">

          <!--begin::Filter-->
          <button type="button" class="btn btn-light-primary me-3 btn-rounded" data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end">
            <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
            <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                              d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                              fill="currentColor"/>
                        </svg>
                    </span>
            <span translate="MENU.FILTER"></span>
            <!--end::Svg Icon-->
          </button>
          <!--begin::Menu 1-->
          <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px mt-5" data-kt-menu="true">
            <!--begin::Header-->
            <div class="px-7 py-5">
              <div class="fs-5 text-dark fw-bolder" translate="MENU.FILTER"></div>
            </div>
            <!--end::Header-->
            <!--begin::Separator-->
            <div class="separator border-gray-200"></div>
            <!--end::Separator-->
            <!--begin::Content-->
            <form>
              <div class="px-7 py-5" data-kt-user-table-filter="form">
                <div class="mb-10">
                  <label class="form-label fs-6 fw-bold" translate="MENU.NUM_TRANSACTION"></label>
                  <input [(ngModel)]="filterModel.numTransaction" type="text"
                         class="form-control form-control-lg form-control-solid form-control-rounded " id="numero"
                         placeholder="numéro transaction" name="numero"/>
                </div>
                <div class="mb-10">
                  <label class="form-label fs-6 fw-bold" translate="MENU.DATEDEBUT"></label>
                  <input [(ngModel)]="filterModel.debut" type="date"
                         class="form-control form-control-lg form-control-solid form-control-rounded " id="debut"
                         placeholder="Date début" name="debut"/>
                </div>
                <div class="mb-10">
                  <label class="form-label fs-6 fw-bold" translate="MENU.DATEFIN"></label>
                  <input [(ngModel)]="filterModel.fin" type="date"
                         class="form-control form-control-lg form-control-solid form-control-rounded " id="fin"
                         placeholder="Date début" name="fin"/>
                </div>
                <div class="mb-10">
                  <label class="form-label fs-6 fw-bold">Société:</label>
                  <select [(ngModel)]="filterModel.societe" name="societe" aria-label="Selectionner un role"
                          data-control="select2"
                          data-placeholder="Selectionner une societe..."
                          class="form-select form-select-solid form-select-rounded form-select-lg fw-bold select2-hidden-accessible"
                          data-select2-id="select2-data-10-men3" tabindex="-1" aria-hidden="true">
                    <option value="" data-select2-id="select2-data-12-qv2a">---choisir---</option>
                    <option *ngFor="let item of societes" [value]=item?.id>{{item?.nom}} </option>
                  </select>
                </div>
                <div class="mb-10">
                  <label class="form-label fs-6 fw-bold">Société Client:</label>
                  <select [(ngModel)]="filterModel.societeCarte" name="societe" aria-label="Selectionner un role"
                          data-control="select2"
                          data-placeholder="Selectionner une societe..."
                          class="form-select form-select-solid form-select-rounded form-select-lg fw-bold select2-hidden-accessible"
                          data-select2-id="select2-data-10-men3" tabindex="-1" aria-hidden="true">
                    <option value="" data-select2-id="select2-data-12-qv2a">---choisir---</option>
                    <option *ngFor="let item of societesE" [value]=item?.id>{{item?.nom}} </option>
                  </select>
                </div>

                <div class="mb-10">
                  <label class="form-label fs-6 fw-bold">Services:</label>
                  <select [(ngModel)]="filterModel.services" name="societe" aria-label="Selectionner "
                          data-control="select2"
                          data-placeholder="Selectionner une societe..."
                          class="form-select form-select-solid form-select-rounded form-select-lg fw-bold select2-hidden-accessible"
                          data-select2-id="select2-data-10-men3" tabindex="-1" aria-hidden="true">
                    <option value="" data-select2-id="select2-data-12-qv2a">---choisir---</option>
                    <option *ngFor="let item of services" [value]=item?.id>{{item?.nom}} </option>
                  </select>
                </div>
                <div class="mb-10">
                  <label class="form-label fs-6 fw-bold" translate="MENU.STATUS"></label>
                  <select [(ngModel)]="filterModel.status" name="status" id="status"
                          class="form-select form-select-solid form-select-rounded fw-bolder" data-kt-select2="true"
                          aria-label="Selectionner un status" data-allow-clear="true"
                          data-kt-user-table-filter="two-step" data-hide-search="true">
                    <option></option>
                    <option value='1' translate="MENU.ACTIF"></option>
                    <option value='0' translate="MENU.INACTIF"></option>
                  </select>
                </div>

                <!--end::Input group-->
                <!--begin::Actions-->
                <div class="d-flex justify-content-end">
                  <button type="reset" class="btn btn-light btn-active-light-primary btn-rounded fw-bold me-2 px-6"
                          data-kt-menu-dismiss="true" data-kt-user-table-filter="reset" (click)="filterItem() "
                          translate="MENU.CANCEL"></button>
                  <button type="submit" class="btn btn-primary btn-rounded fw-bold px-6" data-kt-menu-dismiss="true"
                          data-kt-user-table-filter="filter" (click)="filterItem()" translate="MENU.FILTER"></button>
                </div>
                <!--end::Actions-->
              </div>
            </form>
            <!--end::Content-->
          </div>
          <!--end::Menu 1-->
          <!--end::Filter-->


          <!--begin::Export Filter-->
          <div class="m-0">
            <!--begin::Menu toggle-->
            <button type="button" class="btn btn-light-primary btn-rounded me-3" data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
              <span class="svg-icon svg-icon-2" translate="MENU.EXPORTER">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1"
                                      transform="rotate(90 12.75 4.25)" fill="currentColor"/>
                                <path
                                  d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z"
                                  fill="currentColor"/>
                                <path
                                  d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z"
                                  fill="#C4C4C4"/>
                            </svg>
                        </span>
              <!--end::Svg Icon-->
            </button>

            <!--end::Menu toggle-->
            <!--begin::Menu 1-->
            <div class="menu menu-sub menu-sub-dropdown w-125px w-md-250px mt-5" data-kt-menu="true"
                 id="kt_menu_624476767c26c">
              <!--begin::Header-->
              <div class="px-7 py-5">
                <div class="fs-5 text-dark fw-bolder" translate="MENU.EXPORTER"></div>
              </div>
              <!--end::Header-->
              <!--begin::Menu separator-->
              <div class="separator border-gray-200"></div>
              <!--end::Menu separator-->
              <!--begin::Form-->
              <div class="px-7 py-5">
                <div class="menu-item px-3">
                  <a class="menu-link px-3 text-dark text-hover-primary"
                     (click)="export('pdf')" translate="MENU.EXPORT_PDF"></a>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <a class="menu-link px-3 text-dark text-hover-primary"
                     (click)="export('csv')" translate="MENU.EXPORT_EXCEL"></a>
                </div>
              </div>
              <!--end::Form-->
            </div>
            <!--end::Menu 1-->
          </div>
          <!--end::Export Filter-->
          <!--begin::Add user-->

          <!--end::Add user-->
        </div>
        <!--end::Toolbar-->

      </div>
      <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card-body py-4">
      <div id="kt_table_users_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="loaderPage" *ngIf="loading">
          <div class="lds-roller ">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="table-responsive" *ngIf="!loading">

          <div class="row mb-4">
            <div class="col-md-3">
              <span>
                <i class="fa fa-sort-numeric-up m-3"></i>Nombre: <span class="text-bold">{{reports?.nombreTotal}}</span>
              </span>
            </div>
            <div class="col-md-3">
              <span>
                <i class="fa fa-money-check m-3"></i>Montant: <span
                class="text-success"> {{(reports?.montantTotal || 0) | number}}</span>
              </span>
            </div>
            <div class="col-md-3" *ngIf="societeUser &&  socClt">
              <span class="text-danger">
                {{socClt}} doit {{(reports?.montantTotal || 0) | number}} à {{societeUser?.nom}}
              </span>
            </div>
          </div>
          <!--begin::Table-->
          <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users" *ngIf="totals>0 && !loading">
            <!--begin::Table head-->
            <thead>
            <!--begin::Table row-->
            <tr class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              <th class="min-w-100px" translate="GENERAL.NUM_TRANSACTION"></th>

              <th class="min-w-125px" translate="MENU.REFERENCE"></th>
              <th class="min-w-100px" translate="GENERAL.COMPAGNIE_AUTEUR"></th>
              <th class="min-w-100px" translate="GENERAL.COMPAGNIE_CARTE"></th>
              <!--                            <th class="min-w-100px" translate="GENERAL.COMPAGNIE_AGREGATEUR"></th>-->
              <!--                            <th class="min-w-100px" translate="MENU.SOLDE_AVANT"></th>-->
              <th class="min-w-100px" translate="MENU.MONTANT"></th>
              <!--                            <th class="min-w-100px" translate="MENU.SOLDE_APRES"></th>-->
              <th class="min-w-100px" translate="GENERAL.AUTEUR"></th>
              <th class="min-w-125px" translate="GENERAL.CLIENT"></th>
              <th class="min-w-100px" translate="GENERAL.STATUS"></th>
              <th class="text-end min-w-100px" translate="GENERAL.ACTIONS"></th>
            </tr>
            <!--end::Table row-->
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody class="text-gray-700 fw-bold">
            <tr *ngFor="let item of items | paginate: { itemsPerPage: pageSize, currentPage: page ,totalItems:totals }">

              <!--begin::User=-->
              <td class="d-flex flex-column">
                <a class="text-gray-800 text-hover-primary mb-1">
                  {{item?.numTransaction}}</a>

              </td>

              <td>
                <div>
                  <a class="text-gray-900 text-hover-primary mb-1">{{item?.carte?.reference}}</a>

                </div>
                <!--begin::User details-->
              </td>
              <td class=" flex-column">
                <a class="text-gray-900 text-hover-primary mb-1">
                  {{item?.societe?.nom}}</a>
              </td>
              <td class=" flex-column">
                <a class="text-primary  text-hover-primary mb-1">
                  {{item?.carte?.compagnie?.nom}}</a>
              </td>
              <!--                          <td class=" flex-column">-->
              <!--                            <a class="text-gray-700 text-hover-primary mb-1">-->
              <!--                              {{item?.carte?.compagnie?.parent?.nom}}</a>-->
              <!--                          </td>-->
              <!--                            <td class="text-danger">{{item?.soldeAvant |number}}</td>-->
              <td class="text-success">{{item?.montant |number}}</td>
              <!--                            <td class="text-primary">{{item?.soldeApres |number}}</td>-->
              <td class="d-flex flex-column">
                <a class="text-gray-800 text-hover-primary mb-1"
                >{{item?.auteur}}</a>
              </td>
              <td>
                <div>
                  <a
                    class="text-gray-900 text-hover-primary mb-1">{{item.carte?.client?.prenom}} {{item.carte?.client?.nom}}</a>

                </div>
                <!--begin::User details-->
              </td>
              <td>
                <span *ngIf="item?.status === 1" class="badge badge-light-success" translate="MENU.ACTIF"></span>
                <span *ngIf="item?.status !== 1" class="badge badge-light-danger" translate="MENU.INACTIF"></span>
              </td>
              <!--- <td>{{item?.bon?.nom}}</td>-->
              <td class="text-end">
                <div class="d-flex justify-content-end flex-shrink-0">
                  <a (click)="changeStatus(item.id, item)"
                     class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                    <span class="svg-icon svg-icon-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none">
                                                <path
                                                  d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                                  fill="currentColor"/>
                                                <path opacity="0.3"
                                                      d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                                      fill="currentColor"/>
                                            </svg>
                                        </span>
                    <!--end::Svg Icon-->
                  </a>
                  <a *ngIf="guard.pass(transaction.actions.transactionsGet)"
                     class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                     [routerLink]="['../detail',item.id]" type="button"
                     class="btn btn-sm btn-icon btn-light btn-active-light-primary me-2" data-kt-menu-trigger="click"
                     data-kt-menu-placement="bottom-end">
                    <!--begin::Svg Icon | path: icons/duotune/general/gen052.svg-->
                    <span
                      [inlineSVG]="'./assets/media/icons/duotune/arrows/arr064.svg'"
                      class="svg-icon svg-icon-2 svg-icon-primary"
                    ></span>
                    <!--end::Svg Icon-->
                  </a>
                </div>
              </td>
            </tr>

            </tbody>
          </table>
          <!--end::Table-->
        </div>

        <div class="row mt-5 mb-5">
          <div
            class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
          </div>
          <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
            <pagination-controls previousLabel="" nextLabel="" class=" atbd-pagination__link page-number"
                                 (pageChange)="paginate($event)"></pagination-controls>
          </div>
        </div>
        <div *ngIf="items.length===0 && !loading">
          <h1 class="styleTabVide mt-3" translate="GENERAL.TRANSACTION_NOT_FOUND"></h1>
        </div>
      </div>
    </div>

  </div>
</div>




