import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AuthModel } from '../models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpBackend } from '@angular/common/http';
import {Entity} from "../models/entity.model";

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  private unsubscribe: Subscription[] = [];
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor( private authHttpService: AuthHTTPService, private router: Router, private httpClient: HttpClient,) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);

  }

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  login(credentials:any) {
    return this.httpClient.post<any>(`${environment.apiAuthUrl + "/login"}`, credentials)
  }

  verifyOtpToken(data:any)
  {
    return this.httpClient.post<any>(`${environment.apiAuthUrl + "/otp/verify"}`, data)
  }

  logout(){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('entity');
    localStorage.removeItem('societe');
    this.router.navigateByUrl('/auth/login');
  }

  onForgotPassword(endpoint: string, data: any) {
    return this.httpClient.post(`${environment.apiAuthUrl}${"/" + endpoint}`, data);
  }

  getJwtToken(){
    return localStorage.getItem('userToken')
  }

  get(id: any, endpoint: String){
    return this.httpClient.get<any []>(`${environment.apiAuthUrl}${endpoint+'/'+id}`);
  }
  getReseau(id: any, endpoint: String){
    return this.httpClient.get<any []>(`${environment.apiAuthUrl}${endpoint+'/'+id}`);
  }

   getAll(endpoint: String, page: any = 1, query: String = ''){
    if(page != null){
      return this.httpClient.get<any []>(`${environment.apiAuthUrl}${endpoint}?_page=${page}${query}`);
    }
   
    else{
      return this.httpClient.get<any []>(`${environment.apiAuthUrl}${endpoint+'/all'}?${query}`);
    }
  }
  activateUser(userId:any){
    return this.httpClient.put<any[]>(`${environment.apiAuthUrl}/users/setstatus/${userId}`,{status: 1});
  }
  getUser(){
    return JSON.parse(<any>localStorage.getItem('userInfo'));
  }

  updatePassword(endpoint:string,data:any){
    return  this.httpClient.put<any[]>(`${environment.apiAuthUrl}${endpoint}`,data);
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }


  upsert(id: any = null, endpoint: any = null, data: any){
    if(id) return this.httpClient.put<any[]>(`${environment.apiAuthUrl}${endpoint+'/'+id}`,data);
    if(!id) return this.httpClient.post<any>(`${environment.apiAuthUrl}${endpoint}`,data)
  }

  export(endpoint ='', query='') {
    return this.httpClient.get(`${environment.apiAuthUrl}${endpoint}?true=true${query}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }


  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  getListeStation(endpoint: String, page: any = 1, query: String = '', api : any = environment.apiStation){
    return this.httpClient.get<any []>(`${api}${endpoint}?_page=${page}${query}`);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
  getEntityByCode(codeClient=''){
    return this.httpClient.get<Entity>(`${environment.apiAdminUrl}/entites?codeEntite=${codeClient}`);
  }
  getSocieteByCode(codeSociete=''){
    return this.httpClient.get<Entity>(`${environment.apiStation}/societes?code=${codeSociete}`);
  }
  generatePassword(id:any){
    return this.httpClient.put<any>(`${environment.apiAuthUrl}/users/password/regenerate/${id}`,{});
  }
  setUserPin(userId:any) {
    return this.httpClient.put<any[]>(`${environment.apiAuthUrl}/users/pin/regenerate/${userId}`, []);
  }
}
