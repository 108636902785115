import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lists-widget5',
  templateUrl: './lists-widget5.component.html',
})
export class ListsWidget5Component {

  @Input('items') items : any [] = [];
  @Input('total') total : number = 0;
  @Input('loading') loading : boolean = false;

  constructor() {}
  
}
