<!--begin::Advance Table Widget 9-->
<div class="card card-custom" [ngClass]="cssClass">
  <!--begin::Header-->
  <div class="card-header border-0 py-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark">Agents Stats</span>
      <span class="text-muted mt-3 font-weight-bold font-size-sm"
        >More than 400+ new members</span
      >
    </h3>
    <div class="card-toolbar">
      <a href="#" class="btn btn-info font-weight-bolder font-size-sm mr-3"
        >New Arrivals</a
      >
      <a href="#" class="btn btn-danger font-weight-bolder font-size-sm"
        >Create</a
      >
    </div>
  </div>
  <!--end::Header-->

  <!--begin::Body-->
  <div class="card-body pt-0 pb-3">
    <div class="tab-content">
      <!--begin::Table-->
      <div class="table-responsive">
        <table
          class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-borderless
          "
        >
          <thead>
            <tr class="text-left">
              <th style="min-width: 250px" class="pl-7">
                <span class="text-dark-75">products</span>
              </th>
              <th style="min-width: 120px">earnings</th>
              <th style="min-width: 100px">comission</th>
              <th style="min-width: 100px">company</th>
              <th style="min-width: 100px">rating</th>
              <th style="min-width: 100px"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="pl-0 py-8">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-50 symbol-light mr-4">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/avatars/001-boy.svg'"
                        class="svg-icon h-75 align-self-end"
                      ></span>
                    </span>
                  </div>
                  <div>
                    <a
                      href="#"
                      class="
                        text-dark-75
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Brad Simmons</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >HTML, JS, ReactJS</span
                    >
                  </div>
                </div>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  $8,000,000
                </span>
                <span class="text-muted font-weight-bold"> In Proccess </span>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  $520
                </span>
                <span class="text-muted font-weight-bold"> Paid </span>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  Intertico
                </span>
                <span class="text-muted font-weight-bold">
                  Web, UI/UX Design
                </span>
              </td>
              <td>
                <img
                  src="./assets/media/logos/stars.png"
                  alt="image"
                  style="width: 5rem"
                />
                <span class="text-muted font-weight-bold d-block">
                  Best Rated
                </span>
              </td>
              <td class="pr-0 text-right">
                <a
                  href="#"
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  >View Offer</a
                >
              </td>
            </tr>
            <tr>
              <td class="pl-0 py-0">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-50 symbol-light mr-4">
                    <span class="symbol-label">
                      <img
                        src="./assets/media/svg/avatars/018-girl-9.svg"
                        class="h-75 align-self-end"
                        alt=""
                      />
                    </span>
                  </div>
                  <div>
                    <a
                      href="#"
                      class="
                        text-dark-75
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Jessie Clarcson</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >C#, ASP.NET, MS SQL</span
                    >
                  </div>
                </div>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  $23,000,000
                </span>
                <span class="text-muted font-weight-bold"> Pending </span>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  $1,600
                </span>
                <span class="text-muted font-weight-bold"> Rejected </span>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  Agoda
                </span>
                <span class="text-muted font-weight-bold">
                  Houses & Hotels
                </span>
              </td>
              <td>
                <img
                  src="./assets/media/logos/stars.png"
                  alt="image"
                  style="width: 5rem"
                />
                <span class="text-muted font-weight-bold d-block">
                  Above Average
                </span>
              </td>
              <td class="pr-0 text-right">
                <a
                  href="#"
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  >View Offer</a
                >
              </td>
            </tr>
            <tr>
              <td class="pl-0 py-8">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-50 symbol-light mr-4">
                    <span class="symbol-label">
                      <img
                        src="./assets/media/svg/avatars/047-girl-25.svg"
                        class="h-75 align-self-end"
                        alt=""
                      />
                    </span>
                  </div>
                  <div>
                    <a
                      href="#"
                      class="
                        text-dark-75
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Lebron Wayde</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >PHP, Laravel, VueJS</span
                    >
                  </div>
                </div>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  $34,000,000
                </span>
                <span class="text-muted font-weight-bold"> Paid </span>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  $6,700
                </span>
                <span class="text-muted font-weight-bold"> Paid </span>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  RoadGee
                </span>
                <span class="text-muted font-weight-bold">
                  Transportation
                </span>
              </td>
              <td>
                <img
                  src="./assets/media/logos/stars.png"
                  alt="image"
                  style="width: 5rem"
                />
                <span class="text-muted font-weight-bold d-block">
                  Best Rated
                </span>
              </td>
              <td class="pr-0 text-right">
                <a
                  href="#"
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  >View Offer</a
                >
              </td>
            </tr>
            <tr>
              <td class="pl-0 py-0">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-50 symbol-light mr-4">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="
                          './assets/media/svg/avatars/014-girl-7.svg'
                        "
                        class="svg-icon h-75 align-self-end"
                      ></span>
                    </span>
                  </div>
                  <div>
                    <a
                      href="#"
                      class="
                        text-dark
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >Natali Trump</a
                    >
                    <span class="text-muted font-weight-bold d-block"
                      >Python, PostgreSQL, ReactJS</span
                    >
                  </div>
                </div>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  $2,600,000
                </span>
                <span class="text-muted font-weight-bold"> Paid </span>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  $14,000
                </span>
                <span class="text-muted font-weight-bold"> Pending </span>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  The Hill
                </span>
                <span class="text-muted font-weight-bold"> Insurance </span>
              </td>
              <td>
                <img
                  src="./assets/media/logos/stars.png"
                  alt="image"
                  style="width: 5rem"
                />
                <span class="text-muted font-weight-bold d-block">
                  Average
                </span>
              </td>
              <td class="pr-0 text-right">
                <a
                  href="#"
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  >View Offer</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
  </div>
  <!--end::Body-->
</div>
<!--end::Advance Table Widget 9-->
