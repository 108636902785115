<div
  [ngClass]="footerContainerCssClasses"
  class="
    d-flex
    flex-column flex-md-row
    align-items-center
    justify-content-between
  "
>
  <!-- begin::Copyright  -->
  <div class="text-dark order-2 order-md-1">
    <span class="text-muted fw-bold me-1">{{ currentDateStr }} &copy;</span>
    <a class="text-gray-800 text-hover-primary cursor-pointer"> B&G Digital </a>
  </div>
  <!-- end::Copyright  -->

  <!-- begin::Nav  -->
  <!--ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
    <li class="menu-item">
      <a class="menu-link ps-0 pe-2 cursor-pointer"> About </a>
    </li>
    <li class="menu-item">
      <a class="menu-link pe-0 pe-2 cursor-pointer"> Contact </a>
    </li>
    <li class="menu-item">
      <a class="menu-link pe-0 cursor-pointer"> Purchase </a>
    </li>
  </ul-->
  <!-- end::Nav  -->
</div>
