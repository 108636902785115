

<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Toolbar-->

    <!--end::Toolbar-->
    <!--begin::Post-->
    <div class="loaderPage" *ngIf="loading">
        <div class="lds-roller " >
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <!--begin::Container-->
        <div id="kt_content_container" class="container-xxl">
            <!--begin::Layout-->
            <div class="d-flex flex-column flex-lg-row">
                <!--begin::Content-->
                <div class="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                    <!--begin::Card-->
                    <div class="card card-flush pt-3 mb-5 mb-xl-10">
                        <!--begin::Card header-->
                        <div class="card-header">
                            <!--begin::Card title-->
                            <div class="card-title">
                                <h2 class="fw-bolder"translate="MENU.DETAIL_USER"> </h2>
                            </div>

                        </div>
                        <!--end::Card header-->
                        <!--begin::Card body-->
                        <div class="card-body pt-3">
                            <!--begin::Section-->
                            <div class="mb-10" *ngIf="itemModel">
                                <!--begin::Title-->
                                <h5 class="mb-4"translate="MENU.INFO_USER"></h5>
                                <div class="d-flex align-items-center align-middle">
                                    <!--begin::Avatar-->
                                    <div class="symbol symbol-60px symbol-circle me-3">
                                        <ngx-avatar name="{{itemModel?.prenom+' '+ itemModel?.nom}}" [src]="itemModel?.source"></ngx-avatar>
<!--                                        <img src="{{ itemModel?.source  || 'assets/media/avatars/blank.png'}}"-->
<!--                                                class="w-100" />-->
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Info-->
                                    <div class="d-flex flex-column">
                                        <!--begin::Name-->
                                        <a  class="fs-4 fw-bolder text-gray-900  me-2">{{itemModel?.prenom}} {{itemModel?.nom}}</a>
                                        <!--end::Name-->
                                        <!--begin::Email-->
                                        <a  class="fw-bold text-gray-600 ">Profils: {{itemModel?.profiles[0]?.nom  || '...'}}</a>
                                        <!--end::Email-->
                                    </div>
                                    <!--end::Info-->
                                </div>
                                <!--end::Title-->
                                <!--begin::Details-->
                                <div class="d-flex flex-wrap py-5">
                                    <!--begin::Row-->
                                    <div class="flex-equal me-5">
                                        <!--begin::Details-->
                                        <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                                            <!--begin::Row-->

                                            <!--end::Row-->
                                            <!--begin::Row-->
                                            <tr>
                                                <td class="text-gray-400" translate="MENU.USER"></td>
                                                <td class="text-gray-800 client">{{itemModel.prenom}}{{itemModel.nom}}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-gray-400"  translate="MENU.EMAIL"></td>
                                                <td class="text-gray-800 client">{{itemModel.email}}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-gray-400"translate="MENU.TELEPHONE"></td>
                                                <td class="text-gray-800 client">{{itemModel.tel}}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-gray-400"translate="MENU.USERNAME"></td>
                                                <td class="text-gray-800 client">{{itemModel.username}}</td>
                                            </tr>
                                            <tr *ngIf="itemModel.societe">
                                                <td class="text-gray-400"translate="MENU.SOCIO"></td>
                                                <td class="text-gray-800 client">{{itemModel.societe  || '...'}}</td>
                                            </tr>


                                            <!--end::Row-->
                                            <!--begin::Row-->



                                            <!--end::Row-->
                                        </table>
                                        <!--end::Details-->
                                    </div>
                                    <!--end::Row-->
                                    <!--begin::Row-->
                                    <div class="flex-equal">
                                        <!--begin::Details-->
                                        <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">

                                            <tr>
                                                <td class="text-gray-400" translate="MENU.CREATION"></td>
                                                <td class="text-gray-800 client">{{itemModel.auteur}}</td>
                                            </tr>
                                            <!--end::Row-->
                                            <!--begin::Row-->

                                            <!--begin::Row-->
                                            <tr>
                                                <td class="text-gray-400"translate="MENU.VALIDERBY"></td>
                                                <td class="text-gray-800 client">{{itemModel.auteurValidation}}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-gray-400 min-w-175px w-175px"translate="MENU.DATECREATION"></td>
                                                <td class="text-gray-800  client min-w-200px">
                                                    <a href="#" class="text-gray-800  text-hover-primary">{{itemModel.dateCreation}}</a>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td class="text-gray-400 min-w-175px w-175px"translate="MENU.STATUS"></td>
                                                <td class="text-gray-800  client min-w-200px">
                                                <span *ngIf="itemModel?.status == 1" class="badge badge-light-success"translate="MENU.ACTIF"></span>
                                                <span *ngIf="itemModel?.status === 0" class="badge badge-light-danger"translate="MENU.INACTIF"></span>
                                                <span *ngIf="itemModel?.status === 2" class="badge badge-light-warning"translate="MENU.ENATTENTEF"></span>
                                                </td>
                                            </tr>
                                            <!--end::Row-->
                                            <!--begin::Row-->

                                            <!--end::Row-->
                                            <!--begin::Row-->

                                            <!--end::Row-->
                                            <!--begin::Row-->


                                            <!--end::Row-->
                                        </table>

                                        <!--end::Details-->

                                    </div>




                                    <!--end::Row-->
                                </div>
                                <!--end::Row-->
                            </div>
                              <h2 class="fw-bolder text-warning"translate="MENU.MODIF"> </h2>
                          <table class="table align-middle table-row-dashed gy-5" id="kt_table_users_login_session">
                            <tbody class="fs-6 fw-bold text-gray-600">
<!--                            <tr *ngIf="guard.pass(users.actions.regenererPassword)" >-->
<!--                              <td translate="MENU.MODIFICATION_MOT_DE_PASSE"></td>-->
<!--                              <td>******</td>-->
<!--                              <td class="text-end">-->
<!--                                <button (click)="openModal(updatePwd)"type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-bs-toggle="modal" data-bs-target="#kt_modal_update_email">-->
<!--                                                        <span class="svg-icon svg-icon-3">-->
<!--                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">-->
<!--                                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor" />-->
<!--                                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor" />-->
<!--                                                            </svg>-->
<!--                                                        </span>-->
<!--                                </button>-->
<!--                              </td>-->
<!--                            </tr>-->
                            <tr *ngIf="guard.pass(users.actions.regenererPassword)" >
                              <td translate="MENU.REGENERER_MOT_PASSE"></td>
                              <td>******</td>
                              <td class="text-end">
                                <button (click)="regenererPasword()"type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-bs-toggle="modal" data-bs-target="#kt_modal_update_email">
                                                        <span class="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor" />
                                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                </button>
                              </td>
                            </tr>

                            <div *ngIf="guard.pass(users.actions.regenererCodePin)" >
                              <tr *ngIf="view_regenCodePin" >
                                <td translate="MENU.REGENERER_CODE_PIN"> </td>
                                <td>******</td>
                                <td class="text-end">
                                  <button type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" (click)="regenererPin()">
                                                        <span class="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor" />
                                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                  </button>
                                </td>
                              </tr>
                            </div>


                            <!--<tr>
                                <td>Email</td>
                                <td>{{itemModel.email}}</td>
                                <td class="text-end">
                                    <button (click)="openModal(updateEmail)"type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto" data-bs-toggle="modal" data-bs-target="#kt_modal_update_email">
                                        <span class="svg-icon svg-icon-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor" />
                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor" />
                                            </svg>
                                        </span>
                                    </button>
                                </td>
                            </tr>-->
                            </tbody>
                          </table>
                            <!--end::Section-->
                        </div>
                        <!--end::Card body-->
                    </div>
                    <!--end::Card-->
                    <!--begin::Card-->

                    <!--end::Card-->
                    <!--begin::Card-->

                    <!--end::Card-->
                </div>
                <!--end::Content-->
                <!--begin::Sidebar-->

                <!--end::Sidebar-->
            </div>
            <!--end::Layout-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Post-->
</div>



<ng-template #updatePwd>
    <!--begin::Modal - Update password-->
           <!--begin::Modal content-->
           <div class="modal-content">
               <!--begin::Modal header-->
               <div class="modal-header">
                   <!--begin::Modal title-->
                   <h2 class="fw-bolder" translate="MENU.METTRE_A_JOUR_PASSWORD"></h2>
                   <!--end::Modal title-->
                   <!--begin::Close-->
                   <div class="btn btn-icon btn-sm btn-active-icon-dark" data-kt-users-modal-action="close" (click)="modalService.dismissAll()" >
                       <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                       <span class="svg-icon svg-icon-1">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                               <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                               <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                           </svg>
                       </span>
                       <!--end::Svg Icon-->
                   </div>
                   <!--end::Close-->
               </div>
               <!--end::Modal header-->
               <!--begin::Modal body-->
               <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                   <!--begin::Form-->
                   <form id="kt_modal_update_password_form" class="form" action="#">
                       <!--begin::Input group=-->

                       <div class="fv-row mb-10">
                           <label class="required form-label fs-6 mb-2"translate="AUTH.INPUT.PASSWORDNOW" ></label>
                           <div class="input-container position-relative icon-right">
                               <a  (click)="showPassword(1)"  class="input-icon icon-right position-absolute" style="top: 12px; right:20px;">
                                   <span [class]="show_eye1 ? 'fa fa-eye-slash  opacity-50':'fa fa-eye color-gray opacity-50' " style="color: #0f040a;" ></span>
                                 </a>
                           <input [(ngModel)]="userPassword.current_password" class="form-control form-control-lg form-control-solid form-control-rounded" type="password" placeholder="********" name="current_password" autocomplete="off"[type]="show_eye1 ? 'text':'password'"  value="secret" />
                           </div>
                       </div>
                       <!--end::Input group=-->
                       <!--begin::Input group-->
                       <div class="mb-10 fv-row" data-kt-password-meter="true">
                           <!--begin::Wrapper-->
                           <div class="mb-1">
                               <!--begin::Label-->
                               <label class="form-label fw-bolder text-dark fs-6" translate="AUTH.INPUT.PASSWORDCHANGE"></label>
                               <!--end::Label-->
                               <!--begin::Input wrapper-->
                               <div class="position-relative input-container position-relative icon-right">
                                   <a  (click)="showPassword(2)"  class="input-icon icon-right position-absolute" style="top: 12px; right:20px;">
                                     <span [class]="show_eye2 ? 'fa fa-eye-slash  opacity-50':'fa fa-eye color-gray opacity-50' " style="color: #0f040a;" ></span>
                                   </a>
                                   <input [(ngModel)]="userPassword.password" class="form-control form-control-lg form-control-solid form-control-rounded" type="password" placeholder="********" name="current_password" autocomplete="off"[type]="show_eye2 ? 'text':'password'"  value="secret" />
                               </div>

                           </div>
                           <!--end::Wrapper-->
                           <!--begin::Hint-->
                           <div class="text-muted" translate="AUTH.REGISTER.SYMBOLE_PASSWORD"></div>
                           <!--end::Hint-->
                       </div>
                       <!--end::Input group=-->
                       <!--begin::Input group=-->
                       <div class="fv-row mb-10">
                           <label class="required form-label fs-6 mb-2" translate="MENU.CONFIRMATION_PASSWORD"></label>
                           <div class="input-container position-relative icon-right">
                               <a  (click)="showPassword(3)"  class="input-icon icon-right position-absolute" style="top: 12px; right:20px;">
                                   <span [class]="show_eye3 ? 'fa fa-eye-slash  opacity-50':'fa fa-eye color-gray opacity-50' " style="color: #0f040a;" ></span>
                                 </a>
                           <input [(ngModel)]="userPassword.confirm_password" class="form-control form-control-lg form-control-solid form-control-rounded" type="password" placeholder="********" name="current_password" autocomplete="off"[type]="show_eye3 ? 'text':'password'"  value="secret" />
                           </div>
                       </div>
                       <!--end::Input group=-->
                       <!--begin::Actions-->
                       <div class="text-center pt-15">
                           <button (click)="closeAndReset()" type="reset" class="btn btn-light me-3 btn btn-rounded" data-kt-users-modal-action="cancel" translate="BUTTONS.CANCEL_BUTTON"></button>
                           <button [disabled]="loading"  (click)="updatePassword()" type="submit" class="btn btn-primary btn btn-rounded" data-kt-users-modal-action="submit">
                               <span class="indicator-label" translate="BUTTONS.VALIDATE_BUTTON"></span>
                               <span class="indicator-progress" *ngIf="loading" translate="MENU.PLEASE_WAIT">
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                           </button>

                       </div>
                       <!--end::Actions-->
                   </form>
                   <!--end::Form-->
               </div>
               <!--end::Modal body-->
           </div>
           <!--end::Modal content-->
   <!--end::Modal - Update password-->
</ng-template>





