export class UserPermissions {

  static MANAGE_Default = {
    module: 'Default',
    actions: {
      RechargementCartesMultiples: 'bulk_card',
      virementBulkValider: 'virement_bulk_valider',
      virementBulkConfirmer: 'virement_bulk_confirmer',
      multiCard: 'multi_cartes',
      carteMultipleValider: 'carte_multiple_valider',
      customCardRegeneratePin: 'custom_card_regenerate_pin',
      enrolMultiCarte: 'enrol_multi_cartes',
      enrolarteMultipleValider: 'enrol_carte_multiple_valider',
      permissions: 'permission',
      entityPermissions: 'entity_permission',
      //entityPermissionsUpdate: 'entity_permissions_update',
      qrGenerator: 'qr_generator',
      qrDecoder: 'qrcode_decoder',
      moduleListe: 'modules_list',
      moduleAdd: 'modules_add',
      modukeGet: 'modules_get',
      moduleEdit: 'modules_edit',
      enrolementList: 'enrolements_list',
      enrolementGet: '"enrolements_get',
      carteCarburantList: 'carte_carburants_list',
      carteCarburantsDisponiblesCollection: 'carte_carburants_disponibles_collection',
      carteCarburantsAttribuesCollection: 'carte_carburants_attribues_collection',
      carteCarburantsAdd: 'carte_carburants_add',
      carteCarburantsGet: 'carte_carburants_get',
      carteCarburantsStatusItem: 'carte_carburants_status_item',
      carteCarburantsEdit: 'carte_carburants_edit',
      carteCarburantsCartecarburantsAttributiontem: 'carte_carburants_carte_carburants_attribution_item',
      parametresList: 'parametres_list',
      parametresAdd: 'parametres_add',
      parametresGet: 'parametres_get',
      parametresDelete: 'parametres_delete',
      parametresEdit: 'parametres_edit',
      societeList: 'societes_list',
      societesCompagniesCollection: 'societes_compagnies_collection',
      societesAgregateurCollection: 'societes_agregateur_collection',
      societesCompagniesAllCollection: 'societes_compagnies_all_collection',
      societesAgregateurAllCollection: 'societes_agregateur_all_collection',
      societesAllCollection: 'societes_all_collection',
      societesSavePartnerCollection: 'societes_savePartner_collection',
      societesGet: 'societes_get',
      profilsList: 'profils_list',
      profilsAdd: 'profils_add',
      profilsGet: 'profils_get',
      profilsDelete: 'profils_delete',
      profilsEdit: 'profils_edit',
      bulksList: 'bulks_list',
      bulksGet: 'bulks_get',
      actionsList: 'actions_list',
      actionsAdd: 'actions_add',
      actionsGet: 'actions_get',
      actionsDelete: 'actions_delete',
      actionsEdit: 'actions_edit',
      clientsList: 'clients_list',
      clients_add: 'clients_add',
      clientsGet: 'clients_get',
      clientsStatusItem: 'clients_status_item',
      clientsEdit: 'clients_edit',
      bonsList: 'bons_list',
      bonsAdd: 'bons_add',
      bonsGet: 'bons_get',
      bonsBonsStatusItem: 'bons_bons_status_item',
      bonsEdit: 'bons_edit',
      bonsBonsAttributionItem: 'bons_bons_attribution_item',
      //permissionsList:'permissions_list',
      //permissionsAdd:'permissions_add',
      // permissions_get:'permissions_get',
      permissions_delete: 'permissions_delete',
      // permissionsEdit:'permissions_edit',
      receptionCartesList: 'reception_cartes_list',
      receptionCartesAdd: 'reception_cartes_add',
      receptionCartesGet: 'reception_cartes_get',
      lotsList: 'lots_list',
      lotsAdd: 'lots_add',
      lotsAddBonsCollection: 'lots_add_bons_collection',
      lotsGet: 'lots_get',
      previewErro: 'preview_erro',
      addUser: "add_user",
      updateUser: 'update_user',
      detailUser: 'details_user',
      regenererPassword: 'regenerer_user',
      regenererCodePin: 'regenerer_user_codepin',
      ajoutRole: 'add_roles',
      modifierRole: 'update_roles',
      ajoutStation: 'add_station',
      ajoutCaisse: 'add_caisse',
      ajoutApproSociete: 'add_appro_sct',
      ajoutApproEntite: 'add_appro_en',
      acceptApproSociete: 'accept_societe',
      rejetApproSociete: 'rejeter_societe',
      acceptApproEntite: 'accept_entite',
      rejetApproEntite: 'rejeter_entite',
      list_comm: 'list_comm',
      add_comm: 'add_comm'

    }
  };
  static MANAGE_Entites = {
    module: 'Entites',
    actions: {
      entitesList: 'entites_list',
      entitesentite_save_collectione: 'entitesentite_save_collection',
      entitesGet: 'entites_get',
    }

  };
  static MANAGE_Transactions = {
    module: 'Transactions',
    actions: {
      transactionsList: 'transactions_list',
      transactionsCashinCollection: 'transactions_cashin_collection',
      transactionsCashoutCollection: 'transactions_cashout_collectiont',
      transactionsAgregatTransactioCollection: 'transactions_agregat_transactio_collection',
      transactionsGet: 'transactions_get',
      transactionsCashoutValidationItem: 'transactions_cashout_validation_item',
      transactionsAnnulerItem: 'transactions_annuler_item',
    }
  };

  static MANAGE_Print = {
    module: 'Print',
    actions: {
      Liste_transaction_csv: 'transactions_transactions_csv_collection"',
      carteCarburantsPdfExportCollection: 'carte_carburants_pdf_export_collection',
      carteCarburantsCsvExportCollection: 'carte_carburants_csv_export_collection',
      carteCarburantsCarteCarburantPdfItem: 'carte_carburants_carte_carburant_pdf_item',
      clientsPdfCollection: 'clients_pdf_collectiont',
      clientsCsvCollection: 'clients_csv_collection',
      bonsPdfExportCollection: 'bons_pdf_export_collection',
      bonsCsvExportCollection: 'bons_csv_export_collection',
      bonsCarteCarburantPdfItem: 'bons_carte_carburant_pdf_item',
    }
  };

  static MANAGE_Service = {
    module: 'Service',
    actions: {
      services_list: 'services_list"',
      services_add: 'services_add',
      services_get: 'services_get',
      services_edit: 'services_edit',
    }
  };
  static MANAGE_Permission = {
    module: 'Permission',
    actions: {
      permissions: 'permission',
      entity_permissions_update: 'entity_permissions_update',
      permissionsList: 'permissions_list',
      permissionsAdd: 'permissions_add',
      permissionsGet: 'permissions_get',
      permissionsEdit: 'permissions_edit',
    }
  };

  static MANAGE_SIDEBAR = {
    administration: {
      actions: {
        list_user: "liste_users",
        list_user_wait: "liste_users_wait",
        list_role: "list_roles",
        add_roles: "list_roles",
        update_roles: "updade_roles",
        list_permission: "permissions_list",

      }
    },
    societes: {
      actions: {
        societes_list: "societes_list",
        societesCmp_list: "societes_compagnies_collection",
        societesAg_list: "societes_agregateur_collection",
        add_societe: "societes_savePartner_collection",
      }
    },
    stations: {
      actions: {
        station_list: "list_stations",
        caisse_list: "list_caisse",
      }
    },
    appro: {
      actions: {
        list_appro_en: "list_appro_en",
        add_appro_entite: "add_appro_en",
        add_appro_societe: "add_appro_sct",
        list_appro_societe: "list_appro_sct",
        list_appro_agence: "list_appro_agence",
        add_appro_agence: "add_appro_agence",
        add_appro_caisse: "add_appro_caisse",
        list_appro_caisse: "list_appro_caisse",      }
    },
    clients: {
      actions: {
        list_client: "clients_list",
        add_multiple: "enrol_multi_cartes",
      }
    },
    transaction: {
      actions: {
        list_transaction: "transactions_list",
        list_transaction_C: "transactions_list_c",
        cashin_transaction: "transactions_cashin_collection",
        report_commission: "report_commission",
      }
    },
    tableau_de_bord: {
      actions: {
        view: "view_tableau_de_bord",
      }
    },
    carburant: {
      actions: {
        carte: {
          list_cartes: 'carte_carburants_list',
          recharge_multiple: 'bulk_card',
          chargementFichier_cartes: 'multi_cartes',
          add_lot: 'lots_add',
        },
        bon: {
          list_bon: 'bons_list',
          chargementFichier_bon: 'multi_cartes',
          add_lot_bon: 'lots_add_bons_collection',
          fichier_De_Bon: 'api_multi_bon',
        }

      }
    },
    parametre: {
      actions: {
        list_comm: "list_comm",
      }
    }
  }

}
