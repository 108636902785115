<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Toolbar-->

<div class="card content">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-6 ">
        <!--begin::Card title-->
        <div class="card-title">
            <!--begin::Search-->
            <div class="d-flex align-items-center position-relative my-1">
              <h3>Liste caisses</h3>

            </div>
            <!--end::Search-->
          </div>
        <!--begin::Card title-->
        <!--begin::Card toolbar-->
        <div class="card-toolbar">
            <!--begin::Toolbar-->
            <div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">

                <!--begin::Filter-->
                <button type="button" class="btn btn-light-primary me-3 btn-rounded" data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end">
                    <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
                    <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                fill="currentColor" />
                        </svg>
                    </span>
                    <span>Filtrer</span>
                    <!--end::Svg Icon-->
                </button>
                <!--begin::Menu 1-->
                <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px mt-5" data-kt-menu="true">
                    <!--begin::Header-->
                    <div class="px-7 py-5">
                        <div class="fs-5 text-dark fw-bolder">Filtrer</div>
                    </div>
                    <!--end::Header-->
                    <!--begin::Separator-->
                    <div class="separator border-gray-200"></div>
                    <!--end::Separator-->
                    <!--begin::Content-->
                    <form >
                        <div class="px-7 py-5" data-kt-user-table-filter="form">
                            <!--begin::Input group-->

                            <div class="mb-10">
                                <label class="form-label fs-6 fw-bold">Code:</label>
                                   <input [(ngModel)]="filterModel.caisse" type="text"
                                class="form-control form-control-lg form-control-solid form-control-rounded " id="code"
                                placeholder="code" name="code" />

                            </div>




                              <div class="mb-10">
                                <label class="form-label fs-6 fw-bold">Status :</label>
                                <select [(ngModel)]="filterModel.status" name="status" id="status" class="form-select form-select-solid form-select-rounded fw-bolder" data-kt-select2="true" aria-label="Selectionner un status" data-allow-clear="true" data-kt-user-table-filter="two-step" data-hide-search="true">

                                    <option  value="">--Choisir--</option>
                                    <option  value="0" >Disponible</option>
                                    <option  value="1" >Affecté</option>
                                </select>
                            </div>

                            <div class="d-flex justify-content-end">
                                <button type="reset" class="btn btn-light btn-active-light-primary btn-rounded fw-bold me-2 px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="reset" (click)="initFilter()">Annuler</button>
                                <button type="submit" class="btn btn-primary btn-rounded fw-bold px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="filter" (click)="filterItem()">Valider</button>
                            </div>
                            <!--end::Actions-->
                        </div>
                    </form>

                        <!--begin::Header-->

                        <!--end::Header-->
                        <!--begin::Separator-->

                        <!--end::Separator-->
                        <!--begin::Content-->



                    <!--end::Content-->
                </div>
                <!--end::Menu 1-->
                <!--end::Filter-->


                <!--begin::Export Filter-->
                <div class="m-0">
                    <!--begin::Menu toggle-->
                    <button type="button" class="btn btn-light-primary btn-rounded me-3" data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end">
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                        <span class="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1"
                                    transform="rotate(90 12.75 4.25)" fill="currentColor" />
                                <path
                                    d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z"
                                    fill="currentColor" />
                                <path
                                    d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z"
                                    fill="#C4C4C4" />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->Exporter
                    </button>

                    <!--end::Menu toggle-->
                    <!--begin::Menu 1-->
                    <div class="menu menu-sub menu-sub-dropdown w-125px w-md-250px mt-5" data-kt-menu="true"
                        id="kt_menu_624476767c26c">
                        <!--begin::Header-->
                        <div class="px-7 py-5">
                            <div class="fs-5 text-dark fw-bolder">Exporter</div>
                        </div>
                        <!--end::Header-->
                        <!--begin::Menu separator-->
                        <div class="separator border-gray-200"></div>
                        <!--end::Menu separator-->
                        <!--begin::Form-->
                        <div class="px-7 py-5">
                            <div class="menu-item px-3">
                                <a class="menu-link px-3 text-dark text-hover-primary"
                                    (click)="export('pdf')"> PDF</a>
                            </div>
                            <!--end::Menu item-->
                            <!--begin::Menu item-->
                            <div class="menu-item px-3">
                                <a class="menu-link px-3 text-dark text-hover-primary"
                                    (click)="export('csv')"> Excel</a>
                            </div>
                        </div>
                        <!--end::Form-->
                    </div>
                    <!--end::Menu 1-->
                </div>
                <!--end::Export Filter-->


                <!--begin::Add user-->
                <button *ngIf="guard.pass(caisse.actions.ajoutCaisse)" (click)="openModal(addCaisse)"  type="button" class="btn btn-primary btn-rounded"
                    data-bs-toggle="modal" data-bs-target="#kt_modal_add_user">
                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr075.svg-->
                    <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                transform="rotate(-90 11.364 20.364)" fill="currentColor" />
                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor" />
                        </svg>
                    </span>
                    <span translate="BUTTONS.ADD_BUTTON"></span>
                    <!--end::Svg Icon-->
                </button>
                <!--end::Add user-->
            </div>
            <!--end::Toolbar-->

        </div>
        <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card-body py-4">
      <div id="kt_table_users_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="table-responsive">
            <div class="loaderPage" *ngIf="loading">
                <div class="lds-roller " >
                  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
              </div>
          <!--begin::Table-->
          <table class="table align-middle table-row-dashed fs-6 gy-5">
                    <thead >
                    <tr class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                      <th class="">N°</th>
                      <th class="">Code caisse</th>
                      <th class="" >Station</th>
                      <th class=" text-right">Solde</th>
                      <th class=" text-right">Solde cash</th>
                      <th class=" "> Date</th>
                      <th class=" ">Statut</th>
                    </tr>
                    </thead>
                    <tbody class="text-gray-700 fw-bold">

                <tr *ngFor="let one of caisses | paginate: { itemsPerPage: perPage, currentPage: page, totalItems: totalItems};">
                    <td>{{one?.numero}}</td>
                      <td>{{one?.code}}</td>
                      <td>{{one?.pointDeVente?.nom}}</td>
                      <td class="text-right fw-bold text-success">{{one?.solde | number}}</td>
                      <td class="text-right font-weight-bold">{{one?.soldeCash | number}}</td>
                      <td>{{one?.dateCreation|date:'short'}}</td>
                      <td [ngClass]="{'text-success':one.disponibilite==='Disponible','text-danger': one.disponibilite==='Affecte'}">{{one?.disponibilite}}</td>
                    </tr>
                    </tbody>
                  </table>
                <!--end::Table-->
            </div>
            <div class="row mt-5 mb-5">
                <div
                    class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                </div>
                <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <pagination-controls previousLabel="" nextLabel="" class=" atbd-pagination__link page-number" (pageChange)="paginate($event)"></pagination-controls>
                </div>
            </div>
        </div>
        <div  *ngIf="caisses.length===0 && !loading">
            <h1 class="styleTabVide mt-3"> No cash registers found </h1>
          </div>
    </div>
</div>
</div>
<!---
<ng-template #addCaisse let-modal>
    <div class="modal-header">
      <h3 class="modal-title" id="modal-basic-title">
    AJOUT CAISSE
      </h3>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>

        <div class="row">
          <div class="col-md-12  form-group">

            <div class="row mb-6">
                <label class="col-lg-12 col-form-label required fw-bold fs-6">Veuillez selectionner votre station</label>
            <select   name="pointService" id="agence_id" class="form-control"  [(ngModel)]="addCaisseModel.codeAgence" name="codeAgence">
              <option value="">--Choisir</option>
              <option   value="{{agence.code}}" *ngFor="let agence of stations;let i = index">{{agence.nom}}</option>
            </select>
          </div>
        </div>
    </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark btn-rounded" (click)="modal.dismiss('Cross click')">NON</button>
      <button type="button" class="btn btn-danger  btn-rounded" *ngIf="action"
      (click)="ajoutcaisse()"> OUI
      </button>
      <button type="button" class="btn btn-danger  btn-rounded" *ngIf="!action"
              (click)="modal.close('modif')">Modifier
      </button>
    </div>


  </ng-template>-->
  <ng-template #addCaisse let-modal>

    <!--begin::Modal content-->
    <div class="modal-content">
      <!--begin::Modal header-->
      <div class="modal-header">
        <!--begin::Modal title-->
        <h2 class="fw-bolder">  Nouvelle caisse </h2>
        <!--end::Modal title-->
        <!--begin::Close-->
        <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close"
             (click)="modalService.dismissAll()">
          <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
          <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                              transform="rotate(-45 6 17.3137)" fill="currentColor"/>
                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                              fill="currentColor"/>
                    </svg>
                </span>
          <!--end::Svg Icon-->
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
        <!--begin::Form-->
        <form id="kt_modal_update_email_form" class="form">
          <!--begin::Notice-->
          <!--begin::Notice-->
          <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
            <!--begin::Icon-->
            <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->
            <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                            <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)"
                                  fill="currentColor"/>
                            <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)"
                                  fill="currentColor"/>
                        </svg>
                    </span>
            <!--end::Svg Icon-->
            <!--end::Icon-->
            <!--begin::Wrapper-->
            <div class="d-flex flex-stack flex-grow-1">
              <!--begin::Content-->
              <div class="fw-bold">
                <div class="fs-6 text-gray-700">Veuillez remplir les champs ci-dessous</div>
              </div>
              <!--end::Content-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Notice-->
          <!--end::Notice-->
          <!--begin::Input group-->
          <div class="fv-row mb-7">
            <!--begin::Label-->
            <label class="fs-6 fw-bold form-label mb-2">
              <span class="required">Station</span>
            </label>
            <!--end::Label-->
            <!--begin::Input-->
            <select [(ngModel)]="addCaisseModel.codeAgence" name="codeAgence" aria-label="Selectionner une caisse"
                    data-control="select2"
                    data-placeholder="Selectionner une caisse..."
                    class="form-select form-select-solid form-select-rounded form-select-lg fw-bold select2-hidden-accessible"
                    data-select2-id="select2-data-10-men3" tabindex="-1" aria-hidden="true">
              <option value="" data-select2-id="select2-data-12-qv2a">Select --Station...</option>
              <option   value="{{agence.code}}" *ngFor="let agence of stations;let i = index">{{agence.nom}}</option>

            </select>
            <!--end::Input-->
          </div>


          <!--end::Input group-->
          <!--begin::Actions-->
          <div class="text-center pt-15">
            <button (click)="closeAndReset()" type="reset" class="btn btn-light me-3 btn btn-rounded"
                    data-kt-users-modal-action="cancel" translate="BUTTONS.CANCEL_BUTTON"></button>
            <button (click)="ajoutcaisse()" [disabled]="loading" type="submit" class="btn btn-primary btn btn-rounded"
                    data-kt-users-modal-action="submit">
              <span class="indicator-label" translate="BUTTONS.VALIDATE_BUTTON"></span>

            </button>
          </div>
          <!--end::Actions-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Modal body-->
    </div>
  </ng-template>
