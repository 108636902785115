import { Component, OnInit } from '@angular/core';
import { Endpoint } from 'src/app/models/Endpoint';
import { AuthService } from 'src/app/modules/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Role } from 'src/app/modules/auth';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {PageInfoService} from "../../../_metronic/layout";
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  loading : boolean
  totals:number = 0;
  itemId : number = 0
  page = 1;
  query = '';
  items:any = [];
  itemModel = new Role();
  pageSize : number = 10;
  filterForm : any ;
  searchText : string;
  searchTextNotifier  = new Subject();
  titlePage:any=[
    {
      title: 'Role',
      path: '/utilisateurs/list',
      isSeparator: true
    },

    {
      title: 'Details role',
      path: '/role/list',
      isSeparator: false
    }
  ]
  constructor(private pageInfo:PageInfoService, private authService :AuthService, private activatedRoute: ActivatedRoute, private toastr: ToastrService, private fb : FormBuilder) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.itemId = params['id'];
      console.log("The item id ", this.itemId)
      this.pageInfo.updateTitle('Admistrateur');
      this.pageInfo.updateBreadcrumbs(this.titlePage);
    });

    if(this.itemId){
      this.getItem()
      this.initFilter();
      this.searchTextNotifier.pipe(debounceTime(250)).subscribe(text => this.search());
    }

  }

  initFilter() {
    this.filterForm = this.fb.group({profiles: [],});
    this.page = 1;
    this.query += `&profiles.id=${this.itemId}`;
    this.searchText = '';
    this.getList();
  }

  search($next : Boolean = false){
    if($next) this.searchTextNotifier.next('')
    else{
      this.searchText == '' ? this.query = '' : this.query = `&email=${this.searchText}`;
      this.getList();
    }
  }


  getItem(){
    this.loading = true;
    this.authService.get(this.itemId, Endpoint.ROLES).subscribe((res:any)=>{
      console.log('bintiss ', res)
      this.itemModel = res;
      this.loading = false;
    },error => {
      console.log('error oneuser',error)
      this.loading = false;
      this.itemId = 0;
    })
  }

  getList(){
    this.loading =true
    this.authService.getAll(Endpoint.USERS,this.page,this.query).subscribe((res:any)=>{
    console.log('fatouiss ', res['hydra:member']);
    this.items = res['hydra:member'];
    this.totals = res['hydra:totalItems'];
    this.loading =false;
    },error => {
      console.log('error',error)
      this.loading =false;
    })
  }

  paginate(p: number){
    this.page = p
    this.getList()
  }

}
