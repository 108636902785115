import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Entity } from 'src/app/models/entity.model';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  entite:Entity;
  societe:any;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.entite =  JSON.parse(<string>localStorage.getItem('entity'));
    console.log('entitess', this.entite)
    this.societe =  JSON.parse(<string>localStorage.getItem('societe'));
    this.setPrimaryColor('#bc1423');
  }


  calculateMenuItemCssClass = (url: string): string => checkIsActive(this.router.url, url) ? 'active' : '';

  computeMenuItemCssClass = (urls: any = []): string => urls.some((element: any) => this.router.url.indexOf(element) != -1 ) ? 'active' : '';

  setPrimaryColor(colour:string) {
    const body = document.getElementsByTagName('body')[0];
    body.style.setProperty('--entity-primary', colour);
  }
}

const getCurrentUrl = (pathname: string): string => pathname.split(/[?#]/)[0];

const checkIsActive = (pathname: string, url: string) => {
  if (!getCurrentUrl(pathname) || !url) return false;
  if (getCurrentUrl(pathname) === url) return true;
  if (getCurrentUrl(pathname).indexOf(url) > -1) return true;
  return false;
};
