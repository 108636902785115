import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { Endpoint } from 'src/app/models/Endpoint';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';



import {

  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexAnnotations,
  ApexFill,
  ApexStroke,
  ApexGrid,
  ApexNonAxisChartSeries,
  ApexResponsive,



} from "ng-apexcharts";
import { Station } from 'src/app/models/Models';
import {PageInfoService} from "../../_metronic/layout";




export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  annotations: ApexAnnotations;
  fill: ApexFill;
  stroke: ApexStroke;
  grid: ApexGrid;
  responsive: ApexResponsive[];
  labels: any;



};


@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss']
})

export class DashboardsComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: any;
  activity_numbers = 0;
  roles : any [] = [];
  total_roles: number = 0
  users : any = [];
  total_users: number = 0
  societes : any  = [];
  mois : any  = [];
  total_societes: number = 0
  totals: number = 0
  stations : any [] = [];
  cartesSocietes:any=[];
  total_stations: number = 0
  caisses : any [] = [];
  total_caisses: number = 0
  cartes : any [] = [];
  total_cartes: number = 0
  bons : any [] = [];
  total_bons: number = 0
  caissiers : any [] = [];
  total_caissiers: number = 0
  clients:any =[];
  nbCartes:any=[];
  listCartesAtt:any =[];
  total_clients: number = 0;
  totalsAtt: number = 0;
  loading2 : any = false ;
  loading3 : any = false ;
  loading4 : any = false ;
  loading5 :any =false;
  loading6 :any =false;
  loading: boolean;
  page = 1;
  query = '';
libelleStation:any  = []
libelleSociete:any  = []
montantByStation:any  = []
  nbreByStation:any  = []
  nbreBySociete:any  = []
  nbreBySocieteBon:any  = []
  libelleSocieteBon:any=[]
  picked: any [] = []  ;
  picked1: any [] = []  ;
  picked2:any[]=[];

  titlePage:any=[
    {
      title: '',
      path: '',
    }
  ]
  constructor(private service : GlobalService, private router: Router, private authService : AuthService,private pageInfo : PageInfoService)
  {
}



  ngOnInit(): void {
    this.pageInfo.updateTitle('Dashboard');
    this.pageInfo.updateBreadcrumbs(this.titlePage);
    this.getCartes()
    this.getBons()
    this.getVentesParBonSociete()
     this.getSocietes()
    this.getStations()
    this.getCaissers()
    this.getClients()

    this.getListeCarteAtt()
    this.getVentesMois()
    this.getVentesParCarteSociete()
    this.initChart()
    this.getVentesParstation()

  }




  getClients(){
    this.loading2 =true;
    this.service.getListeStation(Endpoint.CLIENTS).subscribe((res:any)=>{
    console.log('SOCIETES here ', res['hydra:member']);
    this.clients = res['hydra:member'];
    //this.picked1 = this.societes.map((item:any) => item?.nom)

    console.log("Picked items here ", this.picked1)
    this.total_clients = res['hydra:totalItems'];
    this.loading2 =false;
    this.initChart()
    },error => {
      console.log('error',error)
      this.loading3 =false;

    })
  }
  getCaissiers(){
    this.loading2 =true;
    this.service.getListeStation(Endpoint.TRANSACTION_LISTE).subscribe((res:any)=>{
    console.log('SOCIETES here ', res['hydra:member']);
    this.caissiers = res['hydra:member'];
    //this.picked1 = this.societes.map((item:any) => item?.nom)
    console.log("Picked items here ", this.picked1)
   // this.total_caissiers = res['hydra:totalItems'];
    this.loading2 =false;
    this.initChart()
    },error => {
      console.log('error',error)
      this.loading3 =false;

    })
  }


  getSocietes(){
    this.loading2 =true;
    this.service.getListeStation(Endpoint.SOCIETE).subscribe((res:any)=>{
    console.log('SOCIETES here ', res['hydra:member']);
    this.societes = res['hydra:member'];
    this.picked1 = this.societes.map((item:any) => item?.nom)

    console.log("Picked items here ", this.picked1)
    this.total_societes = res['hydra:totalItems'];
    this.loading2 =false;
    this.initChart()
    },error => {
      console.log('error',error)
      this.loading3 =false;

    })
  }
  getVentesMois(){
    this.loading2 =true;
    this.service.getListeStation(Endpoint.REPORTING_MENSUEL).subscribe((res:any)=>{
    console.log('SOCIETES here ', res['hydra:member']);
    this.mois = res.data;
    //this.picked2 = this.mois.map((item:any) => item)
    console.log("mois -> ", this.mois.Janvier)
    this.totals = res['hydra:totalItems'];
    this.loading2 =false;
    this.initChart()
    },error => {
      console.log('error',error)
      this.loading3 =false;

    })
  }
  getVentesParstation(){
    this.loading2 =true;
    this.service.getListeStation(Endpoint.REPORTING_VENTE_STATION).subscribe((res:any)=>{
    console.log('station report here ', res['data']);
      this.stations = res.data.data;
      res['data'].map((item:any) => {
        this.nbreByStation.push(item.nb);
        this.montantByStation.push(item.montant);
        this.libelleStation.push(item.codeAgence);
      })
    this.loading2 =false;
    this.initChart()
    },error => {
      console.log('error',error)
      this.loading3 =false;

    })
  }

  getVentesParCarteSociete(){
    this.loading2 =true;
    this.service.getListeStation(Endpoint.REPORTING_CARTE_SOCIETE).subscribe((res:any)=>{
    //console.log('nbreBySociete report here ', res['data']);
      res['data'].map((item:any) => {
        if(this.total_cartes>0){
          this.nbreBySociete.push(item.nb*100/this.total_cartes);
        }else {
          this.nbreBySociete.push(item.nb*100);
        }

        //console.log('nbreBySociete' ,this.nbreBySociete)
        //this.montantByStation.push(item.montant);
        this.libelleSociete.push(item.nom);
      })
    this.loading2 =false;
    this.initChart()
    },error => {
      console.log('error',error)
      this.loading3 =false;

    })
  }
  getVentesParBonSociete(){
    this.loading2 =true;
    this.service.getListeStation(Endpoint.REPORTING_BONS_SOCIETE).subscribe((res:any)=>{
    //console.log('nbreBySocieteBon report here ', res['data']);
      res['data'].map((item:any) => {
        if(this.total_bons>0){
          this.nbreBySocieteBon.push(item.nb*100/this.total_bons);
        }else
          this.nbreBySocieteBon.push(item.nb * 100);

        this.libelleSocieteBon.push(item.nom);
        //console.log('libellesocieteBon', this.libelleSocieteBon)
      })
    this.loading2 =false;
    this.initChart()
    },error => {
      console.log('error',error)
      this.loading3 =false;

    })
  }
  getStations(){
    this.loading3 =true;
    this.service.getAllReseau(Endpoint.STATIONS).subscribe((res:any)=>{
    console.log('partenaires here ', res['hydra:member']);
    this.stations = res['hydra:member'];
    this.picked = this.stations.map(item => item?.nom)
    console.log("Picked items here ", this.picked)
    this.total_stations = res['hydra:totalItems'];
    this.loading3 =false;
    this.initChart()
    },error => {
      console.log('error',error)
      this.loading3 =false;

    })
  }
  getClient(){
    this.loading3 =true;
    this.service.getListeStation(Endpoint.CLIENTS).subscribe((res:any)=>{
    console.log('partenaires here ', res['hydra:member']);
    this.clients = res['hydra:member'];
    //this.picked = this.stations.map(item => item?.nom)
    //console.log("Picked items here ", this.picked)
    this.total_clients = res['hydra:totalItems'];
    this.loading3 =false;
    //this.initChart()
    },error => {
      console.log('error',error)
      this.loading3 =false;

    })
  }

  getCaissers(){
    this.loading3 =true;
    this.service.getAllReseau(Endpoint.CAISSE_STATION).subscribe((res:any)=>{
    console.log('partenaires here ', res['hydra:member']);
    this.caissiers = res['hydra:member'];
    //this.picked = this.stations.map(item => item?.nom)
    //console.log("Picked items here ", this.picked)
    this.total_caissiers = res['hydra:totalItems'];
    this.loading3 =false;
    //this.initChart()
    },error => {
      console.log('error',error)
      this.loading3 =false;

    })
  }



  getCartes(){
    this.loading5 = true;
    this.service.getListeStation(Endpoint.LISTECARBURANT).subscribe({
      next : (res : any) => {
        this.loading5 =false;
        this.cartes = res['hydra:member'];
        this.total_cartes = res['hydra:totalItems'];
        console.log('entities here ', res['hydra:member']);
      },
      error: err => {
        console.log('error',err)

        this.loading5 =false;
      }})
  }

  getBons(){
    this.loading6 = true;
    this.service.getListeStation(Endpoint.BONS).subscribe({
      next : (res : any) => {
        this.loading6 =false;
        this.bons = res['hydra:member'];
        this.total_bons = res['hydra:totalItems'];
        console.log('entities here ', res['hydra:member']);
      },
      error: err => {
        console.log('error',err)

        this.loading6 =false;
      }})
  }







  getListeCarteAtt(){
    this.loading = true;
    // splash-screen
    // splash-screen
    this.service.getListeStation(Endpoint.CARTE_ATTRIBUTIONS,this.page,this.query).subscribe({
      next: (res:any) => {
        this.loading =false;
        this.listCartesAtt = res['hydra:member'];
        console.log('listCartes', res)
        this.totalsAtt = res['hydra:totalItems']
      },
      error: (err: any) => {
        this.loading = false;

      }
    })
  }


  initChart(){
    console.log('nbreBySociete test', this.nbreBySociete)
    this.chartOptions = {
      series5: this.nbreByStation,
      chart5: {
        width: 450,
        type: "donut"
      },
      legend5: {
        position: "bottom"
      },
      responsive5: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend5: {
              position: "bottom"
            }
          }
        }
      ],
      labels5: this.libelleStation,


      title: {
        text: ""
      },
      title1: {
        text: "Montant ventes par station"
      },

      series1: this.nbreBySociete,
      //labels6: this.libelleSocieteBon,
      series3: this.nbreBySocieteBon,

      chart1: {
        width: 430,
        type: "pie"
      },
       labels: this.libelleSociete,
       labels6: this.libelleSocieteBon,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend2: {
              position: "bottom"
            }
          }
        }
      ],
      series: [
        {
          name: "Montant",
          data: this.montantByStation
        }
      ],
      series2: [
        {
          name: "Nombre",
          data: [parseInt(this.mois?.Janvier?.nb), parseInt(this.mois?.Février?.nb), parseInt(this.mois?.Mars?.nb), parseInt(this.mois?.Avril?.nb), parseInt(this.mois?.Mai?.nb), parseInt(this.mois?.Juin?.nb), parseInt(this.mois?.Juillet?.nb), parseInt(this.mois?.Août?.nb), parseInt(this.mois?.Septembre?.nb),parseInt(this.mois?.Octobre?.nb), parseInt(this.mois?.Novembre?.nb), parseInt(this.mois?.Décembre?.nb)]
        }
      ],
      series4: [
        {
          name: "Montant",
          data: [parseInt(this.mois?.Janvier?.montant), parseInt(this.mois?.Février?.montant), parseInt(this.mois?.Mars?.montant), parseInt(this.mois?.Avril?.montant), parseInt(this.mois?.Mai?.montant), parseInt(this.mois?.Juin?.montant), parseInt(this.mois?.Juillet?.montant), parseInt(this.mois?.Août?.montant), parseInt(this.mois?.Septembre?.montant), parseInt(this.mois?.Octobre?.montant), parseInt(this.mois?.Novembre?.montant), parseInt(this.mois?.Décembre?.montant)]
        }
      ],
      colors6: ["#b77f7f"],
      colors3: ["#678160"],
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0"
              },
              text: "Bananas are good"
            }
          }
        ]
      },
      chart: {
        height: 350,
        type: "bar"
      },
      colors5: ["#00e396"],
      colors4: ["#f8edbb"],
      chart6: {
        height: 350,
        type: "line"
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2
      },

      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"]
        }
      },
      xaxis1: {
        labels: {
          rotate: -45
        },
        categories: [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Aout",
          "Septembre",
          "Octobre",
          "Novembre",
          "Decembre",

        ],
        tickPlacement: "on"
      },
      xaxis: {
        labels: {
          rotate: -45
        },
        categories:this.libelleStation,

        tickPlacement: "on"
      },
      yaxis: {
        title: {
          text: "Nombre de ventes"
        }
      },
      yaxis2: {
        title: {
          text: "Montant ventes"
        }
      },

      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100]
        }
      }
    };
  }

}
