import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import {UserPermissions} from "../../../shared/user_permissions";
import {PageInfoService} from "../../../_metronic/layout";
import {ToastrService} from "ngx-toastr";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AuthService} from "../../../modules/auth";
import {GlobalService} from "../../../services/global.service";
import {FormBuilder} from "@angular/forms";
import {Guard} from "../../../shared/utils/guard";
import {debounceTime} from "rxjs/operators";
import {Endpoint} from "../../../models/Endpoint";
import {ConfirmPinComponent} from "../../confirm-pin/confirm-pin.component";
import * as saveAs from "file-saver";

@Component({
  selector: 'app-reporting-societe',
  templateUrl: './reporting-societe.component.html',
  styleUrls: ['./reporting-societe.component.scss']
})
export class ReportingSocieteComponent implements OnInit {

  loading: boolean;
  page = 1;
  query = '';
  queryExport = '';
  items:any = [];
  reports:any = [];
  totals:number = 0;
  pageSize : number = 10;
  profils:any;
  societes : any [] = [];
  searchText : string;
  searchTextNotifier  = new Subject();
  page_error : boolean = false;
  filterModel:any = {};
  clientSelect :any
  public autority = UserPermissions.MANAGE_Print
  public transaction=UserPermissions.MANAGE_Transactions;
  titlePage:any=[

    {
      title: 'Transactions',
      path: '/transaction/list',
      isSeparator: true
    },
    {
      title: 'Liste',
      path: '/transaction/list',
      isSeparator: false
    }
  ]
  constructor(private pageInfo:PageInfoService,private toastr:ToastrService, private modalService:NgbModal,  private authService : AuthService,private service:GlobalService, private fb : FormBuilder, public guard:Guard) { }
  ngOnInit(): void {
    this.getSociete();
    this.pageInfo.updateTitle('Transactions');
    this.pageInfo.updateBreadcrumbs(this.titlePage);
    const year=new Date().getFullYear()
    this.filterModel.debut = year+"-01-01";
    this.filterModel.fin = year+"-12-31";
    this.filterItem();
  }

  filterItem() {
    this.query = '';
    this.page = 1;
    if (this.filterModel.numTransaction) this.query += `&numTransaction=${this.filterModel.numTransaction}`;
    if (this.filterModel.status) this.query += `&status=${this.filterModel.status}`;
    if (this.filterModel.debut) {
      this.query += `&dateCreation[after]=${this.filterModel.debut}`;
      this.queryExport += `&dateCreation[after]=${this.filterModel.debut}`;
    }
    if (this.filterModel.fin) {
      this.query += `&dateCreation[before]=${this.filterModel.fin}`;
      this.queryExport += `&dateCreation[before]=${this.filterModel.fin}`;
    }if (this.filterModel.societe) {
      this.query += `&societe.code=${this.filterModel.societe}`;
      this.queryExport += `&societe.code=${this.filterModel.societe}`;
    }
    this.getReport()
  }
  getSociete(){
    let query = ``;
    this.service.getListeStation(Endpoint.SOCIETE, 1, query).subscribe((res:any)=>{
      this.societes = res['hydra:member'];
      this.societes.map((i:any) => { i.uri = ['/api/societes/' + i.id]; return i; });
    },error => {
    })
  }
  paginate(p: number){
    console.log("event -> ", p)
    this.page = p
    this.getReport()
  }
  export(type = '') {
    this.loading = true;
    if (type == 'pdf') {
      const fileName = 'transaction.pdf';
      this.service.exportStation(Endpoint.TRANSACTION_PDF, this.query).subscribe((res:any) => {
        this.loading = false;
        saveAs(res.body, fileName);
      }, error => {
        this.loading = false;
      });
    } else {
      const fileName = 'transaction.csv';
      this.service.exportStation(Endpoint.TRANSACTION_CSV, this.query).subscribe((res:any) => {
        this.loading = false;
        saveAs(res.body, fileName);
      }, error => {
        this.loading = false;
      });
    }
    return;
  }
  getReport(){
    this.loading =true
    this.service.getListeStation(Endpoint.APPROCOMMISSION,1,this.queryExport).subscribe((res:any)=>{
      console.log('APPROCOMMISSION', res['hydra:member']);
      this.items = res['hydra:member'];
      this.loading =false;
    },error => {
      console.log('error',error)
      this.loading =false;
    })
  }

}
