import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-blockui',
  template: `
    <div *ngIf="blocking" class="blockui rounded">
      <div style="align-self: center;">
            <img style="width: 50px !important;" src="/src/assets/media/spinner.gif" />
       </div>
    </div>
  `,
  styleUrls: ['./blockui.component.scss']

})
export class BlockuiComponent implements OnInit {

  @Input('block') blocking = false;

  constructor() { }

  ngOnInit(): void {
  }

}
