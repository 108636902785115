import { Injectable } from '@angular/core';
import {CanActivate,ActivatedRouteSnapshot,RouterStateSnapshot,} from '@angular/router';
import { AuthService } from 'src/app/modules/auth';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const jwtToken = this.authService.getJwtToken();
    if (jwtToken) return true;
    this.authService.logout();
    return false;
  }
}
