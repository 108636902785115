import {Component, OnInit, TemplateRef} from '@angular/core';
import {AuthService, UserModel} from "../../../modules/auth";
import {Subject} from "rxjs";
import {FormBuilder} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {debounceTime} from "rxjs/operators";
import {Endpoint} from "../../../models/Endpoint";
import * as saveAs from "file-saver";
import {ToastrService} from "ngx-toastr";
import { PageInfoService } from 'src/app/_metronic/layout';

@Component({
  selector: 'app-user-wait',
  templateUrl: './user-wait.component.html',
  styleUrls: ['./user-wait.component.scss']
})
export class UserWaitComponent implements OnInit {

  loading: boolean;
  page = 1;
  query = '';
  items:any = [];
  totals:number = 0;
  pageSize : number = 10;
  profils:any;
  // filterForm : any ;
  filterModel:any = {};
  roles : any [] = [];
  searchText : string;
  itemModel = new UserModel();
  itemId : any = null;
  searchTextNotifier  = new Subject();
  titlePage:any=[
    {
      title: 'Dashboard',
      path: '/',
      isSeparator: true
   },
    {
      title: 'Utilisateurs en attente',
      path: '/utilisateurs/list',
      isSeparator: true
   },
    {
      title: 'Liste',
      path: '/utilisateurs/list',
      isSeparator: false
    }
  ]
  constructor(  private authService : AuthService, private fb : FormBuilder,  public modalService :NgbModal, private toastr: ToastrService,private pageInfo:PageInfoService) { }
  ngOnInit(): void {
    this.initFilter();
    this.searchTextNotifier.pipe(debounceTime(250)).subscribe(text => this.search());
    this.getRoles();
    this.pageInfo.updateTitle('Admistrator');
    this.pageInfo.updateBreadcrumbs(this.titlePage);

  }

  initFilter() {
    this.filterModel = this.fb.group({
      role: [],
      status: [],
    });
    this.query = '';
    this.searchText = '';
    this.getList();
  }

  filterItem() {
    const tel = this.filterModel.tel?.toString().substring(4)
    this.query = '';
    this.page = 1;
    // if (this.filterModel.value.role) this.query += `&profiles.id=${this.filterModel.value.role}`;
    if (this.filterModel.user) this.query += `&username=${this.filterModel.user}`;
    if (tel) this.query += `&tel=${this.filterModel.tel.toString().substring(4)}`;
    if (this.filterModel.codeclient) this.query += `&agence=${this.filterModel.codeclient}`;
    if (this.filterModel.profile) this.query += `&profiles.id=${this.filterModel.profile}`;
    if (this.filterModel.email) this.query += `&email=${this.filterModel.email}`;
    if (this.filterModel.codeSociete) this.query += `&codeSociete=${this.filterModel.codeSociete}`;
    if (this.filterModel.prenom) this.query += `&prenom=${this.filterModel.prenom}`;
    if (this.filterModel.nom) this.query += `&nom=${this.filterModel.nom}`;
    if (this.filterModel.status !==-1) this.query += `&status=${this.filterModel.status}`;

    this.getList();

  }

  getRoles(){
    let query = ``;
    this.authService.getAll(Endpoint.ROLES_CARBURANT, 1, query).subscribe((res:any)=>{
      console.log('roles ',res);
      this.roles = res['hydra:member'];
      this.roles.map((i:any) => { i.uri = ['/api/roles/' + i.id]; return i; });
    },error => {
      console.log('error',error)
    })
  }



  search($next : Boolean = false){
    if($next) this.searchTextNotifier.next('')
    else{
      if(this.searchText == ''){
        this.query = ''
      }
      else{
        this.query += `&search=${this.searchText}`;
        this.page = 1
      }
      this.getList();
    }
  }

  getList(){
    this.loading =true
    this.authService.getAll(Endpoint.USERS_ENATTENTE,this.page,this.query).subscribe((res:any)=>{
      console.log('items here ', res['hydra:member']);
      this.items = res['hydra:member'];
      this.totals = res['hydra:totalItems'];
      this.loading =false;
    },error => {
      console.log('error',error)
      this.loading =false;
    })
  }
  activate(index:number) {

    this.authService.activateUser(this.items[index].id)
      .subscribe(res => {
        this.toastr.success('Compte activé avec succés!')
        this.items[index].status = 1
        this.getList();
      }, err => {
        console.log(err)
        this.toastr.error(err.error.detail)
      },()=> {
      })
  }
  paginate(p: number){
    console.log("event -> ", p)
    this.page = p
    this.getList()
  }

  changeStatus(id : any, item : any){
    this.authService.upsert(id, Endpoint.USERS, { status: !item.status })?.subscribe({
      next: (res : any) => {
        item.status = !item.status;
        console.log(res);
      },
      error: err => {
        console.log('error',err)
        this.loading =false;
      }
    })
  }


  export(type = '') {

    this.loading = true;
    if (type == 'pdf') {
      const fileName = 'utilisateurs.pdf';
      this.authService.export(Endpoint.USERS_PDF, this.query+'&status=2').subscribe((res:any) => {
        this.loading = false;
        saveAs(res.body, fileName);
      }, error => {
        this.loading = false;
      });
    } else {
      const fileName = 'utilisateurs.csv';
      this.authService.export(Endpoint.USERS_CSV, this.query+'&status=2').subscribe((res:any) => {
        this.loading = false;
        saveAs(res.body, fileName);
      }, error => {
        this.loading = false;
      });
    }
    return;
  }


  pageChange(page:any){
    this.page=page;
    this.getList();
  }

  openModal(dedail: TemplateRef<any>,id:number) {
    this.getdetails(id,dedail);
  }
  getdetails(id:number,content:TemplateRef<any>) {
    this.loading=true;
    this.authService.getAll(Endpoint.USERS+'/'+id).subscribe({
      next: (res:any) => {
        this.loading = false;
        console.log('res detail user',res)
        this.itemModel = res;
        this.itemModel.profiles = res?.profiles[0].nom;
        this.modalService.open(content);
      },
      error: (err: any) => {
        this.loading = false;
      }
    })
  }
  closeAndReset() {

    this.modalService.dismissAll()
  }

}
