import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { AuthService } from 'src/app/modules/auth';
import { Endpoint } from 'src/app/models/Endpoint';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tables-widget10',
  templateUrl: './tables-widget10.component.html',
})
export class TablesWidget10Component implements OnInit {

  @Input('items') items : any [] = [];
  @Input('total') total : number = 0;
  @Input('loading') loading : boolean = false;

  constructor(private authService : AuthService, private toastr : ToastrService, private router: Router) {}

  ngOnInit(): void {}

  changeStatus(id : any, item : any){
    this.loading = true;
    this.authService.upsert(id, Endpoint.MEMBRES, { status: !item.status })?.subscribe({
      next: (res : any) => {
        item.status = !item.status;
        this.loading = false;
        this.toastr.success('Mise à jour réussie')
      },
      error: err => {
        this.loading =false;
        this.toastr.error('Une erreur est survenue')
      }
    })
  }

}
