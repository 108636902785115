import { Component, OnInit } from '@angular/core';
import {Endpoint} from "../../models/Endpoint";
import {GlobalService} from "../../services/global.service";
import {AuthService} from "../../modules/auth";
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-dasboard-societe',
  templateUrl: './dasboard-societe.component.html',
  styleUrls: ['./dasboard-societe.component.scss']
})
export class DasboardSocieteComponent implements OnInit {
  loading: boolean=false;
  stations:any =[];
  stationsStats:any =[];
  pompistStats:any =[];
  totalStations:number =0;
  totalPompiste:number =0;
  itemModel: any={};
  users:any = [];
  datedeb =""
  datefin= ""
  query=""
  constructor(private service :GlobalService,private authService : AuthService) { }

  ngOnInit(): void {
    this.datedeb = new Date().toISOString().slice(0, 10);
    this.datefin = new Date().toISOString().slice(0, 10);
this.filterItems()
    this.getStation()
    this.getListUserP()
  }
  getStation(){
    let query = ``;
    this.service.getAllReseau(Endpoint.STATIONS, 1, '').subscribe((res:any)=>{
      res['hydra:member'].map((i:any) => { i.uri = '/api/point_de_ventes/' + i.id; return i; });
      this.stations = res['hydra:member'];
      this.totalStations = res['hydra:totalItems'];

    },error => {
      console.log('error',error)
    })
  }
  getListUser(e:any){
    console.log('eeee = >>',e)
    this.loading =true
    this.authService.getAll(Endpoint.USERS_CARBURANT,null,`&agence=${e}&profiles.id=218`).subscribe((res:any)=>{

      this.users = res['hydra:member'];
      this.users.map((i:any) => { i.pompiste = i.prenom +' ' + i.nom; return i; });
      this.loading =false;
    },error => {
      this.loading =false;
    })
  }
  getListUserP(){

    this.loading =true
    this.authService.getAll(Endpoint.USERS_CARBURANT,null,`&profiles.id=218`).subscribe((res:any)=>{
      this.totalPompiste = res['hydra:totalItems'];
      this.loading =false;
    },error => {
      this.loading =false;
    })
  }
  loading2 =false
  getVentesParstation(){
    this.loading2 =true;
    this.service.getListeStation(Endpoint.TOTAL_VENTES_STATION,null,this.query).subscribe((res:any)=>{
      console.log('station report here ', res['data']);
      this.stationsStats = res['hydra:member'];
      this.loading2 =false;

    },error => {
      console.log('error',error)
      this.loading2 =false;

    })
  }
  getVentesParPompiste(){
    this.loading2 =true;
    this.service.getListeStation(Endpoint.TOTAL_VENTES_POMPISTE,1,this.query).subscribe((res:any)=>{
      console.log('station report here ', res['data']);
      this.pompistStats = res['hydra:member'];
      this.loading2 =false;

    },error => {
      console.log('error',error)
      this.loading2 =false;

    })
  }
  montantVente=0
  getTotalVente(){
    this.loading =true
    this.service.getListeStation(Endpoint.TOTAL_VENTES,1,this.query).subscribe((res:any)=>{
      this.montantVente =  res['hydra:member'][0].montantTotal
      this.loading =false
    },error => {
      console.log('error',error)
      this.loading =false

    })
  }

  filterItems() {
    console.log('filter', this.datedeb)
    if (this.datedeb) {
      this.query += `&dateCreation[after]=${this.datedeb}`;

    }
    if (this.datefin) {
      this.query += `&dateCreation[before]=${this.datefin}`;
    }
    this.getVentesParstation();

    this.getTotalVente()
    this.getVentesParPompiste()
  }
}
