import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as saveAs from 'file-saver';
import { Endpoint } from 'src/app/models/Endpoint';
import { AuthService } from 'src/app/modules/auth';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from 'src/app/modules/auth';
import { Constantes } from 'src/app/shared/constantes';
import { UserPermissions } from 'src/app/shared/user_permissions';
import { Guard } from 'src/app/shared/utils/guard';
import {ConfirmPinComponent} from "../../confirm-pin/confirm-pin.component";
import { ToastrService } from 'ngx-toastr';
import {PageInfoService} from "../../../_metronic/layout";
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  loading: boolean;
  page = 1;
  roleselect:any=[]
  view_regenPassword = false;
  view_regenCodePin = false;
  query = '';
  items:any = [];
  totals:number = 0;
  pageSize : number = 10;
  profils:any;
  // filterForm : any ;
  filterModel:any = {};
  roles : any [] = [];
  searchText : string;
  itemModel = new UserModel();
  itemId : any = null;
  public users=UserPermissions.MANAGE_Default
  titlePage:any=[

    {
      title: 'Utilisateurs',
      path: '/utilisateurs/list',
      isSeparator: true
   },
    {
      title: 'Liste',
      path: '/utilisateurs/list',
      isSeparator: false
    }
  ]
   entite: any;
  constructor(private toastr : ToastrService,  private authService : AuthService, private pageInfo : PageInfoService,  public modalService :NgbModal, public guard:Guard) { }
  ngOnInit(): void {

    this.filterModel.status =-1
    this.initFilter();
    this.getRoles();
     this.pageInfo.updateTitle('Administrator');
    this.pageInfo.updateBreadcrumbs(this.titlePage);

    this.entite =  JSON.parse(<string>localStorage.getItem('entity'));

  }

  initFilter() {
    this.query = '';
    this.getList();
  }

  filterItem() {
    const tel = this.filterModel.tel?.toString().substring(4)
    this.query = '';
    this.page = 1;
    // if (this.filterModel.value.role) this.query += `&profiles.id=${this.filterModel.value.role}`;
    if (this.filterModel.user) this.query += `&username=${this.filterModel.user}`;
    if (tel) this.query += `&tel=${this.filterModel.tel.toString().substring(4)}`;
    if (this.filterModel.codeclient) this.query += `&agence=${this.filterModel.codeclient}`;
    if (this.filterModel.profile) this.query += `&profiles.id=${this.filterModel.profile}`;
    if (this.filterModel.email) this.query += `&email=${this.filterModel.email}`;
    if (this.filterModel.codeSociete) this.query += `&codeSociete=${this.filterModel.codeSociete}`;
    if (this.filterModel.prenom) this.query += `&prenom=${this.filterModel.prenom}`;
    if (this.filterModel.nom) this.query += `&nom=${this.filterModel.nom}`;
    if (this.filterModel.status !==-1) this.query += `&status=${this.filterModel.status}`;

    this.getList();

  }

  getRoles(){
    let query = ``;
    this.authService.getAll(Endpoint.ROLES_CARBURANT, 1, query).subscribe((res:any)=>{
      this.roles = res['hydra:member'];
      this.roles.map((i:any) => { i.uri = ['/api/roles/' + i.id]; return i; });
    },error => {
    })
  }

  getList(){
    this.loading =true
    this.authService.getAll(Endpoint.USERS_CARBURANT,this.page,this.query).subscribe((res:any)=>{
    this.items = res['hydra:member'];
    this.totals = res['hydra:totalItems'];
    this.loading =false;
    },error => {
      this.loading =false;
    })
  }

  paginate(p: number){
    this.page = p
    this.getList()
  }

  changeStatus(id : any, item : any){
    const dialogRef = this.modalService.open(ConfirmPinComponent, {centered: true, size:"md"});
    dialogRef.componentInstance.data = {
      message: "Etes vous sûr de vouloir changer le statut.",
      title: "Changement statut"
    };
    dialogRef.result
      .then((res) => {
        if(res.confirmed) {
          this.loading = true;
          this.authService.upsert(id, Endpoint.USERS, { status:Number(!item.status)  })?.subscribe({
            next: (res : any) => {
              item.status = Number(!item.status);
              this.toastr.success("Status modifié avec succés ")
              console.log(res);
              this.loading =false;
            },
            error: err => {
              console.log('error',err)
              this.toastr.error("Une erreur est survenue ")
              this.loading =false;
            }
          })
        }
        if (!res) {
          return;
        }
      });
}


  export(type = '') {
    this.loading = true;
    if (type == 'pdf') {
      const fileName = 'utilisateurs.pdf';
      this.authService.export(Endpoint.USERS_PDF, this.query).subscribe((res:any) => {
          this.loading = false;
          saveAs(res.body, fileName);
        }, error => {
          this.loading = false;
        });
    } else {
      const fileName = 'utilisateurs.csv';
      this.authService.export(Endpoint.USERS_CSV, this.query).subscribe((res:any) => {
          this.loading = false;
          saveAs(res.body, fileName);
        }, error => {
          this.loading = false;
        });
    }
    return;
  }

  pageChange(page:any){
    this.page=page;
    this.getList();
  }

  openModal(dedail: TemplateRef<any>,id:number) {
    this.getdetails(id,dedail);
  }
  getdetails(id:number,content:TemplateRef<any>) {
    this.loading=true;
    this.authService.getAll(Endpoint.USERS+'/'+id).subscribe({
      next: (res:any) => {
        this.loading = false;
        this.itemModel = res;
        this.itemModel.profiles = res?.profiles[0].nom;
        this.modalService.open(content);
      },
      error: (err: any) => {
        this.loading = false;
      }
    })
  }
  closeAndReset() {

    this.modalService.dismissAll()
  }



}
