import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/auth';
import { Endpoint } from 'src/app/models/Endpoint';
import { FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  loading :boolean = false;
  page = 1;
  saving: boolean
  totalItemsRole:number;
  currentPage:number = 1;

  page_error : boolean = false;

  pageSize : number = 10;
  query = '';
  roles:any = [];
  module:any;
  permissions:any;
  totals:number = 0;
  filterForm : any ;
  profils:any[] =[]
  modules:any =[];
  items:any = [];
  constructor(private authService: AuthService,private service:GlobalService,
    private _userService:GlobalService,private toastr:ToastrService,
    private fb : FormBuilder,
    ) { }

  ngOnInit(): void {
    const page = 1
    this.initFilter()
    this.getListPermission();
  }

  initFilter() {
    this.filterForm = this.fb.group({
      codeEntite: ['bgdigit'],
    });
    this.filterItem()
  }

  filterItem() {
    this.query = '';
    this.page = 1;
    if (this.filterForm.value.codeEntite) this.query += `&codeEntite=${this.filterForm.value.codeEntite}`;

  }

  //Prev page

 
 

  paginate(p: number){
    console.log("event -> ", p)
    this.page = p
    this. getListPermission()
  }



 

  

    //Recupére les permissions
    getListPermission() {
          this.loading =true
          this.service.getAl(Endpoint.PERMISSION,this.page,this.query).subscribe((res:any)=>{
          console.log('CCCCCC ', res['hydra:member']);
          this.items = res['hydra:member'];
          this.totals = res['hydra:totalItems'];
          this.loading =false;
          },error => {
            console.log('error',error)
            this.loading =false;
          })
        }
      
    

    //AJout/Supprime une permission
  }
