import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/auth';
import {Router} from "@angular/router";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private router: Router) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       let jwtToken = this.authService.getJwtToken();
        //let jwtToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpZCI6MywidXNlcm5hbWUiOiJzZWxsZSIsInJvbGVzIjpbIlJPTEVfQVVUSEVOVElGSUNBVEVEIiwiUk9MRV9BRE1JTklTVFJBVEVVUiJdLCJjb2RlQ2xpZW50IjoiQkdESUdJVCIsImNvZGVQb2ludERlVmVudGUiOm51bGwsImNvZGVDYWlzc2UiOm51bGwsImNvZGVTb2NpZXRlIjpudWxsLCJjb2RlRGlzdHJpYnV0ZXVyIjpudWxsLCJyZWdpb24iOm51bGwsImRlcGFydGVtZW50IjpudWxsLCJjb21tdW5lIjpudWxsLCJidXJlYXUiOm51bGwsIm5vbSI6IkJHIEFkbWluIiwiY2hhbmdlUGFzc3dvcmQiOmZhbHNlLCJwaW4iOiIiLCJleHBpcmUiOjE2NzIxNDAxNDB9.ay5DtkV6UoY5JmLgEkUhYryZ9-jUu152sJXmUu_eHJpaXw1E2EHSs7jTScwIBWls2z6bXSiXWf49KhfXM-Y3qypy-Q75g4oKpM5wAEYtQrkGEIHCow81-lOImGx9gobHVOQPWZkoHAGc9c349JgwZ87NZiYhbvXOPxHClqtte4FRYuxt-lsYvpqtCI-GmmTcyIH8G-H7Kf5gcGb5tnz9-gRnJWtAghmy8tV9lbcAjjj-5ROazJvQzaDK26meqxmph_18pW4bksTREw7M7dtUWEQIKL3TNS5FFTZ5YNeR9OxkryLvGRsIQCvvPMBMido2_atXbkR9JGXaBhsrpYnM-Q"
        if (jwtToken) {
            request = request.clone({
                setHeaders: {
                  'X-AUTH-TOKEN': `${jwtToken}`,
                  'X-Content-Type-Options': 'nosniff',
                  'X-XSS-Protection' : '1',
                  'Cache-Control': 'no-store',
                  'Pragma': 'no-cache',
                  'Content-Security-Policy': 'self',
                  'Access-Control-Allow-Origin':'*',
                }
            });
        }
        return next.handle(request);
    }
}
