import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import {Constantes} from "../shared/constantes";
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  codeClient: string;
  private _soldeEnt = new BehaviorSubject<any>(1);
  private _permissionsCodes = new BehaviorSubject<any>({ modulesCodes: [], actionsCodes: [] });
  private dataStore: { _permissionsCodes: any } = { _permissionsCodes: { modulesCodes: [], actionsCodes: [] } }; // store our data in memory
  readonly permissionsCodes = this._permissionsCodes.asObservable()
  soldeEnt = this._soldeEnt.asObservable();
  constructor( private httpClient: HttpClient) {
  }

  get(id: any, endpoint: String, api: any = environment.apiAdminUrl){
    return this.httpClient.get<any []>(`${api}${endpoint+'/'+id}`);
  }
  getStation(id: any, endpoint: String){
    return this.httpClient.get<any []>(`${environment.apiStation}${endpoint+'/'+id}`);
  }
  getReseau(id: any, endpoint: String, api: any = environment.apiUrlReseau){
    return this.httpClient.get<any []>(`${api}${endpoint+'/'+id}`);
  }

  getAll(endpoint: String, page: any = 1, query: String = '', api : any = environment.apiAdminUrl){
    if(page) return this.httpClient.get<any []>(`${api}${endpoint}?_page=${page}${query}`);
    else return this.httpClient.get<any []>(`${api}${endpoint+'/all'}?${query}`);
  }
  getAl(endpoint: String, page: any = 1, query: String = '', api : any = environment.apiStation){
    if(page) return this.httpClient.get<any []>(`${api}${endpoint}?_page=${page}${query}`);
    else return this.httpClient.get<any []>(`${api}${endpoint+'/all'}?${query}`);
  }
  getAllReseau(endpoint: String, page: any = 1, query: String = '', api : any = environment.apiUrlReseau){
    if(page) return this.httpClient.get<any []>(`${api}${endpoint}?_page=${page}${query}`);
    else return this.httpClient.get<any []>(`${api}${endpoint+'/all'}?${query}`);
  }


  upsert(id: any = null, endpoint: any = null, data: any, api : any = environment.apiAdminUrl){
    console.log('ee',data)
    if(id) return this.httpClient.put<any[]>(`${api}${endpoint+'/'+id}`,data);
    else return this.httpClient.post<any>(`${api}${endpoint}`,data)
  }
  upsert1(id: any = null, endpoint: any = null, data: any, api : any = environment.apiStation){
    console.log('ee',data)
    if(id) return this.httpClient.put<any[]>(`${api}${endpoint+'/'+id}`,data);
    else return this.httpClient.post<any>(`${api}${endpoint}`,data)
  }

  upsertSer(id: any = null, endpoint: any = null, data: any){
    if(id) return this.httpClient.put<any[]>(`${environment.apiStation}${endpoint+'/'+id}`,data);
    if(!id) return this.httpClient.post<any>(`${environment.apiStation}${endpoint}`,data)
  }
  upsertsert(id: any = null, endpoint: any = null, data: any){
    if(id) return this.httpClient.put<any[]>(`${environment.apiStation}${endpoint+'/'+id}`,data);
    if(!id) return this.httpClient.post<any>(`${environment.apiStation}${endpoint}`,data)
  }
  upsertStation(id: any = null, endpoint: any = null, data: any, api : any = environment.apiStation){
    console.log('ee',data)
    if(id) return this.httpClient.put<any[]>(`${api}${endpoint+'/'+id}`,data);
    else return this.httpClient.post<any>(`${api}${endpoint}`,data)
  }
  updade(endpoint:string,data:any){
    return  this.httpClient.put<any[]>(`${environment.apiStation}${endpoint}`,data);
  }

  upsertReseau(id: any = null, endpoint: any = null, data: any, api : any = environment.apiUrlReseau){
    if(id) return this.httpClient.put<any[]>(`${api}${endpoint+'/'+id}`,data);
    else return this.httpClient.post<any>(`${api}${endpoint}`,data)
  }

  export(endpoint ='', query='',  api : any = environment.apiAdminUrl ) {
    return this.httpClient.get(`${api}${endpoint}?true=true${query}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }
  exportReseau(endpoint ='', query='',  api : any = environment.apiUrlReseau ) {
    return this.httpClient.get(`${api}${endpoint}?true=true${query}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }


// API STATION SERVICE
  getListeStation(endpoint: String, page: any = 1, query: String = '', api : any = environment.apiStation){
    return this.httpClient.get<any []>(`${api}${endpoint}?_page=${page}${query}`);
  }
  upsertStationSer(endpoint: any = null, data: any){

    return this.httpClient.post<any>(`${environment.apiStation}${endpoint}`,data)
  }
  upsertStationSerAppro(endpoint: any = null, data: any){

    return this.httpClient.post<any>(`${environment.apiAdminUrl}${endpoint}`,data)
  }

  upsertClient(id: any = null, endpoint: any = null, data: any){
    if(id) return this.httpClient.put<any[]>(`${environment.apiStation}${endpoint+'/'+id}`,data);
    if(!id) return this.httpClient.post<any>(`${environment.apiStation}${endpoint}`,data)
  }

  getListeAll(endpoint:string) {
    return this.httpClient.get<any>(`${environment.apiUrlReseau}${endpoint}`)
  }

  getListeAl(endpoint:string) {
    return this.httpClient.get<any>(`${environment.apiStation}${endpoint}`)
  }
  getone(id: any, endpoint: String, api: any = environment.apiStation){
    return this.httpClient.get<any []>(`${api}${endpoint+'/'+id}`);
  }
  getone1(id: any, endpoint: String){
    return this.httpClient.get<any []>(`${environment.apiStation}${endpoint+'/'+id}`);
  }
  getoneAppro(id: any, endpoint: String, api: any = environment.apiAdminUrl){
    return this.httpClient.get<any []>(`${api}${endpoint+'/'+id}`);
  }
  exportStation(endpoint ='', query='',  api : any = environment.apiStation ) {
    return this.httpClient.get(`${api}${endpoint}?true=true${query}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  update(endpoint: String,data = {}){
    return this.httpClient.put<any[]>(`${environment.apiStation}${endpoint}`,data);
  }

  saveActionPermissions(params:any) {
    if (params.id >0) {
      return  this.httpClient.put<any[]>(`${environment.apiStation}/permissions/${params.id}`, params);
    }
    else
    return  this.httpClient.post<any[]>(`${environment.apiStation}/permissions`, params);

  }
  getPermission(){
    return this.httpClient.get<any>(`${environment.apiStation}/permissions/mespermissions`)
  }
  setPermission(endpoint:string,data:any){
    if(data.id){
      return this.httpClient.put<any>(`${environment.apiStation}${endpoint}/${data.id}`,data);
    }
    else {
      return this.httpClient.post<any>(`${environment.apiStation}${endpoint}`,data);
    }
  }
  setPermissions() {
    let codeModule:any = [];
    let codeAction:any = [];
    this.getPermission()
      .subscribe(res => {

        res['permissions'].map((item:any) => {

          codeAction.push(item.codeAction);
          if (!codeModule.includes(item.codeModule)) {
            codeModule.push(item.codeModule);
          }
        });
        this.dataStore._permissionsCodes = {
          codeModule: codeModule,
          codeAction: codeAction
        };
        this._permissionsCodes.next(Object.assign({}, this.dataStore)._permissionsCodes);
      });
  }

  acceptApprovis(id:any,data={}) {
    return this.httpClient.put<any[]>(`${environment.apiStation}/approvisionnements/validation/${id}`, data);
  }
  rejectApprovis(id:any) {
    return this.httpClient.put<any[]>(`${environment.apiStation}/approvisionnements/rejeter/${id}`, {});
  }
  acceptApprovisR(id:any,data={}) {
    return this.httpClient.put<any[]>(`${environment.apiUrlReseau}/approvisionnement_agences/validation/${id}`, data);
  }
  rejectApprovisR(id:any) {
    return this.httpClient.put<any[]>(`${environment.apiStation}/approvisionnement_agences/rejeter/${id}`, {});
  }
  getInvoice(id:any){
    return this.httpClient.get(`${environment.apiStation}/approvisionnements/recu/pdf/${id}`,{ observe: 'response', responseType: 'blob'})
  }
  approvisionnerStation(params:any){
    return this.httpClient.post<any[]>(`${environment.apiUrlReseau}/approvisionnement_agences`,params);

  }
  approvisionnerEntite(params:any){
    return this.httpClient.post<any[]>(`${environment.apiAdminUrl}/approvisionnements`,params);
  }

  getCaisseNonAff(endpoint='',query=''){
   return this.httpClient.get<any []>(`${environment.apiUrlReseau}${endpoint}?${query}`);
  }

  setClientPin(clientId:any) {
    return this.httpClient.get<any[]>(`${environment.apiStation}/carte_carburants/pin/regenerate/${clientId}`);
  }
  soldeEntite(code='') {
    return this.httpClient.get<any[]>(`${environment.apiStation}/entites?code=${code}`);
  }

  setsolde(entity:number){
    this._soldeEnt.next(entity);

}
getInvoiceCarteAPPRO(id:any){
  return this.httpClient.get(`${environment.apiStation}/appro_cartes/recu/pdf/${id}`,{ observe: 'response', responseType: 'blob'})
}
acceptApprovisCARTEAPPRO(id:any,data={}) {
  return this.httpClient.put<any[]>(`${environment.apiStation}/appro_cartes/validation/${id}`, data);
}
rejectApprovisCARTEAPPRO(id:any) {
  return this.httpClient.put<any[]>(`${environment.apiStation}/appro_cartes/rejeter/${id}`, {});
}


}
