import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { Endpoint } from 'src/app/models/Endpoint';
import { query } from '@angular/animations';
import {saveAs} from "file-saver";
import { FormBuilder } from '@angular/forms';
import { Countries } from 'src/app/models/Constants';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  items : any = [];
  loading : any = true ;
  page : number = 1 ;
  pageSize : number = 10 ;
  totals : number = 0 ;
  query : string = "" ;
  searchText : string;
  searchTextNotifier  = new Subject();
  filterForm : any ;
  test : string;
  countries = Countries

  constructor(private service : GlobalService, private fb : FormBuilder ) {}

   ngOnInit() {
    this.initFilter()
    this.searchTextNotifier.pipe(debounceTime(250)).subscribe(text => this.search());
  }

  initFilter() {
    this.filterForm = this.fb.group({
      pays: [''],
      status: [],
    });
    this.query = '';
    this.searchText = '';
    this.getList();
  }

  filterItem() {
    this.query = '';
    this.page = 2;
    if (this.filterForm.value.pays) this.query += `&pays=${this.filterForm.value.pays}`;
    if (this.filterForm.value.status) this.query += `&status=${this.filterForm.value.status}`;
    this.getList();
  }

  search($next : Boolean = false){
    if($next) this.searchTextNotifier.next('')
    else{
      this.searchText == '' ? this.query = '' : this.query += `&search=${this.searchText}`;
      this.getList();
    }
  }

  getList(){
    this.service.getAll(Endpoint.ENTITES_GET,this.page,this.query).subscribe({
      next : (res : any) => {
        this.loading =false;
        this.items = res['hydra:member'];
        this.totals = res['hydra:totalItems'];
        console.log('items here ', this.items);
      },
      error: err => {
        console.log('error',err)
        this.loading =false;
      }})
  }

  paginate(p: number){
    console.log("event -> ", p)
    this.page = p
    this.getList()
  }

  changeStatus(id : any, item : any){
    this.service.upsert(id, Endpoint.ENTITES_POST, { status: !item.status })?.subscribe({
      next: (res : any) => {
        item.status = !item.status;
        console.log(res);
        
      },
      error: err => {
        console.log('error',err)
        this.loading =false;
      }
    })
  }

  export(type = '') {
    this.loading = true;
    if (type == 'pdf') {
      const fileName = 'entites.pdf';
      this.service.export(Endpoint.ENTITES_PDF, this.query).subscribe((res:any) => {
          this.loading = false;
          saveAs(res.body, fileName);
        }, error => {
          this.loading = false;
        });
    } else {
      const fileName = 'entites.csv';
      this.service.export(Endpoint.ENTITES_CSV, this.query).subscribe((res:any) => {
          this.loading = false;
          saveAs(res.body, fileName);
        }, error => {
          this.loading = false;
        });
    }
    return;
  }


  ngOnDestroy() {
		this.searchTextNotifier.complete();
	}

}
