import { Component, OnInit, TemplateRef } from '@angular/core';
import { Countries, Currency } from 'src/app/models/Constants';
import { Contact, Entite } from 'src/app/models/Models';
import { GlobalService } from 'src/app/services/global.service';
import { Endpoint } from 'src/app/models/Endpoint';
import { ActivatedRoute, Router } from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  itemId : any = null
  itemModel = new Entite()
  countries = Countries
  currency = Currency
  loading = false
  public contacts : any = [];
  public contactModel = new Contact();
  public pos : any = null
  public titleModal = '';
  public modalRef: BsModalRef = new BsModalRef();
  public buttonTitle: String = ""
  isAdd: boolean = false;
  type: any;

  constructor(private service: GlobalService, private router: Router, private activatedRoute: ActivatedRoute, private modalService : BsModalService) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      console.log(params)
      this.itemId = params['id'];
      console.log("The item id ", this.itemId)
    });

    if(this.itemId) this.getItem();
  }

  getItem(){
    this.loading = true;
    this.service.get(this.itemId, Endpoint.ENTITES_GET).subscribe((res:any)=>{
      console.log('the item we get ', res)
      this.itemModel = res;
      this.loading = false;
    },error => {
      console.log('error oneuser',error)
      this.loading = false;
      this.itemId = null;
    })
  }

  saveItem() { //fonction d'enregistrer un user
    console.log('item model here ',this.itemModel)
    this.loading = true;
    this.itemModel.status = true;
    this.itemModel.contacts = this.contacts;
    this.service.upsert(this.itemId, Endpoint.ENTITES_POST, this.itemModel)?.subscribe((res:any)=>{
      this.loading =false;
      console.log('Requete :', res);
 
      this.itemModel = new Entite()

      this.loading =false;
      setTimeout(() => {
        this.router.navigateByUrl('entites/list')
      }, 2500);
      
    },error => {
      console.log('add item error',error.error['hydra:description'])
      this.loading = false;

    })
  }

  fileChangeListener($event:any) {
    let file = $event.target.files[0];
    this.itemModel.fileType = file.type;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.itemModel.source  = reader.result as string;
    }
  }

  resetImage(){
    this.itemModel.source  = '';
    this.itemModel.fileType  = '';
  }

  async openModal(template: TemplateRef<any>, item: any, pos : any = null) {

    if (item == null) {
      this.titleModal = "Ajouter un contact";
      this.type = {};
      this.isAdd = true;
    } 
    else {
      var itemClone = {...item};
      this.contactModel = itemClone;
      this.pos = pos
      this.titleModal = "Modifier le contact";
      this.isAdd = false;
    }

    if(this.isAdd){
      this.buttonTitle = "Ajouter"
    }else {
      this.buttonTitle = "Modifier"
    }

    this.modalRef = this.modalService.show(template, {ignoreBackdropClick: true, class: 'modal-xl'});

  }

  close() {
    this.contactModel = new Contact()
    this.pos = null;
    this.modalRef.hide();
  }

  saveContact(){

    if(this.pos == null){
      this.contacts.push(this.contactModel);
      console.log('Tableau d\'ajout :',this.contacts);
    }
    else{
      this.contacts[this.pos] = this.contactModel;
      console.log('Tableau de modification :', this.contacts);
    }
    
    this.close();
  }

  deleteContact (index : number) {
    this.contacts.splice(index, 1)
    console.log('Tableau de suppression :', this.contacts);
  }

}
