import { Component, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
interface DialogData {
  title?: string;
  message?: string;
}
@Component({
  selector: 'app-confirm-pin',
  templateUrl: './confirm-pin.component.html',
  styleUrls: ['./confirm-pin.component.scss']
})
export class ConfirmPinComponent implements OnInit {
  loading: boolean;
  data:  DialogData

  constructor(
    public activeModal: NgbActiveModal,public modalService:NgbModal 
  ) { }

  ngOnInit() {
  }

  confirm(){
   
    this.activeModal.close({confirmed: true})
  }
  closeAndReset() {
   
    this.modalService.dismissAll()
    
  }


}
