import { Component, OnInit } from '@angular/core';
import { Endpoint } from 'src/app/models/Endpoint';
import { GlobalService } from 'src/app/services/global.service';
import { Entite, Service } from 'src/app/models/Models';
import { Router } from '@angular/router';
import {ActivatedRoute} from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/modules/auth';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  loading:boolean
  itemId : any = null;
  itemModel = new Entite  ();
  filterForm : any ;
  totals:number = 0;
  page = 1;
  query = '';
  users_items:any = [];
  services_items:any = [];

  searchText : string;

  constructor(
    private service: GlobalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
   public modalService :NgbModal,
   private  authService: AuthService,
   private fb : FormBuilder
   ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.itemId = params['id'];
      console.log("The item id ", this.itemId)
    });
    if(this.itemId) this.getItem();
    this.getListServices()
    this.getListUsers()
  
  }

  getItem(){
    this.loading = true;
    this.service.get(this.itemId, Endpoint.ENTITES_GET).subscribe((res:any)=>{
      console.log('ss', res)
      this.itemModel = res;
      this.loading = false;
    },error => {
      console.log('error oneuser',error)
      this.loading = false;
      this.itemId = null;
    })
  }
  
  openModal(content:any)
  {
    this.modalService.open(content,{size:'md'})
  }

  closeAndReset() {
    this.modalService.dismissAll()
  }

  getListServices(){
    let list_query = `&entite_id=${this.itemId}`;
    this.loading =true
    this.service.getAll(Endpoint.SERVICES,this.page,list_query).subscribe((res:any)=>{
    console.log('items here ', res['hydra:member']);
    this.services_items = res['hydra:member'];
    this.totals = res['hydra:totalItems'];
    this.loading =false;
    },error => {
      console.log('error',error)
      this.loading =false;
    })
  }

  getListUsers(){
    let list_query = `&entite_id=${this.itemId}`;
    this.loading =true
    this.authService.getAll(Endpoint.USERS,this.page,list_query).subscribe((res:any)=>{
    console.log('items here users entites ', res['hydra:member']);
    this.users_items = res['hydra:member'];
    this.totals = res['hydra:totalItems'];
    this.loading =false;
    },error => {
      console.log('error',error)
      this.loading =false;
    })
  }

  changeStatusUser(id : any, item : any){
    this.authService.upsert(id, Endpoint.USERS, { status: !item.status })?.subscribe({
      next: (res : any) => {
        item.status = !item.status;
        console.log(res);
      },
      error: err => {
        console.log('error',err)
        this.loading =false;
      }
    })
  }

  changeStatusService(id : any, item : any){
    this.service.upsert(id, Endpoint.SERVICES, { status: !item.status })?.subscribe({
      next: (res : any) => {
        item.status = !item.status;
        console.log(res);
        
      },
      error: err => {
        console.log('error',err)
        this.loading =false;
      }
    })
    

  }
 

}

