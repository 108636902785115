<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Toolbar-->

  <div class="card content">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-6 ">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <h3 translate="GENERAL.LISTE_PARAMETRES_COMMISSION"></h3>

        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->
      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Toolbar-->
        <div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">

          <!--begin::Filter-->
          <button type="button" class="btn btn-light-primary me-3 btn-rounded" data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end">
            <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
            <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                              d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                              fill="currentColor" />
                        </svg>
                    </span>
            <span translate="MENU.FILTER"></span>
            <!--end::Svg Icon-->
          </button>
          <!--begin::Menu 1-->
          <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px mt-5" data-kt-menu="true">
            <!--begin::Header-->
            <div class="px-7 py-5">
              <div class="fs-5 text- fw-dark bolder"translate="GENERAL.FILTER"></div>
            </div>
            <!--end::Header-->
            <!--begin::Separator-->
            <div class="separator border-gray-200"></div>
            <!--end::Separator-->
            <!--begin::Content-->
            <form>
              <div class="px-7 py-5" data-kt-user-table-filter="form">
                <!--begin::Input group-->
                <div class="mb-10">
                  <label class="form-label fs-6 fw-bold"translate="GENERAL.SOCIETE"> </label>
                  <ng-select [items]="societes"
                             bindLabel="nom"
                             bindValue="code"
                             [ngModelOptions]="{standalone: true}"
                             [(ngModel)]="filterModel.codeSociete">
                  </ng-select>
                </div>
                <div class="d-flex justify-content-end">
                  <button type="reset" class="btn btn-light btn-active-light-primary btn-rounded fw-bold me-2 px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="reset"translate="MENU.CANCEL"></button>
                  <button type="submit" class="btn btn-primary btn-rounded fw-bold px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="filter" (click)="filterItem()"translate="BUTTONS.VALIDATE_BUTTON"></button>
                </div>
                <!--end::Actions-->
              </div>
            </form>
            <!--end::Content-->
          </div>
          <!--end::Menu 1-->
          <!--end::Filter-->


          <!--begin::Export Filter-->
          <div class="m-0">
            <!--begin::Menu toggle-->
            <button type="button" class="btn btn-light-primary btn-rounded me-3" data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"translate="MENU.EXPORTER">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
              <span class="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1"
                                      transform="rotate(90 12.75 4.25)" fill="currentColor" />
                                <path
                                  d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z"
                                  fill="currentColor" />
                                <path
                                  d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z"
                                  fill="#C4C4C4" />
                            </svg>
                        </span>
              <!--end::Svg Icon-->
            </button>

            <!--end::Menu toggle-->
            <!--begin::Menu 1-->
            <div class="menu menu-sub menu-sub-dropdown w-125px w-md-250px mt-5" data-kt-menu="true"
                 id="kt_menu_624476767c26c">
              <!--begin::Header-->
              <div class="px-7 py-5">
                <div class="fs-5 text-primary fw-bolder"translate="MENU.EXPORTER"></div>
              </div>
              <!--end::Header-->
              <!--begin::Menu separator-->
              <div class="separator border-gray-200"></div>
              <!--end::Menu separator-->
              <!--begin::Form-->
              <div class="px-7 py-5">
                <div class="menu-item px-3">
                  <a class="menu-link px-3 text-white text-primary"
                     (click)="export('pdf')"translate="MENU.EXPORT_PDF"></a>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <a class="menu-link px-3 text-white text-primary"
                     (click)="export('csv')"translate="MENU.EXPORT_EXCEL"></a>
                </div>
              </div>
              <!--end::Form-->
            </div>
            <!--end::Menu 1-->
          </div>
          <!--end::Export Filter-->


          <!--begin::Add user-->

          <button  *ngIf="guard.pass(autority.actions.add_comm)" (click)="openModal(addEditCommission)" type="button" class="btn btn-primary btn-rounded"
                  data-bs-toggle="modal" data-bs-target="#kt_modal_add_user">
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr075.svg-->
            <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                  transform="rotate(-90 11.364 20.364)" fill="currentColor" />
                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor" />
                        </svg>
                    </span>
            <span translate="BUTTONS.ADD_BUTTON"></span>
            <!--end::Svg Icon-->
          </button>
          <!--end::Add user-->
        </div>
        <!--end::Toolbar-->

      </div>
      <!--end::Card toolbar-->
    </div>
    <div class="loaderPage" *ngIf="loading">
      <div class="lds-roller " >
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
      </div>
    </div>
    <small class="small"> {{totals}} <smal  translate="GENERAL.TROUVEES"></smal></small>
    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card-body py-4">
      <div id="kt_table_users_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="table-responsive">
          <!--begin::Table-->
          <table class=" table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
            <!--begin::Table head-->
            <thead>
            <!--begin::Table row-->
            <tr class="text-start text-center text-muted fw-bolder fs-7 text-uppercase gs-0">

              <th class="min-w-125px text-start" translate="MENU.SOCIETE"></th>
              <th class="min-w-125px" translate="GENERAL.DATE"></th>
              <th class="min-w-125px" translate="MENU.MONTANT"></th>
              <th class="min-w-125px text-end" translate="GENERAL.ACTIONS"></th>

            </tr>
            <!--end::Table row-->
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody class="text-gray-600 fw-bold">
            <!--begin::Table row-->
            <tr class="text-center" *ngFor="let  item of commissions | paginate: { itemsPerPage: pageSize, currentPage: page ,totalItems:totals }">
              <td class="d-flex align-items-center">
                <!--begin:: Avatar -->
                <div class="symbol symbol-50px overflow-hidden me-3">
                  <a [routerLink]="['../detail',item.id]">
                    <div class="symbol-label">
                      <ngx-avatar name="{{item?.societe?.nom || 'N N ' }}" [src]="item?.societe?.logo"  class="w-100"></ngx-avatar>
                    </div>
                  </a>
                </div>
                <!--end::Avatar-->
                <!--begin::User details-->
                <div class="d-flex flex-column ">
                  <a class="text-gray-800  mb-1"
                  >{{item?.societe?.nom| titlecase}} </a>
                </div>
                <!--begin::User details-->
              </td>

              <td> {{item.dateCreation| date:'medium'}}</td>

              <td class="text-success "> {{item.montant | number}}</td>
              <td>
                <div class="d-flex justify-content-end flex-shrink-0">
                  <div class="ms-2">
                    <button (click)="openModalpUdate(addEditCommission,item)"  class="btn btn-sm btn-icon btn-light btn-active-light-primary me-2" data-bs-toggle="tooltip" title="Détails">
                      <span class="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                         <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor" />
                         <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor" />
                          </svg>
                      </span>
                    </button>
                  </div>

                </div>
              </td>
              <!--begin::Joined-->
              <!--begin::Action=-->
            </tr>
            </tbody>
          </table>
          <!--end::Table-->
        </div>

        <div class="row mt-5 mb-5">
          <div
            class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
          </div>
          <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
            <pagination-controls previousLabel="" nextLabel="" class=" atbd-pagination__link page-number" (pageChange)="paginate($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #addEditCommission >
  <div class="modal-content">
    <!--begin::Modal header-->
    <div class="modal-header">
      <!--begin::Modal title-->
      <h2 class="fw-bolder"translate="GENERAL.NOUVELLE_COMMISSION"></h2>
      <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" (click)="modalService.dismissAll()">
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
        <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                    </svg>
                </span>
        <!--end::Svg Icon-->
      </div>
    </div>
    <!--end::Modal header-->
    <!--begin::Modal body-->
    <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
      <!--begin::Form-->
      <form id="kt_modal_update_email_form" >

        <div class="fv-row mb-7">
          <!--begin::Label-->
          <label class="fs-6 fw-bold form-label mb-2">
            <span class="required" translate="MENU.ENTREPRISES"></span>
          </label>
          <!--end::Label-->
          <!--begin::Input-->
          <ng-select [items]="societes"
                     bindLabel="nom"
                     bindValue="uri"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="itemModel.societe">
          </ng-select>

          <!--end::Input-->
        </div>
        <div class="fv-row mb-7">
          <!--begin::Label-->
          <label class="fs-6 fw-bold form-label mb-2">
            <span class="required"translate="MENU.MONTANT"></span>
          </label>
          <!--end::Label-->
          <!--begin::Input-->
          <input  [required]="true" type="number"  class="form-control form-control-solid form-control-rounded"  [(ngModel)]="itemModel.montant" placeholder="Montant" name="montant"/>
          <!--end::Input-->
        </div>
        <div class="text-center pt-15">
          <button (click)="close()" type="reset" class="btn btn-light me-3 btn btn-rounded" data-kt-users-modal-action="cancel" translate="BUTTONS.CANCEL_BUTTON"></button>
          <button  (click)="addCommission()" type="submit" class="btn btn-primary btn btn-rounded" data-kt-users-modal-action="submit">
            <span class="indicator-label" translate="BUTTONS.VALIDATE_BUTTON"></span>
            <span class="indicator-progress" *ngIf="loading"translate="GENERAL.EN_COURS">
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
        </div>

        <!--end::Actions-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Modal body-->
  </div>
</ng-template>
