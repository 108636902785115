<div *ngIf="loading" class="blockui rounded">
  <div style="align-self: center;">
        <img style="width: 50px !important;" src="./assets/spinner.gif" />
   </div>
</div>
<!-- begin::Header-->
<div class="card-header border-0 pt-5">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder text-dark">Clients</span>
    <span class="text-muted mt-1 fw-bold fs-7" *ngIf="total > 0">{{total + ' services destinés aux artisans'}}</span>
  </h3>
</div>
<!-- end::Header-->
<!-- begin::Body-->
<div class="card-body pt-5">

  <div style="height: 375px; overflow-y:scroll">
        <!-- begin::Item-->
        <div *ngFor="let item of items" class="d-flex align-items-sm-center mb-7">
          <!-- begin::Symbol-->
          <div class="image-type1 me-5">
            <img src="{{ item?.source  || 'assets/media/avatars/blank2.png'}}" alt=""/>
          </div>
          <!-- end::Symbol-->
          <!-- begin::Section-->
          <div class="d-flex align-items-center flex-row-fluid flex-wrap">
            <div class="flex-grow-1 me-2">
              <a href="#" class="text-gray-800 text-hover-primary fs-6 fw-bolder">
                {{item?.nom}}
              </a>
              <span class="text-muted fw-bold d-block fs-7">
                {{item?.nationalite}}
              </span
              >
            </div>
          </div>
          <!-- end::Section-->
        </div>
        <!-- end::Item-->
  </div>

  <div  class="w-100 text-end mt-5">
    <span class="link-type1" routerLink="/services/list">Voir+</span>
  </div>

</div>
<!-- end::Body-->
