<div class="row d-flex">
  <div class="col-md-4 m-4" *ngIf="entite?.source">
    <ngx-avatar class="mt-3 mb-3 " [src]=" entite?.source"></ngx-avatar>
  </div>
  <div class="col-md-4 m-4" *ngIf="societe?.logo">
    <ngx-avatar class="mt-3 mb-3 " [src]="societe?.logo"></ngx-avatar>
  </div>
</div>



