// USA
export const locale = {
  lang: 'en',
  data: {

    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    GENERAL:{
      STATIONS_CAISSES:'Stations/Cashiers',
      EN_COURS:'In progress ...',
      NOUVELLE_COMMISSION:'New commission',
      TROUVEES:'Found',
      SOCEITE:'Company:',
      LISTE_PARAMETRES_COMMISSION:'List of commission parameters',
      HEURE_FERMETURE:'Closing time:',
      HEURE_OUVVERTURE:'Opening time',
      CODES:'Code:',
      TAUX:'Rate:',
      CODE_ENTITE:'Entity code:',
      COMMUNES:'Municipalities',
      DEPARTEMEN:'Departement:',
      NOM_STATION:'Station name:',
      INFORMATION_STATION:'Gas station information:',
      DETAIL_STATION:'Gas station details:',
      FERMETURE:'Closing',
      OUVERTURE :'Opening',
      HORAIRE:'Schedule',
      AUCUN_STATION_TROUVEE:'No stations found',
      COMMUNE:'Municipality',
      DEPARTEMENT:'Department',
      LISTE_STATIONS:'List of stations',
      NOMBRE_BON:'Number of good',
      AUCUNE_BON_TROUVEE:'No vouchers found',
      DETAIL_BON:'Vouchers Details',
      FICHIER_OBLIGATOIRE:'The file is required',
      AJOUT_FICHIER_CARTE:'Add file card',
      AUCUNE_CARTE_TROUVEE:'No cards found',
      NOM_CLIEN:'Customer name:',
      LISTE_CARTE:'List of cards',
      CARTE_DISTRIBUES:'Card(s) available',
      CARTE_ATTRIBUES:'Assigned card(s)',
      TOTALS_CARTE:'Total cards',
      NOUVEAU_RECHARGE:'Credit a card',
      REFERENCES:'Reference:',
      VLOICI_REFERENCE_MONTANT_CARTE:'Here is the reference and the amount of the card.',
      CHOISIR_CODE_PIN:'Choose a pin code',
      INFORMATION_CARTE_RECHARGEMENT:'Reload card information:',
      DETAIL_CARTE_RECHARGEMENT:'Credit card transaction details',
      //MESSAGE:'Etes vous sûr de vouloir changer le statut.',
     // TITLE: "Changement statut",
      RESULTAT_SEARCH:'No results were found for your search.',
      LISTE_RECHARGEMENT_CARTE:'List of card top-ups',
      NUME_TRANSACTION:'Transaction number:',
      ETAT_BON:'State  vouchers:',
      MONTANT_BON:'Vouchers amount:',
      REFERENCE_BON:'vouchers Reference:',
      NINEA_SOCIETE:'Ninea society:',
      SOLDE_SOCIETE:'Society balance:',
      CODE_SOCIETE :'Society Code:',
      NOM_SOCIETE:'Society name:',
      TELEPHONE_CLIENT:'Customer telephone:',
      NATIONALITE_CLIENT:'Customer nationality:',
      ADRESSE_CLIENT:'Customer Address:',
      NOM_PRENOM_CLIENT:'Customer\'s first and last name:',
      DATE_MODIFICATION_CARTE:'Card modification date:',
      DATE_CREATION_CARTE:'Card creation date:',
      SOLDE_CARTE:'Card balance:',
      REFERENCE_CARTE:'Card reference:',
      COMMENTAIRE:'Comment:',
      CODE_CAISSE:'Cash code:',
      OPERATION:'Operation:',
      FRAIS:'Cost:',
      SOLDEAPRES:'Balance After:',
      SOLDEAVANT:'Balance Before:',
      MONTANTE:'Amount:',
      INFORMATION_TRANSACTION:'Transaction information:',
      VALIDER:'Validate',
      DETAIL_TRANSACTION:'Detail Transaction',
      TRANSACTION_NOT_FOUND:'No Transaction Found',
      NUM_TRANSACTION:'Transaction number',
      RESULT_NOT_FOUND:'No results match your search.',
      TRANSACTION:'List of transactions',

      DOUBLONS:'Duplicates',
      NOMBRE_CLIEN:'Number of customers',
      GLISSER_DEPOSER:'drag, drop!',
      SOCIETE_OBLIGATOIRE:'The company is obligatory',
      SELECTIONNER_SOCIETE:'Select the --Company...',
      COMPAGNIE_AGREGATEUR:'Companies/Aggregators',
      AJOUT_CLIENT_PAR_FICHIER:'Addition of Customers by file',
      CIN:'CIN',
      PASSPORT:'PASSPORT',
      AJOUT_CLIENT:'Add Customer',
      //PARTNERS: 'PARlNAIRES',
     // COUNTRY: 'Pays',
      CODEPIN:'Pin code',
      REFERENCE:'Reference',
      MONTANT:'Amount',
      //ACTIVITY_AREA: "Secteur d'activité",

      CONFIRM:'To confirm',
      MESSAGE:'Are you sure you want to change the status.',
      TITLE:'Status change',
      PARTNERS: 'PARTNERS',
      COUNTRY: 'COUNTRY',
      ACTIVITY_AREA: "Activity area",

      STATUS: "Status",
      CREATED_DATE: "Creation date",
      ACTIONS: "Shares",
      USER : 'User',
      ROLE : 'Rule',
      ETAT:'State',
      AUTEUR :'Author',
      SOCIETE:'Company',
      ISCOMPAGNIE:'IsCompany',
      ISAGREGATEUR:'IsAgregator',
      LOGIN: 'Username',
      NUM: 'Phone',
      STATION:'Station',
      COOPERATIVE: 'Cooperative',
      DATE:'Date',
      ADDUSER: 'Add a user',
      ADDROOM:'Add a consular room',
      UPDATEROOM:'Modify a consular room',
      UPDATEUSER: 'Edit a user',
      NAME: 'Name',
      RCCM:'Rccm',
      TELEPHONE: 'Phone',
      LOGO:'Logo',
      PHOTO:'Photo',
      FIRSTNAME: 'First name',
      LASTNAME: 'Name',
      ADDRESS: 'Address',
      DEVISE: 'Currency',


      NAMESOCIETE:'Company name',
      CURRENCY :'Currency',
      TARIFICATION :'Pricing',
      DESC :'Description',
      USERNAME  : 'Username',
      CODE: 'Code',
      DESCRIPTION: 'Description',
      CATEGORIE_CHAMBRE:'categoryRoom',
      DIRECTION:'Director',
      SOLDE:'Balance (XOF)',
      SOLDE_SEUIL:'Threshold balance (XOF)',
      ISAPORTEUR:'Is this aggregator a business provider',
      APORTEUR:'Apporteur d\'affaire',
      CLIENT:'Customer',
      NAISSANCE:'Nationalite',
      DATENAISSANCE:'Date de naissance',
      NUMPIECE:'Numéro de piéce',
      TYPEPIECE:'Type de piéce',
      DATEDELIVRANCE:'Date de délivrance',
      DATEXPIRATION:'Date d\'expiration',
      LIEUDELIVRANCE:'Lieu de deélivrance',
      ADRESSE:'Adresse',
      COLOR:'Couleur',
      EMAIL: 'Email',
      CATEGORY: 'Categories',
      FULNAME:'FULNAME',
      CONTACT:'Contact',
      NEW_STATION: 'New Station',
      UPDATE_STATION: 'Edit a station',
      SOCIETECODE:'Company',
      CHANGE_STATUS:'Change status',
      CHANGE_PIN:'Regenerate pin code',
    },
    MENU: {
      MON_PROFIL:'My profile',
      FR:'Frensh',
      EN:'English',
      ENGLISH:'English',
      LANG:'Language',
      DECONNEXION:'Disconnect',
      APPRO_SOCETE:'Supplies/Companies',
      RECHARGE_UNE_CARTE:'Credit a card(s)',
      CLIENT_CAISSES:'Customers/Cashiers',
      CARBURANT_TRANSACTION:'Fuel/Transaction',
      AJOUT_BON_PAR_FICHIER:'Adding vouchers per file',
      PREMIER_REFERENCE_BON:'1st Reference Good',
      AJOUT_BON:'Add vouchers',
      AJOUTER_BON:'Add vouchers',
      LISTE_BONS:'List of vouchers',
      COMMISIONS:'Commissions',
      PARAMETRAGES:'Settings',
      TOUS:'ALL',
      LISTE_DES_TRANSACTIONS_CAISSIERS:' Cashier transaction list',
      LISTE_DOUBLONS:'Liste doublons',
      LISTE_CARTE_INTROUVABLES:'Liste Cartes Introuvables',
      LISTE_CARTE_TROUVES:'Liste Cartes Trouvées',
      NOMBRE_DE_CARTE:'Number of card',
      MONTANT_OBLIGATOIRE:'The amount is obligatory',
      MONTANT_TOTAL:'Total Amount',
      NOMBRE_CART:'Number',
      AJOUT_CARTE_FICHIER:'Adding cards per file',
      LA_CARTE_USE_BOUTIQUE:'Can the card be used in stores?',
      NOMBRE_OBLIGATOIRE:'The number is obligatory',
      SOCIETE_OBLIGATOIRE:'The company is required',
      AJOUT_LOT_CARTE:'Adding card batch',
      SELECTIONER_UNE_COMPAGNIE:'Select a company...',
      SELECTIONNER_LA_SOCIETE:'Select --Company ...',
      AJOUTER_CARTE_CARBURANT:'Add a fuel card ',
      SOLDE_APRES:'Balance After',
      SOLDE_AVANT:'Balance before',
      DETAIL_CARTE:'Card Details',
      SELECTIONNER_CARTE:'Select a card...',
      VEUILLEZ_SELECTIONNER_UNE_CARTE_DISPO:'Please select an available card.',
      METTRE_JOUR_CARTE:'Update the card',
      LIEU_DELIVRANCE:'Place of issue:',
      DATE_DEXPIRATION:'Expiration date:',
      DATE_DELIVRANCE:'Date of issue:',
      INFORMATIONS_CLIENT:'Additional customer information',
      LISTE_CARTE:'List of Cards',
      ADRESSE:'Adress:',
      TYPE_DE_PIECE:'Piece Type:',
      NUM_DE_PIECE:'Piece Number:',
      DATE_DE_NAISSANCE:'Date of birth:',
      INFORMATION_CLIENT:'Customer information:',
      NATIONALITE:'Nationality:',
      CODE_PIN:'Code PIN',
      DETAIL_CLIENT:'Customer Details',
      CLIENT_NOT_FOUND:'No customers found',
      NUM_PIECE:'Piece Number:',
      LISTE_CLIENTS:'Customers list',
      AGREGATEUR_NOT_FOUND:'No aggregators found',
      LISTE_AGREGATEUR:'List of aggregators',
      MODIFIER_AGREGATEUR:'Modifier un agrégateur',
      AJOUTER_AGREGATEUR:'Add an aggregator',
      CODE_ENTREPRISE:'Company Code:',
      ENTREPRISEE:'company:',
      IINFORMATION_ENTREPRISE:'Company information:',
      DETAIL_ENTREPRISE:'Company Details:',
      MODIFIER_COMPAGNIE:'Edit a company',
      AJOUTER_COMPAGNIE:'Add a company',
      OUI:'YES',
      NON:'NO',
      APPORTEUR_AFFAIRE:'Intermediary business:',
      CODE_AGREGATEUR:'Aggregator Code',
      AGREGATEURE:'Aggregator:',
      INFORMATION_AGREGATEUR:'Aggregator information:',
      DETAIL_AGREGATEUR:'Aggregator Details',
      SOLDIER:'Balance:',
      CODE_COMPAGNI:'Company Code:',
      COMPAGNI:'Company:',
      INFORMATION_COMPAGNIE:'Company Information:',
      DETAIL_COMPAGNIE:'Company Details',
      DTAIL_SOCIETE:'Company details',
      COMPAGNIE_NOTFOUND:'No companies found',
      SOLDE:'Balance',
      LISTE_COMPAGNIE:'List of companies',
      PRENO:'First name',
      VEUILLEZ_RENSEIGNER_LES_CHAMPS:'Please complete the fields below',
      RCC:'Trade register:',
      NINE :'Ninea:',
      NAME:'Last name & First Name',
      AJOUTER_CONTACT:'Add a contact',
      MODIFIER_ENTREPRISE:'Edit a business',
      AJOUT_ENTREPRISE:'Add a business',
      STATION_NOTFOUND:'No stations found',
      TEL:'Phone:',
      CODE_SOCIETE:'Company Code:',
      LISTE_ENTREPRISE:'List of companies',
      VALIDER: 'To validate',
      REMOVE:'Supprimer',
      REFERENCE:'Reference',
      VEILLEZ_PATIENTER:'Please wait...',
      GENERER:'Generate',
      CARTE_USER:'Can the card be used in stores?',
      REFERENCE_OBLIGATOIRE:'Reference is mandatory',
      PREMIER_REFERENCE:'1st card Reference',
      CREATION_LOT_CARTE:'Card batch creation',
      LISTE_DEMNADE_CARTE_APPRO_SOCIETE:'List of company supply card requests',
      CHOISIR:'---Choose---',
      SOCIOTE:'Company:',
      LISTE_APPRO_SOCIETES:'List of company supplies',
      LISTE_DEMANDE_APPRO_CARTE:'Card supply request list',
      MONTANT_VIREMENT:'Reason for transfer amount',
      MONTANT_INSCRIRE_VIREMENT:'Amount entered on the transfer receipt.',
      DEMANDE_APPRO:'Supply request',
        REFUSE:'Refused',
      ACCODE:'Granted',
      REJETER:'Dismiss',
      ACCEPTER:'Accept',
      EN_ATTENTE:'On hold',
      REFUSER:'Denied',
      ACCORDER:'Granted',
      AUTEURE:'Author:',
      LSITE_APPROS_ENTITES:'Supply list Entities',
      YES:'Yes, I confirm',
      NO:'No, I cancel',
      NOT_FOUND_PERMISSION:'No permissions found',
      PRECEDENT:'Previous',
      SUIVANT:'Next',
      DATE_REGISTRE:'REGISTRATION DATE',
      UTLISATEUR_AFFECTER:'Affected users',
      UPDATE:'Modify',
      NOT_FOUND:'No roles found',
      ERROR:'An error has occurred.',
      RESULT:'No results were found for your search',
      LISTE_ROLE:'List of roles',
      PLEASE_WAIT:'Please wait....',
      CONFIRMATION_PASSWORD:'Password Confirmation',
      METTRE_A_JOUR_PASSWORD:'Update password',
      REGENERER_CODE_PIN:'Regenerate pin code',
      REGENERER_MOT_PASSE:'Regenerate password',
      MODIFICATION_MOT_DE_PASSE:'Change the password',
      MODIF:'UPDATE',
      DATECREATION:'Creation date:',
      VALIDERBY:'Validate by:',
      CREATION:'Created by:',
      SOCIO:'COMPANY',
      USERNAME: 'username',
      USER:'User:',
      INFO_USER:'User information:',
      DETAIL_USER:'User Details',
      SELECTION_CAISSE:'Select checkout',
      PHOTOS:'Types de fichier autorisés : png, jpg, jpeg.',
      CANCEL:'Annuler',
      LISTE_USERS:'List Users',
      ROLE:'Rule',
      NOM:'Last name:',
      PRENOM:'First name:',
      TELEPHONE:'Phone:',
      EMAIL:'Email:',
      STATUS:'Status:',
      FILTER:'Filter',
      EXPORTER:'Export',
      EXPORT_PDF:'Export to PDF',
      EXPORT_EXCEL:'Export to Excel',
      ACTIF:'Active',
      INACTIF:'Inactive',
      ENATTENTEF:'On hold',
      RECHARGER_CARTE:'Credit a card',
      RECHARGE_CARTE:'Card Reload',
      RECHARGE_CARD:'Top up a card',
      GESTION_DES_TRANSACTION:'Transaction management',
      NEW_FILE_CLIENT:'New customer file',
      GESTION_DES_CLIENTS:'Customer management',
      NEW_FILE_BON:'New voucher file',
      NEW_LOT_BON:'New batch of voucher',
      LISTE_BON:'Voucher List',
      RECHARGEMENT_CARD:'Credit card',
      NEW_FILE_CARD:'New card file',
      NEW_LOT_CARD:'New set of cards',
      CARTES:'Cards',
      CARBURANT:'Fuel card management',
      GESTIONDESSTATIONS:'STATION MANAGEMENT',
      ADDAGREGATEUR:'Add Aggregator ',
      AGREGAT:'Aggregators',
      ADDCOMPAGNIE:'Add company',
      ADDSOCIETE:'Add society',
      COMPAGNIES:'Companies',
      ENTREPRISES:'Businesses',
      SOCIETES:'Companies',
      APPROSVISIONNEMENT:'Supplies',
      PERMISSIONS:'Permissions',
      ROLES:'Roles',
      USERS_EN_ATTENTE:'Pending users',
      USERS:'Users',
      CONFIGURATION:'Configurations',
      TRANSACTION:'Transaction',
      NEW: 'New',
      ACTIONS: 'Actions',
      CREATE_POST: 'Créer un nouveau Post',
      PAGES: 'Pages',
      FEATURES: 'Fonctionnalités',
      APPS: 'Applications',
      DASHBOARD: 'Dashboard  ',
      STATION: 'Stations',
      LISTE: 'List',
      LISTESTATION:'List stations',
      POINT_DE_VENTE: 'Point de ventes',
      DISTRIBUTEUR: 'Distributeurs',
      APPROVISIONNEMENT:'List',
      APPRO:'Entities',
      APPROS:'Companies',
      GESTION_DES_SERVICES:'Gestion des services',
      CLIENT:'Customers',
      PARTNERS: 'Cash register',
      CAISSIERS:' Cashiers',
      CAISSE: 'Cash registe',
      CONTACTS:'Contacts',
      SERVICES: 'Services',
      COMMISSIONS:'Commisssions',
      BONS : 'Voucher',
      //MESSAGES: 'Messages',
      CLIENTS: 'Customers',
      ADMINISTRATIONS: 'Administrations',
      CONFIGURATIONS : 'Configurations',
      SETTINGS: 'Settings',
      REF: 'Reference',
      MONTANT: 'Amount',
      SOLDEAVANT:'Balance before',
      SOLDEAPRES:'Balance after',
      AUTEUR:'Author',
      ENTITE:'Entity',
      DOCUMENT:'Document',
      DATE_DE_DEMANDE:'Request date',
      DATE_DE_REPONSE:'Response date',
      ETAT:'Etat',
      MONTANT_DEMANDE:'Amount requested',
      SOLDE_ENTITE:'Entity balance',
      SOCIETE:'society',
      ENTREPRISE:'Business',
      COMPAGNIE:'Company',
      AGREGATEUR:'Aggregator',
      MOTIF:'Reason',
      NUM:'No. transaction',
      AUTEUR_VALIDATION:'Author validation',
      APPROVISIONNEMENT_ENTITE:' Entities',
      APPROVISIONNEMENT_SOCIETE:' Companies',
      APPROVISIONNEMENT_AJOUT:'Provision',
      DEMANDE_CARTE_APPRO:'Card approval request',
      LISTE_DEMANDE_CARTE_APPRO:'Card supply list',
      NOMBRE:'Number',
      NOMBREAVANT:'Number before',
      NOMBREAPRES:'Number after',
      DATEDEDEMANDE:'Request date',
      DATEDEREPONSE:'Response date',
      REPORTSOCIETE:'Card approval commission',
      RECHARGE_CARD_MULTIPLE:"credit card by file",
      STATISQUE:"statistic",
      COMPANIES:"Companies",
      CAISSIER:"Cash register",
      STATISTIC_BAR:'Bar chart for fuel sales numbers per month',
      STATISTIC_BAR_2:"Bar chart for fuel sales amount per month",
      STATISTIC_STATION:"Number of sales per station",
      STATISTIC_STATION_M:"Amount of sales per station",
      STATISTIC_CARD_M:"Number of cards per company",
      STATISTIC_BON_M:"Number of vouchers per company",
    },

    AUTH: {
      GENERAL: {
        OR: 'Ou',
        SUBMIT_BUTTON: 'Soumettre',
        NO_ACCOUNT: 'Ne pas avoir de compte?',
        SIGNUP_BUTTON: 'Registre',
        FORGOT_BUTTON: 'Forgot your password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Créer un compte',
        BUTTON: 'Sign In',
        WELCOME_TEXT: 'Connectez-vous à la Plateforme',
        ICI : 'Nouveau ici? ',
      },
      NEWPASSWORD: {
        TITLE: 'Configurer un nouveau mot de passe ?',
        SOUSTITLE: 'Vous avez déjà réinitialisé votre mot de passe ?',

      },
      FORGOT: {
        TITLE: 'Forgot your password?',
        DESC: 'Entrez votre e-mail pour réinitialiser votre mot de passe.',
        SUCCESS: 'Your account has been successfully reset.',
        ENREGISTER : 'Enregistrer',
        ANNULE : 'Annuler',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
        COMPTE_CREER : 'Vous avez déjà un compte ?    ',
        SIGN_HERE :'Se connecter ici',
        LASTNAME: 'Nom',
        FIRSTNAME: 'Prénom',
        SYMBOLE_PASSWORD :'Utilisez 8 caractères ou plus avec un mélange de lettres, de chiffres & symboles.'
      },

      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'First Name & Last Name',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm the password',
        PASSWORDNOW : 'Current Password',
        PASSWORDCHANGE : ' New Password',
        USERNAME: 'Username',
        NOM:'Name',
        CODE:'Code',
        AGENCESUPERIEUR:'Superior agency',
        REGIONS:'Regions',
        DEPARTEMENT:'Department',
        COMMUNE:'Municipality',
        ADRESSE:'Adress',
        SEUILSOLDE:'Balance Threshold',
        NOMBRECAISSE:'Number cash register',
        HORAIRE:'Horaire',
        TELEPHONE:'Phone',
        SOCIETE:'Company',
        LOGIN:'Username',
        LOGINTO:'Log in to PétroCash',
        CONNEXION:'To log in',
      },
      VALIDATION: {
        INVALID: '{{name}} n\'est pas valide',
        REQUIRED: '{{name}} est requis',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },

    ENTITY: {
      ACTIONS_TITLE :{
        ADD : 'Ajouter une entité',
        UPDATE : 'Modifier l\'entité',
        LIST: 'Lister les entités',
        DELETE: 'Supprimer l\'entité',
        ADDROLE:'Ajouter un role',
        UPDATEROLE: 'Modifier un role',
      },
      UTILS :{

      },
      FORM_ENTITY :{
        SOCIAL_REASON:'Raison sociale / Nom',
        COMMERCIAL_REGISTER:'Registre de commerce',
        //ACTIVITY_AREA:'Secteur d\'activité',
        PHONE:'Téléphone',
        ADDRESS:'Adresse',
        CURRENCY:'Devise',
        VAT_RATE:'Taux de TVA',
        FILE_TYPE: 'Types de fichiers autorisés',

      }

    },

    PARTNER: {
      FORM_TITLE_ADD: 'Ajouter un partenaire',
      FORM_TITLE_UPDATE: 'Modifier un partenaire',
    },

    SERVICE: {
      FORM_TITLE_ADD: 'Ajouter un service',
      FORM_TITLE_UPDATE: 'Modifier un service',
    },


    BUTTONS:{
      ADD_BUTTON: 'Add',
      CANCEL_BUTTON : 'To cancel',
      UPDATE_BUTTON : 'Edit',
      SUBMIT_BUTTON: 'Save',
      VALIDATE_BUTTON: 'To validate',
      FILTER: 'Filtrer',
      SEARCH: 'Chercher',
      EXPORT: 'Exporter',

    },
    PERMISSIONS:{
      PERMISSIONS_NAME:'Permissions',
      PERMISSIONS_ROLE: 'Assigné à',

    },
    SERVICES:{
      DETAIL_DEBUT:'Début',
      DETAIL_FIN: 'Fin',
      DETAIL_FRAIS:'Frais',
      DETAIL_COMMISSION:'Commission',
      DETAIL_TAUXPOINT:'Taux point envoi',
      DETAIL_TAUXRETRAIT:'Taux point retrait',
      DETAIL_TAUXOPERATEURS:'Taux Opérateur',
      DETAIL_TAUXPLATEFORME:'Taux Plateforme',
      DETAIL_TAUXBANQUE:'Taux Banque',
    },
    CARTECARBURANT:{
      COMPAGNIE:'Compagnie',
      REFERENCE: 'Référence',
      SOLDE:'Balance',
      STATUS:'Statut',

    }
  }




};
