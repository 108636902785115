export enum Endpoint{
    HYDRA_MEMBER='hydra:member',
    LOGIN = "login",
    PWD_FORGOT="users/password/forget",
    PWD_CHANGE= "/users/password/change",
    USERS ="/users",
    USERS_CARBURANT ="/users/carburant",
    USERS_ENATTENTE ="/users/enattente",
    ROLES="/roles",
    ROLES_CARBURANT="/roles/carburant",
    ROLES_ALL='/roles/all',
    STATION_ALL="/agences",
    POINT_DE_VENTES='/point_de_ventes',
    CAISSE="/caisses",
    CAISSE_STATION="/caisses/stations",
    CAISSENOTAFFECTES="/caisses/non/affectes",
    SOCIETE="/societes",
    SERVICE="/services",
    MODULE="/modules",
    PARTENAIRES = '/partenaires',
    SERVICES = '/services',
    CATEGORIES = '/categories',
    USERS_CSV = '/users/entite/csv',
    USERS_PDF = '/users/entite/pdf',
    ENTITES_POST = '/entities',
    ENTITES_GET = '/entites',
    ENTITES_PDF = '/entities/pdf',
    ENTITES_CSV = '/entities/csv',
    CHAMBRE_CONSULAIRES = '/chambre_consulaires',
    ACTION="/actions",
    COMMISSIONS='/commissions',
    AGENCE='/agences/all',
    REGION='/regions',
    DEPARTEMENT='/departements',
    COMMUNE='/communes',
    LISTECARBURANT='/carte_carburants',
    LISTESOCIETE='/societes/all',
    LISTESOCIETE_EMETTEUR='/societes/emetteur',
    LOTS='/lots',
    FILETEMPCARTE='/cartes/multiples',
    FILEPCARTEVALIDER='/carte_carburants/valider',
    EXPORTCARTEPDF='/carte_carburants/pdf',
    EXPORTCARTECSV='/carte_carburants/csv',
    CLIENTS='/clients',
    CARTE_ATTRIBUTIONS='/carte_carburants/attribues',
    ATTRIBUUTION_CARTES='/carte_carburants/attribution',
    CARTE_DISPONIBLE='/carte_carburants/disponibles',
    CLIENTS_PDF='/clients/pdf',
    CLIENTS_CSV='/clients/csv',
    RECHARGGECARTEM='/bulk',
    RECHARGGEVCARTEM='/bulk/confirmer',
    CARTECHANGESTATUS='/carte_carburants/status',
    CARTEQRCODE='/carte_carburants/qrCode/',
    TRANSACTION_CASHIN='/transactions/cashin',
    PERMISSION='/permissions',
    MESPERMISSIONS='/permissions/generate',
    STATUS='users/setstatus/{id}',
    STATION_PDF='/point_de_ventes/pdf',
    STATION_CSV='/point_de_ventes/csv',
    TRANSACTION_LISTE='/transactions',
    BONS='/bons',
    BONS_PDF='/bons/pdf',
    BONS_CVS='/bons/cvs',
    ADD_LOT_BONS='/lots/bons',
    FILE_BON_VALIDER='/bon/valider',
    FILE_TEMP_BON='/bons/multiples',
    TRANSACTION_CSV='/transactions/csv',
    CONTACT='/contacts',
    MEMBRES = '/membres',
    STATIONS='/stations',
    STATIONS_PDF='/stations/pdf',
    STATIONS_CSV='/stations/csv',
    STATIONS_UPDATE='/stations/edit',
    APPROVISIONNEMENT='/approvisionnements',
    APPROVISIONNEMENT_PDF='/approvisionnements/pdf',
    APPROVISIONNEMENT_CSV='/approvisionnements/csv',
    APPRO='/approvisionnements',
    APPROVISIONNEMENTSOCIETES='/approvisionnements/societes',
    APPROVISIONNEMENTAGENCE='/approvisionnement_agences',
    COMPAGNIE='/societes/compagnies',
    COMPAGNIE_ALL='/societes/compagnies/all',
    AGREGATEUR='/societes/agregateurs/all',
    AGREGATEUR_='/societes/agregateurs',
    REPORTING_MENSUEL='/reporting/vente/mensuel',
    REPORTING_CARTE_SOCIETE='/reporting/cartes/par_societe',
    REPORTING_VENTE_STATION='/reporting/vente/par_station',
    REPORTING_BONS_SOCIETE='/reporting/bons/par_societe',
    TRANSACTION='/transactions',
    TRANSACTIONAGREGAT='/transactions/agregat',
    TRANSACTIONCOMMISSION='/transactions/commission',
    APPROCOMMISSION='/appro_cartes/commissions',
    CLIENT_FILE='/enrolements/cartes/valider',
    SAVE_CLIENT='/enrolements/cartes/multiples',
    DETAIL_SOCIETE='/societes/{id}',
    SOLDE='/entites/solde',
    EXPORT_LISTE_CAISSE='/caisses/pdf',
    TRANSACTION_PDF='/transactions/pdf',
    STATION_DETAIL='/point_de_ventes',
    APPROVISIONNEMENT_SOCIETE_PDF='/approvisionnements/societes/pdf',
    APPROVISIONNEMENT_SOCIETE_CSV='/approvisionnements/societes/csv',
    APPROVISIONNEMENT_COMPAGNIE='/approvisionnements/compagnies/pdf',
     SOCIETE_PDF='/societes/pdf',
     SOCIETE_CSV='/societes/csv',
     SOCIETE_CAMPAGNIE_PDF='/societes/compagnies/pdf',
     SOCIETE_CAMPAGNIE_CSV='/societes/compagnies/csv',
     SOCIETE_AGREGATEUR_PDF='/societes/agregateurs/pdf',
    SOCIETE_AGREGATEUR_CSV='/societes/agregateurs/csv',
    STATUS_CLIENT='/clients/status',
    ROLLBACK_TRANSACTION='/transactions/rollback/',
    ROLECARBURANT='/roles/carburant',
    EDIT_STATION='/stations/edit',
    TRANSACTION_EDIT='/transactions/',
    ROLES_PDF='/roles/carburant/pdf',
    ROLES_CSV='/roles/carburant/csv',
    DEMANDE_CARTE_APPRO='/appro_cartes',
    LISTE_DEMANDE_CARTE_APPRO='/appro_cartes',
    APPRO_CARTE_CSV='/appro_cartes/csv',
    APPRO_CARTE_PDF='/appro_cartes/pdf',
    APPRO_CARTE_REJETER='/appro_cartes/rejeter/',
    APPRO_CARTE_VALIDATION='/appro_cartes/validation',
    COMMISSION='/commissions',
    COMMISSION_csv='/commissions/csv',
    COMMISSION_pdf='/commissions/pdf',
    TOTAL_VENTES='/transactions/agregat',
    TOTAL_VENTES_STATION='/transactions/agregat/ventes',
    TOTAL_VENTES_POMPISTE='/transactions/agregat/ventes/pompiste',




}
