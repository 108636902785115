<div *ngIf="loading" class="blockui rounded">
  <div style="align-self: center;">
        <img style="width: 50px !important;" src="./assets/spinner.gif" />
   </div>
</div>

<!-- begin::Header -->
<div class="card-header border-0 pt-5">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder text-dark">Cartes</span>
    <span class="text-muted mt-1 fw-bold fs-7" *ngIf="total > 0">{{'+' + total + ' cartes'}}</span>
  </h3>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body pt-2" style="height: 385px; overflow-y:scroll; margin-right: 24px;">
  <!-- begin::Item -->
  <div *ngFor="let item of items" class="d-flex align-items-center mb-8">
    <!-- begin::Bullet -->
    <span [ngStyle]="{ background: item?.couleur }" class="bullet bullet-vertical h-40px me-5"></span>
    <!-- end::Bullet -->
    <!-- begin::Description -->
    <div class="flex-grow-1">
      <a href="#" class="text-gray-800 text-hover-primary fw-bolder fs-6">
        {{item?.compagnie?.nom}}
      </a>
      <span class="text-muted fw-bold d-block">{{item?.reference}}</span>
    </div>
    <!-- end::Description -->
    <span [ngClass]="item?.status ? 'badge badge-light-success': 'badge badge-light-danger'">{{item?.status ? 'Actif' : 'Inactif'}}</span>
  </div>
  <!-- end:Item -->
  
</div>
<!-- end::Body -->

<div  class="w-100 text-end p-10">
  <span class="link-type1" routerLink="/entites/list">Voir+</span>
</div>

