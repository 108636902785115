import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/modules/auth';
import { AuthService } from 'src/app/modules/auth';
import { Endpoint } from 'src/app/models/Endpoint';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PageInfoService } from 'src/app/_metronic/layout';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  itemId : number = 0
  entite:any;
  itemModel = new Role()
  loading = false
  titlePage:any=[

    {
      title: 'Role',
      path: '/utilisateurs/list',
      isSeparator: true
   },
    {
      title: 'Nouveau role',
      path: '/role/list',
      isSeparator: false
    }
  ]
  constructor(private pageInfo:PageInfoService,   private authService: AuthService, private router:Router, private toastr: ToastrService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.entite =  JSON.parse(<string>localStorage.getItem('entity'));
    this.activatedRoute.params.subscribe(params => {
      this.itemId = params['id'];
      console.log("The item id ", this.itemId)
    });
    if(this.itemId)
    this.pageInfo.updateTitle('Admistrateur');
    this.pageInfo.updateBreadcrumbs(this.titlePage);this.getItem();
  }

  getItem(){
    this.loading = true;
    this.authService.get(this.itemId, Endpoint.ROLES).subscribe((res:any)=>{
      console.log('the item we get ', res)
      this.itemModel = res;
      this.loading = false;
    },error => {
      console.log('error oneuser',error)
      this.loading = false;
      this.itemId = 0;
    })
  }


  saveItem() { //fonction d'enregistrer un user
    this.itemModel.codeEntite=this.entite.codeEntite;
    this.itemModel.forNetwork = !this.itemModel.isInternal ;
    console.log('item model here ',this.itemModel)
    this.loading = true;
    this.authService.upsert(this.itemId, Endpoint.ROLES_CARBURANT, this.itemModel)?.subscribe((res:any)=>{
      this.loading =false;
      console.log('save item :', res);
      this.itemModel = new Role()
      this.loading =false;
      this.toastr.success('Mise à jour réussie')
      setTimeout(() => {
        this.router.navigateByUrl('roles/list')
      }, 2500);

    },error => {
      this.toastr.error('Une erreur est survenue')
      console.log('save item error',error.error['hydra:description'])
      this.loading = false;
    })
  }
  updsteItem() { //fonction d'enregistrer un user
    this.itemModel.codeEntite=this.entite.codeEntite;
    this.itemModel.forNetwork = !this.itemModel.isInternal ;
    console.log('item model here ',this.itemModel)
    this.loading = true;
    this.authService.upsert(this.itemId, Endpoint.ROLES, this.itemModel)?.subscribe((res:any)=>{
      this.loading =false;
      console.log('save item :', res);
      this.itemModel = new Role()
      this.loading =false;
      this.toastr.success('Mise à jour réussie')
      setTimeout(() => {
        this.router.navigateByUrl('roles/list')
      }, 2500);

    },error => {
      this.toastr.error('Une erreur est survenue')
      console.log('save item error',error.error['hydra:description'])
      this.loading = false;
    })
  }
}
