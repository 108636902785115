import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as saveAs from 'file-saver';
import { Endpoint } from 'src/app/models/Endpoint';
import { AuthService } from 'src/app/modules/auth';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { UserPermissions } from 'src/app/shared/user_permissions'
import { Guard } from 'src/app/shared/utils/guard';
import { ConfirmPinComponent } from '../../confirm-pin/confirm-pin.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PageInfoService } from 'src/app/_metronic/layout';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  loading: boolean;
  page = 1;
  query = '';
  queryExport = '';
  items:any = [];
  reports:any = [];
  totals:number = 0;
  pageSize : number = 10;
  profils:any;
  societes : any [] = [];
  societesE : any [] = [];
  services : any [] = [];
  searchText : string;
  searchTextNotifier  = new Subject();
  page_error : boolean = false;
  filterModel:any = {};
  clientSelect :any;
  societeUser:any;
  socClt:any;
  public autority = UserPermissions.MANAGE_Print
  public transaction=UserPermissions.MANAGE_Transactions;
  titlePage:any=[

    {
      title: 'Transactions',
      path: '/transaction/list',
      isSeparator: true
   },
    {
      title: 'Liste',
      path: '/transaction/list',
      isSeparator: false
    }
  ]
  constructor(private pageInfo:PageInfoService,private toastr:ToastrService, private modalService:NgbModal,  private authService : AuthService,private service:GlobalService, private fb : FormBuilder, public guard:Guard) { }
  ngOnInit(): void {
    this.societeUser =  JSON.parse(<string>localStorage.getItem('societe'));
    console.log('societeUser =>',this.societeUser)
    this.getSociete();
    this.getSocieteEm();
    this.getService();
    this.pageInfo.updateTitle('Transactions');
    this.pageInfo.updateBreadcrumbs(this.titlePage);
    const year=new Date().getFullYear()
    this.filterModel.debut = year+"-01-01";
    this.filterModel.fin = year+"-12-31";
    this.filterItem();
  }

  filterItem() {
    this.query = '';
    this.queryExport = '';
    this.page = 1;
    if (this.filterModel.numTransaction) this.query += `&numTransaction=${this.filterModel.numTransaction}`;
    if (this.filterModel.status) this.query += `&status=${this.filterModel.status}`;
    if (this.filterModel.debut) {
      this.query += `&dateCreation[after]=${this.filterModel.debut}`;
      this.queryExport += `&dateCreation[after]=${this.filterModel.debut}`;
    }
    if (this.filterModel.fin) {
      this.query += `&dateCreation[before]=${this.filterModel.fin}`;
      this.queryExport += `&dateCreation[before]=${this.filterModel.fin}`;
    }
    if (this.filterModel.societe) {
      this.query += `&societe.id=${this.filterModel.societe}`;
      this.queryExport += `&societe.id=${this.filterModel.societe}`;
    } if (this.filterModel.services) {
      this.query += `&service.id=${this.filterModel.services}`;
      this.queryExport += `&service.id=${this.filterModel.services}`;
    }
    if (this.filterModel.societeCarte) {
      this.socClt = this.findSocieteByID(this.filterModel.societeCarte)
      this.query += `&carte.compagnie.id=${this.filterModel.societeCarte}`;
      this.queryExport += `&carte.compagnie.id=${this.filterModel.societeCarte}`;
    }
    this.getList();
    this.getReport()
  }


  getSociete(){
    let query = ``;
    this.service.getListeStation(Endpoint.SOCIETE, 1, query).subscribe((res:any)=>{
      this.societes = res['hydra:member'];
      this.societes.map((i:any) => { i.uri = ['/api/societes/' + i.id]; return i; });
    },error => {
    })
  }

  getSocieteEm(){
    let query = ``;
    this.service.getListeStation(Endpoint.LISTESOCIETE, 1, query).subscribe((res:any)=>{
      this.societesE = res['hydra:member'];
      this.societesE.map((i:any) => { i.uri = ['/api/societes/' + i.id]; return i; });
    },error => {
    })
  }
  getService(){
    let query = ``;
    this.service.getListeStation(Endpoint.SERVICE, 1, query).subscribe((res:any)=>{
      this.services = res['hydra:member'];
      this.services.map((i:any) => { i.uri = ['/api/services/' + i.id]; return i; });
    },error => {
    })
  }
  getList(){
    this.loading =true
    this.service.getListeStation(Endpoint.TRANSACTION,this.page,this.query).subscribe((res:any)=>{
    console.log('transactions', res['hydra:member']);
    this.items = res['hydra:member'];
    this.totals = res['hydra:totalItems'];
    },error => {
      console.log('error',error)
      this.loading =false;
    })
  }

  paginate(p: number){
    console.log("event -> ", p)
    this.page = p
    this.getList()
  }


  changeStatus(id : any, item : any){
    const dialogRef = this.modalService.open(ConfirmPinComponent, {centered: true, size:"md"});
    dialogRef.componentInstance.data = {
      message: "Etes vous sûr de vouloir d'annuler la transaction.",
      title: "Changement statut"
    };
    dialogRef.result
      .then((res) => {
        if(res.confirmed) {
          this.loading = true;
          this.service.update(Endpoint.ROLLBACK_TRANSACTION + id  )?.subscribe({
            next: (res : any) => {
              this.clientSelect.status = !this.clientSelect.status;
              this.toastr.success("Status modifié avec succés ")
              console.log(res);
              this.loading =false;
            },
            error: err => {
              console.log('error',err)
              this.toastr.error("Une erreur est survenue ")
              this.loading =false;
            }
          })
        }
        if (!res) {
          return;
        }
      });
}

  export(type = '') {
    this.loading = true;
    if (type == 'pdf') {
      const fileName = 'transaction.pdf';
      this.service.exportStation(Endpoint.TRANSACTION_PDF, this.query).subscribe((res:any) => {
          this.loading = false;
          saveAs(res.body, fileName);
        }, error => {
          this.loading = false;
        });
    } else {
      const fileName = 'transaction.csv';
      this.service.exportStation(Endpoint.TRANSACTION_CSV, this.query).subscribe((res:any) => {
          this.loading = false;
          saveAs(res.body, fileName);
        }, error => {
          this.loading = false;
        });
    }
    return;
  }

  pageChange(page:any){
    this.page=page;
    this.getList();
  }
  getReport(){
    this.loading =true
    this.service.getListeStation(Endpoint.TRANSACTIONAGREGAT,1,this.queryExport).subscribe((res:any)=>{
      console.log('transactions', res['hydra:member']);
      this.reports = res['hydra:member'][0];
      this.loading =false;
    },error => {
      console.log('error',error)
      this.loading =false;
    })
  }
  findSocieteByID(id:number){
   var soc = this.societesE.find(item=>item.id == id);
   return soc?.nom;
  }
}
