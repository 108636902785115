<!-- Dashboard -->
<!--<div class="menu-item"  *ngIf="guard.passModules('tableau_de_bord')">-->
<!--  <a class="menu-link without-sub" routerLink="/" [routerLinkActive]="'active'"><span class="menu-icon">-->
<!--      <span [inlineSVG]="'./assets/media/icons/duotune/art/art002.svg'" class="svg-icon svg-icon-2"></span>-->
<!--    </span>-->
<!--    <span class="menu-title" translate="MENU.DASHBOARD"></span></a>-->

<!--  <hr>-->
<!--</div>-->
<!-- Separator -->
<div class="menu-item"  *ngIf="guard.passModules('tableau_de_bord')">
  <div class="menu-content pt-8 pb-2">
    <span class="menu-section text-muted text-uppercase fs-8 ls-1"> DASHBOARD</span>
  </div>
</div>

<!-- Administrations -->
<div class="menu-item menu-accordion"  data-kt-menu-trigger="click" [routerLinkActive]="'here show'"  *ngIf="guard.passModules('tableau_de_bord')">
  <span class="menu-link"><span class="menu-icon"><span class="svg-icon svg-icon-2"
                                                        [inlineSVG]="'./assets/media/icons/duotune/art/art002.svg'" ></span></span><span class="menu-title"
                                                                                                                                               translate="MENU.DASHBOARD" data-link="/crafted/account"></span><span class="menu-arrow"></span></span>

  <div class="menu-sub menu-sub-accordion" [routerLinkActive]="'menu-active-bg'">
    <div class="menu-item" >
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
        class="menu-title"  >Général</span></a>
    </div>

    <div class="menu-item" >
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/dashboard-societe'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
        class="menu-title">Ventes</span></a>
    </div>

  </div>

  <hr>
</div>

<!-- Separator -->
<div class="menu-item"  *ngIf="guard.passModules('administration')">
  <div class="menu-content pt-8 pb-2">
    <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="MENU.CONFIGURATION"></span>
  </div>
</div>

<!-- Administrations -->
<div class="menu-item menu-accordion"  data-kt-menu-trigger="click" [routerLinkActive]="'here show'"  *ngIf="guard.passModules('administration')">
  <span class="menu-link"><span class="menu-icon"><span class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/technology/teh004.svg'"></span></span><span class="menu-title"
      translate="MENU.ADMINISTRATIONS" data-link="/crafted/account"></span><span class="menu-arrow"></span></span>

  <div class="menu-sub menu-sub-accordion" [routerLinkActive]="'menu-active-bg'">
    <div class="menu-item" *ngIf="guard.pass(autority.administration.actions.list_user)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/utilisateurs/list'"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
          class="menu-title"  translate="MENU.USERS"></span></a>
    </div>
    <div class="menu-item" *ngIf="guard.pass(autority.administration.actions.list_user_wait)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/utilisateurs/list_attente'"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
          class="menu-title" translate="MENU.USERS_EN_ATTENTE"></span></a>
    </div>

    <div class="menu-item" *ngIf="guard.pass(autority.administration.actions.list_role)">
      <a class="menu-link without-sub" routerLinkActive="active" routerLink="/roles/list"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.ROLES"></span></a>
    </div>

    <div class="menu-item" *ngIf="guard.pass(autority.administration.actions.list_permission)">
      <a class="menu-link without-sub" routerLinkActive="active" routerLink="/permissions/list"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
          class="menu-title"  translate="MENU.PERMISSIONS"></span></a>
    </div>

  </div>

  <hr>
</div>
<div class="menu-item"   *ngIf="guard.passModules('approvisionnement')" >
  <div class="menu-content pt-8 pb-2">
    <span class="menu-section text-muted text-uppercase fs-8 ls-1"  translate="MENU.APPROSVISIONNEMENT"></span>
  </div>
</div>
<!--Gestion des stations -->
<div class="menu-item menu-accordion" data-kt-menu-trigger="click" [routerLinkActive]="'here show'" routerLinkActive="active"  *ngIf="guard.passModules('approvisionnement')">
  <span class="menu-link" *ngIf="guard.pass(autority.appro.actions.list_appro_en) || guard.pass(autority.appro.actions.add_appro_entite)"><span class="menu-icon"><span class="svg-icon svg-icon-2"
      [inlineSVG]="'./assets/media/icons/duotune/general/gen003.svg'"></span></span><span class="menu-title"
      translate="MENU.APPROVISIONNEMENT_ENTITE" data-link="/crafted/account"></span><span class="menu-arrow"></span></span>
  <div class="menu-sub menu-sub-accordion" [routerLinkActive]="'menu-active-bg'">
    <div class="menu-item" *ngIf="guard.pass(autority.appro.actions.list_appro_en)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/approvisionnements/liste_approvisionnement'"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.APPROVISIONNEMENT"></span></a>
    </div>
    <div class="menu-item" *ngIf="guard.pass(autority.appro.actions.add_appro_entite)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/approvisionnements/form_approvisionnement'"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.APPROVISIONNEMENT_AJOUT"></span></a>
    </div>
    <div class="menu-item" *ngIf="guard.pass(autority.appro.actions.add_appro_entite)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/approvisionnements/liste_appro_ent'"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.LISTE_DEMANDE_CARTE_APPRO"></span></a>
    </div>
</div>
</div>
<!-- Caisses -->
<div class="menu-item menu-accordion" data-kt-menu-trigger="click" [routerLinkActive]="'here show'" routerLinkActive="active"  *ngIf="guard.passModules('approvisionnement')">
  <span class="menu-link" *ngIf="guard.pass(autority.appro.actions.add_appro_societe) || guard.pass(autority.appro.actions.list_appro_societe)"><span class="menu-icon"><span class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/communication/com001.svg'"></span></span><span class="menu-title"
      translate="MENU.APPROVISIONNEMENT_SOCIETE" data-link="/crafted/account"></span><span class="menu-arrow"></span></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item" *ngIf="guard.pass(autority.appro.actions.list_appro_societe)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/approvisionnements/liste_approvisionnement_societe'"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.APPROVISIONNEMENT"></span></a>
    </div>
    <div class="menu-item" *ngIf="guard.pass(autority.appro.actions.add_appro_societe)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/approvisionnements/form_approvisionnement_societe'"><span
          class="menu-bullet">
      <span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.APPROVISIONNEMENT_AJOUT"></span></a>
    </div>
    <div class="menu-item">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/approvisionnements/demande_carte_approvisionnement_societe'"><span
          class="menu-bullet">
      <span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.DEMANDE_CARTE_APPRO"></span></a>
    </div>
    <div class="menu-item">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/approvisionnements/liste_carte_approvisionnement_societe'"><span
          class="menu-bullet">
      <span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.LISTE_DEMANDE_CARTE_APPRO"></span></a>
    </div>
  </div>
  <span class="menu-link" *ngIf="guard.pass(autority.appro.actions.list_appro_agence) || guard.pass(autority.appro.actions.add_appro_agence)"><span class="menu-icon"><span class="svg-icon svg-icon-2"
  [inlineSVG]="'./assets/media/icons/duotune/communication/com001.svg'"></span></span><span class="menu-title" translate="MENU.APPROVISIONNEMENT_STATION" data-link="/crafted/account"></span><span class="menu-arrow"></span></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item" *ngIf="guard.pass(autority.appro.actions.list_appro_agence)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/approvisionnements/liste_approAgence'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.APPROVISIONNEMENT"></span></a>
    </div>
    <div class="menu-item" *ngIf="guard.pass(autority.appro.actions.add_appro_agence)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/approvisionnements/add_approAgence'"><span
        class="menu-bullet">
      <span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.APPROVISIONNEMENT_AJOUT"></span></a>
    </div>
  </div>
  <hr>
</div >
<!--Sociétes-->

<div class="menu-item"  *ngIf="guard.passModules('sociatas')">
  <div class="menu-content pt-8 pb-2">
    <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="MENU.SOCIETES"></span>
  </div>
</div>
<div  class="menu-item menu-accordion" data-kt-menu-trigger="click" [routerLinkActive]="'here show'"  *ngIf="guard.passModules('sociatas')">
  <span class="menu-link" *ngIf="guard.pass(autority.societes.actions.societes_list) || guard.pass(autority.societes.actions.societes_list)">
    <span class="menu-icon"><span class="svg-icon svg-icon-2"
  [inlineSVG]="'./assets/media/icons/duotune/communication/com001.svg'"></span></span><span class="menu-title"
    data-link="/crafted/account"translate="MENU.ENTREPRISES"></span><span class="menu-arrow"></span></span>
  <div class="menu-sub menu-sub-accordion" [routerLinkActive]="'menu-active-bg'">
    <div class="menu-item" *ngIf="guard.pass(autority.societes.actions.societes_list)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/societe/liste_societe'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.LISTE"></span></a>
    </div>
    <div class="menu-item" >
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/societe/form_societe'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.ADDSOCIETE"> </span></a>
    </div>
  </div>
</div>
<div class="menu-item menu-accordion" data-kt-menu-trigger="click" [routerLinkActive]="'here show'"  *ngIf="guard.passModules('sociatas')">
  <span class="menu-link" *ngIf="guard.pass(autority.societes.actions.societesCmp_list) "><span class="menu-icon"><span class="svg-icon svg-icon-2"
  [inlineSVG]="'./assets/media/icons/duotune/art/art009.svg'"></span></span><span class="menu-title"
    data-link="/crafted/account" translate="MENU.COMPAGNIES"></span><span class="menu-arrow"></span></span>

  <div class="menu-sub menu-sub-accordion" [routerLinkActive]="'menu-active-bg'">
    <div class="menu-item" *ngIf="guard.pass(autority.societes.actions.societesCmp_list)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/societe/liste_compagnie'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.LISTE"></span></a>
    </div>
    <div class="menu-item" *ngIf="guard.pass(autority.societes.actions.add_societe)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/societe/form_compagnie'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.ADDCOMPAGNIE"> </span></a>
    </div>
  </div>
</div>
<div class="menu-item menu-accordion" data-kt-menu-trigger="click" [routerLinkActive]="'here show'"  *ngIf="guard.passModules('sociatas')">
  <span class="menu-link" *ngIf="guard.pass(autority.societes.actions.societesAg_list) "><span class="menu-icon"><span class="svg-icon svg-icon-2"
  [inlineSVG]="'./assets/media/icons/duotune/general/gen002.svg'"></span></span><span class="menu-title"
    data-link="/crafted/account" translate="MENU.AGREGAT"></span><span class="menu-arrow"></span></span>
  <div class="menu-sub menu-sub-accordion" [routerLinkActive]="'menu-active-bg'">
    <div class="menu-item" *ngIf="guard.pass(autority.societes.actions.societesAg_list)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'"  [routerLink]="'/societe/liste_agregateur'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.LISTE"></span></a>
    </div>
    <div class="menu-item" *ngIf="guard.pass(autority.societes.actions.add_societe)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/societe/form_agregateur'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.ADDAGREGATEUR"></span></a>
    </div>
  </div>
  <hr>
</div>
<!-- Separator -->
<div class="menu-item" *ngIf="guard.passModules('stations')">
  <div class="menu-content pt-8 pb-2">
    <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="MENU.GESTIONDESSTATIONS"></span>
  </div>
</div>

<!--Gestion des stations -->
<div  *ngIf="guard.pass(autority.stations.actions.station_list)" class="menu-item menu-accordion" data-kt-menu-trigger="click" [routerLinkActive]="'here show'" routerLinkActive="active" >
  <span class="menu-link"><span class="menu-icon"><span class="svg-icon svg-icon-2"
      [inlineSVG]="'./assets/media/icons/duotune/general/gen001.svg'"></span></span><span class="menu-title"
      translate="MENU.STATION" data-link="/crafted/account"></span><span class="menu-arrow"></span></span>
  <div class="menu-sub menu-sub-accordion" [routerLinkActive]="'menu-active-bg'">
    <div class="menu-item">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" routerLink="/stations/list"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.LISTESTATION"></span></a>
    </div>
</div>
</div>
<!-- Caisses -->
<div *ngIf="guard.pass(autority.stations.actions.caisse_list)" class="menu-item menu-accordion" data-kt-menu-trigger="click" [routerLinkActive]="'here show'" routerLinkActive="active" >
  <span class="menu-link"><span class="menu-icon"><span class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/communication/com014.svg'"></span></span><span class="menu-title"
      translate="MENU.CAISSE" data-link="/crafted/account"></span><span class="menu-arrow"></span></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item">
      <a class="menu-link without-sub" routerLinkActive="active" routerLink="/caisse/list"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"translate="MENU.LISTE"></span></a>
    </div>
  </div>
  <hr>
</div>
<!-- Separator -->
<div class="menu-item" *ngIf="guard.passModules('carburant')">
  <div class="menu-content pt-8 pb-2">
    <span class="menu-section text-muted text-uppercase fs-8 ls-1"translate="MENU.CARBURANT"></span>
  </div>
</div>

<!-- Cartes-->
<div class="menu-item menu-accordion" data-kt-menu-trigger="click" [routerLinkActive]="'here show'"  *ngIf="viewcarte()">
  <span class="menu-link"><span class="menu-icon"><span class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/abstract/abs027.svg'"></span></span><span class="menu-title"
       data-link="/crafted/account"translate="MENU.CARTES"></span><span class="menu-arrow"></span></span>

  <div class="menu-sub menu-sub-accordion" [routerLinkActive]="'menu-active-bg'">
    <div class="menu-item" *ngIf="guard.pass(autority.carburant.actions.carte.list_cartes)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/carburant/liste_carte'"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"translate="MENU.LISTE"></span></a>
    </div>
<!--    <div class="menu-item" *ngIf="guard.pass(autority.carburant.actions.carte.add_lot)">-->
<!--      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/carburant/add_carte'"><span-->
<!--          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"translate="MENU.NEW_LOT_CARD"></span></a>-->
<!--    </div>-->

<!--    <div class="menu-item" *ngIf="guard.pass(autority.carburant.actions.carte.chargementFichier_cartes)">-->
<!--      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/carburant/add_filecarte'"><span-->
<!--          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"translate="MENU.NEW_FILE_CARD"></span></a>-->
<!--    </div>-->
<!--    <div class="menu-item" *ngIf="guard.pass(autority.carburant.actions.carte.recharge_multiple)">-->
<!--      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/carburant/rechargement'"><span-->
<!--          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"translate="MENU.RECHARGEMENT_CARD"></span></a>-->
<!--    </div>-->

  </div>
</div>

<!-- Bon -->
<div class="menu-item menu-accordion" data-kt-menu-trigger="click" [routerLinkActive]="'here show'" *ngIf="viewbon()">
  <span class="menu-link"><span class="menu-icon"><span class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/maps/map002.svg'"></span></span><span class="menu-title"
       data-link="/crafted/account" translate="MENU.BONS"></span><span class="menu-arrow"></span></span>
<div class="menu-sub menu-sub-accordion" [routerLinkActive]="'menu-active-bg'">
    <div class="menu-item" *ngIf="guard.pass(autority.carburant.actions.bon.list_bon)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/bons/liste_bon'"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"translate="MENU.LISTE_BON"></span></a>
    </div>
    <div class="menu-item" *ngIf="guard.pass(autority.carburant.actions.bon.add_lot_bon)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/bons/multiple_bon'"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"translate="MENU.NEW_LOT_BON"></span></a>
    </div>

    <div class="menu-item" *ngIf="guard.pass(autority.carburant.actions.bon.fichier_De_Bon)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/bons/fichier_bon'"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"translate="MENU.NEW_FILE_BON"></span></a>
    </div>
    </div>

    </div>
<hr>
<!-- Separator -->
<div class="menu-item" *ngIf="guard.passModules('clients')">
  <div class="menu-content pt-8 pb-2">
    <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="MENU.GESTION_DES_CLIENTS"></span>
  </div>
</div>
<!-- Clients -->
<div class="menu-item menu-accordion" data-kt-menu-trigger="click" [routerLinkActive]="'here show'" >
  <span class="menu-link" *ngIf="guard.passModules('clients')"><span class="menu-icon"><span class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/communication/com013.svg'"></span></span><span class="menu-title"
      translate="MENU.CLIENTS" data-link="/crafted/account"></span><span class="menu-arrow"></span></span>

  <div class="menu-sub menu-sub-accordion" [routerLinkActive]="'menu-active-bg'">
    <div class="menu-item" *ngIf="guard.pass(autority.clients.actions.list_client)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/clients/liste_client'"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.LISTE"></span></a>
    </div>
    <div class="menu-item" *ngIf="guard.pass(autority.clients.actions.add_multiple)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/clients/file_client'"><span
          class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"translate="MENU.NEW_FILE_CLIENT"></span></a>
    </div>

  </div>

</div>


<!-- Clients -->


<div class="menu-item" *ngIf="guard.passModules('transactions')">
  <div class="menu-content pt-8 pb-2">
    <span class="menu-section text-muted text-uppercase fs-8 ls-1"translate="MENU.GESTION_DES_TRANSACTION"></span>
  </div>
</div>
<!-- Transaction -->
<div class="menu-item menu-accordion" data-kt-menu-trigger="click" [routerLinkActive]="'here show'" *ngIf="guard.passModules('transactions')">
  <span class="menu-link"><span class="menu-icon"><span class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/ecommerce/ecm007.svg'"></span></span><span class="menu-title"
      translate="MENU.TRANSACTION" data-link="/crafted/account"></span><span class="menu-arrow"></span></span>


  <div class="menu-sub menu-sub-accordion" [routerLinkActive]="'menu-active-bg'">

    <div class="menu-item" *ngIf="guard.pass(autority.transaction.actions.list_transaction)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/transaction/list'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"translate="MENU.TOUS"></span></a>
    </div>

    <div class="menu-item" *ngIf="guard.pass(autority.transaction.actions.report_commission)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/transaction/report_societe'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.REPORTSOCIETE"></span></a>
    </div>

    <div class="menu-item" *ngIf="guard.pass(autority.transaction.actions.list_transaction_C)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/caissiers/list_recharge_carte'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.LISTE_DES_TRANSACTIONS_CAISSIERS"></span></a>
    </div>
    <div class="menu-item" *ngIf="guard.pass(autority.transaction.actions.cashin_transaction)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/caissiers/recharge_carte'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title" translate="MENU.RECHARGE_CARD"></span></a>
    </div>

    <div class="menu-item" *ngIf="guard.pass(autority.carburant.actions.carte.recharge_multiple)">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" [routerLink]="'/carburant/rechargement'"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title"translate="MENU.RECHARGE_CARD_MULTIPLE"></span></a>
    </div>
  </div>

  <hr>
</div>

<!-- Separator -->
<div class="menu-item"  *ngIf="guard.passModules('parametrages')">
  <div class="menu-content pt-8 pb-2">
    <span class="menu-section text-muted text-uppercase fs-8 ls-1"translate="MENU.PARAMETRAGES"></span>
  </div>
</div>
<div class="menu-item menu-accordion" data-kt-menu-trigger="click" [routerLinkActive]="'here show'" *ngIf="guard.passModules('parametrages')" >
  <span class="menu-link"><span class="menu-icon"><span class="svg-icon svg-icon-2"
     [inlineSVG]="'./assets/media/icons/duotune/coding/cod001.svg'"></span></span><span class="menu-title" translate="MENU.SETTINGS" data-link="/crafted/account"></span><span class="menu-arrow"></span></span>
  <div class="menu-sub menu-sub-accordion" [routerLinkActive]="'menu-active-bg'" *ngIf="guard.pass(autority.parametre.actions.list_comm)">
    <div class="menu-item">
      <a class="menu-link without-sub" [routerLinkActive]="'active'" routerLink="/parametres/commission"><span
        class="menu-bullet"><span class="bullet bullet-dot"></span></span><span
        class="menu-title"translate="MENU.COMMISIONS"></span></a>
    </div>
  </div>
</div>





