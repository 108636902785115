<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <!--begin::Toolbar-->
    <div class="toolbar" id="kt_toolbar">
        <!--begin::Container-->
        <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
            <!--begin::Page title-->
            <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                <!--begin::Title-->
                <h1 class="d-flex text-dark fw-bolder fs-3 align-items-center my-1">Services Details</h1>
                <!--end::Title-->
                <!--begin::Separator-->
                <span class="h-20px border-gray-300 border-start mx-4"></span>
                <!--end::Separator-->
                <!--begin::Breadcrumb-->
                <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted">
                        <a routerLink=""  class="text-muted text-hover-primary">Dashboards</a>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item">
                        <span class="bullet bg-gray-300 w-5px h-2px"></span>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted">GESTION DES SERVICES</li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item">
                        <span class="bullet bg-gray-300 w-5px h-2px"></span>
                    </li>
                    <li class="breadcrumb-item text-muted">Entites</li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item">
                        <span class="bullet bg-gray-300 w-5px h-2px"></span>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-dark">Service Details</li>
                    <!--end::Item-->
                </ul>
                <!--end::Breadcrumb-->
            </div>
            <!--end::Page title-->
            <!--begin::Actions-->
           
            <!--end::Actions-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Toolbar-->
    <!--begin::Post-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
        <!--begin::Container-->
        <div id="kt_content_container" class="container-xxl">
            <!--begin::Navbar-->
            <div class="card mb-5 mb-xl-10">
                <div class="card-body pt-9 pb-0">
                    <!--begin::Details-->
                    <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                        <!--begin: Pic-->
                        <div class="me-7 mb-4">
                            <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                <img src="{{ itemModel?.source  || 'assets/media/avatars/blank2.png'}}" alt="image" />
                                <div class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
                            </div>
                        </div>
                        <!--end::Pic-->
                        <!--begin::Info-->
                        <div class="flex-grow-1">
                            <!--begin::Title-->
                            <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                <!--begin::User-->
                                <div class="d-flex flex-column">
                                    <!--begin::Name-->
                                    <div class="d-flex align-items-center mb-2">
                                        <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{{itemModel?.raisonSociale}}</a>
                                        <a href="#">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen026.svg-->
                                            <span class="svg-icon svg-icon-1 svg-icon-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                                    <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                                                    <path class="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                        <a *ngIf="itemModel?.status == true"  class="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Active</a>
                                        <a *ngIf="itemModel?.status != true"  class="btn btn-sm btn-light-danger fw-bolder ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Désactive</a>
                                        
                                    </div>
                                    <!--end::Name-->
                                    <!--begin::Info-->
                                    <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                        <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                        <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg-->
                                        <span class="svg-icon svg-icon-4 me-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="currentColor" />
                                                <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="currentColor" />
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->{{itemModel?.secteurActivite}}</a>
                                        <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                        <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg-->
                                        <span class="svg-icon svg-icon-4 me-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="currentColor" />
                                                <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="currentColor" />
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->{{itemModel?.pays}}</a>
                                        <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                        <!--begin::Svg Icon | path: icons/duotune/communication/com011.svg-->
                                        <span class="svg-icon svg-icon-4 me-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor" />
                                                <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor" />
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->{{itemModel?.email}}</a>
                                    </div>
                                    <!--end::Info-->
                                </div>
                                <!--end::User-->
                                <!--begin::Actions-->
                                <div class="d-flex my-4">
                                    <a href="#" class="btn btn-sm btn-light me-2" id="kt_user_follow_button">
                                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr012.svg-->
                                        <span class="svg-icon svg-icon-3 d-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor" />
                                                <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor" />
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->
                                        <!--begin::Indicator-->
                                        <span class="indicator-label">Follow</span>
                                        <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        <!--end::Indicator-->
                                    </a>
                                    <a href="#" class="btn btn-sm btn-primary me-2" data-bs-toggle="modal" data-bs-target="#kt_modal_offer_a_deal">Hire Me</a>
                                    <!--begin::Menu-->
                                    <div class="me-0">
                                        <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <i class="bi bi-three-dots fs-3"></i>
                                        </button>
                                        <!--begin::Menu 3-->
                                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3" data-kt-menu="true">
                                            <!--begin::Heading-->
                                            <div class="menu-item px-3">
                                                <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                                            </div>
                                            <!--end::Heading-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a href="#" class="menu-link px-3">Create Invoice</a>
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a href="#" class="menu-link flex-stack px-3">Create Payment
                                                <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify a target name for future usage and reference"></i></a>
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a href="#" class="menu-link px-3">Generate Bill</a>
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                                                <a href="#" class="menu-link px-3">
                                                    <span class="menu-title">Subscription</span>
                                                    <span class="menu-arrow"></span>
                                                </a>
                                                <!--begin::Menu sub-->
                                                <div class="menu-sub menu-sub-dropdown w-175px py-4">
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Plans</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Billing</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Statements</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <!--begin::Menu separator-->
                                                    <div class="separator my-2"></div>
                                                    <!--end::Menu separator-->
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <div class="menu-content px-3">
                                                            <!--begin::Switch-->
                                                            <label class="form-check form-switch form-check-custom form-check-solid">
                                                                <!--begin::Input-->
                                                                <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />
                                                                <!--end::Input-->
                                                                <!--end::Label-->
                                                                <span class="form-check-label text-muted fs-6">Recuring</span>
                                                                <!--end::Label-->
                                                            </label>
                                                            <!--end::Switch-->
                                                        </div>
                                                    </div>
                                                    <!--end::Menu item-->
                                                </div>
                                                <!--end::Menu sub-->
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3 my-1">
                                                <a href="#" class="menu-link px-3">Settings</a>
                                            </div>
                                            <!--end::Menu item-->
                                        </div>
                                        <!--end::Menu 3-->
                                    </div>
                                    <!--end::Menu-->
                                </div>
                                <!--end::Actions-->
                            </div>
                            <!--end::Title-->
                            <!--begin::Stats-->
                            <div class="d-flex flex-wrap flex-stack">
                                <!--begin::Wrapper-->
                                <div class="d-flex flex-column flex-grow-1 pe-8">
                                    <!--begin::Stats-->
                                    <div class="d-flex flex-wrap">
                                        <!--begin::Stat-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <!--begin::Number-->
                                            <div class="d-flex align-items-center">
                                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                                <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                                                        <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <!--end::Svg Icon-->
                                                <div class="fs-2 fw-bolder" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$">0</div>
                                            </div>
                                            <!--end::Number-->
                                            <!--begin::Label-->
                                            <div class="fw-bold fs-6 text-gray-400">Earnings</div>
                                            <!--end::Label-->
                                        </div>
                                        <!--end::Stat-->
                                        <!--begin::Stat-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <!--begin::Number-->
                                            <div class="d-flex align-items-center">
                                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr065.svg-->
                                                <span class="svg-icon svg-icon-3 svg-icon-danger me-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" />
                                                        <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <!--end::Svg Icon-->
                                                <div class="fs-2 fw-bolder" data-kt-countup="true" data-kt-countup-value="75">0</div>
                                            </div>
                                            <!--end::Number-->
                                            <!--begin::Label-->
                                            <div class="fw-bold fs-6 text-gray-400">Projects</div>
                                            <!--end::Label-->
                                        </div>
                                        <!--end::Stat-->
                                        <!--begin::Stat-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <!--begin::Number-->
                                            <div class="d-flex align-items-center">
                                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                                <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                                                        <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                <!--end::Svg Icon-->
                                                <div class="fs-2 fw-bolder" data-kt-countup="true" data-kt-countup-value="60" data-kt-countup-prefix="%">0</div>
                                            </div>
                                            <!--end::Number-->
                                            <!--begin::Label-->
                                            <div class="fw-bold fs-6 text-gray-400">Success Rate</div>
                                            <!--end::Label-->
                                        </div>
                                        <!--end::Stat-->
                                    </div>
                                    <!--end::Stats-->
                                </div>
                                <!--end::Wrapper-->
                                <!--begin::Progress-->
                                <div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                                    <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                                        <span class="fw-bold fs-6 text-gray-400">Profile Compleation</span>
                                        <span class="fw-bolder fs-6">50%</span>
                                    </div>
                                    <div class="h-5px mx-3 w-100 bg-light mb-3">
                                        <div class="bg-success rounded h-5px" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <!--end::Progress-->
                            </div>
                            <!--end::Stats-->
                        </div>
                        <!--end::Info-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Navs-->
                    
                    <!--begin::Navs-->
                </div>
            </div>
            <!--end::Navbar-->
            <!--begin::Row-->
            <div class="row g-xxl-9">
                <!--begin::Col-->
                <div class="col-xxl-8">
                    <!--begin::Security summary-->
                    <div class="card card-xxl-stretch mb-5 mb-xl-10">
                        <!--begin::Header-->
                        <div class="card-header card-header-stretch">
                            <!--begin::Title-->
                            <div class="card-title">
                                <h3 class="m-0 text-gray-900">Security Summary</h3>
                            </div>
                            <!--end::Title-->
                            <!--begin::Toolbar-->
                            <div class="card-toolbar">
                                <ul class="nav nav-tabs nav-line-tabs nav-stretch border-transparent fs-5 fw-bolder" id="kt_security_summary_tabs">
                                    <li class="nav-item">
                                        <a class="nav-link text-active-primary active" data-kt-countup-tabs="true" data-bs-toggle="tab" href="#kt_security_summary_tab_pane_hours">12 Hours</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link text-active-primary" data-kt-countup-tabs="true" data-bs-toggle="tab" id="kt_security_summary_tab_day" href="#kt_security_summary_tab_pane_day">Day</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link text-active-primary" data-kt-countup-tabs="true" data-bs-toggle="tab" id="kt_security_summary_tab_week" href="#kt_security_summary_tab_pane_week">Week</a>
                                    </li>
                                </ul>
                            </div>
                            <!--end::Toolbar-->
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body pt-7 pb-0 px-0">
                            <!--begin::Tab content-->
                            <div class="tab-content">
                                <!--begin::Tab panel-->
                                <div class="tab-pane fade active show" id="kt_security_summary_tab_pane_hours" role="tabpanel">
                                    <!--begin::Row-->
                                    <div class="row p-0 mb-5 px-9">
                                        <!--begin::Col-->
                                        <div class="col">
                                            <div class="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                                <span class="fs-4 fw-bold text-success d-block">User Sign-in</span>
                                                <span class="fs-2hx fw-bolder text-gray-900" data-kt-countup="true" data-kt-countup-value="36899">0</span>
                                            </div>
                                        </div>
                                        <!--end::Col-->
                                        <!--begin::Col-->
                                        <div class="col">
                                            <div class="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                                <span class="fs-4 fw-bold text-primary d-block">Admin Sign-in</span>
                                                <span class="fs-2hx fw-bolder text-gray-900" data-kt-countup="true" data-kt-countup-value="72">0</span>
                                            </div>
                                        </div>
                                        <!--end::Col-->
                                        <!--begin::Col-->
                                        <div class="col">
                                            <div class="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                                <span class="fs-4 fw-bold text-danger d-block">Failed Attempts</span>
                                                <span class="fs-2hx fw-bolder text-gray-900" data-kt-countup="true" data-kt-countup-value="291">0</span>
                                            </div>
                                        </div>
                                        <!--end::Col-->
                                    </div>
                                    <!--end::Row-->
                                    <!--begin::Container-->
                                    <div class="pt-2">
                                        <!--begin::Tabs-->
                                        <div class="d-flex align-items-center pb-6 px-9">
                                            <!--begin::Title-->
                                            <h3 class="m-0 text-gray-900 flex-grow-1">Activity Chart</h3>
                                            <!--end::Title-->
                                            <!--begin::Nav pills-->
                                            <ul class="nav nav-pills nav-line-pills border rounded p-1">
                                                <li class="nav-item me-2">
                                                    <a class="nav-link btn btn-active-light btn-active-color-gray-700 btn-color-gray-400 py-2 px-5 fs-6 fw-bold active" data-bs-toggle="tab" id="kt_security_summary_tab_hours_agents" href="#kt_security_summary_tab_pane_hours_agents">Agents</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link btn btn-active-light btn-active-color-gray-700 btn-color-gray-400 py-2 px-5 fs-6 fw-bold" data-bs-toggle="tab" id="kt_security_summary_tab_hours_clients" href="#kt_security_summary_tab_pane_hours_clients">Clients</a>
                                                </li>
                                            </ul>
                                            <!--end::Nav pills-->
                                        </div>
                                        <!--end::Tabs-->
                                        <!--begin::Tab content-->
                                        <div class="tab-content px-3">
                                            <!--begin::Tab pane-->
                                            <div class="tab-pane fade active show" id="kt_security_summary_tab_pane_hours_agents" role="tabpanel">
                                                <!--begin::Chart-->
                                                <div id="kt_security_summary_chart_hours_agents" style="height: 300px"></div>
                                                <!--end::Chart-->
                                            </div>
                                            <!--end::Tab pane-->
                                            <!--begin::Tab pane-->
                                            <div class="tab-pane fade" id="kt_security_summary_tab_pane_hours_clients" role="tabpanel">
                                                <!--begin::Chart-->
                                                <div id="kt_security_summary_chart_hours_clients" style="height: 300px"></div>
                                                <!--end::Chart-->
                                            </div>
                                            <!--end::Tab pane-->
                                        </div>
                                        <!--end::Tab content-->
                                    </div>
                                    <!--end::Container-->
                                </div>
                                <!--end::Tab panel-->
                                <!--begin::Tab panel-->
                                <div class="tab-pane fade" id="kt_security_summary_tab_pane_day" role="tabpanel">
                                    <!--begin::Row-->
                                    <div class="row p-0 mb-5 px-9">
                                        <!--begin::Col-->
                                        <div class="col">
                                            <div class="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                                <span class="fs-4 fw-bold text-success d-block">User Sign-in</span>
                                                <span class="fs-2hx fw-bolder text-gray-800" data-kt-countup="true" data-kt-countup-value="30467">0</span>
                                            </div>
                                        </div>
                                        <!--end::Col-->
                                        <!--begin::Col-->
                                        <div class="col">
                                            <div class="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                                <span class="fs-4 fw-bold text-primary d-block">Admin Sign-in</span>
                                                <span class="fs-2hx fw-bolder text-gray-800" data-kt-countup="true" data-kt-countup-value="120">0</span>
                                            </div>
                                        </div>
                                        <!--end::Col-->
                                        <!--begin::Col-->
                                        <div class="col">
                                            <div class="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                                <span class="fs-4 fw-bold text-danger d-block">Failed Attempts</span>
                                                <span class="fs-2hx fw-bolder text-gray-800" data-kt-countup="true" data-kt-countup-value="23">0</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Row-->
                                    <!--begin::Container-->
                                    <div class="pt-2">
                                        <!--begin::Tabs-->
                                        <div class="d-flex align-items-center pb-9 px-9">
                                            <h3 class="m-0 text-gray-800 flex-grow-1">Activity Chart</h3>
                                            <!--begin::Nav pills-->
                                            <ul class="nav nav-pills nav-line-pills border rounded p-1">
                                                <li class="nav-item me-2">
                                                    <a class="nav-link btn btn-active-light btn-active-color-gray-700 btn-color-gray-400 py-2 px-5 fs-6 fw-bold active" data-bs-toggle="tab" id="kt_security_summary_tab_day_agents" href="#kt_security_summary_tab_pane_day_agents">Agents</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link btn btn-active-light btn-active-color-gray-700 btn-color-gray-400 py-2 px-5 fs-6 fw-bold" data-bs-toggle="tab" id="kt_security_summary_tab_day_clients" href="#kt_security_summary_tab_pane_day_clients">Clients</a>
                                                </li>
                                            </ul>
                                            <!--end::Nav pills-->
                                        </div>
                                        <!--end::Tabs-->
                                        <!--begin::Tab content-->
                                        <div class="tab-content">
                                            <div class="tab-pane fade active show" id="kt_security_summary_tab_pane_day_agents" role="tabpanel">
                                                <!--begin::Chart-->
                                                <div id="kt_security_summary_chart_day_agents" style="height: 300px"></div>
                                                <!--end::Chart-->
                                            </div>
                                            <div class="tab-pane fade" id="kt_security_summary_tab_pane_day_clients" role="tabpanel">
                                                <!--begin::Chart-->
                                                <div id="kt_security_summary_chart_day_clients" style="height: 300px"></div>
                                                <!--end::Chart-->
                                            </div>
                                        </div>
                                        <!--end::Tab content-->
                                    </div>
                                    <!--end::Container-->
                                </div>
                                <!--end::Tab panel-->
                                <!--begin::Tab panel-->
                                <div class="tab-pane fade" id="kt_security_summary_tab_pane_week" role="tabpanel">
                                    <!--begin::Row-->
                                    <div class="row p-0 mb-5 px-9">
                                        <!--begin::Col-->
                                        <div class="col">
                                            <div class="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                                <span class="fs-lg-4 fs-6 fw-bold text-success d-block">User Sign-in</span>
                                                <span class="fs-lg-2hx fs-2 fw-bolder text-gray-800" data-kt-countup="true" data-kt-countup-value="340">36,899</span>
                                            </div>
                                        </div>
                                        <!--end::Col-->
                                        <!--begin::Col-->
                                        <div class="col">
                                            <div class="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                                <span class="fs-lg-4 fs-6 fw-bold text-primary d-block">Admin Sign-in</span>
                                                <span class="fs-lg-2hx fs-2 fw-bolder text-gray-800" data-kt-countup="true" data-kt-countup-value="90">1220</span>
                                            </div>
                                        </div>
                                        <!--end::Col-->
                                        <!--begin::Col-->
                                        <div class="col">
                                            <div class="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                                                <span class="fs-lg-4 fs-6 fw-bold text-danger d-block">Failed Attempts</span>
                                                <span class="fs-lg-2hx fs-2 fw-bolder text-gray-800" data-kt-countup="true" data-kt-countup-value="230">0</span>
                                            </div>
                                        </div>
                                        <!--end::Col-->
                                    </div>
                                    <!--end::Row-->
                                    <!--begin::Container-->
                                    <div class="pt-2">
                                        <!--begin::Tabs-->
                                        <div class="d-flex align-items-center pb-9 px-9">
                                            <h3 class="m-0 text-gray-800 flex-grow-1">Activity Chart</h3>
                                            <!--begin::Nav pills-->
                                            <ul class="nav nav-pills nav-line-pills border rounded p-1">
                                                <li class="nav-item me-2">
                                                    <a class="nav-link btn btn-active-light py-2 px-5 fs-6 btn-active-color-gray-700 btn-color-gray-400 fw-bold active" data-bs-toggle="tab" id="kt_security_summary_tab_week_agents" href="#kt_security_summary_tab_pane_week_agents">Agents</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link btn btn-active-light py-2 px-5 btn-active-color-gray-700 btn-color-gray-400 fs-6 fw-bold" data-bs-toggle="tab" id="kt_security_summary_tab_week_clients" href="#kt_security_summary_tab_pane_week_clients">Clients</a>
                                                </li>
                                            </ul>
                                            <!--end::Nav pills-->
                                        </div>
                                        <!--end::Tabs-->
                                        <!--begin::Tab content-->
                                        <div class="tab-content">
                                            <div class="tab-pane fade active show" id="kt_security_summary_tab_pane_week_agents" role="tabpanel">
                                                <!--begin::Chart-->
                                                <div id="kt_security_summary_chart_week_agents" style="height: 300px"></div>
                                                <!--end::Chart-->
                                            </div>
                                            <div class="tab-pane fade" id="kt_security_summary_tab_pane_week_clients" role="tabpanel">
                                                <!--begin::Chart-->
                                                <div id="kt_security_summary_chart_week_clients" style="height: 300px"></div>
                                                <!--end::Chart-->
                                            </div>
                                        </div>
                                        <!--end::Tab content-->
                                    </div>
                                    <!--end::Container-->
                                </div>
                                <!--end::Tab panel-->
                            </div>
                            <!--end::Tab content-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Security summary-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-xxl-4">
                    <!--begin::Security recent alerts-->
                    <div class="card card-xxl-stretch-50 mb-5 mb-xl-10">
                        <!--begin::Body-->
                        <div class="card-body pt-5">
                            <!--begin::Carousel-->
                            <div id="kt_security_recent_alerts" class="carousel carousel-custom carousel-stretch slide" data-bs-ride="carousel" data-bs-interval="8000">
                                <!--begin::Heading-->
                                <div class="d-flex flex-stack align-items-center flex-wrap">
                                    <h4 class="text-gray-400 fw-bold mb-0 pe-2">    Informations Entites</h4>
                                  
                                    <!--end::Carousel Indicators-->
                                </div>
                                <!--end::Heading-->
                                <!--begin::Carousel inner-->
                                <div class="carousel-inner pt-6">
                                    <!--begin::Item-->
                                    <div class="carousel-item active">
                                        <!--begin::Wrapper-->
                                        <div class="carousel-wrapper">
                                            <!--begin::Description-->
                                            <div class="d-flex flex-column flex-grow-1">
                                                <a href="#" class="fs-5 fw-bolder text-dark text-hover-primary">Ninea :</a>
                                                <p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">{{itemModel?.ninea}}</p>
                                                <a href="#" class="fs-5 fw-bolder text-dark text-hover-primary">Registre de commerce :</a>
                                                <p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">{{itemModel?.rc}}</p>
                                                <a href="#" class="fs-5 fw-bolder text-dark text-hover-primary">Telephone :</a>
                                                <p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">{{itemModel?.tel}}</p>
                                            </div>
                                            <!--end::Description-->
                                            <!--begin::Summary-->
                                            <div class="d-flex flex-stack pt-8">
                                                <span class="badge badge-light-primary fs-7 fw-bolder me-2">{{(itemModel?.dateCreation | date : 'short') || '...'}}</span>
                                               
                                            </div>
                                            <!--end::Summary-->
                                        </div>
                                        <!--end::Wrapper-->
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="carousel-item">
                                        <!--begin::Wrapper-->
                                        <div class="carousel-wrapper">
                                            <!--begin::Description-->
                                            <div class="d-flex flex-column flex-grow-1">
                                                <a href="#" class="fw-bolder text-dark text-hover-primary">Login Attempt Failed</a>
                                                <p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">As we approach one year of working remotely, we wanted to take a look back and share some ways teams around the world have collaborated effectively.</p>
                                            </div>
                                            <!--end::Description-->
                                            <!--begin::Summary-->
                                            <div class="d-flex flex-stack pt-8">
                                                <span class="badge badge-light-primary fs-7 fw-bolder me-2">Oct 05, 2021</span>
                                                <a href="#" class="btn btn-light btn-sm btn-color-muted fs-7 fw-bolder px-5">Join</a>
                                            </div>
                                            <!--end::Summary-->
                                        </div>
                                        <!--end::Wrapper-->
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="carousel-item">
                                        <!--begin::Wrapper-->
                                        <div class="carousel-wrapper">
                                            <!--begin::Description-->
                                            <div class="d-flex flex-column flex-grow-1">
                                                <a href="#" class="fw-bolder text-dark text-hover-primary">Top Picks For You</a>
                                                <p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">Today we are excited to share an amazing certification opportunity which is designed to teach you everything</p>
                                            </div>
                                            <!--end::Description-->
                                            <!--begin::Summary-->
                                            <div class="d-flex flex-stack pt-8">
                                                <span class="badge badge-light-primary fs-7 fw-bolder me-2">Sep 11, 2021</span>
                                                <a href="#" class="btn btn-light btn-sm btn-color-muted fs-7 fw-bolder px-5">Collaborate</a>
                                            </div>
                                            <!--end::Summary-->
                                        </div>
                                        <!--end::Wrapper-->
                                    </div>
                                    <!--end::Item-->
                                </div>
                                <!--end::Carousel inner-->
                            </div>
                            <!--end::Carousel-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Security recent alerts-->
                    <!--begin::Security guidelines-->
                    <div class="card card-xxl-stretch-50 mb-5 mb-xl-10">
                        <!--begin::Body-->
                        <div class="card-body pt-5">
                            <!--begin::Carousel-->
                            <div id="kt_security_guidelines" class="carousel carousel-custom carousel-stretch slide" data-bs-ride="carousel" data-bs-interval="8000">
                                <!--begin::Heading-->
                                <div class="d-flex flex-stack align-items-center flex-wrap">
                                    <h4 class="text-gray-400 fw-bold mb-0 pe-2">Informations Entites</h4>
                                    <!--begin::Carousel Indicators-->
                                   

                                    <!--end::Carousel Indicators-->
                                </div>
                                <!--end::Heading-->
                                <!--begin::Carousel inner-->
                                <div class="carousel-inner pt-6">
                                    <!--begin::Item-->
                                    <div class="carousel-item active">
                                        <!--begin::Wrapper-->
                                        <div class="carousel-wrapper">
                                            <!--begin::Description-->
                                            <div class="d-flex flex-column flex-grow-1">
                                                <a href="#" class="fs-5 fw-bolder text-dark text-hover-primary">Adresse :</a>
                                                <p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">{{itemModel?.adresse}}</p>
                                                <a href="#" class="fs-5 fw-bolder text-dark text-hover-primary">Devise :</a>
                                                <p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">{{itemModel?.devise}}</p>
                                                <a href="#" class="fs-5 fw-bolder text-dark text-hover-primary">Taux TVA</a>
                                                <p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">{{itemModel?.tauxTva || 0}} %</p>
                                            </div>
                                           
                                            <div class="mb-0">
                                                <a  [routerLink]="['../../form',itemId]" class="btn btn-warning btn btn-rounded" id="kt_subscriptions_create_button">Modifier</a>
                                            </div>
                                            <!--end::Description-->
                                            <!--begin::Summary-->
                                           
                                            <!--end::Summary-->
                                        </div>
                                        <!--end::Wrapper-->
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="carousel-item">
                                        <!--begin::Wrapper-->
                                        <div class="carousel-wrapper">
                                            <!--begin::Description-->
                                            <div class="d-flex flex-column flex-grow-1">
                                                <a href="#" class="fw-bolder text-dark text-hover-primary">Security Policy Update</a>
                                                <p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">As we approach one year of working remotely, we wanted to take a look back and share some ways teams around the world have collaborated effectively.</p>
                                            </div>
                                            <!--end::Description-->
                                            <!--begin::Summary-->
                                            <div class="d-flex flex-stack pt-8">
                                                <span class="badge badge-light-primary fs-7 fw-bolder me-2">Oct 05, 2021</span>
                                                <a href="#" class="btn btn-light btn-sm btn-color-muted fs-7 fw-bolder px-5">Explore</a>
                                            </div>
                                            <!--end::Summary-->
                                        </div>
                                        <!--end::Wrapper-->
                                    </div>
                                    <!--end::Item-->
                                    <!--begin::Item-->
                                    <div class="carousel-item">
                                        <!--begin::Wrapper-->
                                        <div class="carousel-wrapper">
                                            <!--begin::Description-->
                                            <div class="d-flex flex-column flex-grow-1">
                                                <a href="#" class="fw-bolder text-dark text-hover-primary">Terms Of Use Document</a>
                                                <p class="text-gray-600 fs-6 fw-bold pt-3 mb-0">Today we are excited to share an amazing certification opportunity which is designed to teach you everything</p>
                                            </div>
                                            <!--end::Description-->
                                            <!--begin::Summary-->
                                            <div class="d-flex flex-stack pt-8">
                                                <span class="badge badge-light-primary fs-7 fw-bolder me-2">Nov 10, 2021</span>
                                                <a href="#" class="btn btn-light btn-sm btn-color-muted fs-7 fw-bolder px-5">Discover</a>
                                            </div>
                                            <!--end::Summary-->
                                        </div>
                                        <!--end::Wrapper-->
                                    </div>
                                    <!--end::Item-->
                                </div>
                                <!--end::Carousel inner-->
                            </div>
                            <!--end::Carousel-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::Security guidelines-->
                </div>
                <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Login sessions-->
            
            <div class="card mb-5 mb-xl-10">
                <!--begin::Card header-->
                <div class="card-header">
                    <!--begin::Heading-->
                    <div class="card-title">
                        <h3>Services</h3>
                    </div>
                    <!--end::Heading-->
                    <!--begin::Toolbar-->
                    <div class="card-toolbar">
                        
                        
                        <a class="btn btn-primary btn btn-rounded" (click)="openModal(AddUser)">Ajouter</a>
                    </div>
                    <!--end::Toolbar-->
                </div>
                <!--end::Card header-->
                <!--begin::Card body-->
                <div class="card-body p-0">
                    <!--begin::Table wrapper-->
                    <div class="table-responsive">
                        <!--begin::Table-->
                        <table class="table table-flush align-middle table-row-bordered table-row-solid gy-4" id="kt_security_license_usage_table">
                            <!--begin::Thead-->
                            <thead class="border-gray-200 fs-5 fw-bold bg-lighten">
                                <tr>
                                    <th class="w-150px ps-9">Services</th>
                                    <th class="px-0 min-w-250px">Partenaire</th>
                                    <th class="px-0 min-w-250px">Categories</th>
                                    <th class="px-0 min-w-150px">Status</th>
                                    <th class="pe-0 min-w-150px action">Action</th>
                                  
                                </tr>
                            </thead>
                            <!--end::Thead-->
                            <!--begin::Tbody-->
                            <tbody class="fw-6 fw-bold text-gray-600 pd">
                                <tr class="" *ngFor="let item of services_items">
                                    <td class="d-flex align-items-center">
                                        <!--begin:: Avatar -->
                                        <div class="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                            <a >
                                                <div class="symbol-label">
        
                                                    <img src="{{ item?.source  || 'assets/media/avatars/blank.png'}}"
                                                        class="w-100" />
                                                </div>
                                            </a>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::User details-->
                                        <div class="d-flex flex-column">
                                            <a class="text-gray-800 text-hover-primary mb-1"
                                                > {{item?.nom}}</a>
                                            <span>{{item?.Description}}</span>
                                        </div>
                                        <!--begin::User details-->
                                    </td>
                                    <td class="ps-0">
                                        <a href="" class="text-hover-primary text-gray-600">{{item?.partenaire?.nom}}</a>
                                    </td>
                                    <td>{{item?.categorie?.nom}}</td>
                                    <td> <span *ngIf="item?.status == true" class="badge badge-light-success">Actif</span>
                                        <span *ngIf="item?.status != true" class="badge badge-light-danger">Inactif</span></td>
                                        <td >
                                            <div class="d-flex justify-content-end flex-shrink-0">
                                                <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" (click)="changeStatusService(item.id, item)">
                                                    <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                                                    <span class="svg-icon svg-icon-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none">
                                                            <path
                                                                d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                                                fill="currentColor" />
                                                            <path opacity="0.3"
                                                                d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                                                fill="currentColor" />
                                                        </svg>
                                                    </span>
                                                    <!--end::Svg Icon-->
                                                </a>
                                                <a  (click)="openModal(UpdateServiceEntite)"  class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                    <span class="svg-icon svg-icon-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor" />
                                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor" />
                                                        </svg> 
                                                  </span>
                                               </a>
                                            </div>
                                      </td>
                                    
                                </tr>
                               
                                
                                
                                
                               
                            </tbody>
                            <!--end::Tbody-->
                        </table>
                        <!--end::Table-->
                    </div>
                    <!--end::Table wrapper-->
                </div>
                <!--end::Card body-->
            </div>
            <!--end::Login sessions-->
            <!--begin::License usage-->
            <div class="card">
                <!--begin::Card header-->
                <div class="card-header">
                    <!--begin::Heading-->
                    <div class="card-title">
                        <h3>Utilisateurs</h3>
                    </div>
                    <!--end::Heading-->
                    <!--begin::Toolbar-->
                    <div class="card-toolbar">
                        
                        <a class="btn btn-primary btn btn-rounded" (click)="openModal(AddUser)">Ajouter</a>
                    </div>
                    <!--end::Toolbar-->
                </div>
                <!--end::Card header-->
                <!--begin::Card body-->
                <div class="card-body p-0">
                    <!--begin::Table wrapper-->
                    <div class="table-responsive">
                        <!--begin::Table-->
                        <table class="table table-flush align-middle table-row-bordered table-row-solid gy-4" id="kt_security_license_usage_table">
                            <!--begin::Thead-->
                            <thead class="border-gray-200 fs-5 fw-bold bg-lighten">
                                <tr>
                                    <th class="w-150px ps-9">User</th>
                                    <th class="px-0 min-w-250px">Role</th>
                                    <th class="px-0 min-w-250px">Telephone</th>
                                    <th class="px-0 min-w-150px">Status</th>
                                    <th class="pe-0 min-w-150px">Action</th>
                                  
                                </tr>
                            </thead>
                            <!--end::Thead-->
                            <!--begin::Tbody-->
                            <tbody class="fw-6 fw-bold text-gray-600 pg">
                                <tr class="" *ngFor="let item of users_items">
                                    <td class="d-flex align-items-center">
                                        <!--begin:: Avatar -->
                                        <div class="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                            <a >
                                                <div class="symbol-label">
        
                                                    <img src="{{ item?.source  || 'assets/media/avatars/blank.png'}}"
                                                        class="w-100" />
                                                </div>
                                            </a>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::User details-->
                                        <div class="d-flex flex-column">
                                            <a class="text-gray-800 text-hover-primary mb-1"
                                                >{{item?.prenom}} {{item?.nom}}</a>
                                            <span>{{item?.email}}</span>
                                        </div>
                                        <!--begin::User details-->
                                    </td>
                                    <td class="ps-0">
                                        <a href="" class="text-hover-primary text-gray-600">{{item?.profiles[0]?.nom}}</a>
                                    </td>
                                    <td>{{item?.tel}}</td>
                                    <td> <span *ngIf="item?.status == true" class="badge badge-light-success">Actif</span>
                                        <span *ngIf="item?.status != true" class="badge badge-light-danger">Inactif</span></td>
                                        <td >
                                            <div class="d-flex justify-content-end flex-shrink-0">
                                                <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" (click)="changeStatusUser(item.id, item)">
                                                    <!--begin::Svg Icon | path: icons/duotune/general/gen019.svg-->
                                                    <span class="svg-icon svg-icon-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none">
                                                            <path
                                                                d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                                                fill="currentColor" />
                                                            <path opacity="0.3"
                                                                d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                                                fill="currentColor" />
                                                        </svg>
                                                    </span>
                                                    <!--end::Svg Icon-->
                                                </a>
                                                <a [routerLink]="['../../../utilisateurs/form',item.id]" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                    <span class="svg-icon svg-icon-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor" />
                                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor" />
                                                        </svg> 
                                                  </span>
                                               </a>
                                            </div>
                                      </td>
                                    
                                </tr>
                               
                                
                                
                                
                               
                            </tbody>
                            <!--end::Tbody-->
                        </table>
                        <!--end::Table-->
                    </div>
                    <!--end::Table wrapper-->
                </div>
                <!--end::Card body-->
            </div>
            <!--end::License usage-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Post-->
</div>

<ng-template #AddUser>
    <!--begin::Modal - Update password-->
           <!--begin::Modal content-->
           <div class="modal-content">
               <!--begin::Modal header-->
               <div class="modal-header">
                   <!--begin::Modal title-->
                   <h2 class="fw-bolder">Mettre à jour le mot de passe</h2>
                   <!--end::Modal title-->
                   <!--begin::Close-->
                   <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close"  (click)="modalService.dismissAll()">
                       <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                       <span class="svg-icon svg-icon-1">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                               <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                               <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                           </svg>
                       </span>
                       <!--end::Svg Icon-->
                   </div>
                   <!--end::Close-->
               </div>
               <!--end::Modal header-->
               <!--begin::Modal body-->
               <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                   <!--begin::Form-->
                   <form id="kt_modal_update_password_form" class="form" action="#">
                       <!--begin::Input group=-->
                       
                       <div class="fv-row mb-10">
                           <label class="required form-label fs-6 mb-2"translate="SERVICES.DETAIL_DEBUT" ></label>
                           <div class="input-container position-relative icon-right">
                               <a   class="input-icon icon-right position-absolute" style="top: 12px; right:20px;">
                                   
                                 </a>
                           <input  class="form-control form-control-lg form-control-solid form-control-rounded" type="number" placeholder="0" name="debut" />
                           </div>
                       </div>
                       <!--end::Input group=-->
                       <!--begin::Input group-->
                       <div class="mb-10 fv-row" data-kt-password-meter="true">
                           <!--begin::Wrapper-->
                           <div class="mb-1">
                               <!--begin::Label-->
                               <label class=" required form-label fw-bolder text-dark fs-6" translate="SERVICES.DETAIL_FIN"></label>
                               <!--end::Label-->
                               <!--begin::Input wrapper-->
                               <div class="position-relative input-container position-relative icon-right">
                                   <a   class="input-icon icon-right position-absolute" style="top: 12px; right:20px;">
                                     
                                   </a>
                                   <input  class="form-control form-control-lg form-control-solid form-control-rounded" type="number" placeholder="Montant Fin" name="montantFin" />
                               </div>
                              
                           </div>
                          
                       </div>
                       <!--end::Input group=-->
                       <!--begin::Input group=-->
                       <div class="fv-row mb-10">
                           <label class="required form-label fs-6 mb-2"translate="SERVICES.DETAIL_FRAIS" ></label>
                           <div class="input-container position-relative icon-right">
                               <a   class="input-icon icon-right position-absolute" style="top: 12px; right:20px;">
 
                                 </a>
                           <input  class="form-control form-control-lg form-control-solid form-control-rounded" type="NUMBER" placeholder="0" name="frais"  />
                           </div>
                       </div>
                       <div class="fv-row mb-10">
                        <label class="required form-label fs-6 mb-2"translate="SERVICES.DETAIL_COMMISSION" ></label>
                        <div class="input-container position-relative icon-right">
                            <a   class="input-icon icon-right position-absolute" style="top: 12px; right:20px;">

                              </a>
                        <input  class="form-control form-control-lg form-control-solid form-control-rounded" type="NUMBER" placeholder="0" name="frais"  />
                        </div>
                    </div>
                       <!--end::Input group=-->
                       <!--begin::Actions-->
                       <div class="text-center pt-15">
                           <button (click)="closeAndReset()" type="reset" class="btn btn-light me-3 btn btn-rounded" data-kt-users-modal-action="cancel" translate="BUTTONS.CANCEL_BUTTON"></button>
                           <button [disabled]="loading"   type="submit" class="btn btn-primary btn btn-rounded" data-kt-users-modal-action="submit">
                               <span class="indicator-label" translate="BUTTONS.VALIDATE_BUTTON"></span>
                               <span class="indicator-progress">Please wait...
                               <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                           </button>
                       </div>
                       <!--end::Actions-->
                   </form>
                   <!--end::Form-->
               </div>
               <!--end::Modal body-->
           </div>
           <!--end::Modal content-->
   <!--end::Modal - Update password-->
</ng-template>
<ng-template #UpdateServiceEntite>
    <!--begin::Modal - Update password-->
           <!--begin::Modal content-->
           <div class="modal-content">
               <!--begin::Modal header-->
               <div class="modal-header">
                   <!--begin::Modal title-->
                   <h2 class="fw-bolder">Modifier un abonnement</h2>
                   <!--end::Modal title-->
                   <!--begin::Close-->
                   <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close"  (click)="modalService.dismissAll()">
                       <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                       <span class="svg-icon svg-icon-1">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                               <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                               <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                           </svg>
                       </span>
                       <!--end::Svg Icon-->
                   </div>
                   <!--end::Close-->
               </div>
               <!--end::Modal header-->
               <!--begin::Modal body-->
               <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                   <!--begin::Form-->
                   <form id="kt_modal_update_password_form" class="form" action="#">
                       <!--begin::Input group=-->
                       
                       <div class="fv-row mb-5">
                           <label class="required form-label fs-6 mb-2"translate="SERVICES.DETAIL_TAUXPOINT" ></label>
                           <div class="input-container position-relative icon-right">
                               <a   class="input-icon icon-right position-absolute" style="top: 12px; right:20px;">
                                   
                                 </a>
                           <input  class="form-control form-control-lg form-control-solid form-control-rounded" type="number" placeholder="0" name="debut" />
                           </div>
                       </div>
                       <!--end::Input group=-->
                       <!--begin::Input group-->
                       <div class="fv-row mb-5">
                        <label class="required form-label fs-6 mb-2"translate="SERVICES.DETAIL_TAUXRETRAIT" ></label>
                        <div class="input-container position-relative icon-right">
                            <a   class="input-icon icon-right position-absolute" style="top: 12px; right:20px;">

                              </a>
                        <input  class="form-control form-control-lg form-control-solid form-control-rounded" type="NUMBER" placeholder="0" name="frais"  />
                        </div>
                    </div>
                       <!--end::Input group=-->
                       <!--begin::Input group=-->
                       <div class="fv-row mb-10">
                           <label class="required form-label fs-6 mb-2"translate="SERVICES.DETAIL_TAUXOPERATEURS" ></label>
                           <div class="input-container position-relative icon-right">
                               <a   class="input-icon icon-right position-absolute" style="top: 12px; right:20px;">
 
                                 </a>
                           <input  class="form-control form-control-lg form-control-solid form-control-rounded" type="NUMBER" placeholder="0" name="frais"  />
                           </div>
                       </div>
                       <div class="fv-row mb-10">
                        <label class="required form-label fs-6 mb-2"translate="SERVICES.DETAIL_TAUXPLATEFORME" ></label>
                        <div class="input-container position-relative icon-right">
                            <a   class="input-icon icon-right position-absolute" style="top: 12px; right:20px;">

                              </a>
                        <input  class="form-control form-control-lg form-control-solid form-control-rounded" type="NUMBER" placeholder="0" name="frais"  />
                        </div>
                    </div>
                    <div class="fv-row mb-10">
                        <label class="required form-label fs-6 mb-2"translate="SERVICES.DETAIL_TAUXBANQUE" ></label>
                        <div class="input-container position-relative icon-right">
                            <a   class="input-icon icon-right position-absolute" style="top: 12px; right:20px;">

                              </a>
                        <input  class="form-control form-control-lg form-control-solid form-control-rounded" type="NUMBER" placeholder="0" name="frais"  />
                        </div>
                    </div>
                    <div class="row mb-0">
                        <!--begin::Label-->
                        <label class="col-lg-4 col-form-label fw-bold fs-6"translate="GENERAL.STATUS"></label>
                        <!--begin::Label-->
                        <!--begin::Label-->
                        <div class="col-lg-8 d-flex align-items-center">
                            <div class="form-check form-check-solid form-switch fv-row">
                                <input   name="status" class="form-check-input w-45px h-30px" type="checkbox" id="allowmarketing" checked="checked" />
                                <label class="form-check-label" for="allowmarketing"></label>
                            </div>
                        </div>
                        <!--begin::Label-->
                    </div>
                       <!--end::Input group=-->
                       <!--begin::Actions-->
                       <div class="text-center pt-15">
                           <button (click)="closeAndReset()" type="reset" class="btn btn-light me-3 btn btn-rounded" data-kt-users-modal-action="cancel" translate="BUTTONS.CANCEL_BUTTON"></button>
                           <button [disabled]="loading"   type="submit" class="btn btn-primary btn btn-rounded" data-kt-users-modal-action="submit">
                               <span class="indicator-label" translate="BUTTONS.VALIDATE_BUTTON"></span>
                               <span class="indicator-progress">Please wait...
                               <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                           </button>
                       </div>
                       <!--end::Actions-->
                   </form>
                   <!--end::Form-->
               </div>
               <!--end::Modal body-->
           </div>
           <!--end::Modal content-->
   <!--end::Modal - Update password-->
</ng-template>