import { Component, OnInit } from '@angular/core';
import { FormBuilder, NgModel } from '@angular/forms';
import * as saveAs from 'file-saver';
import { Endpoint } from 'src/app/models/Endpoint';
import { AuthService, Caisse, } from 'src/app/modules/auth';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Guard } from 'src/app/shared/utils/guard';
import { UserPermissions } from 'src/app/shared/user_permissions';
import {ConfirmPinComponent} from "../../confirm-pin/confirm-pin.component";
import { PageInfoService } from 'src/app/_metronic/layout';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  loading: boolean;
  page = 1;
  query = '';
  // items:any = [];
  totals:number = 0;
  pageSize : number = 10;
  profils:any;

  codes : any [] = [];
  searchText : string;
  searchTextNotifier  = new Subject();
  caisses: any[] = [];
  stations: any[] = [];
  totalItems = 0;
  perPage = 10;
  action =  1;
  filterModel:any = {};
  itemId : any = null;
  public addCaisseModel: Caisse= new Caisse();
  searche: any[] = [];
  public caisse = UserPermissions.MANAGE_Default

  titlePage:any=[

    {
      title: 'Cash register',
      path: '/caisse/list',
      isSeparator: true
   },
    {
      title: 'List',
      path: '/caisse/list',
      isSeparator: false
    }
  ]


  constructor(private pageInfo : PageInfoService,
     private authService : AuthService,
     private fb : FormBuilder,
     private globalService: GlobalService,
     public modalService: NgbModal,
     private toastr:ToastrService,
     public guard:Guard) { }

  ngOnInit(): void {

    this.getAgences();
    this.getCaisse();
    this.pageInfo.updateTitle('Station');
    this.pageInfo.updateBreadcrumbs(this.titlePage);
    this.searchTextNotifier.pipe(debounceTime(250)).subscribe(text => this.search());


  }

  initFilter() {
    this.query = '';
    this.searchText = '';
    this.getCaisse();
  }

  ajoutcaisse(){
    const data ={
      codeAgence: this.addCaisseModel.codeAgence
  }
  this.loading = true;
  this.globalService.upsert(this.itemId ,Endpoint.CAISSE, data,environment.apiUrlReseau).subscribe({
    next: (res) => {
      this.loading = false;
      this.toastr.success('Ajout effectué avec succes'),
      this.modalService.dismissAll();
      this.getCaisse();

    },
    error: (err: any) => {
    this.loading = false;
    this.toastr.error(err.error['hydra:description'],'Erreur !')
    }
  })
}




  filterItem() {
    console.log(this.filterModel)
    this.query = '';
    this.page = 1;
    if (this.filterModel.station) this.query += `&pointDeVente.code=${this.filterModel.station}`;
    if (this.filterModel.caisse) this.query += `&code=${this.filterModel.caisse}`;
    if (this.filterModel.status) this.query += `&status=${this.filterModel.status}`
    this.getCaisse();
  }

  search($next : Boolean = false){
    if($next) this.searchTextNotifier.next('')
    else{
      this.searchText == '' ? this.query = '' : this.query = `&search=${this.searchText}`;
      this.getCaisse();
    }
  }

  getCaisse(){
    this.loading =true
    this.globalService.getAllReseau(Endpoint.CAISSE_STATION,this.page, this.query).subscribe((res:any)=>{
    console.log('items here ', res['hydra:member']);
    this.caisses = res['hydra:member'];
    this.totals = res['hydra:totalItems'];
    this.loading =false;
    },error => {
      console.log('error',error)
       this.loading =false;
     })
   }

  paginate(p: number){
    console.log("event -> ", p)
    this.page = p
    this.getCaisse()
  }

  export(type = '') {
    this.loading = true;
    if (type == 'pdf') {
      const fileName = 'caisses.pdf';
      this.authService.export(Endpoint.EXPORT_LISTE_CAISSE, this.query).subscribe((res:any) => {
          this.loading = false;
          saveAs(res.body, fileName);
        }, error => {
          this.loading = false;
        });
    } else {
      const fileName = 'caisses.csv';
      this.authService.export(Endpoint.USERS_CSV, this.query).subscribe((res:any) => {
          this.loading = false;
          saveAs(res.body, fileName);
        }, error => {
          this.loading = false;
        });
    }
    return;
  }

  pageChange(page:any){
    this.page=page;
    this.getCaisse();
  }

  getAgences(){
    console.log('stations éé');
    let query = ``;
    this.globalService.getAllReseau(Endpoint.STATIONS, 1, query).subscribe((res:any)=>{

      this.stations = res['hydra:member'];
      console.log('rs ag',this.stations)
      this.stations.map((i:any) => { i.uri = '/api/point_de_ventes/' + i.id; return i; });
    },error => {
      console.log('error',error)
    })
  }


  openModal(content:any, one:any={}){
    this.modalService.open(content,{size:'md'})
  }

  closeAndReset() {
    this.modalService.dismissAll()
  }


}
