import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Endpoint } from 'src/app/models/Endpoint';
import { Approvisionnements, AuthService, Societe } from 'src/app/modules/auth';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { caissier, Client } from 'src/app/models/Models';
import { UserPermissions } from 'src/app/shared/user_permissions';
import { Guard } from 'src/app/shared/utils/guard';
import {PageInfoService} from "../../../_metronic/layout";


@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  currentUser? : any;
  filterForm : any ;
  userPassword:any={};
  itemModel = new caissier();
  itemModelDetail:any
  loading :Boolean;
  itemId : any = null;
  selectedRole: any;
  currentIndex = 1;
  cartesDisponibles:any =[];
  page = 1;
  query = '';
  items:any = [];
  totals:number = 0;
  titlePage:any=[

    {
      title: 'Transactions',
      path: '/transaction/list',
      isSeparator: true
    },
    {
      title: 'Transaction Details',
      path: '/transaction/list',
      isSeparator: false
    }
  ]
  public autority=UserPermissions.MANAGE_Transactions
  constructor( private pageInfo: PageInfoService, private activatedRoute: ActivatedRoute,private fb : FormBuilder,private service:GlobalService,
    private authService: AuthService, public modalService :NgbModal, private toastr:ToastrService
    ,private router:Router,public guard:Guard) { }
    ngOnInit(): void {
      this.activatedRoute.params.subscribe(params => {
        this.itemId = params['id'];
        console.log("The item id ", this.itemId)
      });
      if(this.itemId) this.getItem();
      this.initFilter()
      this.pageInfo.updateTitle('Transactions');
      this.pageInfo.updateBreadcrumbs(this.titlePage);



    }
    initFilter() {
      this.filterForm = this.fb.group({
        codeEntite: ['bgdigit'],
      });
      this.filterItem()
    }

    filterItem() {
      this.query = '';
      this.page = 1;
      if (this.filterForm.value.codeEntite) this.query += `&codeEntite=${this.filterForm.value.codeEntite}`;

    }


    getItem(){
      this.loading = true;
      this.service.getone(this.itemId, Endpoint.TRANSACTION).subscribe((res:any)=>{
        console.log('detailsTransaction ', res)
        this.itemModel = res;
        //this.itemModel.profiles = res?.profiles[0].id;

        this.loading = false;
      },error => {
        console.log('error oneuser',error)
        this.loading = false;
        this.itemId = null;
        this.toastr.error('Une erreur est survenue')
      })
    }



  openModal(content:any)
  {
    this.modalService.open(content,{size:'md'})
  }

  closeAndReset() {
    this.userPassword.password=''
    this.modalService.dismissAll()

  }

  saveItem() { //fonction d'enregistrer un user
    console.log('item model here ',this.itemModel)
    this.loading = true;
    const data ={
      client: '/api/societes/'+this.itemId
    }
    //this.itemModel.codeClient = "platforme_services";
    //this.itemModel.codeSociete = "platforme_services";
    this.service.update(Endpoint.TRANSACTION+'/'+this.itemModelDetail, data)?.subscribe((res:any)=>{
      this.loading =false;
      console.log('Requete :', res);
      this.itemModel = new caissier()
      this.loading =false;
      this.toastr.success('Mise à jour réussie')
      setTimeout(() => {
       this.getItem();
        this.modalService.dismissAll();
       // this.router.navigateByUrl('clients/detail_client item.id');
      }, 2500);

    },error => {
      this.toastr.error('Une erreur est survenue')
      console.log('add item error',error.error['hydra:description'])
      this.loading = false;

    })
  }




}
