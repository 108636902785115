import { Component, OnInit } from '@angular/core';
import { Endpoint } from 'src/app/models/Endpoint';
import { UserModel } from 'src/app/modules/auth';
import { AuthService } from 'src/app/modules/auth';
import { ActivatedRoute, } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Station } from 'src/app/models/Models';
import { PageInfoService } from 'src/app/_metronic/layout';



@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  itemModel = new Station();
  roles : any = [] ;
  stations:any =[];
  regions:any =[];
  departements:any =[]
  communes:any =[]
  filtered_communes:any =[]
  listSocietes:any = [];
  loading :Boolean;
  itemId : any = null
  currentUser = new UserModel()
  titlePage:any=[

    {
      title: 'Stations',
      path: '/station/list',
      isSeparator: true
   },
    {
      title: 'Nouvelle Station',
      path: '/station/list',
      isSeparator: false
    }
  ]
  entite: any;
  constructor(private service :GlobalService,
    private router:Router,
     private activatedRoute:ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    private pageInfo:PageInfoService
    ) { }

  ngOnInit(): void {
    this.entite =  JSON.parse(<string>localStorage.getItem('entity'));
    this.currentUser = this.authService.getUser();
    console.log('currentUser',this.currentUser)
    this.activatedRoute.params.subscribe(params => {
      this.itemId = params['id'];
      console.log("The item id ", this.itemId)
    });
    if(this.itemId) this.getItem();
    this.getSocietes();
    this.getRegion();
    this.getDepartement();
    this.getCommune();
    this.pageInfo.updateTitle('Station');
    this.pageInfo.updateBreadcrumbs(this.titlePage);
  }



  getItem(){
    this.loading = true;
    this.service.getReseau(this.itemId, Endpoint.POINT_DE_VENTES).subscribe((res:any)=>{
      console.log('the item we get ', res)
      this.itemModel = res;
      this.itemModel.region = res?.departement?.region?.id
      this.itemModel.departement = res?.departement?.id
      this.itemModel.parent = res?.parent?.id
      this.itemModel.commune = res?.commune.id
      console.log('nn',res)
      this.loading = false;
    },error => {
      console.log('error oneuser',error)
      this.loading = false;
      this.itemId = null;
    })
  }

  saveItem() { //fonction d'enregistrer un user
    let endpoint =''
    this.itemModel.region = '/api/regions/' +  this.itemModel.region
    this.itemModel.departement = '/api/departements/' + this.itemModel.departement
    this.itemModel.commune ='/api/communes/' + this.itemModel.commune
    console.log('item model here ',this.itemModel)
    this.loading = true;
    this.itemModel.status == true ? this.itemModel.status = 1 : this.itemModel.status = 0;
    if(this.itemId){
      endpoint = Endpoint.STATIONS_UPDATE
    }
    else {
      endpoint= Endpoint.STATIONS
    }
    this.service.upsertReseau(this.itemId, endpoint, this.itemModel)?.subscribe((res:any)=>{
      this.loading =false;
      this.toastr.success('Mise à jour réussie')
      setTimeout(() => {
        this.itemModel = new Station()
        this.router.navigateByUrl('stations/list')
      }, 2500);

    },error => {
      this.toastr.error(error.error['hydra:description'])
      this.loading = false;

    })
  }
  getRegion(){
    let query = ``;
    this.service.getAllReseau(Endpoint.REGION, null, query).subscribe((res:any)=>{
      this.regions = res['hydra:member'];
    },error => {
      console.log('error',error)
    })
  }
  getDepartement(){
    this.service.getListeAll(Endpoint.DEPARTEMENT).subscribe((res:any)=>{
      //this.departements = res['hydra:member'];
    },error => {
      console.log('error',error)
    })
  }
  getCommune(){
    this.service.getListeAll(Endpoint.COMMUNE).subscribe((res:any)=>{
      this.filtered_communes =res['hydra:member']
    },error => {
      console.log('error',error)
    })
  }


  onFormChanges(tag ='') {
    if(tag === 'region'){
        const index = this.regions.findIndex((item:any) => item['id'] == this.itemModel.region );
        this.departements = this.regions[index]["departements"];
        // if(this.stations.id){
        //   this.departements.forEach((item:any)=>{
        //     const index = this.departements.findIndex((item:any) => item['id'] == this.stations.departement['id'] );
        //     this.communes = this.departements[index]['communes']
        //   })
        // }
    }

    if(tag ==='departement'){
      this.communes = this.filtered_communes.filter((value:any)=> value?.departement?.id === this.itemModel.departement);
      // if(this.stations.id){
      //   this.communes.forEach((item:any)=>{
      //     const index = this.communes.findIndex((item:any) => item['@id'] == this.departements.commune['@id'] );
      //     this.communes = this.departements[index]['communes']
      //   })
      // }
  }


 }
  getSocietes(){
    this.service.getListeStation(Endpoint.LISTESOCIETE).subscribe((res:any)=>{
      console.log('LISTESOCIETE ',res);
      this.listSocietes = res['hydra:member'];

      if(this.currentUser.codeSociete){
        const listSocietesFiltres = this.listSocietes.filter((item:any) => item.code === this.currentUser.codeSociete);
        this.listSocietes = listSocietesFiltres
      }
    },error => {
      console.log('error',error)
    })
  }
}
