import { Component, OnInit } from '@angular/core';
import { AuthService, UserModel } from 'src/app/modules/auth';
import { LayoutService } from '../../core/layout.service';
import {TranslationService} from "../../../../modules/i18n";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  currentIndex = 1;
  toolbarButtonMarginClass = 'ms-1 ms-lg-3';
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px';
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';
  toolbarButtonIconSizeClass = 'svg-icon-1';
  headerLeft: string = 'menu';
  currentUser? : any;
  theme : string = 'light';

  constructor(private layout: LayoutService, private authService : AuthService, private translateService:TranslationService) {}

  ngOnInit(): void {
    this.headerLeft = this.layout.getProp('header.left') as string;
    this.getCurrentUser();
  }

  getCurrentUser(){
    this.currentUser = this.authService.getUser();
  }
  Deconnexion(){
    this.authService.logout()
  }
  changeTheme(theme : string){
    this.theme = theme;
    //let path = (theme == 'dark') ? 'src/styles-dark.scss' : 'src/styles-light.scss'
  }
  changeLangue(langue : string){
    this.translateService.setLanguage(langue);
  }

  nextIndex(index:number){
    this.currentIndex = index;
  }

}
