import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invite-users-modal',
  templateUrl: './invite-users-modal.component.html',
})
export class InviteUsersModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
