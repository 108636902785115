import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { LayoutService } from '../../core/layout.service';
import { Endpoint } from 'src/app/models/Endpoint';
import { Entity } from 'src/app/models/entity.model';
import {Constantes} from "../../../../shared/constantes";
import {AuthService} from "../../../../modules/auth";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, AfterViewInit {
  loading: boolean;
  items:any
  entite:Entity;
  societe:any;
  user:any;
  solde: any;
  etat=false;
  @ViewChild('ktPageTitle', { static: true }) ktPageTitle: ElementRef;
  pageTitleAttributes: {
    [attrName: string]: string | boolean;
  };
  toolbarContainerCssClasses: string = '';
  pageTitleCssClasses: string = '';


  constructor(private layout: LayoutService, private service:GlobalService,private authService: AuthService,) {}
  ngOnInit(): void {
    this.toolbarContainerCssClasses =
      this.layout.getStringCSSClasses('toolbarContainer');
    this.pageTitleCssClasses = this.layout.getStringCSSClasses('pageTitle');
    this.pageTitleAttributes = this.layout.getHTMLAttributes('pageTitle');
    this.entite =  JSON.parse(<string>localStorage.getItem('entity'));
    this.societe =  JSON.parse(<string>localStorage.getItem('societe'));
    this.user =  JSON.parse(<string>localStorage.getItem('userInfo'));
   if(this.user?.roles.includes(Constantes.ROLECAISSIER) || this.user?.roles.includes('ROLE_CAISSIER')){
     this.etat =true
   }
     console.log(';;;;',this.etat);
    this.service.soldeEnt.subscribe(solde=>this.solde=solde)

    // //this.setPrimaryColor('#bc1423');

  }


  ngAfterViewInit() {
    if (this.ktPageTitle) {
      for (const key in this.pageTitleAttributes) {
        if (
          this.pageTitleAttributes.hasOwnProperty(key) &&
          this.ktPageTitle.nativeElement
        ) {
          this.ktPageTitle.nativeElement.attributes[key] =
            this.pageTitleAttributes[key];
        }
      }
    }
  }
  getList(){
    this.loading =true
    this.service.getListeAll(Endpoint.SOLDE).subscribe((res:any)=>{
    console.log('appprovbvv ', res['hydra:member']);
    this.items = res['hydra:member'];
   //this.soldeEntite =this.items[0].entite.solde
    console.log('sonounaa', this.items[0].entite.solde)
    //this.totals = res['hydra:totalItems'];
    this.loading =false;
    },error => {
      console.log('error',error)
      this.loading =false;
    })

  }
}
