<div class="loaderPage" *ngIf="loading">
  <div class="lds-roller " >
    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
  </div>
</div>

<div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Row-->
      <div class="row gy-5 g-xl-8">
        <!--begin::Col-->
        <div class="col-xl-4">
          <!--begin::Mixed Widget 2-->
          <div class="card card-xl-stretch">
            <!--begin::Header-->
            <div class="card-header border-0 bg-primary py-5">
              <h3 class="card-title fw-bolder text-white" translate="MENU.STATISQUE"> </h3>
              <div class="card-toolbar">

                <!--begin::Menu 3-->

                <!--end::Menu 3-->
                <!--end::Menu-->
              </div>
            </div>

            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body p-0">
              <!--begin::Chart-->
              <div class="mixed-widget-2-chart card-rounded-bottom bg-primary" data-kt-color="danger" style="height: 200px"></div>
              <!--end::Chart-->
              <!--begin::Stats-->
              <div class="card-p grisSpace position-relative">
                <!--begin::Row-->
                <div class="row g-0">
                  <!--begin::Col-->
                  <div class="col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7">
                    <!--begin::Svg Icon | path: icons/duotune/general/gen032.svg-->
                    <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor"/>
                        <path d="M19 10.4C19 10.3 19 10.2 19 10C19 8.9 18.1 8 17 8H16.9C15.6 6.2 14.6 4.29995 13.9 2.19995C13.3 2.09995 12.6 2 12 2C11.9 2 11.8 2 11.7 2C12.4 4.6 13.5 7.10005 15.1 9.30005C15 9.50005 15 9.7 15 10C15 11.1 15.9 12 17 12C17.1 12 17.3 12 17.4 11.9C18.6 13 19.9 14 21.4 14.8C21.4 14.8 21.5 14.8 21.5 14.9C21.7 14.2 21.8 13.5 21.9 12.7C20.9 12.1 19.9 11.3 19 10.4Z" fill="currentColor"/>
                        <path d="M12 15C11 13.1 10.2 11.2 9.60001 9.19995C9.90001 8.89995 10 8.4 10 8C10 7.1 9.40001 6.39998 8.70001 6.09998C8.40001 4.99998 8.20001 3.90005 8.00001 2.80005C7.30001 3.10005 6.70001 3.40002 6.20001 3.90002C6.40001 4.80002 6.50001 5.6 6.80001 6.5C6.40001 6.9 6.10001 7.4 6.10001 8C6.10001 9 6.80001 9.8 7.80001 10C8.30001 11.6 9.00001 13.2 9.70001 14.7C7.10001 13.2 4.70001 11.5 2.40001 9.5C2.20001 10.3 2.10001 11.1 2.10001 11.9C4.60001 13.9 7.30001 15.7 10.1 17.2C10.2 18.2 11 19 12 19C12.6 20 13.2 20.9 13.9 21.8C14.6 21.7 15.3 21.5 15.9 21.2C15.4 20.5 14.9 19.8 14.4 19.1C15.5 19.5 16.5 19.9 17.6 20.2C18.3 19.8 18.9 19.2 19.4 18.6C17.6 18.1 15.7 17.5 14 16.7C13.9 15.8 13.1 15 12 15Z" fill="currentColor"/>
                        </svg>
                    </span>
                    <!--end::Svg Icon-->
                    <span class="text-gray-700 fw-boldest d-block fs-2qx lh-1 ls-n1 mb-1">{{total_societes}}</span>
                    <a href="societe/liste_societe" class="text-warning fw-bold fs-6" translate="MENU.COMPANIES"></a>
                  </div>
                  <!--end::Col-->
                  <!--begin::Col-->
                  <div class="col bg-light-primary px-6 py-8 rounded-2 mb-7">
                    <!--begin::Svg Icon | path: icons/duotune/finance/fin006.svg-->
                    <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="currentColor" />
                        <path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="currentColor" />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                    <span class="text-gray-700 fw-boldest d-block fs-2qx lh-1 ls-n1 mb-1">{{total_caissiers}}</span>
                    <a href="/caissiers/list_recharge_carte" class="text-primary fw-bold fs-6" translate="MENU.CAISSIER"></a>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Row-->
                <!--begin::Row-->
                <div class="row g-0">
                  <!--begin::Col-->
                  <div class="col bg-light-danger px-6 py-8 rounded-2 me-7">
                    <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                    <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor" />
                        <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor" />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                    <span class="text-gray-700 fw-boldest d-block fs-2qx lh-1 ls-n1 mb-1">{{total_cartes}}</span>
                    <a href="carburant/liste_carte" class="text-danger fw-bold fs-6 mt-2" translate="MENU.CARTES"></a>
                  </div>
                  <!--end::Col-->
                  <!--begin::Col-->
                  <div class="col bg-light-success px-6 py-8 rounded-2">
                    <!--begin::Svg Icon | path: icons/duotune/communication/com010.svg-->
                    <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z" fill="currentColor" />
                        <path opacity="0.3" d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z" fill="currentColor" />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                    <span class="text-gray-700 fw-boldest d-block fs-2qx lh-1 ls-n1 mb-1">{{total_clients}}</span>
                    <a href="clients/liste_client" class="text-success fw-bold fs-6 mt-2" translate="MENU.CLIENT"></a>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Row-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Mixed Widget 2-->
        </div>
        <!--end::Col-->
        <!--begin::Col-->

        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-xl-8">
          <!--begin::List Widget 5-->
          <div class="card card-xl-stretch">
            <!--begin::Header-->
            <div class="card-header align-items-center border-0 mt-4">
              <h3 class="card-title align-items-start flex-column">
                <span class="fw-bolder mb-2 text-dark" translate="MENU.STATISTIC_BAR"></span>
              </h3>
            </div>
            <!--end::Header-->
            <div class="card-body pt-5">
            <apx-chart
              [series]="chartOptions.series2"
              [chart]="chartOptions.chart"
              [xaxis]="chartOptions.xaxis1"
              [title]="chartOptions.title"
              [colors]="chartOptions?.colors4"
              [yaxis]="chartOptions.yaxis"
            ></apx-chart>
            </div>
          </div>
          <!--end: List Widget 5-->
        </div>
        <div class="card card-xl-stretch-50 mb-5 mb-xl-8">
          <!--begin::Header-->
          <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title align-items-start flex-column">
              <span class="fw-bolder mb-2 text-dark" translate="MENU.STATISTIC_BAR_2"></span>
            </h3>
          </div>
          <!--end::Header-->
            <div class="card-body">
              <apx-chart
                [series]="chartOptions.series4"
                [chart]="chartOptions.chart6"
                [xaxis]="chartOptions.xaxis1"
                [title]="chartOptions.title"
                [colors]="chartOptions?.colors6"
                [yaxis]="chartOptions.yaxis"></apx-chart>
            </div>
            <!--end::Body-->
        </div>
      </div>
      <div class="row ">
      <div class="card card-xl-stretch-50 mb-5 mb-xl-8 col-md-6">
        <!--begin::Body-->
        <div class="card-header align-items-center border-0 mt-4">
          <h3 class="card-title align-items-start flex-column">
            <span class="fw-bolder mb-2 text-dark" translate="MENU.STATISTIC_STATION"></span>
          </h3>
        </div>
        <div class="card card-xl-stretch-50 mb-xl-8">
          <div id="chart2">
            <apx-chart
              [series]="chartOptions.series5"
              [chart]="chartOptions.chart5"
              [labels]="chartOptions.labels5"
              [legend]="chartOptions?.legend5"
              [colors]="chartOptions?.colors5"
              [responsive]="chartOptions.responsive5"
            ></apx-chart>
          </div>
        </div>
      </div>
      <div class="card card-xl-stretch-50 mb-5 mb-xl-8 col-md-6" >
        <div class="card-header align-items-center border-0 mt-4">
          <h3 class="card-title align-items-start flex-column">
            <span class="fw-bolder mb-2 text-dark"translate="MENU.STATISTIC_STATION_M"></span>
          </h3>
        </div>


          <div id="chart " style="text-align:center">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [dataLabels]="chartOptions.dataLabels"
              [plotOptions]="chartOptions.plotOptions"
              [yaxis]="chartOptions.yaxis2"
              [xaxis]="chartOptions.xaxis"
              [colors]="chartOptions?.colors3"
              [annotations]="chartOptions.annotations"
            ></apx-chart>

          </div>


          <!--begin::Body-->
        </div>
        <div class="card card-xl-stretch-50 mb-5 mb-xl-8 col-md-6">
          <!--begin::Body-->
          <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title align-items-start flex-column">
              <span class="fw-bolder mb-2 text-dark" translate="MENU.STATISTIC_CARD_M"></span>
            </h3>
          </div>
          <div class="card card-xl-stretch-50 mb-xl-8">
            <div id="chart2">
              <apx-chart
              [series]="chartOptions.series1"
              [chart]="chartOptions.chart1"
              [labels]="chartOptions.labels"
              [legend]="chartOptions?.legend5"
              [responsive]="chartOptions.responsive5"
            ></apx-chart>
            </div>
          </div>
        </div>
        <div class="card card-xl-stretch-50 mb-5 mb-xl-8 col-md-6">
          <!--begin::Body-->
          <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title align-items-start flex-column">
              <span class="fw-bolder mb-2 text-dark" translate="MENU.STATISTIC_BON_M"></span>
            </h3>
          </div>
          <div class="card card-xl-stretch-50 mb-xl-8">
            <div id="chart8">
              <apx-chart
              [series]="chartOptions.series3"
              [chart]="chartOptions.chart1"
              [labels]="chartOptions.labels6"
              [legend]="chartOptions?.legend5"
              [responsive]="chartOptions.responsive5"
            ></apx-chart>
            </div>
          </div>
        </div>


      </div>
    </div>
    <!--end::Container-->
  </div>


