import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/services/auth.service';
import { environment } from 'src/environments/environment';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';
import { ListComponent as utilisateurList } from './pages/utilisateurs/list/list.component';
import { FormComponent as utilisateurForm } from './pages/utilisateurs/form/form.component';
import { DetailComponent as utilisateurDetail } from './pages/utilisateurs/detail/detail.component';
import { ListComponent as entiteList } from './pages/entites/list/list.component';
import { FormComponent as entiteForm } from './pages/entites/form/form.component';
import { ListComponent as roleList } from './pages/roles/list/list.component';
import { FormComponent as roleForm } from './pages/roles/form/form.component';
import { DetailComponent as roleDetail } from './pages/roles/detail/detail.component';
import { ListComponent as caisseList } from "./pages/caisse/list/list.component";
import { FormComponent as permissionList } from './pages/permissions/form/form.component';
import { ListComponent as permissionForm } from './pages/permissions/list/list.component';
import { JwtInterceptor } from "./services/helpers/jwt-interceptor";
import { ErrorInterceptor } from "./services/helpers/error-interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from '@angular/router';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { DetailComponent } from './pages/roles/detail/detail.component';
import { MessagesComponent } from './pages/parametres/messages/messages.component';
import { AffichageComponent } from './pages/parametres/affichage/affichage.component';
import { ModulesActionsComponent } from './pages/parametres/modules-actions/modules-actions.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { ListComponent as messagesList } from './pages/messages/list/list.component';
import { ComposeComponent } from './pages/messages/compose/compose.component';
import { ReplyComponent } from './pages/messages/reply/reply.component';
import { DetailComponent as entiteDetail } from './pages/entites/detail/detail.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListComponent } from './pages/caisse/list/list.component';
import { FormComponent } from './pages/caisse/form/form.component';
import { ListComponent as stationList } from './pages/stations/list/list.component';
import { FormComponent as stationForm } from './pages/stations/form/form.component';
import {BlockuiComponent} from './_metronic/layout/components/blockui/blockui.component';
import {InternationalPhoneNumberModule} from "ngx-international-phone-number";
import  {AvatarModule } from 'ngx-avatar'
import {NgApexchartsModule} from 'ng-apexcharts'
import { DashboardsComponent } from './pages/dashboards/dashboards.component';
import {ChartComponent} from "ng-apexcharts";
import { UserWaitComponent } from './pages/utilisateurs/user-wait/user-wait.component';
import { ListComponent as listTransaction } from './pages/Transaction/list/list.component';
import { DetailComponent as detailTransaction } from './pages/Transaction/detail/detail.component';
import { ConfirmPinComponent } from './pages/confirm-pin/confirm-pin.component';
import { DetailComponent as stationDetail} from './pages/stations/detail/detail.component';
import {ListCommissionApproComponent} from "./pages/parametres/list-commission-appro/list-commission-appro.component";
import {ReportingSocieteComponent} from "./pages/Transaction/reporting-societe/reporting-societe.component";
import {DasboardSocieteComponent} from "./pages/dasboard-societe/dasboard-societe.component";
// #fake-end#
function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      //@ts-ignore
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [AppComponent, utilisateurList, utilisateurForm, utilisateurDetail, entiteList, entiteForm,
   roleList, roleForm, roleDetail, permissionForm, permissionList, caisseList,
    DetailComponent,
    AffichageComponent, MessagesComponent, ModulesActionsComponent, messagesList, ComposeComponent, ReplyComponent,
   entiteDetail,
   ListComponent,
   stationList,
   stationForm,
   FormComponent,
   BlockuiComponent,
   DashboardsComponent,
   UserWaitComponent,
   listTransaction,
   detailTransaction,
   ConfirmPinComponent,
   stationDetail,
    ReportingSocieteComponent,
   ListCommissionApproComponent,
    DasboardSocieteComponent
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    NgSelectModule,
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    RouterModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    NgxPaginationModule,
    InternationalPhoneNumberModule,
    AvatarModule,
    NgApexchartsModule
  ],
  providers: [
    BsModalService,
    BsModalRef,
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService], },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
