

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/auth';
import { Endpoint } from 'src/app/models/Endpoint';
import { FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { ToastrService } from 'ngx-toastr';
import { UserPermissions } from 'src/app/shared/user_permissions';
import { Guard } from 'src/app/shared/utils/guard';
import {PageInfoService} from "../../../_metronic/layout";
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  loading :boolean = false;
  page = 1;
  saving: boolean
  totalItemsRole:number;
  currentPage:number = 1;

  page_error : boolean = false;

  pageSize : number = 10;
  query = '';
  roles:any = [];
  module:any;
  permissions:any=[];
  totals:number = 0;
  filterForm : any ;
  profils:any[] =[]
  modules:any =[];
  entite:any
  public autority = UserPermissions.MANAGE_Permission
  titlePage:any=[

    {
      title: 'Permission',
      path: '/permission/list',
      isSeparator: true
   },
    {
      title: 'Liste',
      path: '/rpermission/list',
      isSeparator: false
    }
  ]
  constructor(private authService: AuthService,private service:GlobalService,
    private _userService:GlobalService,private toastr:ToastrService, public guard:Guard,private pageInfo : PageInfoService,
    ) { }

  ngOnInit(): void {
    this.entite =  JSON.parse(<string>localStorage.getItem('entity'));
    const page = 1
    this.getListModule()
    this.getListRole(page);
    this.getListPermission();
    this.pageInfo.updateTitle('Administrator');
    this.pageInfo.updateBreadcrumbs(this.titlePage);
  }

  // initFilter() {
  //   this.filterForm = this.fb.group({
  //     codeEntite: [this.entite.codeEntite],
  //   });
  //   this.filterItem()
  // }
  //
  // filterItem() {
  //   this.query = '';
  //   this.page = 1;
  //   if (this.filterForm.value.codeEntite) this.query += `&codeEntite=${this.filterForm.value.codeEntite}`;
  //   this.getListRole(this.page);
  // }

  //Prev page

  getListRole(page:any){
    this.loading =true
    this.authService.getAll(Endpoint.ROLES_CARBURANT,this.currentPage,this.query).subscribe((res:any)=>{

    this.roles = res['hydra:member'];
    this.totalItemsRole = res['hydra:totalItems'];
    this.loading =false;
    },error => {

      this.loading =false;
      this.page_error =true;
    })
  }

  getListModule(){
    this.loading =true;
    this.service.getAl(Endpoint.MODULE,this.page, this.query).subscribe((res:any)=>{

      this.modules = res['hydra:member'];
      this.totals = res['hydra:totalItems'];
      this.loading =false;
    },error => {
      this.loading =false;
    });

  }

  paginate(p: number){

    this.page = p
    this.getListModule()
  }

  pageChange(page:any){
    this.page=page;
    this.getListModule();
  }
   //Check if role is in permissions

  //Verifier que le role a cette permission
  isChecked(action:any,role:any)  {
    const perm = this.permissions.find((item:any) => { return item.actionCode.toLowerCase() == action.toLowerCase()})
    if(perm){
      return !!perm.roles.includes(role);
    }
    return false;
  }

  //Enléve un role de la liste des roles
  deleteRole(roles:any, role:any) {
    for(let i = 0; roles.length ; i ++) {
      if(roles[i] === role) {
        roles.splice(i,1);
        return roles;
      }
    }
  }

  //Prev page
  prevPage() {
    if(this.currentPage > 1) {
      this.currentPage -=1;
      this.getListRole(this.currentPage);
    }
  }

//next page
  nextPage() {
    if(this.currentPage < this.totalItemsRole) {
      this.currentPage +=1;
      this.getListRole(this.currentPage);

    }
  }

    //Recupére les permissions
    getListPermission() {
      this.service.getAl(Endpoint.PERMISSION,this.page, this.query).subscribe ((res:any)=> {
        let actionsList :any= []
        this.permissions = res['hydra:member'];
        this.permissions.map((item:any) =>  {
            actionsList.push( {
              actionCode:item.action.code,
              roles: item.roles,
              id: item['@id'].split('/')[4]
            })
        })
        this.permissions  = actionsList
      })
    }


    //AJout/Supprime une permission
    handleCheck(event: any, action :any, role:any) {
      var data    = {
        action: action['@id'].replace("/station_services", ""),
        roles:role,
        id:Number,
        status: 1,
        codeEntite:this.entite.codeEntite
      }
      const perm = this.permissions.find((item:any) => { return item.actionCode.toLowerCase() == action.code.toLowerCase()})
      console.log('perm',perm)
      if(event.target.checked) {
        if(perm != undefined) {
          data.roles  = perm.roles
          data.roles.push(role);
          data['id'] = perm.id
        } else {
          data.roles  = [role]
        }
      } else {
        if(perm != undefined) {
          data.roles  = perm.roles
          const index = perm.roles.findIndex((item:any) => { return item == role})
          if(index != -1){
            data.roles.splice(index, 1)
            data['id'] = perm.id
          }
        }

      }
    this.saving = true
    this.loading = true
      this.service.saveActionPermissions(data).subscribe(res => {
        this.service.setPermissions();
        this.toastr.success('Permission mise à jour!')
        this.loading = false
      }, err => {
        this.loading = false
        this.toastr.error('Une erreur est survenue')
      }, () => {
        this.saving = false
      })

    }

}


