import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import {Entity} from "../../../../../modules/auth/models/entity.model";
import { Guard } from 'src/app/shared/utils/guard';
import { UserPermissions } from 'src/app/shared/user_permissions'
import { GlobalService } from 'src/app/services/global.service';
import {AuthService, Societe} from "../../../../../modules/auth";
import {Constantes} from "../../../../../shared/constantes";
import { Approvisionnements } from '../../../../../modules/auth';
import { Endpoint } from 'src/app/models/Endpoint';
import { ToastrService } from 'ngx-toastr';

export class Permission{
  modulesCodes:String[] = [];
  actionsCodes :String[] = [];
}
@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
})

export class AsideMenuComponent implements OnInit {
  appAngularVersion: string = environment.appVersion;
  appPreviewChangelogUrl: string = environment.appPreviewChangelogUrl;
  entite:Entity;
  societe:Societe;
  userconnect:any
  loading :Boolean;
  itemModel = new Approvisionnements();
  itemId : any = null;
  public autority = UserPermissions.MANAGE_SIDEBAR;
  public permission = UserPermissions.MANAGE_Default;
  constructor(private service:GlobalService,
  public guard: Guard, private authService : AuthService,
  private toastr:ToastrService) {}
  permissions:any =new Permission()
  ngOnInit(): void {
    this.entite =  JSON.parse(<string>localStorage.getItem('entity'));
    this.societe =  JSON.parse(<string>localStorage.getItem('societe'));
    this.getCurrentUser();
    this.getPermission();
    if(this.societe){
      this.initsocietwSolde(this.societe.code)
    }
    else{
      this.initEntitySolde()
    }
  }
  getCurrentUser(){
    this.userconnect = this.authService.getUser();
  }

  getPermission(){
    this.service.setPermissions();
  }

  initEntitySolde(){
    this.service.soldeEntite(this.entite.codeEntite).subscribe({
      next:(entity:any)=>{
        this.service.setsolde(entity[Endpoint.HYDRA_MEMBER][0].solde)
      }
    })
  }
  initsocietwSolde(codeSociete=''){
    this.authService.getSocieteByCode(codeSociete)
      .subscribe({
        next: (res: any) => {
          this.loading = false;
          this.service.setsolde(res[Endpoint.HYDRA_MEMBER][0].solde)
        }, error: (err: any) => {
          this.loading = false;
        }
      })
  }

  viewcarte() {
    let check =false;
  let values= Object.values(this.autority.carburant.actions.carte)
    this.service.permissionsCodes.subscribe((permissions:any) => {
      this.permissions = permissions;
    })
     values?.map((item:any)=>{
      if(this.permissions.codeAction?.includes(item)){
        check= true;
      }
    })
    return check;

  } viewbon() {
    let check =false;
  let values= Object.values(this.autority.carburant.actions.bon)
    this.service.permissionsCodes.subscribe((permissions:any) => {
      this.permissions = permissions;
    })
     values?.map((item:any)=>{
      if(this.permissions.codeAction?.includes(item)){
        check= true;
      }
    })
    return check;

  }
}
