import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { AuthService } from 'src/app/modules/auth';
import { Endpoint } from 'src/app/models/Endpoint';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lists-widget3',
  templateUrl: './lists-widget3.component.html',
})
export class ListsWidget3Component {

  @Input('items') items : any [] = [];
  @Input('total') total : number = 0;
  @Input('loading') loading : boolean = false;

  constructor() {}

  ngOnInit(): void {}

}
