<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Toolbar-->

  <div class="card content" style="min-height: 74.5vh;">
    <app-blockui [block]="loading"></app-blockui>

    <!--begin::Card header-->
    <div *ngIf="modules.length > 0" class="card-header py-4"
         style="display: flex; flex-direction: row; justify-content: end; border: none; ">
      <div class="">
        <div class="arrows-pagitation text-right">
          <span (click)="prevPage()" class="btn btn-light-primary btn-rounded me-5 " type="button">
            <span class="me-3 fs-7">❮</span> <span class="" translate="MENU.PRECEDENT"></span>
          </span>
          <span (click)="nextPage()" class="btn btn-light-primary btn-rounded " type="button">
            <span class="me-3" translate="MENU.SUIVANT"></span> <span class="fs-7">❯</span>
          </span>
        </div>
      </div>
    </div>
    <div class="card-body py-4">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer" id="kt_table_users_wrapper">
        <div class="table-responsive rounded">
          <div *ngIf="loading" class="loaderPage">
            <div class="lds-roller ">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <!--begin::Table-->
          <table *ngIf="roles.length > 0 && modules.length > 0"
                 class="table align-middle table-row-dashed fs-6 gy-5 rounded">
            <thead class="border">
            <tr class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              <th class="min-w-200px">
              </th>

              <th *ngFor="let role of roles;let i = index" class="min-w-125px text-center">{{role.nom}}</th>

            </tr>
            </thead>
            <tbody
              *ngFor="let module of modules | paginate: { itemsPerPage: pageSize, currentPage: page ,totalItems:totals } ; let i = index;"
              class="border">
            <tr class="border-bottom bg-gray-100 ">

              <td (click)="(module['show'] = !module['show']) " colspan="11"><span
                class="font-weight-bold">Gestion {{module.libelle}}</span><i class="la la-angle-right"
                                                                             style="margin-left: 14px !important; font-weight:800 !important; color: white;"></i>
              </td>
            </tr>

            <ng-container *ngIf="module['show']" >
              <tr *ngFor="let action of module.actions; let i = index " class="border-bottom">
                <td class="min-w-200px"><span class="pl-4">{{action.libelle}}</span></td>
                <td *ngFor="let role of roles; let j = index " class="text-center border-left action-item min-w-100px"
                    id="action-item-{{i}}-{{j}}">

                  <div *ngIf="guard.pass(autority.actions.permissions)"  class="form-check form-check-custom form-check-solid">
                    <input  (click)="handleCheck($event, action, role.libelle)" [checked]="isChecked(action.code,role.libelle)"
                           [disabled]="saving" class="form-check-input" style="margin: auto;" type="checkbox"
                         value="{{role.nom}}">
                  </div>
                </td>
              </tr>
            </ng-container>


            </tbody>
          </table>
        </div>

        <div class="row mt-5 mb-5">
          <div
            class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
          </div>
          <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
            <pagination-controls (pageChange)="paginate($event)" *ngIf="totals > 10"
                                 class=" atbd-pagination__link page-number" nextLabel=""
                                 previousLabel=""></pagination-controls>
          </div>
        </div>
      </div>
      <div *ngIf="permissions.length===0 && !loading">
        <h1 class="styleTabVide mt-3" translate="MENU.NOT_FOUND_PERMISSION">  </h1>
      </div>
    </div>
  </div>
</div>



