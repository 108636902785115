import { AuthModel } from './auth.model';
import { AddressModel } from './address.model';
import { SocialNetworksModel } from './social-networks.model';

export class UserModel extends AuthModel {
 id ? : any
  login ? :string
  username ? :string
  codeClient ?: string
  codeSociete ?: any
  email ?:  string
  password ?: string
  nom ?: string
  prenom ?: string
  tel ?: string
  agence ?: any
  societe ?: any
  caisse ?: string
  CAISSIER?: string
  nomAgence ?: string
  source ?: any
  auteurValidation ?:string
  auteur ?: string
  photo ?: string
  dateCreation ?: Date
  profiles?:any=[]
  roles:Role[];
  //fileType ?: string
  codeDistributeur ?: string
  nomDistributeur ?: string
  status ?: number
  statusAml?: string
  userBank?: true
  motif?: string
  enrolement?: true
  codeEntite ?: string
  pin ?: string
  fileType: any
  plateformes : any
  plateforme: any = 'carburant'
  signature?: string

  setUser(_user: unknown) {
    const user = _user as UserModel;
    this.id = user.id;
    this.username = user.username || '';
    this.password = user.password || '';
    this.prenom = user.prenom || '';
    this.nom = user.nom || '';
    this.source = user.source || './assets/media/users/default.jpg';

  }

}

export class Caisse
{
    codeAgence?: string
    id?: string
    code?: string
    numero?: number
    solde?: number
    soldeCash?: number
    longitude?: string
    disponibilite?: string
    latitude?: string
    seuilAlerte?: number
}

export class Bon
{
  id ?: string
  code_entite?: string
  compagnie?: any
  montant?: number
  reference?: string
  telephone?: string
  qrCode?: string
  etat?:string
  status?: boolean
}

export class GenererLotCarte {

  code ?: string
  compagnie ?: string
  cartes:any
  status:boolean=false
}

export class GenererLotBon {

  compagnie ?: string
  montantUnitaire ?: number
  bons: any
}
export class RechargeCarte {
  fichier ? : string
  nombre? : number
  montant ?: number
}

export  class Societe {
  id ? : any
  nom ? : string
  code? : string
  logo ?: string
  isCompagnie ?: boolean
  isAgregateur?: boolean
  isApporteur?: boolean
  ninea?: string
  rccm?: string
  telephone?: string
  solde?:string
  email?:string
  contacts: any
  status?: boolean
  auteur?: string
  dateCreation?: "2022-09-30T10:19:22.581Z"
  dateModification?: "2022-09-30T10:19:22.581Z"
  compagnies?:any ;
  entreprises?:any;
  uri:string
}

export class addSociete {
  nom ? : string
  code? : string
  logo ?: string
  isCompagnie ?: boolean
  isAgregateur?: boolean
}
export  class CarteCarburant {
  id ? : number
  nom ?: string
  societe ?: any
  reference?:string
  status?:boolean
  boutique: false
  client: any
  compagnie: any
  enrolement: null
  qrCode: string
  solde: number
  etat: string
  telephone: string
}


export class Role

{
  id ? : any
  nom ?: string
  couleur ?: any
  codeEntite ?: string
  codeSociete?: string
  status ?: boolean
  forNetwork ?: boolean
  isInternal?: boolean
}

export class ModuleAction
{
  libelle ?: string
  actions ?: [
    string
  ]

}


export class Modules
{
libelle?: string
  actions?: [
    {
      permissions?: [
        string
      ]
    }
  ]
}
export class Contact
{
  id?: number
  nom?: string
  prenom?: string
  email?: string
  tel?: string
  status?: true
}
export class Approvisionnements
{
  id?: 0
  montant?: 0
  montantDemande?: 0
  soldeAvant?: 0
  soldeApres?: 0
  soldeEntite?: 0
  entite?: {
    telephone?: string
    ninea?: string
    email?: string
    rc?: string
    codeIsoPays?: string
    solde?: 0
    devise?: string
    tauxTva?: string
    code?:string
    nom?:string
  }
  societe?: {
    id?: 0
    isCompagnie?: boolean
    isAgregateur?:boolean
    nom?: string
    ninea?: string
    rccm?: string
    telephone?: string
    email?: string
    code?: string
    solde?: 0
    status?: true
    logo?: string
  }
  motif?: string
  statusTxt?: string
  status?: number
  uid?: 0
  auteur?: string
  uidValidation?: 0
  auteurValidation?: string
  uidApprouveur?: 0
  approuveur?: string
  dateCreation?: "2022-09-17T13:41:01.362Z"
  dateModification: "2022-09-17T13:41:01.362Z"
  dateValidation?: "2022-09-17T13:41:01.362Z"
  dateApprobation: "2022-09-17T13:41:01.362Z"
  dateRejet?: "2022-09-17T13:41:01.362Z"
  numTransaction: string
}
export class demandeCarteAppro{
    nombre?: number = 0
    montant?: number = 0
    genere?: false
    motif?: string
}
