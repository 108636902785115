
	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
        <!--begin::Toolbar-->

        <!--end::Toolbar-->
        <!--begin::Post-->
        <div class="loaderPage" *ngIf="loading">
            <div class="lds-roller " >
              <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
          </div>
        <div class="post d-flex flex-column-fluid" id="kt_post">
            <!--begin::Container-->
            <div id="kt_content_container" class="container-xxl">
                <!--begin::Layout-->
                <div class="d-flex flex-column flex-lg-row">
                    <!--begin::Content-->
                    <div class="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
                        <!--begin::Card-->
                        <div class="card card-flush pt-3 mb-5 mb-xl-10">
                            <!--begin::Card header-->
                            <div class="card-header">
                                <!--begin::Card title-->
                                <div class="card-title">
                                    <h2 class="fw-bolder"translate="GENERAL.DETAIL_STATION"> </h2>
                                </div>
                                <div class="m-0">
                                    <!--begin::Menu toggle-->







                                    <!--end::Menu toggle-->
                                    <!--begin::Menu 1-->
                                    <div class="menu menu-sub menu-sub-dropdown w-125px w-md-250px mt-5" data-kt-menu="true"
                                        id="kt_menu_624476767c26c">
                                        <!--begin::Header-->

                                        <!--end::Header-->
                                        <!--begin::Menu separator-->
                                        <div class="separator border-gray-200"></div>
                                        <!--end::Menu separator-->
                                        <!--begin::Form-->


                                        <!--end::Form-->
                                    </div>
                                    <!--end::Menu 1-->
                                </div>
                                <!--begin::Card title-->
                                <!--begin::Card toolbar-->
                                <!--end::Card toolbar-->
                            </div>
                            <!--end::Card header-->
                            <!--begin::Card body-->
                            <div class="card-body pt-3">
                                <!--begin::Section-->
                                <div class="mb-10">
                                    <!--begin::Title-->
                                    <h5 class="mb-4"translate="GENERAL.INFORMATION_STATION"></h5>
                                    <!--end::Title-->
                                    <!--begin::Details-->
                                    <div class="d-flex flex-wrap py-5">
                                        <!--begin::Row-->
                                        <div class="flex-equal me-5">
                                            <!--begin::Details-->
                                            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                                                <!--begin::Row-->

                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.NOM_STATION"></td>
                                                    <td class="text-gray-800 client">{{itemModel.nom}}</td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400">Region:</td>
                                                    <td class="text-gray-800 client">{{itemModel?.region}}</td>
                                                </tr>

                                                <!--end::Row-->
                                                <!--begin::Row-->


                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.DEPARTEMENT"></td>
                                                    <td class="text-gray-800 client">{{itemModel?.commune?.departement.nom}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.COMMUNES"></td>
                                                    <td class="text-gray-800 client">{{itemModel.commune.nom}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-400 min-w-175px w-175px"translate="MENU.TEL"></td>
                                                    <td class="text-gray-800  client min-w-200px">
                                                        <a href="#" class="text-gray-800  text-hover-primary">{{itemModel.tel}}</a>
                                                    </td>
                                                </tr>
                                                <!--end::Row-->
                                            </table>
                                            <!--end::Details-->
                                        </div>
                                        <!--end::Row-->
                                        <!--begin::Row-->
                                        <div class="flex-equal">
                                            <!--begin::Details-->
                                            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400 min-w-175px w-175px"translate="MENU.ADRESSE"></td>
                                                    <td class="text-gray-800  client min-w-200px">
                                                        <a href="#" class="text-gray-800  text-hover-primary">{{itemModel.adresse}}</a>
                                                    </td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.CODE_ENTITE"></td>
                                                    <td class="text-gray-800 client">{{itemModel?.codeEntite}}</td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.CODE_SOCIETE"></td>
                                                    <td class="text-gray-800 client">{{itemModel.codeSociete}}</td>
                                                </tr>
                                                <!--end::Row-->
                                                <!--begin::Row-->

                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.CODES"></td>
                                                    <td class="text-gray-800 client">{{itemModel.code}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-gray-400"translate="GENERAL.TAUX"></td>
                                                    <td class="text-gray-800 client">{{itemModel?.taux}}%</td>
                                                </tr>
                                                <!--end::Row-->
                                            </table>
                                            <!--end::Details-->
                                        </div>

                                        <!--end::Row-->
                                    </div>
                                    <!--end::Row-->
                                </div>
                                <!--end::Section-->
                                <!--begin::Section-->

                                <!--end::Section-->
                            </div>
                            <!--end::Card body-->
                        </div>
                        <!--end::Card-->
                        <!--begin::Card-->

                        <!--end::Card-->
                        <!--begin::Card-->

                        <!--end::Card-->
                    </div>
                    <!--end::Content-->
                    <!--begin::Sidebar-->
                    <div class="flex-column flex-lg-row-auto w-lg-250px w-xl-300px mb-10 order-1 order-lg-2">
                        <!--begin::Card-->
                        <div class="card card-flush mb-0" data-kt-sticky="true" data-kt-sticky-name="subscription-summary" data-kt-sticky-offset="{default: false, lg: '200px'}" data-kt-sticky-width="{lg: '250px', xl: '300px'}" data-kt-sticky-left="auto" data-kt-sticky-top="150px" data-kt-sticky-animation="false" data-kt-sticky-zindex="95">
                            <!--begin::Card header-->
                            <div class="card-header">
                                <!--begin::Card title-->
                                <div class="card-title">

                                </div>
                                <!--end::Card title-->
                                <!--begin::Card toolbar-->

                                <!--end::Card toolbar-->
                            </div>
                            <!--end::Card header-->
                            <!--begin::Card body-->
                            <div class="card-body pt-0 fs-6">
                                <!--begin::Section-->
                                <div class="mb-7">
                                    <!--begin::Details-->
                                    <div class="d-flex align-items-center">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-60px symbol-circle me-3">
                                            <ngx-avatar  name="{{itemModel?.nom}}" >
                                            </ngx-avatar>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-column">
                                            <!--begin::Name-->
                                            <a href="#" class="fs-4 fw-bolder text-gray-900 text-hover-primary me-2">{{itemModel.nom}}</a>
                                            <!--end::Name-->
                                            <!--begin::Email-->
                                            <a href="#" class="fw-bold text-gray-600 text-hover-primary">{{itemModel?.email}}</a>
                                            <!--end::Email-->
                                        </div>
                                        <!--end::Info-->
                                    </div>
                                    <!--end::Details-->
                                </div>
                                <!--end::Section-->
                                <!--begin::Seperator-->
                                <!--begin::Seperator-->
                                <div class="separator separator-dashed mb-7"></div>
                                <!--end::Seperator-->
                                <!--begin::Section-->
                                <div class="mb-10">
                                    <!--begin::Title-->

                                    <!--end::Title-->
                                    <!--begin::Details-->
                                    <table class="table fs-6 fw-bold gs-0 gy-2 gx-2">
                                        <!--begin::Row-->
                                        <tr class="">
                                            <td class="text-gray-400"translate="GENERAL.HEURE_OUVVERTURE"></td>
                                            <td class="text-gray-800">{{itemModel.heureOuverture}}</td>
                                        </tr>
                                        <!--end::Row-->
                                        <!--begin::Row-->
                                        <tr class="">
                                            <td class="text-gray-400"translate="GENERAL.HEURE_FERMETURE"></td>
                                            <td class="text-gray-800">{{itemModel.heureFermeture}}</td>
                                        </tr>
                                        <!--end::Row-->
<!--                                        <tr class="">-->
<!--                                            <td class="text-gray-400"translate="CARTECARBURANT.SOLDE"></td>-->
<!--                                            <td class="text-gray-800">{{itemModel.solde}}</td>-->
<!--                                        </tr>-->

                                        <!--begin::Row-->
                                        <tr>

                                            <td class="text-gray-400 min-w-175px w-175px"translate="MENU.STATUS"></td>
                                            <td class="text-gray-800  client min-w-200px">
                                            <span *ngIf="itemModel?.status == 1" class="badge badge-light-success"translate="MENU.ACTIF"></span>
                                            <span *ngIf="itemModel?.status == 0" class="badge badge-light-danger"translate="MENU.INACTIF"></span>

                                            </td>
                                        </tr>
                                        <!--end::Row-->
                                        <!--begin::Row-->

                                        <!--end::Row-->
                                    </table>
                                    <!--end::Details-->
                                </div>
                                <!--end::Section-->
                                <!--begin::Actions-->

                                <!--end::Actions-->
                            </div>
                            <!--end::Card body-->
                        </div>
                        <!--end::Card-->
                    </div>
                    <!--end::Sidebar-->
                </div>
                <!--end::Layout-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Post-->
    </div>



