import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-affichage',
  templateUrl: './affichage.component.html',
  styleUrls: ['./affichage.component.scss']
})
export class AffichageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
