

<ng-container *ngIf="title$ | async as title">
  <ng-container *ngIf="showTitle">
    <!-- begin::Title -->
    <h1 class="d-flex align-items-center text-white fw-bolder my-1 fs-3">
     {{ title }}
    </h1>
    <!-- end::Title -->
  </ng-container>

  <ng-container *ngIf="showBC">
    <ng-container *ngIf="bc$">
      <ng-container *ngIf="bc$.length > 0">
        <ng-container *ngIf="pageTitleDirection === 'row'">
          <span class="h-20px border-gray-200 border-start mx-4"></span>
        </ng-container>
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li
            *ngFor="let bc of bc$"
            class="breadcrumb-item text-muted">
            <ng-container>
              <a [href]="bc.path" class="text-white  fw-bolder my-1 fs-3" >
                {{ bc.title }}
              </a>
              <span *ngIf="bc.isSeparator" class="bullet bg-gray-200 w-10px h-3px m-2"></span>
            </ng-container>

          </li>
<!--          <li class="breadcrumb-item text-white fw-bolder my-1 fs-3">  <a  class="text-white  fw-bolder my-1 fs-3">{{ title }}</a></li>-->
        </ul>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
