import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { Endpoint } from 'src/app/models/Endpoint';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  roles : any [] = [];
  total_roles: number = 0
  users : any [] = [];
  total_users: number = 0
  societes : any = [];
  total_societes: number = 0
  stations : any [] = [];
  total_stations: number = 0
  caisses : any [] = [];
  total_caisses: number = 0
  cartes : any [] = [];
  total_cartes: number = 0
  bons : any  = [];
  total_bons: number = 0
  caissiers : any [] = [];
  total_caissiers: number = 0
  clients:any =[];
  total_clients: number = 0;
  car:any =[];
  totalsAtt:number=0;
  listCartesAtt:any=[]

  loading0 : any = false ;
  loading1 : any = false ;
  loading2 : any = false ;
  loading3 : any = false ;
  loading4 : any = false ;
  loading5 :any =false;
  loading6 :any =false;
  loading7 :any =false;
  loading8 :any =false;
  loading: boolean;
  page = 1;
  query = '';


  constructor(private service : GlobalService, private router: Router, private authService : AuthService, private toastr : ToastrService) {}

  ngOnInit(): void {
    this.getRoles()
    // this.getUsers()
     this.getSocietes()
    // this.getStations()
    // this.getCaisses()
    // this.getCartes()
     this.getBons()
    // this.getCaissiers()
    this.getListClient()
  }

  getRoles(){
    this.loading0 =true
    this.authService.getAll(Endpoint.ROLES).subscribe((res:any)=>{
    console.log('latests members here => ', res['hydra:member']);
    this.roles = res['hydra:member'];
    this.total_roles = res['hydra:totalItems'];
    this.loading0 =false;
    },error => {
      console.log('error',error)
      this.loading0 =false;
      this.toastr.error('Une erreur est survenue');
    })
  }


  getUsers(){
    this.loading1 =true
    this.authService.getAll(Endpoint.USERS).subscribe((res:any)=>{
    console.log('latest users here ', res['hydra:member']);
    this.users = res['hydra:member'];
    this.total_users = res['hydra:totalItems'];
    this.loading1 =false;
    },error => {
      console.log('error',error)
      this.loading1 =false;
      this.toastr.error('Une erreur est survenue');
    })
  }

  getSocietes(){
    this.loading2 =true
    this.service.getListeStation(Endpoint.SOCIETE).subscribe((res:any)=>{
    console.log('services here ', res['hydra:member']);
    this.societes = res['hydra:member'];
    this.total_societes = res['hydra:totalItems'];
    this.loading2 =false;
    },error => {
      console.log('error',error)
      this.toastr.error('Une erreur est survenue')
      this.loading2 =false;
    })
  }

  getStations(){
    this.loading3 =true;
    this.service.getAllReseau(Endpoint.POINT_DE_VENTES).subscribe((res:any)=>{
    console.log('partenaires here ', res['hydra:member']);
    this.stations = res['hydra:member'];
    this.total_stations = res['hydra:totalItems'];
    this.loading3 =false;
    },error => {
      console.log('error',error)
      this.loading3 =false;
      this.toastr.error('Une erreur est survenue');
    })
  }

  getCaisses(){
    this.loading4 = true;
    this.service.getAllReseau(Endpoint.CAISSE).subscribe({
      next : (res : any) => {
        this.loading4 =false;
        this.caisses = res['hydra:member'];
        this.total_caisses = res['hydra:totalItems'];
        console.log('entities here ', res['hydra:member']);
      },
      error: err => {
        console.log('error',err)
        this.toastr.error('Une erreur est survenue')
        this.loading4 =false;
      }})
  }
  getCartes(){
    this.loading5 = true;
    this.service.getListeStation(Endpoint.LISTECARBURANT).subscribe({
      next : (res : any) => {
        this.loading5 =false;
        this.cartes = res['hydra:member'];
        this.total_cartes = res['hydra:totalItems'];
        console.log('entities here ', res['hydra:member']);
      },
      error: err => {
        console.log('error',err)
        this.toastr.error('Une erreur est survenue')
        this.loading5 =false;
      }})
  }

  getBons(){
    this.loading6 = true;
    this.service.getListeStation(Endpoint.BONS).subscribe({
      next : (res : any) => {
        this.loading6 =false;
        this.bons = res['hydra:member'];
        this.total_bons = res['hydra:totalItems'];
        console.log('entities here ', res['hydra:member']);
      },
      error: err => {
        console.log('error',err)
        this.toastr.error('Une erreur est survenue')
        this.loading6 =false;
      }})
  }

  getCaissiers(){
    this.loading7 = true;
    this.service.getListeStation(Endpoint.TRANSACTION_LISTE).subscribe({
      next : (res : any) => {
        this.loading7 =false;
        this.caissiers = res['hydra:member'];
        this.total_caissiers = res['hydra:totalItems'];
        console.log('entities here ', res['hydra:member']);
      },
      error: err => {
        console.log('error',err)
        this.toastr.error('Une erreur est survenue')
        this.loading7 =false;
      }})
  }

  getListClient(){
    this.loading7 = true;
    this.service.getListeStation(Endpoint.CLIENTS).subscribe({
      next : (res : any) => {
        this.loading7 =false;
        this.clients = res['hydra:member'];
        this.total_clients = res['hydra:totalItems'];
        console.log('entities here ', res['hydra:member']);
      },
      error: err => {
        console.log('error',err)
        this.toastr.error('Une erreur est survenue')
        this.loading7 =false;
      }})
  }

  // getListClient(){
  //   this.loading =true
  //   this.service.getListeStation(Endpoint.CLIENTS,this.page,this.query).subscribe((res:any)=>{
  //   console.log('clients ', res['hydra:member']);
  //   this.clients = res['hydra:member'];
  //   this.total_clients = res['hydra:totalItems'];
  //   this.loading =false;
  //   },error => {
  //     console.log('error',error)
  //     this.loading =false;
  //   })
  // }
  
  getListeCarteAtt(){
    this.loading = true;
    // splash-screen
    // splash-screen
    this.service.getListeStation(Endpoint.CARTE_ATTRIBUTIONS,this.page,this.query).subscribe({
      next: (res:any) => {
        this.loading =false;
        this.listCartesAtt = res['hydra:member'];
        console.log('listCartes', res)
        this.totalsAtt = res['hydra:totalItems']
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error(err.error.message,'Erreur !')
      }
    })
  }

}

