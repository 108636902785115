import { Service } from "src/app/models/Models";

// France
export const locale = {
  lang: 'fr',
  data: {
    TRANSLATOR: {
      SELECT: 'choisissez votre langue',
    },
    GENERAL:{
      STATIONS_CAISSES:'Stations/Caisses',
      EN_COURS:'En cours ...',
      NOUVELLE_COMMISSION:'Nouvelle commission',
      TROUVEES:'Trouvés',
      SOCEITE:'Société:',
      LISTE_PARAMETRES_COMMISSION:'Liste des parametres commission',
      HEURE_FERMETURE:'Heure fermeture:',
      HEURE_OUVVERTURE:'Heure d\'ouverture:',
      CODES:'Code:',
      TAUX:'Taux:',
      CODE_ENTITE:'Code entité:',
      COMMUNES:'COMMUNE',
      DEPARTEMEN:'Departement:',
      NOM_STATION:'Nom de station:',
      INFORMATION_STATION:'Information du station:',
      DETAIL_STATION:'Detail Station',
      FERMETURE:'Fermeture',
      OUVERTURE :'Ouverture',
      HORAIRE:'Horraire',
      AUCUN_STATION_TROUVEE:'Aucune station trouvée',
      COMMUNE:'Commune',
      DEPARTEMENT:'Departement',
      LISTE_STATIONS:'Liste des stations',
      NOMBRE_BON:'Nombre de bon',
      AUCUNE_BON_TROUVEE:'Aucune bon trouvée',
      DETAIL_BON:' Details bon',
      FICHIER_OBLIGATOIRE:' le fichier est obligatoire',
      AJOUT_FICHIER_CARTE:'Ajout Cartes par fichie',
      AUCUNE_CARTE_TROUVEE:'Aucune carte trouvée',
      NOM_CLIEN:'Nom du client :',
      LISTE_CARTE:'Liste des cartes',
      CARTE_DISTRIBUES:'Carte(s) disponible(s)',
      CARTE_ATTRIBUES:'Carte(s) attribuée(s)',
      TOTALS_CARTE:'Total cartes',
      NOUVEAU_RECHARGE:'Nouveau rechargement',
      REFERENCES:'Reference:',
      VLOICI_REFERENCE_MONTANT_CARTE:'Voici le reference et le montant du carte.',
      CHOISIR_CODE_PIN:'Choisir une code pin',
      INFORMATION_CARTE_RECHARGEMENT:'Information d\'une carte de rechargement:',
      DETAIL_CARTE_RECHARGEMENT:'Detail d\'une carte de rechargement',
      CONFIRM:'Confirmer',
      MESSAGE:'Etes vous sûr de vouloir changer le statut.',
      TITLE: "Changement statut",
      RESULTAT_SEARCH:'Aucun résultat ne correspond à votre recherche.',
      LISTE_RECHARGEMENT_CARTE:'Liste des rechargements de cartes',
      NUME_TRANSACTION:'Numéro de transaction:',
      ETAT_BON:'Etat du bon:',
      MONTANT_BON:'Montant du bon:',
      REFERENCE_BON:'Reference du bon:',
      NINEA_SOCIETE:'IFU du société:',
      SOLDE_SOCIETE:'Solde du société:',
      CODE_SOCIETE :'Code du société:',
      NOM_SOCIETE:'Nom du société:',
      TELEPHONE_CLIENT:'Télephone du client:',
      NATIONALITE_CLIENT:'Nationalité du client:',
      ADRESSE_CLIENT:'Adresse du client:',
      NOM_PRENOM_CLIENT:'Nom et Prénom du client:',
      DATE_MODIFICATION_CARTE:'Date modification du carte:',
      DATE_CREATION_CARTE:'Date création du carte:',
      SOLDE_CARTE:'Solde du carte:',
      REFERENCE_CARTE:'Reference du carte:',
      COMMENTAIRE:'Commentaire:',
      CODE_CAISSE:'Code Caisse:',
      OPERATION:'Opération:',
      FRAIS:'Frais:',
      SOLDEAPRES:'Solde Aprés:',
      SOLDEAVANT:'Solde Avant:',
      MONTANTE:'Montant:',
      INFORMATION_TRANSACTION:'Information d\'un transaction:',
      DETAIL_TRANSACTION:'Detail Transaction',
      TRANSACTION_NOT_FOUND:'Aucun Transaction trouvée',
      NUM_TRANSACTION:'Numéro de transaction',
      RESULT_NOT_FOUND:'Aucun résultat ne correspond à votre recherche.',
      TRANSACTION:'Liste des transactions',
      VALIDER:'Valider',
      DOUBLONS:'Doublons',
      NOMBRE_CLIEN:'Nombre de client',
      GLISSER_DEPOSER:'glissez , déposez!',
      SOCIETE_OBLIGATOIRE:'La société est obligatoire',
      SELECTIONNER_SOCIETE:'Selectionner la --Société ...',
      COMPAGNIE_AGREGATEUR:'Compagnies/Agrégateurs',
      COMPAGNIE_CARTE:'Societe Client',
      COMPAGNIE_AUTEUR:'Societe Auteur',
      AJOUT_CLIENT_PAR_FICHIER:'Ajout Clients par fichier',
      CIN:'CIN',
      PASSPORT:'PASSEPORT',
      AJOUT_CLIENT:'Ajout Client',
      PARTNERS: 'PARlNAIRES',
      COUNTRY: 'Pays',
      CODEPIN:'Code Pin',
      REFERENCE:'Reference',
      MONTANT:'Montant',
      ACTIVITY_AREA: "Secteur d'activité",
      STATUS: "Status",
      CREATED_DATE: "Date de création",
      ACTIONS: "Actions",
      USER : 'Utilisateur',
      ROLE : 'Rôle',
      LOGIN :'Username',
      NUM :'Téléphone',
      STATION:'Station',
      COOPERATIVE:'Coopérative',
      ETAT:'Etat',
      AUTEUR :'Auteur',
      DATE:'Date',
      ADDUSER : 'Ajouter un utilisateur',
      ADDROOM:'Ajouter un chambre consulaire',
      UPDATEROOM:'Modifier un chambre consulaire',
      UPDATEUSER: 'Modifier un utilisateur',
      NAME :'Nom',
      SOCIETE:'Sociéte',
      ISCOMPAGNIE:'IsCompagnie',
      ISAGREGATEUR:'IsAgregateur',
      RCCM:'Rccm',
      TELEPHONE:'Téléphone',
      LOGO:'Logo',
      PHOTO:'Photo',
      FIRSTNAME :'Prénom',
      LASTNAME :'Nom',
      ADDRESS :'Adresse',
      DEVISE :'Adresse',
      RC :'Rc',
      NINEA :'IFU',
      NAMESOCIETE:'Nom du société',
      CURRENCY :'Devise',
      EMAIL :'Email',
      CATEGORY :'Catégories',
      TARIFICATION :'Tarification',
      DESC :'Description',
      USERNAME  : 'Username',
      CODE: 'Code',
      DESCRIPTION: 'Description',
      CATEGORIE_CHAMBRE:'categorieChambre',
      DIRECTION:'Direction',
      SOLDE:'Solde (XOF)',
      SOLDE_SEUIL:'Solde seuil (XOF)',
      ISAPORTEUR:'Est-ce que cet agrégateur est un apporteur d\'affaire',
      APORTEUR:'Apporteur d\'affaire',
      CLIENT:'Client',
      NAISSANCE:'Nationalite',
      DATENAISSANCE:'Date de naissance',
      NUMPIECE:'Numéro de piéce',
      TYPEPIECE:'Type de piéce',
      DATEDELIVRANCE:'Date de délivrance',
      DATEXPIRATION:'Date d\'expiration',
      LIEUDELIVRANCE:'Lieu de deélivrance',
      ADRESSE:'Adresse',
      COLOR:'Couleur',
      FULNAME:'Nom&Prénom',
      CONTACT:'Contact',
      NEW_STATION: 'Nouvelle Station',
      UPDATE_STATION: 'Modifer une station',
      SOCIETECODE:'Societe',
      CHANGE_PIN:'Regénérer code pin',
      CHANGE_STATUS:'Changer statut',
      POMPISTE:'Pompiste'
    },
    MENU: {
      MON_PROFIL:'Mon Profil',
      FR:'Frensh',
      EN:'English',
      ENGLISH:'English',
      LANG:'Language',
      DECONNEXION:'Déconnexion',
      APPRO_SOCETE:'Approvisionnements/Sociétés',
      RECHARGE_UNE_CARTE:'Recharger une carte (s)',
      CLIENT_CAISSES:'Clients/Caissiers',
      CARBURANT_TRANSACTION:'Carburant/Transaction',
      AJOUT_BON_PAR_FICHIER:'Ajout bons par fichier',
      PREMIER_REFERENCE_BON:'1ére Référence Bon',
      AJOUT_BON:'Ajout de bon',
      AJOUTER_BON:'Ajouter un bon',
      LISTE_BONS:'Liste des bons',
      COMMISIONS:'Commissions',
      PARAMETRAGES:'Paramétrages',
      TOUS:'Tous',
      LISTE_DES_TRANSACTIONS_CAISSIERS:' Liste transaction caissier',
      LISTE_DOUBLONS:'Liste doublons',
      LISTE_CARTE_INTROUVABLES:'Liste Cartes Introuvables',
      LISTE_CARTE_TROUVES:'Liste Cartes Trouvées',
      NOMBRE_DE_CARTE:'Nombre de carete',
      MONTANT_OBLIGATOIRE:'le montant est obligatoire',
      MONTANT_TOTAL:'Montant Total',
      NOMBRE_CART:'Nombre de carte',
      AJOUT_CARTE_FICHIER:'Ajout Cartes par fichier',
      LA_CARTE_USE_BOUTIQUE:'La carte peut-il être utilisée dans les  boutiques',
      NOMBRE_OBLIGATOIRE:' Le nombre est obligatoire',
      SOCIETE_OBLIGATOIRE:'La société est\n' +
        '                  obligatoire',
      AJOUT_LOT_CARTE:'Ajout lot de carte',
      SELECTIONER_UNE_COMPAGNIE:'Selectionner une société...',
      SELECTIONNER_LA_SOCIETE:'Selectionner la --Société ...',
      AJOUTER_CARTE_CARBURANT:'Ajouter une carte de carburant ',
      SOLDE_APRES:'Solde Aprés',
      SOLDE_AVANT:'Solde Avant',
      DETAIL_CARTE:'  Details carte',
      SELECTIONNER_CARTE:'Selectionner une carte ...',
      VEUILLEZ_SELECTIONNER_UNE_CARTE_DISPO:'Veuillez selectionner une carte disponible.',
      METTRE_JOUR_CARTE:'Mettre a jour la carte',
      LIEU_DELIVRANCE:'Lieu de delivrance:',
      DATE_DEXPIRATION:'Date d\'expiration:',
      DATE_DELIVRANCE:'Date de delivrance:',
      INFORMATIONS_CLIENT:'Information complémentaire du client',
      LISTE_CARTE:'Liste des Cartes',
      ADRESSE:'Adresse:',
      TYPE_DE_PIECE:'TYPE DE PIÉCE:',
      NUM_DE_PIECE:'NUMÉRO DE PIÉCE:',
      DATE_DE_NAISSANCE:'DATE DE NAISSANCE:',
      INFORMATION_CLIENT:'Information du client:',
      NATIONALITE:'Nationalite:',
      CODE_PIN:'Code PIN',
      DETAIL_CLIENT:'Detail Client',
      CLIENT_NOT_FOUND:'Aucune client trouvée',
      NUM_PIECE:'Numero Piece:',
      LISTE_CLIENTS:'Liste des clients',
      AGREGATEUR_NOT_FOUND:'Aucun  agrégateur trouvé',
      LISTE_AGREGATEURLISTE_AGREGATEUR:'Liste des agrégateurs',
      MODIFIER_AGREGATEUR:'Modifier un agrégateur',
      AJOUTER_AGREGATEUR:'Ajouter un agrégateur',
      CODE_ENTREPRISE:'Code Entreprise:',
      ENTREPRISEE:'Entreprise:',
      IINFORMATION_ENTREPRISE:'Information de l\'entreprise:',
      DETAIL_ENTREPRISE:'Details Entreprise:',
      MODIFIER_COMPAGNIE:'Modifier une compagnie',
      AJOUTER_COMPAGNIE:'Ajouter une compagnie',
      OUI:'OUI',
      NON:'NON',
      APPORTEUR_AFFAIRE:'Apporteur d\'affaire:',
      CODE_AGREGATEUR:'Code Agrégateur',
      AGREGATEURE:'Agrégateur:',
      INFORMATION_AGREGATEUR:'Information de l\'agrégateur:',
      DETAIL_AGREGATEUR:' Details Agrégateur',
      SOLDIER:'Solde:',
      CODE_COMPAGNI:'Code Compagnie:',
      COMPAGNI:'Copmpagnie:',
      INFORMATION_COMPAGNIE:'Information de la Compagnie:',
      DETAIL_COMPAGNIE:'Details Compagnie',
      DTAIL_SOCIETE:'Détail d\'une société',
      COMPAGNIE_NOTFOUND:'Aucune compagnie trouvée',
      SOLDE:'Solde',
      LISTE_COMPAGNIE:'Liste des compagnies',
      PRENO:'Prénom',
      VEUILLEZ_RENSEIGNER_LES_CHAMPS:'Veuillez renseigner les champs ci-après',
      RCC:'Régistre de commerce:',
      NINE :'IFU:',
      NAME:'Nom & Prénom',
      AJOUTER_CONTACT:'Ajouter un contact',
      MODIFIER_ENTREPRISE:'Modifier une entreprise',
      AJOUT_ENTREPRISE:'Ajouter une entreprise',
      STATION_NOTFOUND:'Aucune station trouvée',
      TEL:'Téléphone:',
      CODE_SOCIETE:'Code Sociéte:',
      LISTE_ENTREPRISE:'Liste des entreprises',
      VALIDER: 'Valider',
      REMOVE:'Supprimer',
      REFERENCE:'Reference',
      VEILLEZ_PATIENTER:'Veuillez patienter...',
      GENERER:'Générer',
      CARTE_USER:'La carte peut-il être utilisée dans les  boutiques',
      REFERENCE_OBLIGATOIRE:'La réference est obligatoire',
      PREMIER_REFERENCE:'1ére Référence Carte',
      CREATION_LOT_CARTE:'Création de lot de cartes',
      LISTE_DEMNADE_CARTE_APPRO_SOCIETE:'Liste des demandes de carte d\'approvisionnements sociétés',
      CHOISIR:'---Choisir---',
      SOCIOTE:'Société:',
      LISTE_APPRO_SOCIETES:'Liste des approvisionnements sociétés',
      LISTE_DEMANDE_APPRO_CARTE:'Liste Demande approvissionnement cartes',
      MONTANT_VIREMENT:'Motif du montant de virement',
      MONTANT_INSCRIRE_VIREMENT:'Montant inscrit sur le reçu de virement.',
      DEMANDE_APPRO:'Demande d\'approvisionnement',
      REFUSE:'Refusée',
      ACCODE:'Accordée',
      REJETER:'Rejeter',
      ACCEPTER:'Accepter',
      EN_ATTENTE:'En Attente',
      REFUSER:'Refusé',
      ACCORDER:'Accordé',
      AUTEURE:'Auteur:',
      LSITE_APPROS_ENTITES:'Liste des approvisionnements Entités',
      YES:'Oui, je confirme',
      NO:' Non, j\'annule',
      NOT_FOUND_PERMISSION:'Aucune permission trouvée',
      PRECEDENT:'Précédent',
      SUIVANT:'Suivant',
      DATE_REGISTRE:'DATE D\'INSCRIPTION',
      UTLISATEUR_AFFECTER:'Utilisateurs affectés',
      UPDATE:'Modifier',
      NOT_FOUND:'Aucun rôle  trouvée',
      ERROR:'Une erreur est survenue.',
      RESULT:'Aucun résultat ne correspond à votre recherche.',
      LISTE_ROLE:'Liste des roles',
      PLEASE_WAIT:'S\'il vous plaît, attendez....',
      CONFIRMATION_PASSWORD:'Confirmation du mot de passe',
      METTRE_A_JOUR_PASSWORD:'Mettre à jour le mot de passe',
      REGENERER_CODE_PIN:'Regénérer le code pin',
      REGENERER_MOT_PASSE:'Regénérer le mot de passe',
      MODIFICATION_MOT_DE_PASSE:'Modifier le mot de passe',
      MODIF:'Modification',
      DATECREATION:'Date de creation:',
      VALIDERBY:'Valider par:',
      CREATION:'Créer par:',
      SOCIO:'SOCIETE',
      USERNAME: 'Nom d\'utilisateur',
      USER:'Utilisateur:',
      INFO_USER:'Information d\'utilisateur:',
      DETAIL_USER:'Detail   Utilisateur',
      SELECTION_CAISSE:'Selectionner la caisse',
      PHOTOS:'Types de fichier autorisés : png, jpg, jpeg.',
      CANCEL:'Annuler',
      LISTE_USERS:'Liste des utilisateurs',
      ROLE:'Role',
      NOM:'Nom:',
      PRENOM:'Prénom:',
      TELEPHONE:'Téléphone:',
      EMAIL:'Email:',
      STATUS:'Status:',
      FILTER:'Filtrer',
      EXPORTER:'Exporter',
      EXPORT_PDF:'Exporter en PDF',
      EXPORT_EXCEL:'Exporter en Excel',
      ACTIF:'Actif',
      INACTIF:'Inactif',
     ENATTENTEF:'En attente',
      RECHARGER_CARTE:'Card Reload',
      RECHARGE_CARTE:'Card Reload',
      RECHARGE_CARD:'Rechargement carte',
      GESTION_DES_TRANSACTION:'Transaction management',
      NEW_FILE_CLIENT:'Nouveau fichier de client',
      GESTION_DES_CLIENTS:'Gestion des clients',
      NEW_FILE_BON:'Nouveau fichier de bon',
      NEW_LOT_BON:'Nouveau lot de bon',
      LISTE_BON:'Liste Bon',
      RECHARGEMENT_CARD:'Rechargement par fichier e',
      NEW_FILE_CARD:'Nouveau fichier de carte',
      NEW_LOT_CARD:'Nouveau lot de carte',
      CARTES:'Cartes',
      CARBURANT:'Carburant',
      GESTIONDESSTATIONS:'GESTION DES Stations',
      ADDAGREGATEUR:'Ajout agregateur ',
      AGREGAT:'Agregateurs',
      ADDCOMPAGNIE:'Ajout  compagnie',
      ADDSOCIETE:'Ajout société',
      COMPAGNIES:'Compagnies',
      ENTREPRISES:'Entreprises',
      SOCIETES:'Sociétés',
      APPROSVISIONNEMENT:'Approvisionnements',
      PERMISSIONS:'Permissions',
      ROLES:'Roles',
      USERS_EN_ATTENTE:'Utilisateurs en attente',
      USERS:'Utilisateurs',
      CONFIGURATION:'Configurations',
      TRANSACTION:'Transaction',
      NEW: 'Nouveau',
      ACTIONS: 'Actions',
      CREATE_POST: 'Créer un nouveau Post',
      PAGES: 'Pages',
      FEATURES: 'Fonctionnalités',
      APPS: 'Applications',
      DASHBOARD: 'Tableau de Bord',
      STATION: 'Stations',
      LISTE: 'Liste',
      LISTESTATION:'Liste des stations',
      POINT_DE_VENTE: 'Point de ventes',
      DISTRIBUTEUR: 'Distributeurs',
      APPROVISIONNEMENT:'Liste',
      APPRO:'Entité',
      APPROS:'Société',
      GESTION_DES_SERVICES:'Gestion des services',
      CLIENT:'Clients',
      PARTNERS: 'Caisse',
      CAISSIERS:' Caissiers',
      CAISSE: 'Caisses',
      CONTACTS:'Contacts',
      SERVICES: 'Services',
      COMMISSIONS:'Commisssions',
      BONS : 'Bons',
      MESSAGES: 'Messages',
      CLIENTS: 'Clients',
      ADMINISTRATIONS: 'Administrations',
      CONFIGURATIONS : 'Configurations',
      SETTINGS: 'Paramètres',
      REF: 'Reference',
      MONTANT: 'Montant',
      SOLDEAVANT:'Solde avant',
      SOLDEAPRES:'Solde après',
      AUTEUR:'Auteur',
      ENTITE:'Entité',
      DOCUMENT:'Document',
      DATE_DE_DEMANDE:'Date de demande',
      DATE_DE_REPONSE:'Date de réponse',
      ETAT:'Etat',
      MONTANT_DEMANDE:'Montant demander',
      SOLDE_ENTITE:'Solde entite',
      SOCIETE:'Societe',
      ENTREPRISE:'Entreprise',
      COMPAGNIE:'Compagnie',
      AGREGATEUR:'Agregateur',
      MOTIF:'Motif',
      NUM:'Numéro de transaction',
      AUTEUR_VALIDATION:'Auteur validation',
      APPROVISIONNEMENT_ENTITE:' Entités',
      APPROVISIONNEMENT_SOCIETE:' Societés',
      APPROVISIONNEMENT_STATION:' Stations',
      APPROVISIONNEMENT_AJOUT:'Approvisionner',
      DEMANDE_CARTE_APPRO:'Demande appro carte',
      LISTE_DEMANDE_CARTE_APPRO:'Liste appro carte',
      NOMBRE:'Nombre',
      NOMBREAVANT:'Nombre avant',
      NOMBREAPRES:'Nombre après',
      DATEDEDEMANDE:'Date de demande',
      DATEDEREPONSE:'Date de reponse',
      REPORTSOCIETE:'Card approval commission',
      DATEDEBUT:'Date début',
      DATEFIN:'Date fin',
      RECHARGE_CARD_MULTIPLE:"Rechargement par lot de carte",
      STATISQUE:"Statistique",
      COMPANIES:"Compagnies",
      CAISSIER:"Caissiers",
      STATISTIC_BAR:"Nombres ventes de carburant par mois",
      STATISTIC_BAR_2:"Montant des ventes de carburant par mois",
      STATISTIC_STATION:"Nombre de vente par station",
      STATISTIC_STATION_M:"Montant des vente par station",
      STATISTIC_CARD_M:"Nombre de carte par compagnie",
      STATISTIC_BON_M:"Nombre de Bon par compagnie",
    },

    AUTH: {
      GENERAL: {
        OR: 'Ou',
        SUBMIT_BUTTON: 'Soumettre',
        NO_ACCOUNT: 'Ne pas avoir de compte?',
        SIGNUP_BUTTON: 'Registre',
        FORGOT_BUTTON: 'Mot de passe oublié',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Créer un compte',
        BUTTON: 'Sign In',
        WELCOME_TEXT: 'Connectez-vous à la Plateforme',
        ICI : 'Nouveau ici? ',
      },
      NEWPASSWORD: {
        TITLE: 'Configurer un nouveau mot de passe ?',
       SOUSTITLE: 'Vous avez déjà réinitialisé votre mot de passe ?',

      },
      FORGOT: {
        TITLE: 'Mot de passe oublié?',
        DESC: 'Entrez votre e-mail pour réinitialiser votre mot de passe.',
        SUCCESS: 'Your account has been successfully reset.',
        ENREGISTER : 'Enregistrer',
        ANNULE : 'Annuler',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
        COMPTE_CREER : 'Vous avez déjà un compte ?    ',
        SIGN_HERE :'Se connecter ici',
        LASTNAME: 'Nom',
        FIRSTNAME: 'Prénom',
        SYMBOLE_PASSWORD :'Utilisez 8 caractères ou plus avec un mélange de lettres, de chiffres & symboles.'
      },

      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Nom & Prénom',
        PASSWORD: 'Mot de passe',
        CONFIRM_PASSWORD: 'Confirmez le mot de passe',
        PASSWORDNOW : 'Mot de passe actuel',
        PASSWORDCHANGE : ' Nouveau Mot de passe',
        USERNAME: 'Login',
        NOM:'Nom',
        CODE:'Code',
        AGENCESUPERIEUR:'Agence supérieure',
        REGIONS:'Regions',
        DEPARTEMENT:'Département',
        COMMUNE:'Commune',
        ADRESSE:'Adress',
        SEUILSOLDE:'Solde seuil',
        NOMBRECAISSE:'Nombre  de  caisse',
        HORAIRE:'Horaire',
        TELEPHONE:'Télephone',
        SOCIETE:'Compagnie',
        LOGIN:'Login',
        LOGINTO:'Se connecter à PétroCash',
        CONNEXION:'Se connecter',

      },
      VALIDATION: {
        INVALID: '{{name}} n\'est pas valide',
        REQUIRED: '{{name}} est requis',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },

    ENTITY: {
      ACTIONS_TITLE :{
        ADD : 'Ajouter une entité',
        UPDATE : 'Modifier l\'entité',
        LIST: 'Lister les entités',
        DELETE: 'Supprimer l\'entité',
        ADDROLE:'Ajouter un role',
        UPDATEROLE: 'Modifier un role',
      },
      UTILS :{

      },
      FORM_ENTITY :{
        SOCIAL_REASON:'Raison sociale / Nom',
        COMMERCIAL_REGISTER:'Registre de commerce',
        ACTIVITY_AREA:'Secteur d\'activité',
        PHONE:'Téléphone',
        ADDRESS:'Adresse',
        CURRENCY:'Devise',
        VAT_RATE:'Taux de TVA',
        FILE_TYPE: 'Types de fichiers autorisés',

      }

    },

    PARTNER: {
        FORM_TITLE_ADD: 'Ajouter un partenaire',
        FORM_TITLE_UPDATE: 'Modifier un partenaire',
    },

    SERVICE: {
      FORM_TITLE_ADD: 'Ajouter un service',
      FORM_TITLE_UPDATE: 'Modifier un service',
  },


    BUTTONS:{
      ADD_BUTTON: 'Ajouter',
      CANCEL_BUTTON : 'Annuler',
      UPDATE_BUTTON : 'Modifier',
      SUBMIT_BUTTON: 'Enregistrer',
      VALIDATE_BUTTON: 'Valider',
      FILTER: 'Filtrer',
      SEARCH: 'Chercher',
      EXPORT: 'Exporter',

    },
    PERMISSIONS:{
      PERMISSIONS_NAME:'Permissions',
      PERMISSIONS_ROLE: 'Assigné à',

  },
  SERVICES:{
    DETAIL_DEBUT:'Début',
    DETAIL_FIN: 'Fin',
    DETAIL_FRAIS:'Frais',
    DETAIL_COMMISSION:'Commission',
    DETAIL_TAUXPOINT:'Taux point envoi',
    DETAIL_TAUXRETRAIT:'Taux point retrait',
    DETAIL_TAUXOPERATEURS:'Taux Opérateur',
    DETAIL_TAUXPLATEFORME:'Taux Plateforme',
    DETAIL_TAUXBANQUE:'Taux Banque',
},
CARTECARBURANT:{
    COMPAGNIE:'Compagnie',
    REFERENCE: 'Référence',
    SOLDE:'Solde',
    STATUS:'Statut',

}
  }




};
