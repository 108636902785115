import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as saveAs from 'file-saver';
import { Endpoint } from 'src/app/models/Endpoint';
import { AuthService } from 'src/app/modules/auth';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { ToastrService } from 'ngx-toastr';
import { UserPermissions } from 'src/app/shared/user_permissions';
import { Guard } from 'src/app/shared/utils/guard';
import {ConfirmPinComponent} from "../../confirm-pin/confirm-pin.component";
import {Constantes} from "../../../shared/constantes";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { PageInfoService } from 'src/app/_metronic/layout';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  loading: boolean;
  loadBnt: boolean;
  page : number;
  query = '';
  items:any = [];
  totals:number = 0;
  pageSize : number = 10;
  profils:any;
  filterForm : any ;
  roles : any [] = [];
  filterModel:any = {};
  public station = UserPermissions.MANAGE_Default
  titlePage:any=[

    {
      title: 'Stations',
      path: '/station/list',
      isSeparator: true
   },
    {
      title: 'Liste',
      path: '/station/list',
      isSeparator: false
    }
  ]
  constructor(private pageInfo : PageInfoService,public guard:Guard,public modalService :NgbModal,  private toastr : ToastrService, private authService : AuthService, private service:GlobalService, private fb : FormBuilder) { }
  ngOnInit(): void {
    this.pageInfo.updateTitle('Station');
    this.pageInfo.updateBreadcrumbs(this.titlePage);
    this.initFilter();
  }
  initFilter() {
    this.filterModel.status = ''
    this.query = '';
    this.getList();
  }

  filterItem() {
    this.query = '';
    this.page = 1;
    console.log('test 0',this.filterModel.status)
    if (this.filterModel.code) this.query += `&code=${this.filterModel.code}`;
    if (this.filterModel.tel) this.query += `&tel=${this.filterModel.tel}`;
    if (this.filterModel.nom) this.query += `&nom=${this.filterModel.nom}`;
    if (this.filterModel.status) this.query += `&status=${this.filterModel.status}`;
    if (this.filterModel.codeEntite) this.query += `&codeEntite=${this.filterModel.codeEntite}`;
    if (this.filterModel.codeSociete) this.query += `&codeSociete=${this.filterModel.codeSociete}`;
    this.getList();
  }



  getList(){
    this.loading =true
    this.service.getAllReseau(Endpoint.STATIONS, this.page, this.query).subscribe((res:any)=>{
      this.items = res['hydra:member'];
      this.totals = res['hydra:totalItems'];
      this.loading =false;
      //this.items.map((i:any) => { i.uri = ['/api/point_de_vente' + i.id]; return i; });
    },error => {
      console.log('error',error)
      this.loading =false;
    })
  }

  paginate(p: number){
    this.page = p
    this.getList()
  }
  export(type = '') {
    this.loadBnt = true;
    if (type == 'pdf') {
      const fileName = 'stations.pdf';
      this.service.exportReseau(Endpoint.STATIONS_PDF, this.query).subscribe((res:any) => {
          this.loadBnt = false;
          saveAs(res.body, fileName);
        }, error => {
          this.loadBnt = false;
        });
    } else {
      const fileName = 'stations.csv';
      this.service.exportReseau(Endpoint.STATIONS_CSV, this.query).subscribe((res:any) => {
          this.loadBnt = false;
          saveAs(res.body, fileName);
        }, error => {
          this.loadBnt = false;
        });
    }
    return;
  }
  pageChange(page:any){
    this.page=page;
    this.getList();
  }
  changeStatus(id : any, item : any){
    const dialogRef = this.modalService.open(ConfirmPinComponent, {centered: true, size:"md"});
    dialogRef.componentInstance.data = {
      message: "Etes vous sûr de vouloir changer le statut.",
      title: "Changement statut"
    };
    dialogRef.result
      .then((res) => {
        if(res.confirmed) {
          this.loading = true;
          this.service.upsertReseau(id, Endpoint.STATIONS_UPDATE, { status:Number(!item.status)  })?.subscribe({
            next: (res : any) => {
              item.status = Number(!item.status);
              this.toastr.success("Status modifié avec succés ")
              this.loading =false;
            },
            error: err => {
              this.toastr.error("Une erreur est survenue ")
              this.loading =false;
            }
          })
        }
        if (!res) {
          return;
        }
      });
}
}
