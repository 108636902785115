import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/auth';
import { Endpoint } from 'src/app/models/Endpoint';
import {GlobalService} from "../../../services/global.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Client} from "../../../models/Models";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-modules-actions',
  templateUrl: './modules-actions.component.html',
  styleUrls: ['./modules-actions.component.scss']
})
export class ModulesActionsComponent implements OnInit {
  loading:boolean
  page = 1;
  idSelecct :number
  query = '';
  module:any;
  actions:any=[];
  modules:any =[];
  totals:number = 0;
  pageSize : number = 5;
  itemModel: any={};
  constructor(private service:GlobalService,public modalService :NgbModal,private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.getListModule();

  }

  getListModule(){
    this.loading =true;
    this.service.getListeAl(Endpoint.MODULE).subscribe((res:any)=>{
      console.log('modules',res)
      this.modules = res['hydra:member'];
      this.modules.map((i:any) => { i.uri = '/api/modules/' + i.id; return i; });
      //this.totals = res['hydra:totalItems'];
      this.loading =false;
    },error => {
      this.loading =false;
    });

  }


  pageChange(page:any){
    this.page=page;
    this.getListModule();
  }

  paginate(p: number){
    console.log("event -> ", p)
    this.page = p
    this.getListModule()
  }
  openModal(content:any, one:any={}){
this.itemModel = one;
    this.idSelecct = one.id;
    this.modalService.open(content,{size:'md'})
  }

  updateAc() {
    this.loading = true;
    this.service.update(Endpoint.ACTION+'/'+this.idSelecct, this.itemModel)?.subscribe((res:any)=>{
      this.loading =false;
      console.log('Requete :', res);
      this.loading = false;
      this.toastr.success('Mise à jour réussie')
     this.modalService.dismissAll();
    },error => {
      this.toastr.error('Une erreur est survenue')
      console.log('add item error',error.error['hydra:description'])
      this.loading = false;
    })
  }

  closeAndReset() {
    this.modalService.dismissAll();
    this.itemModel= {}
  }
}
