import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Modules } from 'src/app/modules/auth';
import { AuthService } from 'src/app/modules/auth';
import { Endpoint } from 'src/app/models/Endpoint';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  itemModel = new Modules();
  roles : any = [] ;
  loading :Boolean;
  itemId : any = null
  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthService
    ) { }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.itemId = params['id'];
      console.log("The item id ", this.itemId)
    });
    if(this.itemId) this.getItem();
    
  }
  getItem(){
    this.loading = true;
    this.authService.get(this.itemId, Endpoint.MODULE).subscribe((res:any)=>{
      console.log('the item we get ', res)
      this.itemModel = res;
      //this.itemModel.profiles = res?.profiles[0].id;
      this.loading = false;
    },error => {
      console.log('error oneuser',error)
      this.loading = false;
      this.itemId = null;
    })
  }


}
