<div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
        <!--begin::Row-->
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-2 g-5 g-xl-9">
            <!--begin::Col-->
            <div class="col-md-4" *ngFor="let module of modules| paginate: { itemsPerPage: pageSize, currentPage: page ,totalItems:totals }">
                <!--begin::Card-->
                <div class="card card-flush h-md-100 ">

                    <!--begin::Card header-->
                    <div class="card-header">
                        <!--begin::Card title-->
                        <div class="w-100 d-flex flex-row py-2" style="justify-content:space-between !important;">

                        <div class="card-title  align-self-center text-truncate" style="width: 75%;">

                            <h2  class="text-gray-800 text-hover-primary mb-1" routerLink="/modules-actions/form">{{module?.libelle}}</h2>
                        </div>


                        <!--end::Card title-->

                    </div>
                </div>
                    <!--end::Card header-->
                    <!--begin::Card body-->
                    <div class="card-body pt-1">
                        <!--begin::Users-->
                        <div class="fw-bolder text-gray-600 mb-5">Nombre d'actions: ({{module?.actions?.length}}) </div>
                        <!--end::Users-->
                        <!--begin::Permissions-->
                        <div style="width:100%; height: 300px; overflow-y:scroll; padding-right: 8px !important;">
                            <div class="d-flex flex-column text-gray-600"    *ngFor="let action of module?.actions  let i = index;"  >
                                <div class="w-100 d-flex flex-row py-2" style="justify-content:space-between !important;">
                                    <div class="align-self-center text-truncate" id="{{action.id}}"style="width: 75%;">
                                        <span class="bullet bg-primary me-3"></span>{{action?.libelle}}.
                                    </div>
                                    <div class="align-self-center">

                                        <!--Update Button-->
                                        <a  class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" (click)="openModal(updateAction,action)">
                                            <!--begin::Svg Icon | path: icons/duotune/art/art005.svg-->
                                            <span class="svg-icon svg-icon-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor" />
                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor" />
                                            </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                            </a>
                                        <!--Delete Button-->
                                        <a href="#" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg-->
                                            <span class="svg-icon svg-icon-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                                        fill="currentColor"></path>
                                                    <path opacity="0.5"
                                                        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                        fill="currentColor"></path>
                                                    <path opacity="0.5"
                                                        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                    </div>
                               </div>
                            </div>
                        </div>

                        <!--end::Permissions-->
                    </div>
                    <!--end::Card body-->
                    <!--begin::Card footer-->
                    <div class="card-footer flex-wrap pt-0">
                        <a href="../../demo1/dist/apps/user-management/roles/view.html" class="btn btn-light btn-active-primary btn btn-rounded my-1 me-2">Nouvelle action</a>
                        <button type="button" class="btn btn-light btn-active-warning btn btn-rounded my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">Modifier un module</button>
                    </div>
                    <!--end::Card footer-->
                </div>
                <!--end::Card-->
            </div>
            <!--end::Col-->
            <!--begin::Col-->

            <!--end::Col-->
            <!--begin::Add new card-->
            <div class="ol-md-4">
                <!--begin::Card-->
                <div class="card h-md-100">
                    <!--begin::Card body-->
                    <div class="card-body d-flex flex-center">
                        <!--begin::Button-->
                        <button type="button" class="btn btn-clear d-flex flex-column flex-center" data-bs-toggle="modal" data-bs-target="#kt_modal_add_role">
                            <!--begin::Illustration-->
                            <img src="assets/media/illustrations/sketchy-1/4.png" alt="" class="mw-100 mh-150px mb-7" />
                            <!--end::Illustration-->
                            <!--begin::Label-->
                            <div class="fw-bolder fs-3 text-gray-600 text-hover-primary">Ajouter un module</div>
                            <!--end::Label-->
                        </button>
                        <!--begin::Button-->
                    </div>
                    <!--begin::Card body-->
                </div>
                <!--begin::Card-->
            </div>
            <!--begin::Add new card-->

        </div>
        <!--end::Row-->

        <div class="row mt-5 mb-5">
            <div
                class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
            </div>
            <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                <pagination-controls previousLabel="" nextLabel="" class=" atbd-pagination__link page-number" (pageChange)="paginate($event)"></pagination-controls>
            </div>
          </div>
    </div>

    <!--end::Container-->
</div>
<ng-template #updateAction>
  <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
    <!--begin::Form-->
    <form id="kt_modal_update_email_form" class="form" (ngSubmit)="updateAc()">
      <!--begin::Notice-->
      <!--begin::Notice-->
      <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
        <!--begin::Icon-->
        <!--begin::Svg Icon | path: icons/duotune/general/gen044.svg-->
        <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                            <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor" />
                            <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor" />
                        </svg>
                    </span>
        <!--end::Svg Icon-->
        <!--end::Icon-->
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack flex-grow-1">
          <!--begin::Content-->
          <div class="fw-bold">
            <div class="fs-6 text-gray-700">Modification Action</div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Notice-->
      <!--end::Notice-->
      <!--begin::Input group-->
      <div class="fv-row mb-7">
        <!--begin::Label-->
        <label class="fs-6 fw-bold form-label mb-2">
          <span class="required">Action</span>
        </label>
        <!--end::Label-->
        <!--begin::Input-->
       <input [(ngModel)]="itemModel.libelle" name="libelle" class="form-control m-input" placeholder="Action" >
        <!--end::Input-->
      </div>
      <div class="fv-row mb-7">
        <!--begin::Label-->
        <label class="fs-6 fw-bold form-label mb-2">
          <span class="required">Module</span>
        </label>
        <!--end::Label-->
        <!--begin::Input-->
        <ng-select [items]="modules"
                   bindLabel="libelle"
                   bindValue="uri"
                   [ngModelOptions]="{standalone: true}"
                   [(ngModel)]="itemModel.module">
        </ng-select>
        <!--end::Input-->
      </div>
      <!--end::Input group-->
      <!--begin::Actions-->
      <div class="text-center pt-15">
        <button (click)="closeAndReset()" type="reset" class="btn btn-light me-3 btn btn-rounded" data-kt-users-modal-action="cancel" translate="BUTTONS.CANCEL_BUTTON"></button>
        <button clic [disabled]="loading" type="submit" class="btn btn-primary btn btn-rounded" data-kt-users-modal-action="submit">
          <span class="indicator-label" translate="BUTTONS.VALIDATE_BUTTON"></span>

        </button>
      </div>

      <!--end::Actions-->
    </form>
    <!--end::Form-->
  </div>
</ng-template>
