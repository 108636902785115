<div class="card mb-5 mb-xl-10">
    <div *ngIf="!itemId"  class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <!--begin::Card title-->
        <div class="card-title m-0">
            <h3 class="fw-bolder m-0" translate="GENERAL.ADDUSER"></h3>
        </div>
        <!--end::Card title-->
    </div>
    <div *ngIf="itemId>0"  class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <!--begin::Card title-->
        <div class="card-title m-0">
            <h3 class="fw-bolder m-0" translate="GENERAL.UPDATEUSER"></h3>
        </div>
        <!--end::Card title-->
    </div>
    <!--begin::Card header-->
    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <!--begin::Card title-->
        <div class="card-title m-0">
            <h3 class="fw-bolder m-0" translate="ENTITY.ACTIONS_TITLE.ADD"></h3>
        </div>
        <!--end::Card title-->
    </div>
    <!--begin::Card header-->
    <!--begin::Content-->
    <div id="kt_account_settings_profile_details" class="collapse show">
        <!--begin::Form-->
        <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" (ngSubmit)="saveItem()">
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">Logo</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8">
                        <!--begin::Image input-->
                        <div class="image-input image-input-outline" data-kt-image-input="true"
                            style="background-image: url('assets/media/svg/avatars/blank.svg')">
                            <!--begin::Preview existing avatar-->
                            <div class="image-input-wrapper w-125px h-125px"
                            [ngStyle]="{'background-image': 'url(' + (itemModel?.source || 'assets/media/avatars/blank2.png')  + ')'}"></div>
                            <!--end::Preview existing avatar-->
                            <!--begin::Label-->
                            <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="change" data-bs-toggle="tooltip" title=""
                                data-bs-original-title="Change avatar">
                                <i class="bi bi-pencil-fill fs-7"></i>
                                <!--begin::Inputs-->
                                <input type="file" name="logo" accept=".png, .jpg, .jpeg" (input)="fileChangeListener($event)">
                                <input type="hidden" name="logo_remove">
                                <!--end::Inputs-->
                            </label>
                            <!--end::Label-->
                            <!--begin::Cancel-->
                            <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title=""
                                data-bs-original-title="Cancel avatar">
                                <i class="bi bi-x fs-2"></i>
                            </span>
                            <!--end::Cancel-->
                            <!--begin::Remove-->
                            <span (click)="resetImage()" class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="remove" data-bs-toggle="tooltip" title=""
                                data-bs-original-title="Remove avatar">
                                <i class="bi bi-x fs-2"></i>
                            </span>
                            <!--end::Remove-->
                        </div>
                        <!--end::Image input-->
                        <!--begin::Hint-->
                        <div class="form-text" translate="ENTITY.FORM_ENTITY.FILE_TYPE">
                            <span> : png, jpg, jpeg.</span>
                        </div>
                        <!--end::Hint-->
                    </div>
                    <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="ENTITY.FORM_ENTITY.SOCIAL_REASON"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                <input type="text" name="raisonSociale" placeholder="{{ 'ENTITY.FORM_ENTITY.SOCIAL_REASON' | translate }}"
                                    class="form-control form-control-lg form-control-solid form-control-rounded mb-3 mb-lg-0"
                                    [(ngModel)]="itemModel.raisonSociale">
                                <div class="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                    <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="ENTITY.FORM_ENTITY.COMMERCIAL_REGISTER"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                        <input [(ngModel)]="itemModel.rc" type="text" name="rc" class="form-control form-control-lg form-control-solid form-control-rounded"
                            placeholder="{{ 'ENTITY.FORM_ENTITY.COMMERCIAL_REGISTER' | translate }}">
                        <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                        <span class="required">Ninea</span>
                    </label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                        <input [(ngModel)]="itemModel.ninea" type="text" name="ninea" class="form-control form-control-lg form-control-solid form-control-rounded"
                            placeholder="Ninea">
                        <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6 required" translate="ENTITY.FORM_ENTITY.ACTIVITY_AREA"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                        <input [(ngModel)]="itemModel.secteurActivite" type="text" name="secteurActivite" class="form-control form-control-lg form-control-solid form-control-rounded"
                            placeholder="{{ 'ENTITY.FORM_ENTITY.ACTIVITY_AREA' | translate }}" value="">
                    </div>
                    <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                        <span class="required" translate="GENERAL.COUNTRY"></span>
                    </label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                        <select [(ngModel)]="itemModel.pays" name="pays" aria-label="Selectionner un pays" data-control="select2"
                            data-placeholder="Selectionner un pays..."
                            class="form-select form-select-solid form-select-rounded form-select-lg fw-bold select2-hidden-accessible"
                            data-select2-id="select2-data-10-men3" tabindex="-1" aria-hidden="true">
                            <option value="" data-select2-id="select2-data-12-qv2a">Selectionner un pays...</option>
                            <option *ngFor="let item of countries" value={{item.code}}>{{item.name}}</option>
                        </select>
                        <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6 required" translate="ENTITY.FORM_ENTITY.PHONE"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                        <input [(ngModel)]="itemModel.tel" type="tel" name="tel" class="form-control form-control-lg form-control-solid form-control-rounded"
                            placeholder="{{ 'ENTITY.FORM_ENTITY.PHONE' | translate }}" value="">
                    </div>
                    <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                    <div class="row mb-6">
                        <!--begin::Label-->
                        <label class="col-lg-4 col-form-label fw-bold fs-6 required" translate="ENTITY.FORM_ENTITY.ADDRESS"></label>
                        <!--end::Label-->
                        <!--begin::Col-->
                        <div class="col-lg-8 fv-row">
                            <input [(ngModel)]="itemModel.adresse" type="text" name="adresse" class="form-control form-control-lg form-control-solid form-control-rounded"
                                placeholder="{{ 'ENTITY.FORM_ENTITY.ADDRESS' | translate }}" value="">
                        </div>
                        <!--end::Col-->
                    </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6 required" translate="ENTITY.FORM_ENTITY.CURRENCY"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                        <select [(ngModel)]="itemModel.devise" name="devise" aria-label="Selectionner une devise" data-control="select2"
                            data-placeholder="Selectionner une devise..."
                            class="form-select form-select-solid form-select-rounded form-select-lg select2-hidden-accessible"
                            data-select2-id="select2-data-19-irgw" tabindex="-1" aria-hidden="true">
                            <option value="" data-select2-id="select2-data-21-m4x6">Selectionner une devise...</option>
                            <option *ngFor="let item of currency" value={{item.code}}>{{item.name}}</option>
                        </select>
                    </div>
                    <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6 required" translate="ENTITY.FORM_ENTITY.VAT_RATE"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                        <input [(ngModel)]="itemModel.tauxTva" type="text" name="tauxTva" class="form-control form-control-lg form-control-solid form-control-rounded"
                            placeholder="{{ 'ENTITY.FORM_ENTITY.VAT_RATE' | translate }}">
                    </div>
                    <!--end::Col-->
                </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6 required">Email</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                    <input [(ngModel)]="itemModel.email" type="email" name="email" class="form-control form-control-lg form-control-solid form-control-rounded"
                        placeholder="Email">
                </div>
                <!--end::Col-->
            </div>
        <!--end::Input group-->


        <!--begin::Input group-->
            <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6 required">Couleur</label>
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                    <input [(ngModel)]="itemModel.couleur" type="color" name="couleur" class="form-control form-control-lg form-control-solid form-control-rounded"
                        placeholder="Couleur" id="color">
                </div>
                <!--end::Col-->
            </div>
        <!--end::Input group-->

            </div>

            <div class="card card-flush border-top mt-9">
                <!--begin::Card header-->
                <div class="card-header">
                    <div class="card-title">
                        <h2>Contacts</h2>
                    </div>
                </div>
            </div>

            <div class="pl-9 py-9 card-body">
                <div id="kt_table_users_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive">
                        <table   class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_table_users">
                            <!--begin::Table head-->
                            <thead>
                                <!--begin::Table row-->
                                <tr class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                    <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_table_users" rowspan="1"
                                        colspan="1" aria-label="Logo"
                                        style="width: 120.562px;">Nom complet</th>
                                    <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_table_users" rowspan="1"
                                        colspan="1" aria-label="Pays: activate to sort column ascending"
                                        style="width: 139.328px;">Email</th>
                                    <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_table_users" rowspan="1"
                                        colspan="1" aria-label="Status : activate to sort column ascending"
                                        style="width: 139.328px;">Téléphone</th>
                                    <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_table_users" rowspan="1"
                                        colspan="1" aria-label="Date de création: activate to sort column ascending"
                                        style="width: 181.016px;">Fonction</th>
                                    <th class="text-end min-w-100px sorting_disabled" rowspan="1" colspan="1"
                                        aria-label="Actions" style="width: 130.688px;"></th>
                                </tr>
                                <!--end::Table row-->
                            </thead>
                            <!--end::Table head-->
                            <!--begin::Table body-->
                            <tbody class="text-gray-600 fw-bold" >
                                <!--begin::Table row-->
        
                                <tr class="" *ngFor="let item of contacts; let i =index;  ">
        
                                    <!--begin::User=-->
                                    <td>
                                        <!--begin:: Avatar -->
                                        <span>{{item?.nom}} {{item?.prenom}}</span>
                                        <!--end::Avatar-->
                                    </td>
                                    <!--end::User=-->
                                    <!--begin::Two step=-->
                                    <td>{{item?.email}}</td>
                                    <!--end::Two step=-->
                                    <!--begin::Last login=-->
                                    <td>
                                        {{item?.telephone}}
                                    </td>
                                    <!--end::Last login=-->
                                    <!--begin::Joined-->
                                    <td>{{item?.fonction}}</td>
                                    
                                    <!--Switch Button-->
                                    <td class="text-end">
        
                                        <!--Update Button-->
                                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" (click)="openModal(contactModal, item, i )">
                                            <!--begin::Svg Icon | path: icons/duotune/art/art005.svg-->
                                            <span class="svg-icon svg-icon-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <path opacity="0.3"
                                                        d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                        fill="currentColor"></path>
                                                    <path
                                                        d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
        
                                        <!--Delete Button-->
                                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" (click)="deleteContact(i)">
                                            <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg-->
                                            <span class="svg-icon svg-icon-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none">
                                                    <path
                                                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                                        fill="currentColor"></path>
                                                    <path opacity="0.5"
                                                        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                                        fill="currentColor"></path>
                                                    <path opacity="0.5"
                                                        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            <!--end::Svg Icon-->
                                        </a>
                                    </td>
                                    <!--end::Action=-->
                                </tr>
                                
                            </tbody>
                            <!--end::Table body-->
                        </table>
                    </div>
                </div>
    
                <!--begin::Form group-->
                <div class="form-group mt-5">
                    <button type="button" class="btn btn-sm btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_role" (click)="openModal(contactModal, null)">
                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr087.svg-->
                    <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" />
                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor" />
                        </svg>
                    </span>
                    <!--end::Svg Icon-->Ajouter un contact</button>
                </div>
                <!--end::Form group-->
            </div>

            
            
            <!--end::Card body-->
            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button routerLink="/entites/list" type="reset" class="btn btn-light btn-active-light-primary me-2 btn-rounded"translate="BUTTONS.CANCEL_BUTTON"></button>
                <button *ngIf="!itemId" type="submit" class="btn btn-primary btn-rounded" id="kt_account_profile_details_submit"translate="BUTTONS.SUBMIT_BUTTON"></button>
                <button *ngIf="itemId>0" type="submit" class="btn btn-warning btn-rounded" id="kt_account_profile_details_update"translate="BUTTONS.UPDATE_BUTTON"></button>
            </div>
            <!--end::Actions-->
        
       

            <ng-template #contactModal>
                <form (ngSubmit)="saveContact()">
                    <div class="modal-header ">
                        <h4 class="modal-title pull-left">Ajouter un contact</h4>
                        <button type="button" data-repeater-delete="" class="btn btn-sm btn-icon btn-light-secondary" (click)="close()">
                            <!--begin::Svg Icon | path: icons/duotune/arrows/arr088.svg-->
                            <span class="svg-icon svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" />
                                    <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </button>
                     </div>
                     <div class="modal-body py-lg-10 px-lg-10">
                            <!--begin::Form group-->
                            <div class="form-group">
                                <div class="d-flex flex-column gap-1">
                                        <!--begin::Input-->
                                        <div class="row mb-6">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 col-form-label required fw-bold fs-6">Nom complet</label>
                                            <!--end::Label-->
                                            <!--begin::Col-->
                                            <div class="col-lg-8">
                                                <!--begin::Row-->
                                                <div class="row">
                                                    <!--begin::Col-->
                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="prenom_contact" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 form-control-rounded" placeholder="Prénoms" required [(ngModel)]="contactModel.prenom">
                                                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    <!--end::Col-->
                                                    <!--begin::Col-->
                                                    <div class="col-lg-6 fv-row fv-plugins-icon-container">
                                                        <input type="text" name="nom_contact" class="form-control form-control-lg form-control-solid form-control-rounded" placeholder="Nom" required [(ngModel)]="contactModel.nom" >
                                                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                    <!--end::Col-->
                                                </div>
                                                <!--end::Row-->
                                            </div>
                                            <!--end::Col-->
                                        </div>
    
                                        <div class="row mb-6">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 col-form-label fw-bold fs-6 required">Email</label>
                                            <!--end::Label-->
                                            <!--begin::Col-->
                                            <div class="col-lg-8 fv-row">
                                                <input type="email" name="email_contact" class="form-control form-control-lg form-control-solid form-control-rounded"
                                                    placeholder="Email" [(ngModel)]="contactModel.email" required>
                                            </div>
                                            <!--end::Col-->
                                        </div>
    
                                        <div class="row mb-6">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 col-form-label fw-bold fs-6 required" translate="ENTITY.FORM_ENTITY.PHONE"></label>
                                            <!--end::Label-->
                                            <!--begin::Col-->
                                            <div class="col-lg-8 fv-row">
                                                <input type="tel" name="tel_contact" class="form-control form-control-lg form-control-solid form-control-rounded"
                                                    placeholder="{{ 'ENTITY.FORM_ENTITY.PHONE' | translate }}" [(ngModel)]="contactModel.tel" required>
                                            </div>
                                            <!--end::Col-->
                                        </div>
    
                                        <div class="row mb-6">
                                            <!--begin::Label-->
                                            <label class="col-lg-4 col-form-label fw-bold fs-6 required">Fonction</label>
                                            <!--end::Label-->
                                            <!--begin::Col-->
                                            <div class="col-lg-8 fv-row">
                                                <input type="text" name="fonction" class="form-control form-control-lg form-control-solid form-control-rounded"
                                                    placeholder="Fonction" [(ngModel)]="contactModel.fonction" required>
                                            </div>
                                            <!--end::Col-->
                                        </div>
    
                                        <!--end::Input-->
                                        
                                    
                                </div>
                            </div>
                            <!--end::Form group-->
                     </div>
                     <div class="modal-footer">
                        <button type="button" class="btn btn-light btn-active-light-primary me-2 btn-rounded" (click)="close()">Annuler</button>
                        <button type="submit" class="btn btn-primary btn-rounded">Ajouter</button>
                     </div>
                </form>
              
            </ng-template>
            
        </form>
        <!--end::Form-->
    </div>
    <!--end::Content-->
</div>


<!--begin::Modal - Add role-->

<!--end::Modal - Add role-->