<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
<div  class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div *ngIf="!itemId"  class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <!--begin::Card title-->
        <div class="card-title m-0">
            <h3 class="fw-bolder m-0" translate="GENERAL.NEW_STATION"></h3>
        </div>
        <!--end::Card title-->
    </div>
    <div *ngIf="itemId>0"  class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <!--begin::Card title-->
        <div class="card-title m-0">
            <h3 class="fw-bolder m-0" translate="GENERAL.UPDATE_STATION"></h3>
        </div>
        <!--end::Card title-->
    </div>
    <!--begin::Card header-->
    <!--begin::Content-->
    <div id="kt_account_settings_profile_details" class="collapse show">
        <!--begin::Form-->
        <form id="kt_account_profile_details_form row" class="form" (ngSubmit)="saveItem()">
            <!--begin::Card body-->
            <div class="card-body border-top p-9 row">
                <!--begin::Input group-->
                <div class="row mb-6 col-md-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label  required fw-bold fs-6" translate="AUTH.INPUT.NOM"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                        <input [(ngModel)]="itemModel.nom"  type="text" class="form-control form-control-lg form-control-rounded form-control-solid" id="nom"  name="nom" placeholder="nom" />
                    </div>
                    <!--end::Col-->
                </div>
              <div class="row mb-6 col-md-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="AUTH.INPUT.TELEPHONE"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <international-phone-number  [(ngModel)]="itemModel.tel" placeholder="Téléphone" class="mystyle" [ngModelOptions]="{standalone: true}" [defaultCountry]="entite.pays.toLowerCase()" [required]="true"></international-phone-number>
<!--                      <input [(ngModel)]="itemModel.tel"  type="text" class="form-control form-control-lg form-control-solid form-control-rounded" id="tel" name="tel" placeholder="Téléphone" />-->
                    </div>
                    <!--end::Col-->
                </div>
              <div class="row mb-6 col-md-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="AUTH.INPUT.SOCIETE"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <ng-select [(ngModel)]="itemModel.codeSociete"  [items]="listSocietes"
                                 [ngModelOptions]="{standalone: true}"
                                 bindLabel="nom"
                                 bindValue="code"
                                 >
                      </ng-select>
<!--                        <input [(ngModel)]="itemModel.codeSociete"  type="text" class="form-control form-control-lg form-control-solid form-control-rounded" id="Société" name="Société" placeholder="Société" />-->
                    </div>
                    <!--end::Col-->
                </div>
                <div class="row mb-6 col-md-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="AUTH.INPUT.REGIONS"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                      <ng-select [(ngModel)]="itemModel.region"  [items]="regions" (change)="onFormChanges('region')"
                                 [ngModelOptions]="{standalone: true}"
                                 bindLabel="nom"
                                 bindValue="id">
                      </ng-select>
                        <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <!--end::Col-->
                </div>
                <div class="row mb-6 col-md-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="AUTH.INPUT.DEPARTEMENT"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                      <ng-select class="form-control-rounded" [(ngModel)]="itemModel.departement"  [items]="departements" (change)="onFormChanges('departement')"
                                 [ngModelOptions]="{standalone: true}"
                                 bindLabel="nom"
                                 bindValue="id">
                      </ng-select>
                        <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <!--end::Col-->
                </div>
                <div class="row mb-6 col-md-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="AUTH.INPUT.COMMUNE"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                      <ng-select [(ngModel)]="itemModel.commune"  [items]="communes"
                                 [ngModelOptions]="{standalone: true}"
                                 bindLabel="nom"
                                 bindValue="id">
                      </ng-select>
                        <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <!--end::Col-->
                </div>
                <div class="row mb-6 col-md-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="AUTH.INPUT.ADRESSE"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                        <input [(ngModel)]="itemModel.adresse"  type="text" class="form-control form-control-rounded form-control-lg form-control-solid " id="adresse" placeholder=" Address" name="nom" placeholder="Adresse" />
                    </div>
                    <!--end::Col-->
                </div>
<!--                <div class="row mb-6 col-md-6">-->
<!--                    &lt;!&ndash;begin::Label&ndash;&gt;-->
<!--                    <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="AUTH.INPUT.SEUILSOLDE"></label>-->
<!--                    &lt;!&ndash;end::Label&ndash;&gt;-->
<!--                    &lt;!&ndash;begin::Col&ndash;&gt;-->
<!--                    <div class="col-lg-8 fv-row">-->
<!--                        <input [(ngModel)]="itemModel.seuilAlerte"  type="number" class="form-control form-control-lg form-control-solidform-control-rounded " id="solde" placeholder="Solde" name="solde" placeholder="solde" />-->
<!--                    </div>-->
<!--                    &lt;!&ndash;end::Col&ndash;&gt;-->
<!--                </div>-->
                <div class="row mb-6 col-md-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="AUTH.INPUT.NOMBRECAISSE"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                        <input [(ngModel)]="itemModel.nbCaisse"  type="number" class="form-control form-control-lg form-control-solid form-control-rounded " id="nombre_caisse" placeholder="NombreCaisse" name="nombre_caisse"/>
                    </div>
                    <!--end::Col-->
                </div>
                <div class="row col-md-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6"translate="GENERAL.HORAIRE"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                        <div class="row row-cols-lg-2 g-10">
                            <div class="col" data-kt-calendar="datepicker">
                                <div class="fv-row ">
                                    <!--begin::Label-->
                                    <label class="fs-6 fw-bold mb-2"translate="GENERAL.OUVERTURE"></label>
                                    <!--end::Label-->
                                    <!--begin::Input-->
                                    <input  [(ngModel)]="itemModel.heureOuverture" type="time"class="form-control form-control-rounded  form-control-solid " name="calendar_event_start_time" placeholder="Ouverture" id="kt_calendar_datepicker_end_time" />
                                    <!--end::Input-->
                                </div>
                            </div>
                            <div class="col" data-kt-calendar="datepicker">
                                <div class="fv-row ">
                                    <!--begin::Label-->
                                    <label class="fs-6 fw-bold mb-2"translate="GENERAL.FERMETURE"></label>
                                    <!--end::Label-->
                                    <!--begin::Input-->
                                    <input  [(ngModel)]="itemModel.heureFermeture" type="time" class="form-control form-control-solid form-control-rounded " name="calendar_event_start_time" placeholder="Fermeture" id="kt_calendar_datepicker_end_time" />
                                    <!--end::Input-->
                                </div>
                            </div>
                        </div>

                    </div>
                    <!--end::Col-->
                </div>
                <div class="row  col-md-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6" translate="GENERAL.ETAT"></label>
                    <!--begin::Label-->
                    <!--begin::Label-->
                    <div class="col-lg-8 d-flex align-items-center">
                        <div class="form-check form-check-solid form-switch fv-row">
                            <input  [(ngModel)]="itemModel.status" name="status" class="form-check-input w-45px h-30px form-control-rounded" type="checkbox" id="allowmarketing" checked="checked" />
                            <label class="form-check-label" for="allowmarketing"></label>
                        </div>
                    </div>
                    <!--begin::Label-->
                </div>
                <!--end::Input group-->
            </div>
            <!--end::Card body-->
            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button routerLink="/utilisateurs/list" type="reset" class="btn btn-light btn-active-light-primary me-2 btn-rounded"translate="BUTTONS.CANCEL_BUTTON"></button>
                <button *ngIf="!itemId" type="submit" class="btn btn-primary btn-rounded" id="kt_account_profile_details_submit"translate="BUTTONS.SUBMIT_BUTTON"></button>
                <button *ngIf="itemId>0" type="submit"  [disabled]="loading"  class="btn btn-warning btn-rounded" id="kt_account_profile_details_update"translate="BUTTONS.UPDATE_BUTTON"></button>
            </div>

            <!--end::Actions-->
        </form>
        <!--end::Form-->
    </div>
    <!--end::Content-->
</div>
</div>
