<div id="kt_toolbar_container" [ngClass]="toolbarContainerCssClasses" class="d-flex flex-stack" >
  <app-page-title #ktPageTitle class="page-title d-flex" [ngClass]="pageTitleCssClasses"></app-page-title>
  <!-- begin::Actions  -->
   <div class="d-flex align-items-center py-1">
    <div>
     <h3 class="font-weight-bold " *ngIf="!etat" translate="MENU.SOLDIER"> <span class="font-weight-bold text-success">{{solde | number  }} FCFA</span></h3>
    </div>
  </div>
  <!-- end::Actions  -->
</div>
