import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Endpoint } from 'src/app/models/Endpoint';
import { AuthService, UserModel } from 'src/app/modules/auth';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { ConfirmPinComponent } from '../../confirm-pin/confirm-pin.component';
import { Constantes } from 'src/app/shared/constantes';
import { GlobalService } from 'src/app/services/global.service';
import { Station } from 'src/app/models/Models';
import {PageInfoService} from "../../../_metronic/layout";

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  currentUser? : any;
  filterForm : any ;
  items:any;
  soldeEntite:any;
  userPassword:any={};
  itemModel = new Station();
  loading :Boolean;
  itemId : any = null;
  selectedRole: any;
  currentIndex = 1;
  show_eye1: Boolean = false;
  show_eye2: Boolean = false;
  show_eye3: Boolean = false;
  page = 1;
  query = '';
  roleselect:any=[]
  totals:number = 0;
  view_regenCodePin = false;
  view_caisse = false;
  titlePage:any=[

    {
      title: 'Station',
      path: '/station/list',
      isSeparator: true
    },
    {
      title: 'Station Details',
      path: '/station/list',
      isSeparator: false
    }
  ]
  constructor(private pageInfo:PageInfoService, private service:GlobalService, private activatedRoute: ActivatedRoute,private fb : FormBuilder,private router:Router,
    private authService: AuthService, public modalService :NgbModal, private toastr:ToastrService) { }
    ngOnInit(): void {
      this.activatedRoute.params.subscribe(params => {
        this.itemId = params['id'];
        console.log("The item id ", this.itemId)
      });
      if(this.itemId)
       this.getItem();
      this.pageInfo.updateTitle('Gas Station');
      this.pageInfo.updateBreadcrumbs(this.titlePage);

    }

    getItem(){
      this.loading = true;
      this.service.getReseau(this.itemId, Endpoint.STATION_DETAIL).subscribe((res:any)=>{
        console.log('detail sTAION ', res)
        this.itemModel = res;

        this.loading = false;
      },error => {
        console.log('error oneuser',error)
        this.loading = false;
        this.itemId = 0;
      })
    }


    saveItem() { //fonction d'enregistrer un user
      this.itemModel.codeEntite= "",
      console.log('item model here ',this.itemModel)
      this.loading = true;
      this.service.upsertReseau(this.itemId, Endpoint.STATIONS, this.itemModel)?.subscribe((res:any)=>{
        this.loading =false;
        console.log('save item :', res);
        this.itemModel = new Station()
        this.loading =false;
        this.toastr.success('Mise à jour réussie')
        setTimeout(() => {
          this.router.navigateByUrl('stations/list')
        }, 2500);

      },error => {
        this.toastr.error('Une erreur est survenue')
        console.log('save item error',error.error['hydra:description'])
        this.loading = false;
      })
    }

   nextIndex(index:number){
    this.currentIndex = index;
   }
















}
