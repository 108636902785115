export class Constantes {
  static HYDRA_TOTAL_ITEMS = 'hydra:totalItems';
  static HYDRA_MEMBER = 'hydra:member';
  static HYDRA_DESCRIPTION = 'hydra:description';
  static DEVISE = 'XOF';
  static SAVING_SUCCEED = 'Enregistré(e) avec succés!';
  static SAVING_FAILED = "Désolé il y'a un/des soucis lors de l'enregistrement";
  static DGC_JWT_TOKEN = 'guadmin_jwt_token';
  static DGC_CURRENT_USER = 'guadmin_current_user';
  static DGC_LOGIN_STATUS = 'guadmin_login_status';
  static DGC_CODE_CLIENT = 'BGDIGIT';
  static DGC_ROLE_ADMIN = 'ROLE_ADMIN';
  static DGC_PLATEFORME = 'admin';
  static CODE_PLATEFORME_RESEAUX = 'reseau';
  static CURRENT_CODE_ENTITY = 'dgc_current_code_entity';
  static SENT_SUCCESS = 'Envoyé(e) avec succés';
  static CREATE_FAIL = 'Echec lors de la création';
  static CURRENT_ENTITY = 'current_entity';
  static ROLESOCIETE = 'ROLE_Admin Société';
  static ROLEPOMPISTE = 'ROLE_Pompiste';
  static ROLEENTITE = 'ROLE_Admin Entité';
  static ROLECHEFSTATION = 'ROLE_Chef Station';
  static ROLECHEFSTATIOND = 'ROLE_Chef de Station';
  static ROLECAISSIER = 'ROLE_Caissier';
}
