import { CarteCarburant, UserModel } from "../modules/auth";

export class Entite{
    id?: number;
    codeEntite?: string;
    couleur?: string;
    raisonSociale?: string;
    tauxTva?: number;
    email?: string;
    tel?: string;
    adresse?: string;
    pays?: string;
    ninea?: string;
    rc?: string;
    secteurActivite?: string;
    devise?: string;
    seuilAlerte?: number;
    photo?: string;
    source?: string;
    fileType?: string;
    status?: true;
    contacts? : Contact [];
    dateCreation:Date
}

export class CAISSE{

    nom?: string
    email? : string
    tel?: string
    adresse: string
    pays: string
    ninea: string
    rc: string
    secteurActivite? : string
    devise: string
    logo: any
    status?: true;
    fileType: any

    id?: string
    code?: string
    numero?: number
    solde?: number
    soldeCash?: number
    longitude?: string
    disponibilite?: string
    latitude?: string
    seuilAlerte?: number


}

export class Service{
    id ? : any
     categorie ?: any
    partenaire ?: any
    entite_id:  number = 0
    nom: string
    tag: string = ''
    isTarificationFixe: boolean = true
    fraisBtoC: true
    description: string
    source: any
    fileType: any
    forMobile: true
    status: true
    paliers: any
    fraisFixe: any
    codeBanque: string
    prixVente : number
    dateCreation:Date
    codeService:String
    dateModification:Date
}
export class Contact{
    prenom : string
    nom : string
    email : string
    tel : string
    fonction : string
}

export class Categorie{
    id ? : any
    code: string;
    sigle: string;
    nom: string;
    description: string;
    source: string;
    fileType: string;
    niveau: number;
    status: boolean;
}

export class Membre{
    rc: string;
    ninea: string;
    adresse: string;
    typePiece: string;
    numPiece: string;
    numCarteArtisan: string;
    codeChambre: string;
    nomChambre: string;
    codeCategorie: string;
    nomCategorie: string;
    codeCorpsMetier: string;
    nomCorpsMetier: string;
    villageArtisanal: string;
    status: boolean;
    user? : any = new UserModel();

}

export class CategorieChambre{
    id ? : any;
    nom?: string;
    code?: string;
    description?: string;
    status?: boolean;
}

export class ChambreConsulaire{
    id ? : any;
    nom?: string;
    code?: string;
    description?: string;
    codeEntite?: string;
    nomEntite?: string;
    categorieChambre?: string;
    status?: boolean
}

export class CorpsMetier{
    nom: string
    code: string
    description: string
    artisanat: boolean
    agriculture: boolean
    commerce: boolean
}
export class Station
{
    code ?: string
    codeEntite?: string
    seuilAlerte?: 0
    nbCaisse?: 0
    longitude?: string
    latitude?: string
    nom?: string
    email?: string
    tel?: string
    commune?: any
    departement?: string
    region?: string
    adresse?: string
    codeSociete?: string
    heureOuverture?: string
    heureFermeture?: string
    isDistributeur?: true
    master?: true
    taux?: 0
    solde?:0
    etat?:string
    status?: any
    config?: {
      carteUsableByCaisse: 0
    }
    contacts?: [
      string
    ]
    parent?: any
  }
  export class Client
  {
    nom?: string
    prenom?: string
    email?: string
    telephone?: string
    nationalite?: string
    dateNaissance?: string
    numPiece?: string
    typePiece?: string
    dateDelivrance?: string
    dateExpiration?: string
    lieuDelivrance?: string
    adresse?: string
    source?: string;
    fileType?: string;
    status?: true;
    societe?:string;
    cartes?:any  | undefined;
  }
export class caissier
{
  id?: 0
reference?: string
montant?: 0
pin?: string
soldeAvant?: 0
soldeApres?: 0
frais?: 0
numTransaction?: string
operation?: string
codeCaisse?: string
commentaires?: string
status: 1
pending: true
carte?: {
  id: 0
  compagnie?: {
    id?: 0,
    isCompagnie?: true
    isAgregateur?: true
    nom?: string
    code?: string
    solde?: 0
    logo?: string
    ninea?:string
  },
  client?: {
    id?: 0,
    codeEntite?: string
    societe?: {
      id: 0,
      isCompagnie?: true
      isAgregateur?: true
      nom?: string
      code?: string
      solde?: 0
      logo?: string
    },
    nom?: string
    prenom?: string
    telephone?: string
    nationalite?: string
    dateNaissance?: string
    numPiece?: string
    typePiece?: string
    dateDelivrance?: string
    dateExpiration?: string
    lieuDelivrance?: string
    adresse?: string
    dateCreation?: '2022-08-24T11:04:57.060Z'
    dateModification?: '2022-08-24T11:04:57.060Z'
  },
  enrolement?: {
    societe?: {
      id?: 0
      isCompagnie?: true
      isAgregateur?: true
      nom?: string
      code?: string
      solde?: 0
      logo?: string
      ninea?:string
    },
    uid?: 0,
    auteur?: string
    uidValidation?: 0
    auteurValidation?: string
  },
  solde: 0
  reference?: string
  qrCode?: string
  etat?:string
  status?: true,
  boutique?: true,
  dateCreation?: "2022-08-24T11:04:57.060Z",
  dateModification?: "2022-08-24T11:04:57.060Z"
}
bon?: {
  id?: 0,
  compagnie?: {
    id?: 0,
    isCompagnie?: true
    isAgregateur?: true
    nom?: string
    code?: string
    solde?: 0
    logo?: string
  },
  montant?: 0
  reference?: string
  telephone?: string
  qrCode?: string
  etat?: string
  status?: true
}
dateModification: "2022-08-24T11:04:57.060Z"
auteur?: string
}

export class Transactions{
    cartes:CarteCarburant[];
    client:Client[];
}

export class Action
{
    id? : any = null
      libelle: string
      module: string
}
export class Module

{
  id? : any = null
  libelle: string
  actions?: any


  }
export class Permission
{
  id? : any = null
    action: string
    codeEntite?: string
    roless?: any

}
