
<div
  class="d-flex align-items-center"
  [ngClass]="toolbarButtonMarginClass"
  id="kt_header_user_menu_toggle">
  <!-- begin::Toggle -->
  <div
    class="cursor-pointer symbol symbol-circle"
    [ngClass]="toolbarUserAvatarHeightClass"
    data-kt-menu-trigger="click"
    data-kt-menu-attach="parent"
    data-kt-menu-placement="bottom-end"
    data-kt-menu-flip="bottom"
  >
    <img src="{{currentUser?.source  || 'assets/media/avatars/blank.png'}}" alt="logo" />
  </div>

  <!-- end::Toggle  -->
  <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px me-5 symbol-circle">
          <img alt="Logo" src="{{currentUser?.source  || 'assets/media/avatars/blank.png'}}" />
        </div>
        <!--end::Avatar-->
        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">{{currentUser?.nom}} {{currentUser?.prenom}}</div>
          <a href="#" class="fw-bold text-muted text-hover-primary fs-7">{{currentUser?.email}}</a>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->
    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->
    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a [routerLink]="['/utilisateurs/detail', currentUser?.id]" class="menu-link px-5"translate="MENU.MON_PROFIL"></a>
    </div>
    <!--end::Menu item-->
    <!--begin::Menu item-->
<!--    <div class="menu-item px-5">-->
<!--      <a  [routerLink]="['/utilisateurs/detail', currentUser?.id]"  class="menu-link px-5">Modifier Mon Mot De Passe</a>-->
<!--    </div>-->
    <!--end::Menu item-->
    <div class="separator my-2"></div>
    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a (click)="Deconnexion()" class="menu-link px-5 text-hover-danger"translate="MENU.DECONNEXION"></a>
    </div>
    <!--end::Menu item-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->
    <!--begin::Menu item-->
    <div class="menu-item px-5"  data-kt-menu-placement="left-start"data-kt-menu-trigger="hover">
      <a href="#" class="menu-link px-5" data-kt-menu-trigger="hover">
													<span class="menu-title position-relative"translate="MENU.LANG">
													<span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0"translate="ENGLISH">
													<img class="w-15px h-15px rounded-1 ms-2" src="assets/media/flags/united-states.svg" alt="" /></span></span>
      </a>
      <!--begin::Menu sub-->
      <div>
        <!--begin::Menu item-->
        <div class="menu-item px-3"(click)="changeLangue('en')">
          <a class="menu-link d-flex px-5 active">
														<span class="symbol symbol-20px me-4" translate="MENU.EN">
															<img class="rounded-1" src="assets/media/flags/united-states.svg" alt="" />
														</span></a>
        </div>
        <!--end::Menu item-->
        <!--begin::Menu item-->
       <!--<div class="menu-item px-3">
          <a href="../../demo1/dist/account/settings.html" class="menu-link d-flex px-5">
														<span class="symbol symbol-20px me-4">
															<img class="rounded-1" src="assets/media/flags/spain.svg" alt="" />
														</span>Spanish</a>
        </div>-->
        <!--end::Menu item-->
        <!--begin::Menu item-->
        <!--<div class="menu-item px-3">
        <a href="../../demo1/dist/account/settings.html" class="menu-link d-flex px-5">
                          <span class="symbol symbol-20px me-4">
                            <img class="rounded-1" src="assets/media/flags/germany.svg" alt="" />
                          </span>German</a>
      </div>
      <!--end::Menu item-->
        <!--begin::Menu item-->
        <!--<div class="menu-item px-3">
        <a href="../../demo1/dist/account/settings.html" class="menu-link d-flex px-5">
                          <span class="symbol symbol-20px me-4">
                            <img class="rounded-1" src="assets/media/flags/japan.svg" alt="" />
                          </span>Japanese</a>
      </div>
      <!--end::Menu item-->
        <!--begin::Menu item-->
        <div class="menu-item px-3"  (click)="changeLangue('fr')">
          <a  class="menu-link d-flex px-5"translate="MENU.FR">
														<span class="symbol symbol-20px me-4">
															<img class="rounded-1" src="assets/media/flags/france.svg" alt="" />
														</span></a>
        </div>
        <!--end::Menu item-->
      </div>
      <!--end::Menu sub-->
    </div>

  </div>

</div>
<!-- end::User -->

<ng-container *ngIf="headerLeft === 'menu'">
  <div
    class="d-flex align-items-center d-lg-none ms-2 me-n3"
    title="Show header menu"
  >
    <div
      class="
        btn btn-icon btn-active-light-primary
        w-30px
        h-30px
        w-md-40px
        h-md-40px
      "
      id="kt_header_menu_mobile_toggle"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/text/txt001.svg'"
        class="svg-icon svg-icon-1"
      ></span>
    </div>
  </div>


</ng-container>

