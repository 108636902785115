import {Injectable} from "@angular/core";
import { LocalStoreService } from "../services/local-store.service";
//import {Constantes} from "../constantes";
//import {environment} from "../../../environments/environment";
import { AuthService } from "src/app/modules/auth";
import { GlobalService } from "src/app/services/global.service";
import { environment } from "src/environments/environment";
import {UserPermissions} from "../user_permissions";

export class Permission{
  modulesCodes:String[] = [];
  actionsCodes :String[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class Guard {
  constructor(private service:GlobalService
    ) {}
    permissions:any =new Permission()
    pass(codeAction:string = ''){
      this.service.permissionsCodes.subscribe((permissions:any) => {
        this.permissions = permissions;
      })
      if(environment.allowAll)
        return true ;
      return this.permissions.codeAction?.includes(codeAction) ;
    }
    isCheck=false;
    passModules(module:string = ''){
      this.service.permissionsCodes.subscribe((permissions) => {
        this.permissions = permissions;
      });

      if(environment.allowAll)
        return true ;
      return this.permissions.codeModule?.includes(module) ;
    }
}
