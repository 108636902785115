
<div class="loaderPage" *ngIf="loading">
  <div class="lds-roller " >
    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
  </div>
</div>
<div class="card mb-5 mb-xl-10">
  <!--begin::Card header-->
  <div *ngIf="!itemId" class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
       data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
    <!--begin::Card title-->
    <div class="card-title m-0">
      <h3 class="fw-bolder m-0" translate="GENERAL.ADDUSER"></h3>
    </div>
    <!--end::Card title-->
  </div>
  <div *ngIf="itemId>0" class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
       data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
    <!--begin::Card title-->
    <div class="card-title m-0">
      <h3 class="fw-bolder m-0" translate="GENERAL.UPDATEUSER"></h3>
    </div>
    <!--end::Card title-->
  </div>
  <!--begin::Card header-->
  <!--begin::Content-->
  <div id="kt_account_settings_profile_details" class="collapse show">

    <!--begin::Form-->
    <form id="kt_account_profile_details_form" class="form" (ngSubmit)="saveItem()">
      <!--begin::Card body-->
      <div class="card-body border-top p-9">
        <!--begin::Input group-->
        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label fw-bold fs-6" translate="GENERAL.PHOTO"></label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8">
            <!--begin::Image input-->
            <div class="image-input image-input-outline" data-kt-image-input="true"
                 style="background-image: url('assets/media/svg/avatars/blank.svg')">
              <!--begin::Preview existing avatar-->
              <div class="image-input-wrapper w-125px h-125px"
                   [ngStyle]="{'background-image': 'url(' + (itemModel?.source || 'assets/media/avatars/blank2.png')  + ')'}"></div>
              <!--end::Preview existing avatar-->
              <!--begin::Label-->
              <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                     data-kt-image-input-action="change" data-bs-toggle="tooltip" title=""
                     data-bs-original-title="Change avatar">
                <i class="bi bi-pencil-fill fs-7"></i>
                <!--begin::Inputs-->
                <input type="file" name="avatar" accept=".png, .jpg, .jpeg" (input)="fileChangeListener($event)">
                <input type="hidden" name="avatar_remove">
                <!--end::Inputs-->
              </label>
              <!--end::Label-->
              <!--begin::Cancel-->
              <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title=""
                    data-bs-original-title="Cancel avatar">
                                <i class="bi bi-x fs-2"></i>
                            </span>
              <!--end::Cancel-->
              <!--begin::Remove-->
              <span (click)="resetImage()"
                    class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="remove" data-bs-toggle="tooltip" title=""
                    data-bs-original-title="Remove avatar">
                                <i class="bi bi-x fs-2"></i>
                            </span>

              <!--end::Remove-->
            </div>
            <!--end::Image input-->
            <!--begin::Hint-->
            <div class="form-text"translate="MENU.PHOTOS"></div>
            <!--end::Hint-->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="AUTH.INPUT.FULLNAME"></label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8">
            <!--begin::Row-->
            <div class="row">
              <!--begin::Col-->
              <div class="col-lg-6 fv-row">
                <input [(ngModel)]="itemModel.prenom" type="text" name="fname"
                       class="form-control form-control-lg form-control-solid  form-control-rounded mb-3 mb-lg-0"
                       placeholder="prénom"/>
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="col-lg-6 fv-row">
                <input [(ngModel)]="itemModel.nom" type="text" name="lname"
                       class="form-control form-control-lg form-control-solid form-control-rounded" placeholder="nom"/>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="AUTH.INPUT.EMAIL"></label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <input [(ngModel)]="itemModel.email" type="email"
                   class="form-control form-control-lg form-control-solid form-control-rounded" id="emailaddress"
                   placeholder="Addresse E-mail" name="emailaddress" />

          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->
        <!--begin::Input group-->
        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label fw-bold fs-6">
            <span class="required" translate="GENERAL.NUM"></span>
            <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip"
               title="Phone number must be active"></i>
          </label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <international-phone-number class="mystyle" [(ngModel)]="itemModel.tel" placeholder="Enter phone number"
                                        [maxlength]="20" [defaultCountry]="entite.pays.toLowerCase()" [required]="true"
                                        name="telephone"></international-phone-number>
          </div>
          <!--end::Col-->
        </div>

        <div class="row mb-6" *ngIf="roles.length>0">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="GENERAL.ROLE"></label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8 fv-row fv-plugins-icon-container">
            <ng-select [items]="roles"  (ngModelChange)="onChangeProfife($event)"
                       bindLabel="nom"
                       bindValue="id"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="itemModel.profiles">
            </ng-select>
<!--            <select (ngModelChange)="onChangeProfife($event)" [(ngModel)]="itemModel.profiles" name="profiles"-->
<!--                    aria-label="Selectionner un role" data-control="select2"-->
<!--                    data-placeholder="Selectionner un role..."-->
<!--                    class="form-select form-select-solid form-select-rounded form-select-lg fw-bold select2-hidden-accessible"-->
<!--                    data-select2-id="select2-data-10-men3" tabindex="-1" aria-hidden="true">-->
<!--              <option value="" data-select2-id="select2-data-12-qv2a">Selectionner un role...</option>-->
<!--              <option *ngFor="let item of roles" value="{{item?.id}}">{{item?.nom}} </option>-->

<!--            </select>-->
            <div class="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <!--end::Col-->
        </div>
        <div class="row mb-6" *ngIf="view_societe">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="AUTH.INPUT.SOCIETE"></label>
          <!--label class="col-lg-4 col-form-label required fw-bold fs-6" *ngIf="this.entite.codeEntite =='agridev'"translate="GENERAL.STATION"></label-->
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8 fv-row fv-plugins-icon-container" >
            <ng-select [items]="listSocietes" (ngModelChange)="getStationBySociete($event)"
                       bindLabel="nom"
                       bindValue="code"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="itemModel.codeSociete">
            </ng-select>
            <div class="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <!--end::Col-->
        </div>
        <div class="row mb-6" *ngIf="view_station">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="GENERAL.STATION"></label>
          <!--label class="col-lg-4 col-form-label required fw-bold fs-6" *ngIf="this.entite.codeEntite =='agridev'"translate="GENERAL.STATION"></label-->
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8 fv-row fv-plugins-icon-container"  >
            <ng-select [items]="stations" (ngModelChange)="getCaisseByStation($event)"
                       bindLabel="nom"
                       bindValue="uri"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="itemModel.agence">
            </ng-select>
            <div class="fv-plugins-message-container invalid-feedback"></div>
          </div>
          <!--end::Col-->
        </div>
          <div class="row mb-6" *ngIf="view_caisse">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6"translate="MENU.SELECTION_CAISSE"></label>
            <!--end::Label-->
            <!--begin::Col-->
            <div class="col-lg-8 fv-row fv-plugins-icon-container">
              <ng-select [items]="caisses"
                         bindLabel="code"
                         bindValue="code"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="itemModel.caisse">
              </ng-select>
              <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>
            <!--end::Col-->
          </div>

        <!-- <div class="row mb-0"> -->
        <!--begin::Label-->
        <!-- <label class="col-lg-4 col-form-label fw-bold fs-6" translate="GENERAL.STATUS"></label> -->
        <!--begin::Label-->
        <!--begin::Label-->
        <!-- <div class="col-lg-8 d-flex align-items-center">
            <div class="form-check form-check-solid form-switch fv-row">
                <input  [(ngModel)]="itemModel.status" name="status" class="form-check-input w-45px h-30px" type="checkbox" id="allowmarketing" checked="checked" />
                <label class="form-check-label" for="allowmarketing"></label>
            </div>
        </div> -->
        <!--begin::Label-->
        <!-- </div> -->
        <!--end::Input group-->
      </div>
      <!--end::Card body-->
      <!--begin::Actions-->
      <div class="card-footer d-flex justify-content-end py-6 px-9">
        <button routerLink="/utilisateurs/list" type="reset" [disabled]="loading"
                class="btn btn-light btn-active-light-primary me-2 btn-rounded"
                translate="BUTTONS.CANCEL_BUTTON"></button>
        <button *ngIf="!itemId"  [disabled]="loading"  type="submit" class="btn btn-primary btn-rounded" id="kt_account_profile_details_submit"
                translate="BUTTONS.SUBMIT_BUTTON"></button>
        <button *ngIf="itemId>0" [disabled]="loading"   type="submit" class="btn btn-warning btn-rounded"
                id="kt_account_profile_details_update" translate="BUTTONS.UPDATE_BUTTON"></button>
      </div>
      <!--end::Actions-->
    </form>


    <!--end::Form-->
  </div>
  <!--end::Content-->
</div>
