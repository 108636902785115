import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Endpoint } from 'src/app/models/Endpoint';
import { AuthService, UserModel } from 'src/app/modules/auth';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { ConfirmPinComponent } from '../../confirm-pin/confirm-pin.component';
import { Constantes } from 'src/app/shared/constantes';
import { GlobalService } from 'src/app/services/global.service';
import { Guard } from 'src/app/shared/utils/guard';
import { UserPermissions } from 'src/app/shared/user_permissions';
import { PageInfoService } from 'src/app/_metronic/layout';
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})

export class DetailComponent implements OnInit {
  currentUser? : any;
  filterForm : any ;
  items:any;
  soldeEntite:any;
  userPassword:any={};
  itemModel = new UserModel();
  loading :Boolean;
  itemId : any = null;
  selectedRole: any;
  currentIndex = 1;
  show_eye1: Boolean = false;
  show_eye2: Boolean = false;
  show_eye3: Boolean = false;
  page = 1;
  query = '';
  roleselect:any=[]
  totals:number = 0;
  view_regenCodePin = false;
  view_caisse = false;

  public users=UserPermissions.MANAGE_Default
  titlePage:any=[

    {
      title: 'Utilisateurs',
      path: '/utilisateurs/formulaire',
      isSeparator: true
   },
    {
      title: 'Détails Utilisateurs',
      path: '/utilisateurs/formulaire',
      isSeparator: false
    }
  ]
  constructor( private service:GlobalService, private activatedRoute: ActivatedRoute,private fb : FormBuilder,private router:Router,public guard:Guard,
    private authService: AuthService, public modalService :NgbModal, private toastr:ToastrService, private pageInfo:PageInfoService) { }
    ngOnInit(): void {
      this.activatedRoute.params.subscribe(params => {
        this.itemId = params['id'];
        console.log("The item id ", this.itemId)
      });
      if(this.itemId)
       this.getItem();
       this.pageInfo.updateTitle('Administrator');
       this.pageInfo.updateBreadcrumbs(this.titlePage);


    }




    getItem(){
      this.loading = true;
      this.authService.get(this.itemId, Endpoint.USERS).subscribe((res:any)=>{
        console.log('the item we get ', res)
        this.itemModel = res;
        if(res.codeSociete)
         this.getSocieteBycode(res.codeSociete)
        console.log('select',res.profiles.some((item:any) => item.libelle.toLowerCase() === Constantes.ROLEPOMPISTE.toLowerCase()))
        if( res.profiles.some((item:any) => item.libelle.toLowerCase() ===Constantes.ROLEPOMPISTE.toLowerCase() || item.libelle.toLowerCase() ===Constantes.ROLECAISSIER.toLowerCase()) ){
         this.view_regenCodePin = true;
        }
       else {
         this.view_regenCodePin = false;
        }
        this.loading = false;
      },error => {
        console.log('error oneuser',error)
        this.loading = false;
        this.itemId = 0;
      })
    }




   nextIndex(index:number){
    this.currentIndex = index;
   }


  openModal(content:any)
  {
    this.modalService.open(content,{size:'md'})
  }

  changeEmail(item : any){
    this.authService.upsert(item?.id, Endpoint.USERS, { email: item?.email })?.subscribe({
      next: (res : any) => {
        item.email = res?.email;
        this.toastr.success("Adresse email modifiée avec succés ")
        this.modalService.dismissAll()
        console.log("Change email ",res);
      },
      error: err => {
        console.log('Change email ',err)
        this.toastr.error("Une erreur est survenue ")
        this.loading =false;
      }
    })
  }
  showPassword(pos : any) {
    if(pos == 1) this.show_eye1 = !this.show_eye1;
    if(pos == 2) this.show_eye2 = !this.show_eye2;
    if(pos == 3) this.show_eye3 = !this.show_eye3;
  }


  updatePassword() {
    this.modalService.dismissAll()
    this.loading = true;
    this.authService.updatePassword(Endpoint.PWD_CHANGE,this.userPassword).subscribe((res:any)=>{
      console.log("Update password ", res)
      this.loading = false;
      this.toastr.success('Mot de passe modifié avec succès.')
      setTimeout(() => {
        this.authService.logout()
      }, 2250);

    },error => {
      this.loading = false;
      console.log("error => ", error)
      this.toastr.error(error.error.message)
    })
  }

  closeAndReset() {
    this.userPassword.password=''
    this.modalService.dismissAll()
  }

  handleChange(evt: any, item:any) {
    console.log("checked event ", evt)
    console.log("given item  ", item)
    if (evt.target.checked == true) this.selectedRole = item;
  }

  regenererPasword(){
    const dialogRef = this.modalService.open(ConfirmPinComponent, {centered: true, size:"lg"});
    dialogRef.componentInstance.data = {
      message: "Confirmez la regénération du mot de passe.",
      title: "Regénération du mot de passe"
    };
    dialogRef.result
      .then((res) => {
        if(res.confirmed) {
          this.loading = true;

          this.authService.generatePassword(this.itemId)
          .subscribe(res => {
            this.loading = false;
            this.toastr.success("Nouveau mot de passe envoyé à l'adresse email suivante "+this.itemModel.email);
          }, err => {
            this.loading = false;
            this.toastr.error(err[Constantes.HYDRA_DESCRIPTION])
          })
        }
        if (!res) {
          return;
        }
      })
  }
  regenererPin(){
    const dialogRef = this.modalService.open(ConfirmPinComponent, {centered: true, size:"lg"});
    dialogRef.componentInstance.data = {
      message: "Confirmez la regénération code pin.",
      title: "Regénération du code pin"
    };
    dialogRef.result
      .then((res) => {
        if(res.confirmed) {
          this.loading = true;
          this.authService.setUserPin(this.itemId)
          .subscribe(res => {
            this.loading = false;
            this.toastr.success("Nouveau code pin envoyé à l'adresse email suivante "+this.itemModel.email);
          }, err => {
            this.loading = false;
            this.toastr.error(err[Constantes.HYDRA_DESCRIPTION])
          })
        }
        if (!res) {
          return;
        }
      })




  }
  getSocieteBycode(code=''){
    let query = `&code=${code}`;
    let data:any
    this.service.getListeStation(Endpoint.LISTESOCIETE, 1, query).subscribe((res:any)=>{
      this.itemModel.societe = res['hydra:member'][0].nom

    },error => {
      console.log('error',error)
    })
  }



}
