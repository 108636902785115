import { Component, OnInit } from '@angular/core';
import { Endpoint } from 'src/app/models/Endpoint';
import { UserModel } from 'src/app/modules/auth';
import { AuthService } from 'src/app/modules/auth';
import { ActivatedRoute, } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Constantes} from "../../../shared/constantes";
import { PageInfoService } from 'src/app/_metronic/layout';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  itemModel = new UserModel();
  roles : any = [] ;
  items : any = [] ;
  stations:any =[];
  soldeEntite: any = [] ;
  stationsSearch:any =[];
  caisses:any =[];
  caissesSearch:any =[];
  listSocietes:any =[];
  loading :Boolean;
  itemId : any = null;
 view_societe = false;
 view_station = false;
 view_caisse = false;
  entite:any;
  currentUser:UserModel;
  roleselect:any=[]
  stationselect:any=[]
  titlePage:any=[

    {
      title: 'Utilistateurs',
      path: '/utilisateurs/formulaire',
      isSeparator: true
   },
    {
      title: 'Nouveau utilisateur',
      path: '/utilisateurs/formulaire',
      isSeparator: false
    }
  ]
  constructor(private service :GlobalService,private pageInfo:PageInfoService,
    private router:Router,
     private activatedRoute:ActivatedRoute,
    private authService: AuthService,
    private toastr: ToastrService,
    ) { }

 async ngOnInit() {

    this.pageInfo.updateTitle('Admistrateur');
    this.pageInfo.updateBreadcrumbs(this.titlePage);
    await this.getRoles();
    await this.getSocietes();

    await this.getStation();
    this.entite =  JSON.parse(<string>localStorage.getItem('entity'));
    this.currentUser =  JSON.parse(<string>localStorage.getItem('userInfo'));
    console.log('current user',this.currentUser)
    this.activatedRoute.params.subscribe(params => {
      this.itemId = params['id'];
    })
    if(this.itemId) this.getItem();
  }
  getItem(){
    this.loading = true;
    this.authService.get(this.itemId, Endpoint.USERS).subscribe((res:any)=>{
      console.log('the item we get ', res)
      this.itemModel = res;
      this.itemModel.tel ='+221 '+res.tel;
      //this.stationselect =  this.stationsSearch.find((value:any) =>  value.uri === res.agence)
      this.itemModel.agence = res.agence
      this.itemModel.profiles = res?.profiles[0].id;
      this.loading = false;
      this.onChangeProfife(this.itemModel.profiles)
    },error => {
      console.log('error oneuser',error)
      this.loading = false;
      this.itemId = null;
    })
  }
  saveItem() { //fonction d'enregistrer un user
    let tel = this.itemModel?.tel;
    this.itemModel.agence = this.stationselect.code;
    console.log('item model here ',this.itemModel)
    this.loading = true;
    this.itemModel.codeClient = this.currentUser.codeClient
    this.itemModel.plateformes=['carburant'];
    this.itemModel.status = 1;
    this.itemModel.nomAgence = this.stationselect.nom;
    this.itemModel.profiles = this.roleselect.uri
    this.itemModel.tel = tel?.toString().substring(4)
    delete this.itemModel.enrolement;
    console.log('item model after ',this.itemModel)
    this.authService.upsert(this.itemId, Endpoint.USERS, this.itemModel)?.subscribe((res:any)=>{
      this.loading =false;
      console.log('Requete :', res);
      this.itemModel = new UserModel()
      this.loading =false;
      this.itemModel.tel=tel
      this.toastr.success('Mise à jour réussie')
      this.router.navigateByUrl('utilisateurs/list')
    },error => {
      this.toastr.error('Une erreur est survenue')
      console.log('add item error',error.error['hydra:description'])
      this.loading = false;
      this.itemModel.tel = tel;

    })
  }

fileChangeListener($event:any) {
    let file = $event.target.files[0];
    this.itemModel.fileType = file.type;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.itemModel.source  = reader.result as string;
      this.itemModel.photo  = reader.result as string;
    }
  }
  resetImage(){
    this.itemModel.source  = null
    this.itemModel.fileType  = null
  }

  async getRoles(){
    let query = ``;
    this.authService.getAll(Endpoint.ROLES_CARBURANT, 1, query).subscribe((res:any)=>{
      console.log('roles ',res);
      this.roles = res['hydra:member'];
      this.roles.map((i:any) => { i.uri = ['/api/roles/' + i.id]; return i; });
      if(this.currentUser.codeSociete){
        const rolesFiltres = this.roles.filter((role:any) => role.libelle === "ROLE_Chef de Station" || role.libelle === "ROLE_Caissier" || role.libelle === "ROLE_Pompiste");
         this.roles = rolesFiltres
      }
    },error => {
      console.log('error',error)
    })
  }
  getStation(){
    let query = ``;
    this.service.getAllReseau(Endpoint.STATIONS, 1, query).subscribe((res:any)=>{
    res['hydra:member'].map((i:any) => { i.uri = '/api/point_de_ventes/' + i.id; return i; });
      this.stations = res['hydra:member'];
      this.stationsSearch = res['hydra:member'];

    },error => {
      console.log('error',error)
    })
  }


  onChangeProfife(e:any) {
    this.itemModel.codeClient=''
     this.roleselect  = this.roles.find((role: { id: any; })=>role.id === e)

    console.log('role select', this.roleselect)
    if( this.roleselect.libelle === Constantes.ROLESOCIETE){
      this.view_societe = true;
      this.view_station = false;
      this.view_caisse = false;
      this.itemModel.agence = ''
      this.itemModel.caisse = ''
    }
  else  if( this.roleselect.libelle === Constantes.ROLECAISSIER ||  this.roleselect.libelle === Constantes.ROLEPOMPISTE || this.roleselect.libelle ==='ROLE_CAISSIER'){
      this.view_societe = true;
      this.view_station = true;
      this.view_caisse = true;
    }
   else if( this.roleselect.libelle === Constantes.ROLECHEFSTATION || this.roleselect.libelle === Constantes.ROLECHEFSTATIOND ){
      this.view_societe = true;
      this.view_station = true;
      this.view_caisse = false;
      this.itemModel.caisse = ''

    }
   else if( this.roleselect.libelle === Constantes.ROLEENTITE ){
      this.view_societe = false;
      this.view_station = false;
      this.view_caisse = false;
      this.itemModel.caisse = ''
      this.itemModel.agence = ''
      this.itemModel.codeSociete = ''

    }
    else {
      this.view_societe = true;
      this.view_station = false;
      this.view_caisse = false;
      this.itemModel.caisse = ''
      this.itemModel.agence = ''
      this.itemModel.codeSociete = ''

    }
  }
  getSocietes(){
    this.service.getListeStation(Endpoint.LISTESOCIETE).subscribe((res:any)=>{
      this.listSocietes = res['hydra:member'];
      if(this.currentUser.codeSociete){
        const listSocietesFiltres = this.listSocietes.filter((item:any) => item.code === this.currentUser.codeSociete);
        this.listSocietes = listSocietesFiltres
      }
    },error => {
      console.log('error',error)
    })
  }

 async getStationBySociete(e:any) {
    this.itemModel.agence =  ''
    this.stations = [];
    let afterVerif: any[]=[];
      this.stationsSearch.find((value:any, index:number) => {

      if (value?.codeSociete === e) {
        afterVerif.push(value)
      }
        this.stations = afterVerif;
    }
    );

  }
  getCaisseByStation(e:any) {
   this.stationselect =  this.stationsSearch.find((value:any) =>  value.uri === e)
    let query = `pointDeVente.code=${this.stationselect.code}`;
    this.service.getCaisseNonAff(Endpoint.CAISSENOTAFFECTES, query).subscribe((res:any)=>{
      this.caisses = res['hydra:member'];
      this.caissesSearch = res['hydra:member'];
    },error => {
      console.log('error',error)
    })
    // this.itemModel.caisse =  ''
    // this.caisses = [];
    // let afterVerif: any[]=[];
    // this.caissesSearch.find((value:any, index:number) => {
    //     console.log('value cccc',e)
    //     if (value?.pointDeVente.id === e) {
    //       afterVerif.push(value)
    //     }
    //     this.caisses = afterVerif;
    //   }
    // );
  }
}
