
<div class="card mb-5 mb-xl-10">
    <div class="loaderPage" *ngIf="loading">
        <div class="lds-roller " >
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
    <!--begin::Card header-->
    <div *ngIf="!itemId" class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <!--begin::Card title-->
        <div class="card-title m-0">
            <h3 class="fw-bolder m-0" translate="ENTITY.ACTIONS_TITLE.ADDROLE"></h3>
        </div>
        <!--end::Card title-->
    </div>
    <div *ngIf="itemId>0" class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
    data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
    <!--begin::Card title-->
    <div class="card-title m-0">
        <h3 class="fw-bolder m-0" translate="ENTITY.ACTIONS_TITLE.UPDATEROLE"></h3>
    </div>
    <!--end::Card title-->
</div>
    <!--begin::Card header-->
    <!--begin::Content-->
    <div id="kt_account_settings_profile_details" class="collapse show">
        <!--begin::Form-->
            <form id="kt_account_profile_details_form" class="form">
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
                <!--begin::Input group-->

                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6" translate="GENERAL.ROLE"></label>
                    <!--end::Label-->
                    <!--begin::Col-->
                            <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                <input [(ngModel)]="itemModel.nom" type="text" name="role" placeholder="Role"
                                    class="form-control form-control-lg form-control-solid form-control-rounded mb-3 mb-lg-0"
                                    value="" >
                                <div class="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                    <!--end::Col-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->

                <div class="row mb-0">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold required fw-bold   fs-6"translate="GENERAL.STATUS"></label>
                    <!--begin::Label-->
                    <!--begin::Label-->
                    <div class="col-lg-8 d-flex align-items-center">
                        <div class="form-check form-check-solid form-switch fv-row">
                            <input  [(ngModel)]="itemModel.status" name="status" class="form-check-input w-45px h-30px" type="checkbox" id="allowmarketing" checked="checked" />
                            <label class="form-check-label" for="allowmarketing"></label>
                        </div>
                    </div>
                    <!--begin::Label-->
                </div>
<!--                <div class="row mt-2 mb-6">-->
<!--                    &lt;!&ndash;begin::Label&ndash;&gt;-->
<!--                    <label class="col-lg-4 col-form-label fw-bold fs-6 required">Couleur</label>-->
<!--                    &lt;!&ndash;end::Label&ndash;&gt;-->
<!--                    &lt;!&ndash;begin::Col&ndash;&gt;-->
<!--                    <div class="col-lg-8 fv-row">-->
<!--                        <input [(ngModel)]="itemModel.couleur" type="color" name="couleur" class="form-control form-control-lg form-control-solid form-control-rounded"-->
<!--                            placeholder="Couleur" id="color">-->
<!--                    </div>-->
<!--                    &lt;!&ndash;end::Col&ndash;&gt;-->
<!--                </div>-->

        <!--end::Input group-->

            </div>
            <!--end::Card body-->
            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button routerLink="/roles/list" type="reset" class="btn btn-light btn-active-light-primary me-2 btn-rounded" translate="BUTTONS.CANCEL_BUTTON"></button>
                <button *ngIf="!itemId" type="button" (click)="saveItem()" class="btn btn-primary btn-rounded" id="kt_account_profile_details_submit"><span translate="BUTTONS.SUBMIT_BUTTON"></span></button>
                <button  type="button" (click)="updsteItem()" [disabled]="loading" *ngIf="itemId>0" class="btn btn-warning btn-rounded" translate="MENU.UPDATE"></button>
            </div>
            <!--end::Actions-->
            <input type="hidden">
            <div></div>
        </form>
        <!--end::Form-->
    </div>
    <!--end::Content-->
</div>

