import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/auth/services/auth.guard';
import { ListComponent as entiteList } from './pages/entites/list/list.component';
import { FormComponent as entiteForm } from './pages/entites/form/form.component';
import { DetailComponent as entiteDetail } from './pages/entites/detail/detail.component';
import { ListComponent as utilisateurList } from './pages/utilisateurs/list/list.component';
import { FormComponent as utilisateurForm } from './pages/utilisateurs/form/form.component';
import { DetailComponent as utilisateurDetail } from './pages/utilisateurs/detail/detail.component';
import { ListComponent as roleList } from './pages/roles/list/list.component';
import { FormComponent as roleForm } from './pages/roles/form/form.component';
import { DetailComponent as roleDetail } from './pages/roles/detail/detail.component';
import { ListComponent as permissionList } from './pages/permissions/list/list.component';
import { FormComponent as permissionForm } from './pages/permissions/form/form.component';
import { ModulesActionsComponent  } from './pages/parametres/modules-actions/modules-actions.component';
import { AffichageComponent } from './pages/parametres/affichage/affichage.component';
import { LayoutComponent } from './_metronic/layout/layout.component';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ListComponent as messagesList} from './pages/messages/list/list.component';
import { ComposeComponent } from './pages/messages/compose/compose.component';
import { ReplyComponent } from './pages/messages/reply/reply.component';

import { ListComponent as caisseList } from "./pages/caisse/list/list.component";

import { ListComponent as stationList } from './pages/stations/list/list.component';
import { FormComponent as stationForm } from './pages/stations/form/form.component';
import {CarteCarburantModule} from "./pages/carte-carburant/carte-carburant.module";
import { MessagesComponent } from './pages/parametres/messages/messages.component';
import { DashboardsComponent } from './pages/dashboards/dashboards.component';
import {UserWaitComponent} from "./pages/utilisateurs/user-wait/user-wait.component";
import { ListComponent as listTransaction } from './pages/Transaction/list/list.component';
import { DetailComponent as detailTransaction } from './pages/Transaction/detail/detail.component';
import { DetailComponent as stationDetail } from './pages/stations/detail/detail.component';
import {ListCommissionApproComponent} from "./pages/parametres/list-commission-appro/list-commission-appro.component";
import {ReportingSocieteComponent} from "./pages/Transaction/reporting-societe/reporting-societe.component";
import {DasboardSocieteComponent} from "./pages/dasboard-societe/dasboard-societe.component";


export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'transaction/detail/:id',
        component: detailTransaction
      },
      {
        path: 'transaction/list',
        component: listTransaction
      },
      {
        path: 'transaction/report_societe',
        component: ReportingSocieteComponent
      },
       {
        path: 'caisse/list',
        component: caisseList
      },
      {
        path: 'caisse/list/',
        component: caisseList
      },
      {
        path: 't',
        component: DashboardComponent
      },
      {
        path: '',
        component: DashboardsComponent
      },
      {
        path: 'dashboard-societe',
        component: DasboardSocieteComponent
      },

      {
        path: 'entites/list',
        component: entiteList
      },
      {
        path: 'entites/form/:id',
        component: entiteForm
      },
      {
        path: 'entites/form',
        component: entiteForm
      },
      {
        path: 'entites/detail/:id',
        component: entiteDetail
      },
      {
        path: 'utilisateurs/list',
        component: utilisateurList
      },{
        path: 'utilisateurs/list_attente',
        component: UserWaitComponent
      },
      {
        path: 'utilisateurs/form',
        component: utilisateurForm
      },
      {
        path: 'utilisateurs/form/:id',
        component: utilisateurForm
      },

      {
        path: 'utilisateurs/detail/:id',
        component: utilisateurDetail,
      },
      {
        path: 'roles/list',
        component: roleList
      },
      {
        path: 'roles/form',
        component: roleForm
      },
      {
        path: 'roles/form/:id',
        component: roleForm
      },
      {
        path: 'roles/detail/:id',
        component: roleDetail
      },

      {
        path: 'permissions/list',
        component: permissionList
      },
      {
        path: 'permissions/form',
        component: permissionForm
      },

      {
        path: 'permissions/form/:id',
        component: permissionForm
      },
      {
        path: 'pages/parametres/modules-actions',
        component:ModulesActionsComponent
      },{
        path: 'parametres/commission',
        component:ListCommissionApproComponent
      },
      {
        path: 'stations/list',
        component: stationList
      },
      {
        path: 'stations/form',
        component: stationForm
      },
      {
        path: 'stations/form/:id',
        component: stationForm
      },
      {
        path: 'stations/detail/:id',
        component:stationDetail
      },

      {
        path: 'messages/list',
        component: messagesList,
      },
      {
        path: 'messages/compose',
        component: ComposeComponent,
      },
      {
        path: 'messages/reply',
        component: ReplyComponent,
      },
      {
        path: 'pages/parametres/mess',
        component: MessagesComponent,
      },




      ]
  },

  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'carburant',
    loadChildren: () =>
      import('./pages/carte-carburant/carte-carburant.module').then((m) => m.CarteCarburantModule),
  },

  {
    path: 'approvisionnements',
    loadChildren: () =>
      import('./pages/approvisonnements/approvisonnements.module').then((m) => m.ApprovisonnementsModule),
  },

  {
    path: 'societe',
    loadChildren: () =>
      import('./pages/societe/societe.module').then((m) => m.SocieteModule),
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./pages/clients/clients.module').then((m) => m.ClientsModule),
  },
  {
    path: 'caissiers',
    loadChildren: () =>
      import('./pages/caissier/caissier.module').then((m) => m.CaissierModule),
  },


  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },

  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
  },

  {
    path: 'bons',
    loadChildren: () =>
      import('./pages/bon/bon.module').then((m) => m.BonModule),
  },

  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
