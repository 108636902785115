<!-- begin::Header -->

<div class="card-header border-0 pt-5">
  
  <div *ngIf="loading" class="blockui rounded">
    <div style="align-self: center;">
          <img style="width: 50px !important;" src="./assets/spinner.gif" />
     </div>
  </div>

  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">Societes</span>
    <span class="text-muted mt-1 fw-bold fs-7">{{'+' + total}} societes</span>
  </h3>
  <div
    class="card-toolbar"
    data-bs-toggle="tooltip"
    data-bs-placement="top"
    data-bs-trigger="hover"
    title="Click to add a user"
  >
    <a routerLink="/membres/form" class="btn btn-sm btn-light-dark btn-rounded">
      <span
        [inlineSVG]="'./assets/media/icons/duotune/arrows/arr075.svg'"
        class="svg-icon svg-icon-3"
      ></span>
      Ajouter
    </a>
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body py-3">
  <!-- begin::Table container -->
  <div class="table-responsive" style="height: 385px; overflow-y:scroll">
    <!-- begin::Table -->
    <table
      class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
    >
      <!-- begin::Table head -->
      <thead>
        <tr class="fw-bolder text-muted">
          <th class="min-w-150px">Societes</th>
          <th class="min-w-140px">Ninea</th>
          <th class="min-w-120px">Status</th>
          <th class="min-w-100px text-end">Actions</th>
        </tr>
      </thead>
      <!-- end::Table head -->
      <!-- begin::Table body -->
      <tbody>
        <tr *ngFor="let item of items">
          <td>
            <div class="d-flex align-items-center">
              <div  class="image-type1 me-5 ">
                <img src={{item?.logo}} alt="" />
              </div>
              <div class="d-flex justify-content-start flex-column w-150px">
                <a  class="text-dark fw-bolder text-hover-primary fs-6 w-100 ellipsis-type0">
                  {{item?.nom}}
                </a>
                <span class="text-muted fw-bold text-muted d-block fs-7 w-100 ellipsis-type0">
                  {{item?.email}}
                </span>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-start flex-column w-200px"> 
                <a href="#" class="text-dark fw-bolder text-hover-primary d-block fs-6 w-100 ellipsis-type0" >
                  {{item?.ninea}}
                </a>
                <span class="text-muted fw-bold text-muted d-block fs-7 w-100 ellipsis-type0">
                  {{item?.telephone}}
                </span>
            </div>
          </td>
          <td class="">
              <span [ngClass]="item?.status ? 'badge badge-light-success': 'badge badge-light-danger'">{{item?.status ? 'Actif' : 'Inactif'}}</span>
          </td>
          <td>
            <div class="d-flex justify-content-end flex-shrink-0">
              <a
                (click)="changeStatus(item.id, item)"
                class="btn btn-icon btn-bg-light btn-active-color-dark btn-sm me-1"
              >
                <span
                  [inlineSVG]="'./assets/media/icons/duotune/general/gen019.svg'"
                  class="svg-icon svg-icon-3"
                >
                </span>
              </a>
              <a
               
                class="
                  btn btn-icon btn-bg-light btn-active-color-dark btn-sm
                  me-1
                "
              >
                <span
                  [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                  class="svg-icon svg-icon-3"
                ></span>
              </a>
             
            </div>
          </td>
        </tr>
       
      </tbody>
      <!-- end::Table body -->
    </table>
    <!-- end::Table -->
  </div>
  <!-- end::Table container -->
  <div  class="w-100 text-end mt-5">
    <span class="link-type1" routerLink="/membres/list">Voir+</span>
  </div>
</div>
<!-- begin::Body -->
