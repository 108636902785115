import {Component, OnInit} from '@angular/core';
import {Endpoint} from "../../../models/Endpoint";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GlobalService} from "../../../services/global.service";
import {ToastrService} from "ngx-toastr";
import {$e} from "@angular/compiler/src/chars";
import * as saveAs from "file-saver";
import {UserPermissions} from "../../../shared/user_permissions";
import {Guard} from "../../../shared/utils/guard";

@Component({
  selector: 'app-list-commission-appro',
  templateUrl: './list-commission-appro.component.html',
  styleUrls: ['./list-commission-appro.component.scss']
})
export class ListCommissionApproComponent implements OnInit {
  loading: boolean = false;
  totals: number = 0;
  societes: any;
  commissions: any;
  filterModel: any = {};
  itemModel: any = {};
  pageSize: number=10;
  page: number=1;
  query = '';
  public autority = UserPermissions.MANAGE_Default;
  constructor(public modalService: NgbModal, private service: GlobalService, private toastr:ToastrService, public guard: Guard) {
  }

  ngOnInit(): void {
    this.getList()
    this.getListSociete()
  }


  getListSociete() {
    this.loading = true
    this.service.getListeStation(Endpoint.LISTESOCIETE, 1, '').subscribe((res: any) => {
      this.societes = res['hydra:member'];

      this.societes.map((i:any) => { i.uri = '/api/societes/' + i.id; return i; });
      this.loading = false;
    }, error => {

      this.loading = false;
    })
  }

  paginate($event: any) {
    this.page = $event
    this.getList();
  }

  openModal(content: any) {
    this.modalService.open(content, {size: 'md'})
  }
  openModalpUdate(content: any,item:any={}) {
    this.itemModel = {
      societe: '/api/societes/' + item.societe.id,
      montant: item.montant,
      id: item.id
    }
    this.modalService.open(content, {size: 'md'})
    console.log('item', this.itemModel)
  }

  addCommission() {
    this.itemModel.parTaux = false;
    this.loading = true
    if(!this.itemModel.id){
      this.service.upsertStationSer(Endpoint.COMMISSION, this.itemModel).subscribe((res: any) => {
        this.loading = false;
        this.toastr.success( 'Commission ajoutée avec succés','Paramétrage')
        this.modalService.dismissAll()
        this.getList()
      }, error => {
        this.toastr.error(error.error['hydra:description'],'Paramétrage')
        this.loading = false;
      })
    }
    else {
      this.service.update(Endpoint.COMMISSION+'/'+this.itemModel.id, this.itemModel).subscribe((res: any) => {
        this.loading = false;
        this.toastr.success( 'Commission modifiée avec succés','Paramétrage')
        this.modalService.dismissAll()
        this.getList()
      }, error => {
        this.toastr.error(error.error['hydra:description'],'Paramétrage')
        this.loading = false;
      })
    }

  }
  close() {
    this.modalService.dismissAll();
  }
  private getList() {
    this.loading = true
    this.service.getListeStation(Endpoint.COMMISSION, 1, '').subscribe((res: any) => {
      this.commissions = res['hydra:member'];
      this.totals = res['hydra:totalItems']
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }
  filterItem(){
    if(this.filterModel.codeSociete)
      this.query +='&societe.id ='+this.filterModel.codeSociete
    this.getList();
  }
  export(type = '') {
    this.loading = true;
    if (type == 'pdf') {
      const fileName = 'commission_societés.pdf';
      this.service.exportStation(Endpoint.COMMISSION_pdf, this.query).subscribe((res:any) => {
        this.loading = false;
        saveAs(res.body, fileName);
      }, error => {
        this.loading = false;
      });
    } else {
      const fileName = 'commission_societés.csv';
      this.service.exportStation(Endpoint.COMMISSION_csv, this.query).subscribe((res:any) => {
        this.loading = false;
        saveAs(res.body, fileName);
      }, error => {
        this.loading = false;
      });
    }
    return;
  }
}
