<div class="loaderPage" *ngIf="loading">
  <div class="lds-roller " >
    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
  </div>
</div>
<div class="card card-body mb-5">
  <form class="row">
    <div  class="mb-10 col-md-4">
      <label  class="form-label fs-6 fw-bold">Date début:</label>
      <input type="date" id="dateD" placeholder="Date débu" name="dateD" [(ngModel)]="datedeb" class="form-control form-control-lg form-control-solid form-control-rounded ng-untouched ng-pristine ng-valid" >
    </div>
    <div  class="mb-10 col-md-4">
      <label  class="form-label fs-6 fw-bold">Date fin:</label>
      <input type="date" id="dateF" placeholder="Date fin" [(ngModel)]="datefin" name="dateF" class="form-control form-control-lg form-control-solid form-control-rounded ng-untouched ng-pristine ng-valid" >
    </div>
    <div class="col-md-2">
    <button class="btn btn-light-primary btn-sm filter" (click)="filterItems()">
      filtrer
    </button>
    </div>
    <!--<div  class="mb-10 col-md-4">
      <label  class="form-label fs-6 fw-bold" translate="GENERAL.STATION"></label>
      <ng-select [items]="stations"  (ngModelChange)="getListUser($event)"
                 bindLabel="nom"
                 bindValue="code"
                 [ngModelOptions]="{standalone: true}"
                 [(ngModel)]="itemModel.agence">
      </ng-select>
    </div>
    <div  class="mb-10 col-md-4">
      <label  class="form-label fs-6 fw-bold" translate="GENERAL.POMPISTE"></label>
      <ng-select [items]="users"
                 bindLabel="pompiste"
                 bindValue="id"
                 [ngModelOptions]="{standalone: true}"
                 [(ngModel)]="itemModel.userId">
      </ng-select>
    </div>-->
  </form>
</div>
<div class="content flex-column-fluid" id="kt_content">

  <!--begin::Row-->
  <div class="row g-5 g-xl-10 mb-xl-10">
    <div class="col-md-4">
      <div class="card  h-110px soldeVente">
        <!--begin::Body-->
        <div class="card-body d-flex flex-column justify-content-between">
          <span class="svg-icon svg-icon-2 svg-icon2"
                [inlineSVG]="'./assets/media/icons/duotune/finance/fin007.svg'" ></span>
          <div class="d-flex flex-column">
            <div class=" fw-bold fs-1 mb-0 mt-5">{{(montantVente | number)  || 0}} </div>
            <div class=" fw-semibold fs-6">Montant total des ventes</div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
    <div class="col-md-4">
      <a href="#" class="card  h-110px">
        <!--begin::Body-->
        <div class="card-body d-flex flex-column justify-content-between">
          <span class="svg-icon svg-icon-2"
                [inlineSVG]="'./assets/media/icons/duotune/finance/fin007.svg'" ></span>
          <div class="d-flex flex-column">
            <div class="text-dark fw-bold fs-1 mb-0 mt-5">{{totalStations | number}}</div>
            <div class="text-muted fw-semibold fs-6">Nombre de stations</div>
          </div>
        </div>
        <!--end::Body-->
      </a>
    </div>
    <div class="col-md-4">
      <a href="#" class="card bg-body h-110px">
        <!--begin::Body-->
        <div class="card-body d-flex flex-column justify-content-between">
           <span class="svg-icon svg-icon-2"
                 [inlineSVG]="'./assets/media/icons/duotune/finance/fin007.svg'" ></span>
          <div class="d-flex flex-column">
            <div class="text-dark fw-bold fs-1 mb-0 mt-5">{{totalPompiste |number}}</div>
            <div class="text-muted fw-semibold fs-6">Nombre de pompiste</div>
          </div>
        </div>
        <!--end::Body-->
      </a>
    </div>
  </div>
  <!--end::Row-->
  <!--begin::Row-->
  <div class="row g-5 g-xl-10 mb-xl-10">
    <div class="col-md-6">
      <div class="card card-flush ">
        <!--begin::Header-->
        <div class="card-header pt-5">
          <!--begin::Title-->
          <h3 class="card-title text-gray-800">Ventes par station XOF</h3>
          <!--end::Title-->
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="loaderPage" *ngIf="loading2">
          <div class="lds-roller " >
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
          </div>
        </div>
        <div class="card-body pt-5" *ngIf="!loading2">

          <div *ngIf="stationsStats.length>0">
            <div *ngFor="let one of stationsStats">

            <!--begin::Item-->
            <div class="d-flex flex-stack" >
              <!--begin::Section-->
              <div class="text-gray-700 fw-semibold fs-6 me-2">{{one.station}}</div>
              <!--end::Section-->
              <!--begin::Statistics-->
              <div class="d-flex align-items-senter">
                <span class="text-gray-900 fw-bolder fs-6">{{one .montantTotal | number}}</span>
              </div>
              <!--end::Statistics-->
            </div>
            <!--end::Item-->
            <!--begin::Separator-->
            <div class="separator separator-dashed my-3"></div>
            <!--end::Separator-->
          </div>
          </div>
          <div *ngIf="stationsStats.length<=0">
            <h1 class="styleTabVide mt-3" >  AUCUNE TRANSACTION</h1>

          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
    <div class="col-md-6">
      <div class="card card-flush ">
        <!--begin::Header-->
        <div class="card-header pt-5">
          <!--begin::Title-->
          <h3 class="card-title text-gray-800">Ventes par Pompiste XOF</h3>
          <!--end::Title-->
        </div>
        <!--end::Header-->
        <div class="loaderPage" *ngIf="loading2">
          <div class="lds-roller " >
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
          </div>
        </div>
        <!--begin::Body-->
        <div class="card-body pt-5" *ngIf="!loading2">
          <div *ngIf="pompistStats.length>0">
            <div *ngFor="let one of pompistStats">

              <!--begin::Item-->
              <div class="d-flex flex-stack" >
                <!--begin::Section-->
                <div class="text-gray-700 fw-semibold fs-6 me-2">{{one.pompiste}}</div>
                <!--end::Section-->
                <!--begin::Statistics-->
                <div class="d-flex align-items-senter">
                  <span class="text-gray-900 fw-bolder fs-6">{{one .montantTotal | number}}</span>
                </div>
                <!--end::Statistics-->
              </div>
              <!--end::Item-->
              <!--begin::Separator-->
              <div class="separator separator-dashed my-3"></div>
              <!--end::Separator-->
            </div>
          </div>
          <div *ngIf="pompistStats.length<=0">
            <h1 class="styleTabVide mt-3" >  AUCUNE TRANSACTION</h1>

          </div>



        </div>
        <!--end::Body-->
      </div>
    </div>

  </div>
  <!--end::Row-->
</div>
